import { useState, useMemo, useEffect, useRef } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import styles from './VectorResponseModal.module.scss';
import Button from '../../Button';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCurrentUser } from '../../../providers/UserProvider';
import { Text } from '../../typography';
import { getLocalTime } from '../../../utilities/time';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { Spinner, Form } from 'react-bootstrap';
import { downloadFile } from '../../../utilities/inspection';
import { keys } from '../../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import TextWithIcon from '../../typography/TextWithIcon';
import { customColors } from '../../../utilities';
import { useFlags } from 'launchdarkly-react-client-sdk';

const responsesQuery = loader('./VectorResponseModal.fetch.graphql');
const vectorSubscription = loader('./VectorResponseModal.subscription.graphql');
const deleteResponseMutation = loader('./VectorResponseModal.delete.graphql');
const addResponseMutation = loader(
  '../../../pages/dashboard/desktop/DashboardV2.vectorTrigger.graphql',
);
const rateResponseMutation = loader('./VectorResponseModal.rate.graphql');
const formatReferences = (references) => {
  const referenceMap = new Map();

  for (const { name, page, url } of references) {
    if (!isNaN(page)) {
      referenceMap.set(name, {
        pages: Array.from(
          new Set([...(referenceMap.get(name)?.pages ?? []), page]),
        ),
        url,
      });
    }
  }

  return [...referenceMap.entries()];
};

const ResponseCard = ({
  response,
  selected,
  onClick = () => {},
  onDelete = () => {},
  onThumbClick = () => {},
  setResponse = () => {},
}) => (
  <div
    className={
      selected?.id === response?.id
        ? classNames(styles.responseContainer, styles.selected)
        : styles.responseContainer
    }
    onClick={(e) => {
      e.stopPropagation();
      setResponse(response);
      onClick();
    }}
  >
    <div className={styles.responseQuery}>
      <Text noMargin weight="semiBold" color="white">
        {response?.query}
      </Text>
    </div>
    <div className={styles.sectionLine} />
    <Text size="sm" truncate color="white">
      {response?.message ?? 'None'}
    </Text>
    <div className={styles.cardFooter}>
      <div className={styles.cardIcons}>
        <Icon
          baseClassName={
            response?.badResponse ? 'material-icons' : 'material-icons-outlined'
          }
          sx={{ fontSize: '1rem' }}
          className={styles.badIcon}
          onClick={(e) => {
            e.stopPropagation();
            onThumbClick();
          }}
        >
          thumb_down
        </Icon>
        <Icon
          baseClassName="material-icons-outlined"
          sx={{ fontSize: '1.25rem' }}
          className={styles.deleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          delete
        </Icon>
      </div>
      <Text
        noMargin
        size="sm"
        weight="semiBold"
        color="secondaryLight"
        textAlign="right"
      >
        {getLocalTime(response?.dateCreated)?.format(
          'dddd MMM Do YYYY - h:mma',
        )}
      </Text>
    </div>
  </div>
);

const Response = ({ response, onClose }) => {
  const references = formatReferences(response?.references ?? []);
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.header}>
        {!!response && (
          <Text noMargin weight="semiBold" color="white" textAlign="right">
            {getLocalTime(response?.dateCreated).format(
              'dddd MMM Do YYYY - h:mma',
            )}
          </Text>
        )}
        <Icon
          sx={{ color: 'white', cursor: 'pointer' }}
          onClick={() => {
            onClose();
          }}
        >
          close
        </Icon>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.sectionLine} />
        <Text noMargin size="sm" color="secondaryLight">
          {t(keys.vectorResponse.QUERY)}
        </Text>
        <Text weight="semiBold" color="white" noMargin>
          {response?.query}
        </Text>
        <div className={styles.sectionLine} />

        <Text noMargin color="secondaryLight" size="sm">
          {t(keys.vectorResponse.RESPONSE)}
        </Text>
        <Text color="white" weight="semiBold" noMargin>
          {response?.message ?? 'None'}
        </Text>

        <div className={styles.sectionLine} />
        {!!!references?.length ? null : (
          <div className={styles.references}>
            <Text noMargin color="secondaryLight">
              {t(keys.vectorResponse.REFERENCES)}
            </Text>
            {references.map(([doc, { pages, url }], i) => (
              <div key={`response-${i}`} className={styles.link}>
                <Icon sx={{ color: customColors.BLUE, fontSize: '0.5rem' }}>
                  circle
                </Icon>
                <Text
                  noMargin
                  size="sm"
                  weight="semiBold"
                  color="accentPrimary"
                  className={styles.referenceLink}
                  onClick={() => downloadFile(url, doc)}
                >
                  {doc}
                </Text>
                <Text noMargin size="sm" weight="semiBold" color="secondary">
                  {t(keys.vectorResponse.PAGES)} {pages.toString()}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function VectorResponseModal({
  loadingSubscription,
  show,
  setShow = () => {},
}) {
  const { openConfirmationModal } = useModal();
  const { user } = useCurrentUser();
  const [selected, setSelected] = useState(null);
  const [response, setResponse] = useState(null);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const { enableVectorDatabase } = useFlags();
  const [query, setQuery] = useState(null);
  const { t } = useTranslation();
  const [state, setState] = useState({ search: '', loadingSubscription: '' });
  const [expandResponses, setExpandResponses] = useState(false);

  const onHide = () => {
    setShow(false);
    setSelected(null);
    setResponse(null);
    setExpandResponses(false);
    setIsSearching(false);
  };

  useEffect(() => {
    if (!show) onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const {
    refetch,
    loading: loadingResponses,
    data: { vectorResponses: responses = [] } = {},
  } = useQuery(responsesQuery, {
    skip: !user?.id || !enableVectorDatabase,
    variables: {
      options: {
        sort: [{ field: 'dateCreated', order: 'desc' }],
        pageSize: 25,
        page: 1,
      },
    },
  });

  useSubscription(vectorSubscription, {
    skip: !enableVectorDatabase,
    onSubscriptionData: ({
      subscriptionData: { data: { pineconeResponse } = {} },
    }) => {
      setResponse(pineconeResponse);
      setHasTimedOut(false);
      setState({ loadingSubscription: false });
      refetch();
    },
  });

  const [deleteResponse] = useMutation(deleteResponseMutation);
  const [addResponse] = useMutation(addResponseMutation);
  const [rateResponse] = useMutation(rateResponseMutation);
  const [minimize, setMinimize] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const filteredResponses = useMemo(() => {
    return responses?.length && !!state.search
      ? responses.filter(
          (u) =>
            u.message?.toLowerCase().includes(state.search?.toLowerCase()) ||
            u.query?.toLowerCase().includes(state.search?.toLowerCase()),
        )
      : responses || [];
  }, [responses, state.search]);

  const rightContent = useMemo(() => {
    if (selected) {
      return (
        <Response
          response={selected}
          onClose={() => {
            setSelected(null);
            setResponse(null);
          }}
        />
      );
    }
    if (!!state.loadingSubscription) {
      return (
        <>
          <Icon
            sx={{
              color: customColors.BLUE,
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
            textAlign="right"
            onClick={() => setMinimize(true)}
          >
            keyboard_arrow_right
          </Icon>
          <div className={styles.spinnerContainer}>
            <div className={styles.spinnerWrapper}>
              <Spinner
                className={styles.spinner}
                animation="border"
                variant="primary"
                style={{ width: '6rem', height: '6rem' }}
              />
              <Icon
                className={styles.spinnerIcon}
                baseClassName="material-icons-outlined"
                sx={{ fontSize: '4rem', color: customColors.BLUE }}
              >
                auto_stories
              </Icon>
            </div>
            <div className={styles.spacer} />
            <Text className={styles.text} color="white" textAlign="center">
              {hasTimedOut
                ? t(keys.vectorResponse.TIMEOUT_MESSAGE)
                : t(keys.vectorResponse.SEARCHING)}
            </Text>
          </div>
        </>
      );
    }
    if (response) {
      return (
        <Response
          response={response}
          setResponse={setResponse}
          onClose={() => {
            setResponse(null);
            setSelected(null);
          }}
        />
      );
    }

    return (
      <div className={styles.formContainer}>
        <div>
          <div className={styles.imgContainer}>
            <Icon
              className={styles.imgIcon}
              baseClassName="material-icons-outlined"
              sx={{ fontSize: '4rem' }}
            >
              auto_stories
            </Icon>
          </div>
          <Text textAlign="center" weight="semibold" color="white">
            {t(keys.vectorResponse.DESCRIPTOR_ONE)}
          </Text>
          <Text textAlign="center" color="secondaryLight" size="sm">
            {t(keys.vectorResponse.DESCRIPTOR_TWO)}
          </Text>
        </div>
        <Form.Control
          as={'textarea'}
          placeholder={t(keys.vectorResponse.FORM_PLACEHOLDER)}
          onChange={({ target: { value } }) => setQuery(value)}
        />
      </div>
    );
  }, [selected, state.loadingSubscription, response, t, hasTimedOut]);

  const buttonCheck = useMemo(
    () => !selected && !response && !loadingSubscription,
    [selected, response, loadingSubscription],
  );

  const timerRef = useRef(null);

  useEffect(() => {
    if (!state.loadingSubscription && response?.message) {
      clearTimeout(timerRef.current);
    }

    if (state.loadingSubscription && !response) {
      timerRef.current = setTimeout(() => {
        setHasTimedOut(true);
      }, 15000);
    }

    return () => clearTimeout(timerRef.current);
  }, [
    loadingSubscription,
    response,
    setResponse,
    query,
    state.loadingSubscription,
  ]);

  useEffect(() => {
    if (isSearching) {
      setExpandResponses(true);
    }
  }, [isSearching]);

  return show ? (
    <div className={styles.container}>
      {minimize ? (
        <div className={styles.minimize}>
          <Icon
            sx={{
              color: customColors.BLUE,
              cursor: 'pointer',
            }}
            textAlign="right"
            onClick={() => setMinimize(false)}
          >
            keyboard_arrow_left
          </Icon>
          {state.loadingSubscription ? (
            <Spinner
              variant="primary"
              animation="border"
              className={styles.spinnerSmall}
            />
          ) : (
            <Icon sx={{ color: customColors.GREEN_LIGHT }}>check</Icon>
          )}
        </div>
      ) : (
        <div className={minimize ? styles.minimize : styles.modalContainer}>
          <div className={styles.mainHeader}>
            <div className={styles.flex}>
              <TextWithIcon
                size="lg"
                color="white"
                icon="auto_fix_high"
                iconProps={{ color: 'white' }}
                noMargin
                className={styles.border}
              >
                The Hub
              </TextWithIcon>
              {!!response && !!state.loadingSubscription && (
                <Spinner
                  className={styles.spinnerSmall}
                  variant="primary"
                  animation="border"
                />
              )}
            </div>
            <Icon className={styles.whiteIcon} onClick={() => setShow(false)}>
              close
            </Icon>
          </div>

          <div className={expandResponses ? styles.hide : styles.right}>
            {rightContent}

            {!state.loadingSubscription && (
              <Button
                className={styles.button}
                align={'right'}
                size="sm"
                value={buttonCheck ? t(keys.action.SUBMIT) : t(keys.common.NEW)}
                icon={buttonCheck ? 'auto_awesome' : 'replay'}
                onClick={() => {
                  if (buttonCheck) {
                    addResponse({
                      variables: {
                        query,
                      },
                    });
                    setState({ loadingSubscription: true });
                  } else {
                    setSelected(null);
                    setResponse(null);
                  }
                }}
              />
            )}
          </div>
          <div className={expandResponses ? styles.full : styles.left}>
            <div className={styles.header}>
              <TextWithIcon
                weight="semiBold"
                noMargin
                icon="search"
                color="white"
                hover
                onClick={() => {
                  setIsSearching(!isSearching);
                }}
              >
                {t(keys.vectorResponse.SAVED_SEARCHES)}
              </TextWithIcon>
              <Icon
                onClick={() => {
                  setExpandResponses(!expandResponses);
                }}
                className={styles.whiteIcon}
              >
                {!expandResponses ? 'expand_less' : 'expand_more'}
              </Icon>
            </div>
            {isSearching ? (
              <div className={styles.searchBar}>
                <Form.Control
                  value={state.search || ''}
                  onChange={(e) =>
                    setState({ ...state, search: e.target.value })
                  }
                />
                <Icon
                  sx={{ color: 'white', fontSize: '1.5rem', cursor: 'pointer' }}
                  onClick={() => {
                    setState({ ...state, search: '' });
                    setIsSearching(false);
                  }}
                >
                  close
                </Icon>
              </div>
            ) : null}
            <div className={styles.sectionLine} />
            {responses?.length > 0 && (
              <div className={styles.responseList}>
                {loadingResponses ? (
                  <Spinner animation="border" className={styles.spinner} />
                ) : (
                  filteredResponses.map((response, i) => (
                    <ResponseCard
                      key={`response-card-${i}`}
                      response={response}
                      selected={selected}
                      onClick={() => {
                        setResponse(response);
                        setExpandResponses(false);
                        selected?.id === response?.id
                          ? setSelected(null)
                          : setSelected(response);
                      }}
                      onThumbClick={() =>
                        rateResponse({
                          variables: {
                            responseId: response?.id,
                            badResponse: !response?.badResponse,
                          },
                        })
                      }
                      onDelete={() =>
                        openConfirmationModal({
                          title: t(keys.action.DELETE_VARIABLE, {
                            variable: t(keys.vectorResponse.RESPONSE),
                          }),
                          description: t(keys.action.DELETE_CONFIRMATION, {
                            variable: t(keys.vectorResponse.RESPONSE),
                          }),
                          variant: 'danger',
                          buttonText: t(keys.action.DELETE),
                          onSubmit: () =>
                            deleteResponse({
                              variables: { responseId: response?.id },
                            }).then(() => refetch()),
                        })
                      }
                    />
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;
}

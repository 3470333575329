import { Icon } from '@mui/material';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { Text } from '../typography';
import styles from './ActionItemCard.module.scss';
import { getLocalTime } from '../../utilities/time';
import LabelsDisplay from '../LabelsDisplay';
import {
  priorityStringLookup,
  statusStringLookup,
} from './action_item_utilities';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useIsMobile } from '../../hooks/misc';
import SimpleUserCard from '../SimpleUserCard';

export default function ActionItemCard({
  actionItem,
  className,
  onClick,
  maxLabels = 2,
  disabled = false,
  boxShadow = true,
  showName = false,
}) {
  const mobile = useIsMobile();
  const { t } = useTranslation();
  const { labels, status, priority } = actionItem || {};
  const allLabels = [
    {
      color: statusStringLookup[status]?.color || 'primary',
      name: t(statusStringLookup[status]?.title) || status,
    },
    {
      color: priorityStringLookup[priority]?.color || 'primary',
      name:
        `${t(priorityStringLookup[priority]?.name)} ${t(
          keys.action_items.PRIORITY,
        )}` || status,
    },
    ...(labels || []),
  ];
  return (
    <div
      className={classNames(
        styles.container,
        boxShadow && styles.boxShadow,
        className,
        disabled ? styles.disabled : null,
      )}
      onClick={onClick}
    >
      <div>
        <div className={styles[actionItem?.status?.toLowerCase()]} />
      </div>

      <div className={styles.leftContainer}>
        <div className={styles.titleContainer}>
          <Text
            className={styles.titleText}
            noMargin
            size="sm"
            weight="semibold"
            truncate
          >
            {actionItem.title}
          </Text>
        </div>
        <Text
          className={styles.titleText}
          noMargin
          size="sm"
          weight="semibold"
          truncate
          color="secondary"
        >
          {actionItem.description}
        </Text>
        <LabelsDisplay
          labels={allLabels || []}
          maxLabels={mobile ? 2 : maxLabels}
        />
        <div className={styles.creationDate}>
          <Icon
            sx={{ fontSize: '1rem' }}
            baseClassName="material-icons-outlined"
            className={styles.timerIcon}
          >
            timer
          </Icon>
          <Text noMargin size="xs" color="secondary" weight="semibold">
            {getLocalTime(actionItem.dateCreated).fromNow()}
          </Text>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div>
          {actionItem?.assignee?.id &&
            (mobile || !showName ? (
              <img
                alt="user avatar"
                className={styles.avatar}
                src={actionItem.assignee.avatarUrl}
              />
            ) : (
              <SimpleUserCard
                mediumAvatar
                user={actionItem.assignee}
                size="sm"
              />
            ))}
        </div>
        <div>
          {actionItem?.dueDate && (
            <div
              className={
                getLocalTime(actionItem.dueDate).isBefore(new moment(Date()))
                  ? styles.lateDueDateButton
                  : styles.dueDateButton
              }
            >
              <Icon
                sx={{ fontSize: '1rem' }}
                baseClassName="material-icons-outlined"
                className={styles.dueDateIcon}
              >
                timer
              </Icon>
              <Text size="xxs" color="primaryLight" weight="semibold" noMargin>
                {getLocalTime(actionItem.dueDate).format('MMM Do')}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useState } from 'react';
import styles from './ReviewInput.module.scss';
import { Text } from '../../../components/typography';
import { Form } from 'react-bootstrap';
import { Button } from '../../../components';
import { reviewStatus } from '../../../constants/misc';

export default function ReviewInput({ onSubmit, isEditing }) {
  const [notes, setNotes] = useState('');

  const message = useMemo(() => {
    return notes.length
      ? 'Feel free to approve or request changes.'
      : 'Add your review to unlock the "Approve" and "Request Changes" options.';
  }, [notes]);

  return (
    <div className={styles.container}>
      <Text weight="bold" noMargin>
        {isEditing ? 'Edit' : 'Add'} Your Review
      </Text>
      <Form.Control
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Share your feedback here..."
        as="textarea"
        rows={2}
      />
      <div className={styles.footer}>
        <Text size="sm" weight="semiBold" color="secondary">
          {message}
        </Text>
        <div className={styles.buttonsContainer}>
          <Button
            icon="edit"
            outlined
            variant="danger"
            value="Request Changes"
            disabled={!notes.length}
            onClick={() => {
              onSubmit({ notes, status: reviewStatus.changeRequest });
            }}
          />
          <Button
            icon="check"
            variant="success"
            value="Approve"
            disabled={!notes.length}
            onClick={() => {
              onSubmit({ notes, status: reviewStatus.approved });
            }}
          />
        </div>
      </div>
    </div>
  );
}

import Modal from '../Modal';
import { useState, useMemo } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { loader } from 'graphql.macro';
import FileUploadInput from '../../files/FileUploadInput';
import { ProgressBar } from 'react-bootstrap';
import { Text } from '../../typography';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { CF_DOMAIN } from '../../../constants/aws';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useApolloClient } from '@apollo/client';
import { Icon } from '@mui/material';
import styles from './MultiFileUploadModal.module.scss';
import TextWithIcon from '../../typography/TextWithIcon';

const uploadUrlQuery = loader('../../../graphql/queries/s3.graphql');

export default function MultiFileUploadModal() {
  const client = useApolloClient();
  const { user: currentUser } = useCurrentUser();
  const { modalState, closeModal } = useModal();
  const [uploading, setUploading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [completedFiles, setCompletedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);

  const { show, accept, onSubmit, refetch } = modalState.multiFileUploadModal;

  const onHide = () => {
    refetch();
    setFiles([]);
    setUploading(false);
    setCompletedFiles([]);
    setComplete(false);
    setProgress(0);
    closeModal({ modalName: modals.multiFileUpload });
  };

  const handleInput = (e) => {
    if (e.target.files?.length > 0) {
      setFiles(Array.from(e.target.files));
    } else {
      setFiles([]);
    }
  };

  const handleUpload = async () => {
    if (complete) {
      onHide();
    }
    if (!files || files.length === 0) return;

    setUploading(true);
    setProgress(0);

    let completedFilesCount = 0;

    const uploadFile = async (file) => {
      try {
        const fileUUID = uuidv4();
        const fileParts = file.name.split('.');
        const fileName = fileUUID;
        const fileType = fileParts[fileParts.length - 1];

        const { data } = await client.query({
          query: uploadUrlQuery,
          variables: {
            fileName: `assets/${currentUser?.company?.id}/vector/${fileName}.${fileType}`,
            fileType,
          },
        });

        const signedUrl = data.simpleStorageUploadUrl;

        const options = {
          headers: {
            'Content-Type': fileType === 'pdf' ? 'application/pdf' : fileType,
          },
        };

        const result = await axios.put(signedUrl, file, options);

        if (result.status === 200) {
          onSubmit({
            url: `${CF_DOMAIN(currentUser)}vector/${fileName}.${fileType}`,
            size: file.size,
            extension: fileType,
            fileType: fileType,
            name: file.name,
            downloadAllowed: true,
            vector: true,
          });
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      } finally {
        completedFilesCount += 1;
        setProgress((completedFilesCount / files.length) * 100);
        setCompletedFiles((prev) => [...prev, file.name]);

        if (completedFilesCount === files.length) {
          setUploading(false);
          setComplete(true);
        }
      }
    };

    const uploadPromises = files.map((file) => uploadFile(file));
    await Promise.all(uploadPromises);
  };

  const submitDisabled = useMemo(
    () => !files?.length || uploading,
    [files?.length, uploading],
  );

  return (
    <Modal
      title={'Upload Files'}
      onClose={onHide}
      open={show}
      disableCloseOnSubmit
      submitDisabled={submitDisabled}
      onSubmit={handleUpload}
      submitText={!complete ? 'Upload' : 'Close'}
      hideCancel={complete}
    >
      {!files?.length ? (
        <FileUploadInput
          multiple={true}
          onChange={handleInput}
          accept={accept}
        />
      ) : (
        <div>
          <div className={styles.fileList}>
            {files?.map(({ name }, i) => (
              <div className={styles.fileName} key={`${name}-${i}`}>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={completedFiles.includes(name) ? styles.green : ''}
                >
                  {completedFiles.includes(name) ? 'check' : 'downloading'}
                </Icon>
                <Text noMargin weight="semiBold">
                  {name}
                </Text>
              </div>
            ))}
          </div>
          {uploading ? (
            <div>
              <Text noMargin>Uploading...</Text>
              <ProgressBar
                animated={progress !== 100}
                variant={'primary'}
                now={progress}
              />
            </div>
          ) : complete ? (
            <Text noMargin weight="semiBold">
              Complete!
            </Text>
          ) : (
            <TextWithIcon
              noMargin
              hover
              size="sm"
              icon="refresh"
              iconProps={{ fontSize: '1rem' }}
              textAlign="right"
              color="accentPrimary"
              weight="semiBold"
              onClick={() => setFiles([])}
            >
              Reset Files
            </TextWithIcon>
          )}
        </div>
      )}
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styles from './GraphDateSelectors.module.scss';
import { Text } from '../../../components/typography';
import { format, endOfMonth, startOfYear, endOfYear, subDays } from 'date-fns';
import { options } from '../../../utilities/analytics';

export default function GraphDateSelectors({
  setSubheader,
  setDates,
  reset = false,
  setReset,
  setDateType,
}) {
  const [rangeType, setRangeType] = useState(options[0]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [customRange, setCustomRange] = useState({ start: '', end: '' });

  const getDateString = (date) => format(date, 'yyyy-MM-dd');

  useEffect(() => {
    if (reset) {
      setRangeType(options[0]);
      setDateType('last30');
      setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (rangeType.value !== 'custom') {
      const updateFormattedDates = () => {
        let start, end, formattedDates;
        if (rangeType.value === 'all') {
          setCustomRange({ start: '', end: '' });
          setDates({
            start: '',
            end: '',
          });
          formattedDates = 'All Time';
        }

        const todayString = getDateString(new Date());

        if (rangeType.value === 'last30' || rangeType.value === 'last90') {
          const days = rangeType.value === 'last30' ? 30 : 90;
          const startDate = subDays(new Date(), days);
          start = getDateString(startDate);
          end = todayString;
          formattedDates = `${start} to ${end}`;
        } else if (rangeType.value === 'monthly') {
          start = getDateString(new Date(selectedYear, selectedMonth, 1));
          end = getDateString(
            endOfMonth(new Date(selectedYear, selectedMonth)),
          );
          formattedDates = `${start} - ${end}`;
        } else if (rangeType.value === 'yearly') {
          start = getDateString(startOfYear(new Date(selectedYear, 0, 1)));
          end = getDateString(endOfYear(new Date(selectedYear, 11, 31)));
          formattedDates = `01/01/${selectedYear} - 12/31/${selectedYear}`;
        }

        if (start && end) {
          setCustomRange({ start, end });
          setDates({
            start: `${start} 00:00:00`,
            end: `${end} 23:59:59`,
          });
          setSubheader(formattedDates);
        }
      };

      updateFormattedDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeType, selectedMonth, selectedYear]);

  const handleCustomChange = (field, value) => {
    const updatedCustomRange = { ...customRange, [field]: value };
    setCustomRange(updatedCustomRange);

    if (updatedCustomRange.start && updatedCustomRange.end) {
      const formattedDates = `${updatedCustomRange.start} - ${updatedCustomRange.end}`;
      setDates({
        start: `${updatedCustomRange.start} 00:00:00`,
        end: `${updatedCustomRange.end} 23:59:59`,
      });
      setSubheader(formattedDates);
    }
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const months = Array.from({ length: 12 }, (e, i) => ({
    value: i,
    label: new Date(0, i).toLocaleString('default', { month: 'long' }),
  })).filter((month) => {
    return selectedYear < currentYear || month.value <= currentMonth;
  });

  const years = Array.from({ length: 10 }, (e, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`,
  }));

  const handleRangeChange = (selectedOption) => {
    setDateType(selectedOption.value);
    setRangeType(selectedOption);
    if (selectedOption.value === 'custom') {
      setSubheader('Custom Range');
      setCustomRange({ start: '', end: '' });
    } else if (
      selectedOption.value === 'last30' ||
      selectedOption.value === 'last90'
    ) {
      const days = selectedOption.value === 'last30' ? 30 : 90;
      const today = new Date();
      const startDate = subDays(today, days);
      const start = getDateString(startDate);
      const end = getDateString(today);
      setCustomRange({ start, end });
    }
  };

  return (
    <div className={styles.container}>
      <Text noMargin weight="semiBold">
        Timeframe
      </Text>
      <Select
        value={rangeType}
        onChange={handleRangeChange}
        options={options}
      />
      {rangeType.value === 'monthly' && (
        <>
          <Text noMargin weight="semiBold">
            Month and Year
          </Text>
          <div className={styles.monthSelector}>
            <Select
              value={months.find((month) => month.value === selectedMonth)}
              onChange={(option) => setSelectedMonth(option.value)}
              options={months}
            />
            <Select
              value={years.find((year) => year.value === selectedYear)}
              onChange={(option) => setSelectedYear(option.value)}
              options={years}
            />
          </div>
        </>
      )}
      {rangeType.value === 'yearly' && (
        <Select
          value={years.find((year) => year.value === selectedYear)}
          onChange={(option) => setSelectedYear(option.value)}
          options={years}
        />
      )}
      {rangeType.value === 'custom' && (
        <>
          <Text noMargin weight="semiBold">
            Dates
          </Text>
          <div className={styles.dateSelectors}>
            <input
              type="date"
              max={customRange?.start || new Date().toISOString().slice(0, 10)}
              className={
                !customRange?.start && customRange.end
                  ? styles.highlight
                  : styles.date
              }
              value={customRange?.start}
              onChange={(e) => handleCustomChange('start', e.target.value)}
              disabled={rangeType.value !== 'custom'}
            />
            <input
              type="date"
              max={new Date().toISOString().slice(0, 10)}
              min={customRange?.start || undefined}
              className={
                customRange?.start && !customRange.end
                  ? styles.highlight
                  : styles.date
              }
              value={customRange?.end}
              onChange={(e) => handleCustomChange('end', e.target.value)}
              disabled={rangeType.value !== 'custom'}
            />
          </div>
        </>
      )}
    </div>
  );
}

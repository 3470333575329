import { Text } from '../../components/typography';
import React from 'react';
import TabSystem from '../../components/TabSystem';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import Button from '../../components/Button.jsx';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import styles from './Toolbox.module.scss';
import ToolboxLibrary from './ToolboxLibrary.jsx';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import ToolboxSchedule from './ToolboxSchedule.jsx';

const addToolboxTalkMutation = loader('./Toolbox.create.graphql');

const defaultTab = 'schedule';

export default function Toolbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const options = [
    {
      title: t(keys.toolbox.SCHEDULE),
      element: ToolboxSchedule,
      key: 'schedule',
    },
    {
      title: t(keys.toolbox.LIBRARY),
      element: ToolboxLibrary,
      key: 'library',
    },
  ];
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const [addToolboxTalk] = useMutation(addToolboxTalkMutation);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          {t(keys.toolbox.SAFETY_TOOLBOX)}
        </Text>

        <Button
          value={t(keys.common.NEW)}
          variant="primary"
          icon="add"
          size="md"
          onClick={() => {
            addToolboxTalk().then(
              ({
                data: {
                  addToolboxTalk: { id },
                },
              }) => navigate(`${id}`),
            );
          }}
        />
      </div>
      <TabSystem type={tab} options={options} />
    </div>
  );
}

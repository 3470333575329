import React, { useMemo, useState } from 'react';
import styles from './ReviewFooter.module.scss';
import { Button } from '../../../components';
import { reviewStatus } from '../../../constants/misc';
import { useReviewFooterHelpers } from './utils';
import ReviewInput from './ReviewInput';
import Review from './Review';
import { userRoles } from '../../../constants/strings';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Text } from '../../../components/typography';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc';

const updateReviewerMutation = loader('./ReviewFooter.update.graphql');
const updateInspectionMutation = loader(
  './ReviewFooter.readyForReview.graphql',
);

export default function ReviewFooter({
  allQuestionsAnswered,
  inspection,
  currentUser,
  onComplete,
  onSecondSight,
  publishInspection,
}) {
  const isAdmin = currentUser.role === userRoles.admin;
  const isOnline = useOnlineStatus();

  const [updateReviewer] = useMutation(updateReviewerMutation);
  const [updateInspection] = useMutation(updateInspectionMutation);

  const [editingReview, setEditingReview] = useState(false);
  const { reviewers, participants, inspectionType } = inspection;
  const { isApproved, isParticipant, isReviewer, hasChangeRequest } =
    useReviewFooterHelpers({
      reviewers,
      currentUser,
      participants,
      inspectionType,
    });

  const myReview = useMemo(() => {
    return reviewers.find((r) => r.user.id === currentUser.id);
  }, [currentUser.id, reviewers]);

  const showReadyForReviewButton = useMemo(() => {
    if (
      isOnline &&
      inspection.template.reviewRequired &&
      inspection.isDraft &&
      !inspection.readyForReview
    ) {
      return true;
    }
    return false;
  }, [
    inspection.isDraft,
    inspection.readyForReview,
    inspection.template.reviewRequired,
    isOnline,
  ]);

  const showPublishButton = useMemo(() => {
    if (
      isOnline &&
      inspection.template.reviewRequired &&
      isParticipant &&
      inspection.readyForReview &&
      inspection.isDraft
    ) {
      return true;
    }
    return false;
  }, [
    inspection.isDraft,
    inspection.readyForReview,
    inspection.template.reviewRequired,
    isOnline,
    isParticipant,
  ]);

  const showOriginalCompleteButton = useMemo(() => {
    if (
      !inspection.template.reviewRequired &&
      inspection.isDraft &&
      (isParticipant || isAdmin)
    ) {
      return true;
    }
    return false;
  }, [
    inspection.isDraft,
    inspection.template.reviewRequired,
    isAdmin,
    isParticipant,
  ]);

  const showOfflineMessage = useMemo(() => {
    return !isOnline && inspection.template.reviewRequired;
  }, [inspection.template.reviewRequired, isOnline]);

  const showReviewInput = useMemo(() => {
    // If I'm a reviewer, the assessment is ready for a review and not published
    if (
      inspection.template.reviewRequired &&
      isReviewer &&
      isOnline &&
      inspection.readyForReview &&
      inspection.isDraft
    ) {
      // If I haven't submitted a review, or I'm editing my review
      if (myReview?.status === reviewStatus.open || editingReview) {
        return true;
      }
    }
    return false;
  }, [
    editingReview,
    inspection.isDraft,
    inspection.readyForReview,
    inspection.template.reviewRequired,
    isOnline,
    isReviewer,
    myReview?.status,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.reviewsContainer}>
        {inspection.reviewers?.map((r) => (
          <Review
            key={`Review:${r.id}`}
            isEditing={editingReview}
            editable={r.user.id === currentUser.id && inspection.isDraft}
            deletable={isAdmin && inspection.isDraft}
            reviewer={r}
            onEditClick={() => setEditingReview(true)}
            onCancelEdit={() => setEditingReview(false)}
            onDelete={() => {
              updateReviewer({
                variables: {
                  reviewerId: r.id,
                  status: reviewStatus.open,
                  notes: '',
                },
              });
            }}
          />
        ))}
      </div>

      {showReadyForReviewButton && (
        <div className={styles.startReviewButtons}>
          <Text size="sm" color="secondary" weight="semiBold" noMargin>
            {isParticipant || isAdmin
              ? allQuestionsAnswered
                ? inspection.template.reviewRequired && reviewers.length === 0
                  ? `No reviewers added. To enable the "Ready for Review" button, please assign at least one reviewer.`
                  : `Click "Ready for Review" to start the review process. If you need to make changes later, you'll have to revert, and it will require review again.`
                : 'Please complete all required questions before clicking "Ready for Review."'
              : `You must be a participant to mark the assessment as "Ready for Review"`}
          </Text>
          <Button
            className={styles.readyForReviewButton}
            size="md"
            value="Ready for Review"
            disabled={
              !allQuestionsAnswered ||
              (inspection.template.reviewRequired && reviewers.length === 0)
            }
            onClick={() => {
              if (inspection.template.analysisRequested) {
                onSecondSight(() => {
                  updateInspection({
                    variables: {
                      assessmentId: inspection.id,
                      readyForReview: true,
                      isDraft: true,
                    },
                  });
                });
              } else {
                updateInspection({
                  variables: {
                    assessmentId: inspection.id,
                    readyForReview: true,
                    isDraft: true,
                  },
                });
              }
            }}
          />
        </div>
      )}

      {showReviewInput ? (
        <ReviewInput
          isEditing={editingReview}
          onSubmit={({ status, notes }) => {
            updateReviewer({
              variables: {
                reviewerId: myReview.id,
                status,
                notes,
              },
            }).then((res) => {
              setEditingReview(false);
              const responseStatus = res.data?.updateInspectionReviewer?.status;
              if (responseStatus === reviewStatus.approved) {
                publishInspection();
              }
            });
          }}
        />
      ) : (
        showPublishButton && (
          <div className={styles.startReviewButtons}>
            <Text size="sm" color="secondary" weight="semiBold" noMargin>
              {isApproved || hasChangeRequest
                ? hasChangeRequest
                  ? `There are pending change requests. Click "Edit" to make the necessary changes and restart the review process.`
                  : `All approvals are in place and there are no change requests. Click "Complete" to finalize and close out the assessment.`
                : `At least one approval is needed to complete the assessment.`}
            </Text>
            <Button
              className={styles.readyForReviewButton}
              icon={hasChangeRequest && 'edit'}
              size="md"
              value={hasChangeRequest ? 'Edit' : 'Complete'}
              disabled={!hasChangeRequest && !isApproved}
              onClick={() => {
                if (hasChangeRequest) {
                  updateInspection({
                    variables: {
                      assessmentId: inspection.id,
                      readyForReview: false,
                      isDraft: true,
                    },
                  });
                } else {
                  onComplete();
                }
              }}
            />
          </div>
        )
      )}
      {showOfflineMessage && (
        <Text size="sm" color="secondary" weight="semiBold">
          Assessment reviews are not available offline
        </Text>
      )}
      {showOriginalCompleteButton && (
        <div className={styles.startReviewButtons}>
          <Text
            size="sm"
            color="secondary"
            weight="semiBold"
            noMargin
            textAlign="center"
          >
            {allQuestionsAnswered
              ? `Click "Complete" to finalize and close out the ${
                  inspection.inspectionType === 'AUDIT' ? 'audit' : 'assessment'
                }.`
              : `Please complete all required questions before clicking "Complete"`}
          </Text>
          <Button
            className={styles.readyForReviewButton}
            size="md"
            value="Complete"
            disabled={!allQuestionsAnswered}
            onClick={onComplete}
          />
        </div>
      )}
    </div>
  );
}

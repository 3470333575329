import styles from './CreateBoardModal.module.scss';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Text } from '../../../components/typography';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../components/modals/Modal';
import { Form } from 'react-bootstrap';
import { useModal } from '../../../providers/ModalProvider';
import { t } from 'i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { modals } from '../../../providers/modals';

const boardMutation = loader('./CreateBoardModal.graphql');

export default function CreateBoardModal() {
  const { closeModal, modalState } = useModal();
  const navigate = useNavigate();
  const [newBoardName, setNewBoardName] = useState('');
  const [newBoardDescription, setNewBoardDescription] = useState('');
  const { show } = modalState.createBoardModal;

  const [addBoard] = useMutation(boardMutation, { fetchPolicy: 'no-cache' });

  const handleSubmit = () => {
    addBoard({
      variables: {
        title: newBoardName,
        description: newBoardDescription,
      },
    }).then(
      ({
        data: {
          addBoard: { id },
        },
      }) => {
        navigate(`analytics/board/${id}`);
      },
    );
  };

  return (
    <Modal
      open={show}
      title={t(keys.boards.NEW_BOARD_TITLE)}
      onClose={() => {
        closeModal({ modalName: modals.board });
        setNewBoardDescription('');
        setNewBoardName('');
      }}
      onSubmit={handleSubmit}
      submitDisabled={!newBoardName.length}
    >
      <div className={styles.modalContentContainer}>
        <img
          className={styles.modalGraphic}
          src="https://app.opasmobile.com/assets/graphics/board_name_empty.png"
          alt="empty board"
        />
        <Text textAlign="center" size="lg" weight="semiBold">
          {t(keys.boards.NEW_BOARD_MESSAGE_ONE)}
        </Text>
        <Text textAlign="center" weight="semiBold" color="secondary">
          {t(keys.boards.NEW_BOARD_MESSAGE_TWO)}
        </Text>
      </div>
      <Form.Label>{t(keys.boards.BOARD_NAME)}</Form.Label>
      <Form.Control
        type="text"
        placeholder={t(keys.boards.ENTER_BOARD_NAME)}
        value={newBoardName}
        onChange={(e) => setNewBoardName(e.target.value)}
      />
      <br />
      <Form.Label>{t(keys.boards.BOARD_DESCRIPTION)}</Form.Label>
      <Form.Control
        as="textarea"
        placeholder={t(keys.boards.ENTER_BOARD_DESCRIPTION)}
        value={newBoardDescription}
        onChange={(e) => setNewBoardDescription(e.target.value)}
      />
    </Modal>
  );
}

import { useMemo } from 'react';
import { Text } from '../../typography';
import InspectionItemText from './InspectionItemText';
import styles from './ConditionalInspectionItem.module.scss';
import SimpleUserCard from '../../SimpleUserCard';
import { evaluateCondition } from '../../../utilities/inspection';
import { Icon } from '@mui/material';
import { actionsKeys } from '../../question_menu/conditionalActions/conditionalAction.utils';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useModal } from '../../../providers/ModalProvider';

export default function ConditionalInspectionItem({
  item,
  question,
  assessment,
  workspace,
  correctiveActions = [],
}) {
  const { openConfirmationModal } = useModal();
  const { t } = useTranslation();
  const { conditionalAction = {} } = question || {};

  const showConditionalActions = useMemo(() => {
    if (
      item?.value &&
      question?.conditionalAction &&
      evaluateCondition(item?.value, question)
    ) {
      return true;
    } else {
      return false;
    }
  }, [item, question]);

  return (
    showConditionalActions && (
      <div className={styles.container}>
        {conditionalAction?.actions?.map(
          ({ id, action, actionable, additionalData }) => {
            let returnedRender = <></>;
            switch (action) {
              case actionsKeys.followUpQuestion:
                returnedRender = (
                  <InspectionItemText
                    correctiveActions={correctiveActions}
                    refetchCPA={() => {}}
                    questionId={actionable?.id}
                    inspectionId={assessment?.id}
                    inspectionVehicleId={assessment?.vehicle?.id}
                    vehicleUnitNumber={assessment.vehicle?.unitNumber}
                    inspectionType={assessment.inspectionType}
                    title={actionable.title}
                    description={actionable.description}
                    item={assessment?.items?.find(
                      ({ questionId, bundleId }) =>
                        questionId === actionable?.id &&
                        bundleId === item?.bundleId,
                    )}
                    additionalData={actionable.additionalData}
                    inspectionQuery={() => {}}
                    required={actionable.required}
                    isDraft={assessment?.isDraft}
                    inputType={actionable.inputType}
                    inspectionTitle={assessment?.template?.title}
                    workspaceId={workspace?.id}
                    internalType={assessment.template.internalType}
                    refetch={() => {}}
                    inspection={assessment}
                    cpas={[]}
                    parentItem={item}
                  />
                );
                break;

              case actionsKeys.sendNotification:
                returnedRender = (
                  <div className={styles.userContainer}>
                    <Icon
                      baseClassName="material-icons-outlined"
                      sx={{ fontSize: '1.5rem' }}
                    >
                      notifications
                    </Icon>
                    <SimpleUserCard user={actionable} />
                  </div>
                );
                break;
              case actionsKeys.showMessage:
                returnedRender = (
                  <Text noMargin weight="semiBold">
                    {additionalData?.message}
                  </Text>
                );
                break;
              case actionsKeys.popUp:
                returnedRender = (
                  <Text
                    className={styles.textContainer}
                    hover
                    noMargin
                    weight="semiBold"
                    color="accentPrimary"
                    onClick={() =>
                      openConfirmationModal({
                        title: additionalData?.title || '',
                        description: additionalData?.message || '',
                        variant: 'warning',
                        hideSubmit: true,
                        cancelText: 'Close',
                      })
                    }
                  >
                    {additionalData?.title}
                  </Text>
                );
                break;
              default:
                returnedRender = (
                  <Text>{t(keys.conditionalActions.NO_ACTIONS)}</Text>
                );
                break;
            }

            return (
              <div key={`conditional-action-${id}`} className={styles.content}>
                {returnedRender}
              </div>
            );
          },
        )}
      </div>
    )
  );
}

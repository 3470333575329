import { actionsKeys } from '../components/question_menu/conditionalActions/conditionalAction.utils';
import {
  csvAdditionalDataInputTypes,
  csvInputTypes,
  csvRequiredColumnData,
  csvRequiredColumns,
  csvValidOperators,
  csvConditionalInputTypes,
  csvConditionalTriggerTypes,
} from '../constants/strings';

export const validateCsv = (rows, allUsers = []) => {
  if (!rows?.length) {
    return { error: 'CSV does not contain any data' };
  }

  const headerValues = Object.keys(rows[0]).map((h) => h.trim().toLowerCase());

  const hasAllColumns = csvRequiredColumns.every((c) =>
    headerValues.includes(c),
  );

  if (!hasAllColumns) {
    return {
      error: 'One or more required columns are missing',
    };
  }

  rows.forEach((row) => {
    Object.keys(row).forEach((c) => {
      row[c.toLowerCase().trim()] = row[c];
    });
  });

  const badRows = [];

  const hasValidInputTypes = rows.every((row) => {
    const isRegularQuestion = row['show initially'] === 'YES';

    if (isRegularQuestion) {
      const isValid = csvInputTypes.includes(row['input type']);
      if (!isValid) {
        badRows.push(row);
        return false;
      }
    } else {
      const isValid = csvConditionalInputTypes.includes(row['input type']);

      if (!isValid) {
        badRows.push(row);
        return false;
      }
    }
    return true;
  });

  if (!hasValidInputTypes) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row using invalid input types`,
    };
  }

  const hasValidOperators = rows.every((row) => {
    if (!!row['logic operator']) {
      const isValid = csvValidOperators.includes(row['logic operator']);

      if (!isValid) {
        badRows.push(row);
        return false;
      }
    }
    return true;
  });

  if (!hasValidOperators) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row using invalid operator`,
    };
  }

  const hasValidTriggers = rows.every((row) => {
    const hasFollowUpActions = !!row['logic operator'] && !!row['question id'];

    if (hasFollowUpActions) {
      const isValid = csvConditionalTriggerTypes.includes(row['input type']);

      if (!isValid) {
        badRows.push(row);
        return false;
      }
    }
    return true;
  });

  if (!hasValidTriggers) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row using invalid input type for a trigger question`,
    };
  }

  const hasAllRequiredColumnData = rows.every((row) =>
    csvRequiredColumnData.every((col) => {
      const hasData = !!row[col]?.length;
      if (!hasData) {
        badRows.push(row);
        return false;
      }
      return true;
    }),
  );

  if (!hasAllRequiredColumnData) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row is missing required data`,
    };
  }

  const hasCorrectRequiredValues = rows.every((row) => {
    const hasValidRequiredField =
      row.required.toLowerCase() === 'yes' ||
      row.required.toLowerCase() === 'no';
    if (!hasValidRequiredField) {
      badRows.push(row);
      return false;
    }
    return true;
  });

  if (!hasCorrectRequiredValues) {
    return {
      error: `Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(
          ', ',
        )}". Row has incorrect values for required fields IE (YES, NO)`,
    };
  }

  const hasRequiredAdditionalData = rows.every((row) => {
    const requiresAdditionalData = csvAdditionalDataInputTypes.includes(
      row['input type'],
    );
    if (requiresAdditionalData && !row.additional?.length) {
      badRows.push(row);
      return false;
    }
    return true;
  });

  if (!hasRequiredAdditionalData) {
    return {
      error: ` Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row missing required additional data`,
    };
  }

  const userToNotifyExists = rows.every((row) => {
    if (row['input type'] === actionsKeys.sendNotification) {
      let result = allUsers.find(({ email }) => email === row?.additional);

      if (!result) {
        badRows.push(row);
        return false;
      }
    }
    return true;
  });

  if (!userToNotifyExists) {
    return {
      error: ` Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". User email not found`,
    };
  }

  const hasOperatorAndValue = rows.every((row) => {
    if (!!row['question id'] && row['show initially'] === 'YES') {
      if (!row['logic operator'] || !row['value check']) {
        badRows.push(row);
        return false;
      }
    }

    return true;
  });

  if (!hasOperatorAndValue) {
    return {
      error: ` Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". No operator or value found to trigger follow up actions`,
    };
  }

  const triggerQuestionIds = [];
  const hasDuplicateQuestionId = rows.every((row) => {
    if (
      row['show initially'] === 'YES' &&
      triggerQuestionIds.includes(row['question id'])
    ) {
      badRows.push(row);
      return false;
    }

    if (!!row['question id'] && row['show initially'] === 'YES') {
      triggerQuestionIds.push(row['question id']);
    }

    return true;
  });

  if (!hasDuplicateQuestionId) {
    return {
      error: ` Error found in rows with title "${badRows
        .map(({ title }) => title)
        .join(', ')}". Row has duplicate question ID`,
    };
  }

  return { rows };
};

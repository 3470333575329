import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import { PageFooter, styles } from './constants';
import { useTranslation } from 'react-i18next';
import { keys } from '../translator/translation_keys';

export const TemplateDocument = ({ inspectionTemplate }) => {
  const { t } = useTranslation();
  const fontSize = 10;
  const grayLight = '#abb5be';

  const localStyles = StyleSheet.create({
    text: { fontSize, marginBottom: 1, fontFamily: 'eina03semibold' },
    subtext: { fontSize: fontSize - 2, color: grayLight },
    headerText: {
      fontSize: fontSize + 5,
      fontFamily: 'eina03bold',
    },
    questionContainer: {
      borderBottom: 1,
      borderColor: grayLight,
      paddingBottom: 25,
      paddingTop: 5,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={localStyles.headerText}>{inspectionTemplate.title}</Text>
          <Text style={localStyles.text}>{inspectionTemplate.description}</Text>
        </View>
        <View style={styles.categoryContainer}>
          {inspectionTemplate.categories.map(
            ({ title, questions, id }, index) => (
              <View key={`${id}-${index}-${title}`}>
                <Text style={styles.categoryHeader}>{title}</Text>
                {questions.map((q) => (
                  <View key={q.id} style={localStyles.questionContainer}>
                    <Text style={localStyles.text}>{q.title}</Text>
                    <Text style={localStyles.subtext}>{q.description}</Text>
                  </View>
                ))}
              </View>
            ),
          )}
        </View>
        {!!inspectionTemplate.images?.length && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeader}>
              {t(keys.common.REFERENCE_IMAGES)}
            </Text>
            <View style={styles.templateImageContainer}>
              {inspectionTemplate.images.map(({ imageUrl, index }) => (
                <Image
                  style={styles.templateImage}
                  src={imageUrl}
                  key={`${imageUrl.id}-${index}`}
                />
              ))}
            </View>
          </View>
        )}
        <PageFooter />
      </Page>
    </Document>
  );
};

import { useMemo, useState } from 'react';
import styles from './Tasks.module.scss';
import TablePrototype from '../../components/TablePrototype';
import { Text } from '../../components/typography';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import {
  assessmentTypes,
  customTaskAssessmentType,
  taskIntervalStringLookup,
} from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { convertTaskListFiltersToQueryParams } from '../../utilities/filtering';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { Icon } from '@mui/material';
import emptyStateTask from '../../assets/empty_state_images/inspections.svg';
import { getColor } from '../../constants/strings';
import { useTableComponents } from '../../components/tables/MTableComponents';
import { generateTaskTargetString } from '../../utilities';

const getTasksQuery = loader('./Tasks.tasks.graphql');

export default function TaskList() {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { workspace } = useWorkspace();
  const { allWorkspaceUsers } = useWorkspacePermissions();

  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rawFilters, setRawFilters] = useState({});
  const { dateCell, textCell, customTextCell, workspaceCell, userCell } =
    useTableComponents();

  const { filters, subfilters } = useMemo(
    () => convertTaskListFiltersToQueryParams(rawFilters),
    [rawFilters],
  );

  const {
    taskLoading,
    previousData: { taskCount: oldCount } = {},
    data: { getTasks: tasks = [], taskCount: count = 0 } = {},
  } = useQuery(getTasksQuery, {
    skip: !workspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters,
        subfilters,
      },
    },
  });

  const userOptions = useMemo(() => {
    const userOptions = allWorkspaceUsers.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    }));
    return userOptions;
  }, [allWorkspaceUsers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.NAME),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        enableColumnFilter: false,
        header: t(keys.common.TYPE),
        minSize: 150,
        enableSorting: false,
        grow: true,
        Cell: ({ row, cell }) => (
          <div className={styles.templateCell}>
            <Icon
              baseClassName="material-icons-outlined"
              sx={{
                fontSize: '1.25rem',
                color: getColor(
                  row?.original?.template?.templateType.toLowerCase(),
                ),
              }}
            >
              {{ ...assessmentTypes, ...customTaskAssessmentType }[
                row?.original?.template?.templateType.toLowerCase()
              ]?.icon || 'content_paste_search'}
            </Icon>
            <Text noMargin size="sm" weight="semiBold">
              {cell.getValue() || 'Observation'}
            </Text>
          </div>
        ),
      },
      {
        accessorKey: 'target',
        header: 'Target',
        enableColumnFilter: false,
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ row }) =>
          customTextCell(generateTaskTargetString(row.original)),
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableColumnFilter: false,
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => workspaceCell({ cell }),
      },
      {
        accessorKey: 'interval',
        header: t(keys.tasks.INTERVAL),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'ONE_TIME', label: t(keys.tasks.ONE_TIME) },
          { value: 'DAILY', label: t(keys.tasks.DAILY) },
          { value: 'MONTHLY', label: t(keys.tasks.MONTHLY) },
          { value: 'WEEKLY', label: t(keys.tasks.WEEKLY) },
        ],
        Cell: ({ cell }) =>
          customTextCell(t(taskIntervalStringLookup[cell.getValue()])),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) => userCell({ cell }),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        minSize: 350,
        grow: true,
        filterVariant: 'date-range',
        Cell: ({ cell }) => dateCell({ cell }),
      },
    ],
    [
      dateCell,
      textCell,
      customTextCell,
      workspaceCell,
      userCell,
      t,
      userOptions,
    ],
  );

  return (
    <div className={styles.container}>
      <TablePrototype
        isLoading={taskLoading}
        columns={columns}
        data={tasks}
        count={count || oldCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setRawFilters={setRawFilters}
        setSortBy={setSortBy}
        setIsAscending={setIsAscending}
        onRowClick={(row) =>
          openModal({
            modalName: modals.task,
            variables: {
              taskId: row.id,
              title: row.title,
              interval: row.interval,
              type: Object.values(assessmentTypes).find(
                (type) => type.key === row.template?.templateType,
              ),
              template: row?.template ?? 'None',
              taskType: row.taskType,
              target: row.target,
              targetInterval: row.targetInterval,
              targetPeriod: row.targetPeriod,
              taskWorkspace: row.workspace,
              assignees: row.assigneeInfo.map(({ assignee }) => assignee),
              readOnly: true,
            },
          })
        }
        emptyState={{
          title: t(keys.action.NOT_FOUND, {
            variable: t(keys.common.TASKS),
          }),
          text:
            filters.length > 2
              ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                  variable: t(keys.common.TASKS),
                })
              : t(keys.action.EMPTY_STATE_MESSAGE, {
                  variable: t(keys.common.TASKS),
                }),
          image: emptyStateTask,
        }}
      />
    </div>
  );
}

import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import styles from './Statistics.module.scss';
import {
  assessmentKPIs,
  optionsLookup,
  prevLookup,
  reportingInfo,
} from '../../../utilities/analytics';
import { differenceInDays, subDays } from 'date-fns';
import { useWorkspacePermissions } from '../../../providers/WorkspacePermissionsProvider';
import { Text } from '../../../components/typography';
import TextWithIcon from '../../../components/typography/TextWithIcon';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { assessmentTypes } from '../../../constants/strings';
import { customColors } from '../../../utilities';

const query = loader('./AssessmentStatistics.count.graphql');
const permissionsQuery = loader('./AssessmentStatistics.permission.graphql');

function AssessmentCount({
  startDate,
  endDate,
  additionalFilters = [],
  prevStart,
  prevEnd,
  info,
  workspaceIds,
  setShow,
}) {
  const { allUsers } = useWorkspacePermissions();

  const { data: { workspacePermissionsCount = 0 } = {} } = useQuery(
    permissionsQuery,
    {
      skip: !workspaceIds?.length,
      variables: {
        workspaceIds,
      },
    },
  );

  const { t } = useTranslation();
  const { data } = useQuery(query, {
    variables: {
      workspaceIds,
      options: {
        filters: [
          ...additionalFilters,
          {
            field: 'dateCreated',
            operator: 'between',
            value: [startDate, endDate],
          },
        ],
      },
    },
  });
  const { data: previousData } = useQuery(query, {
    variables: {
      workspaceIds,
      options: {
        filters: [
          ...additionalFilters,
          {
            field: 'dateCreated',
            operator: 'between',
            value: [prevStart, prevEnd],
          },
        ],
      },
    },
  });

  const { current, difference, currentAv, differenceInAv } = useMemo(() => {
    const users = !!workspaceIds?.length
      ? workspacePermissionsCount
      : allUsers.length;
    const current = data?.assessmentStatisticCount || 0;
    const previous = previousData?.assessmentStatisticCount || 0;
    const difference = current - previous;
    const currentAv = (current / users).toFixed(2);
    const prevAv = (previous / users).toFixed(2);
    const differenceInAv = (currentAv - prevAv).toFixed(2);

    return {
      users,
      current,
      previous,
      difference,
      currentAv,
      prevAv,
      differenceInAv,
    };
  }, [workspaceIds, workspacePermissionsCount, allUsers, data, previousData]);

  return (
    <div className={styles.statBox}>
      <div className={styles.header}>
        <TextWithIcon
          noMargin
          weight="semiBold"
          color={info.color}
          icon={info.icon}
          onClick={() => setShow(true)}
        >
          {t(info.title)}
        </TextWithIcon>
        <Text
          weight="semiBold"
          color={difference > 0 ? 'green' : 'red'}
          noMargin
        >
          {`${difference > 0 ? '+' : ''}${difference}`}
        </Text>
      </div>
      <div className={styles.data}>
        <Text noMargin size="lg" weight="semiBold">
          {current}
        </Text>
        <div className={styles.header}>
          <Text noMargin color="secondary">
            {currentAv} per user
          </Text>
          <Text color={difference > 0 ? 'green' : 'red'} noMargin>
            {differenceInAv}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default function AssessmentStatistics({
  start,
  end,
  workspaceIds,
  dateType,
}) {
  const daysInRange = differenceInDays(new Date(end), new Date(start));
  const previousEnd = subDays(new Date(start), 1);
  const previousStart = subDays(previousEnd, daysInRange);
  const [show, setShow] = useState(false);
  const { auditsAndCorrectiveActions } = useFlags();
  const auditKPI = {
    filter: [{ field: 'inspectionType', operator: 'eq', value: 'AUDIT' }],
    info: assessmentTypes.audit,
  };
  const data = auditsAndCorrectiveActions
    ? [...assessmentKPIs, ...(!!workspaceIds?.length ? [] : [auditKPI])]
    : assessmentKPIs;

  return (
    <div>
      <TextWithIcon
        weight="semiBold"
        noMargin
        icon="info"
        hover
        iconProps={{ color: customColors.BLUE }}
        onClick={() => setShow(!show)}
      >
        Statistics
      </TextWithIcon>

      <Text size="sm" color="secondary">
        Note: These only show statistics for reports that have been completed
      </Text>
      {show ? (
        <div className={styles.flexColumn}>
          <div className={styles.sectionLine} />
          <div className={styles.statBoxLg}>
            <div className={styles.header}>
              <Text noMargin weight="semiBold" color="secondary">
                <span className={styles.span}>1</span> Report Type{' '}
              </Text>
              <Text weight="semiBold" color="secondary" noMargin>
                <span className={styles.span}>4</span> Diff(Count){' '}
                <span className={styles.label}>{prevLookup[dateType]}</span>
              </Text>
            </div>
            <div className={styles.data}>
              <Text noMargin size="lg" weight="semiBold" color="secondary">
                <span className={styles.span}>2</span> Count{' '}
                <span className={styles.label}>{optionsLookup[dateType]}</span>
              </Text>
            </div>
            <div className={styles.header}>
              <Text size="sm" weight="semiBold" noMargin color="secondary">
                <span className={styles.span}>3</span> Avg(per user){' '}
                <span className={styles.label}>{optionsLookup[dateType]}</span>
              </Text>
              <Text size="sm" weight="semiBold" noMargin color="secondary">
                <span className={styles.span}>5</span> Diff(Avg){' '}
                <span className={styles.label}>{prevLookup[dateType]}</span>
              </Text>
            </div>
          </div>
          <div className={styles.info}>
            {reportingInfo.map(({ title, description }, idx) => (
              <div className={styles.list}>
                <Text noMargin weight="semiBold">
                  <span className={styles.span}>{`${idx + 1}`}</span>
                  {` ${title}`}
                </Text>
                <Text noMargin size="sm">
                  {description}
                </Text>
              </div>
            ))}
          </div>
          <div className={styles.sectionLine} />
        </div>
      ) : null}

      <div className={styles.container}>
        {data.map(({ filter, info }, index) => {
          return (
            <AssessmentCount
              key={index}
              startDate={start}
              endDate={end}
              additionalFilters={filter}
              prevStart={previousStart}
              prevEnd={previousEnd}
              info={info}
              workspaceIds={workspaceIds}
              setShow={setShow}
            />
          );
        })}
      </div>
    </div>
  );
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import { modalDefaultState } from './modals';
import CreateAssessmentModal from '../components/modals/provider_modals/CreateAssessmentModal';
import CreateTemplateModal from '../components/modals/provider_modals/CreateTemplateModal';
import CreateIncidentModal from '../components/modals/provider_modals/CreateIncidentModal';
import CreateWorkspaceModal from '../components/modals/provider_modals/CreateWorkspaceModal';
import NoWorkspaceModal from '../components/modals/provider_modals/NoWorkspaceModal';
import FileUploadModal from '../components/modals/provider_modals/FileUploadModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentUser } from './UserProvider';
import { useWorkspace } from './WorkspaceProvider';
import { searchParamKeys } from '../constants/strings';
import RecordMeetingModal from '../components/modals/provider_modals/RecordMeetingModal';
import AnnouncementModal from '../components/modals/provider_modals/AnnouncementModal';
import CustomQuickActionModal from '../components/modals/provider_modals/CustomQuickActionModal';
import TaskModal from '../components/modals/provider_modals/TaskModal';
import RoleModal from '../components/modals/provider_modals/RoleModal';
import CertificateModal from '../components/modals/provider_modals/CertificateModal';
import { useQueryParams } from '../hooks/misc';
import ObservationModal from '../components/modals/provider_modals/ObservationModal';
import ConfirmationModal from '../components/modals/provider_modals/ConfirmationModal';
import { modals } from './modals';
import { useTemplates } from './TemplateProvider';
import CreateAuditModal from '../components/modals/provider_modals/CreateAuditModal';
import CreateCPAModal from '../pages/audit/CreateCPAModal';
import VectorResponseModal from '../components/modals/provider_modals/VectorResponseModal';
import MultiFileUploadModal from '../components/modals/provider_modals/MultiFileUploadModal';
import CreateBoardModal from '../components/modals/provider_modals/CreateBoardModal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user: currentUser } = useCurrentUser();
  const { workspaceVehicles } = useWorkspace();
  const { getParam } = useQueryParams();
  const { templates } = useTemplates();

  const updateUrlParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    params.forEach(({ param, value }) => {
      searchParams.set(param, value);
      if (param === searchParamKeys.type) {
        searchParams.delete(searchParamKeys.asset);
        searchParams.delete(searchParamKeys.template);
      }
    });
    navigate(`${location.pathname}?${searchParams}`, { replace: true });
  };

  const removeUrlParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    params.forEach(({ param }) => {
      searchParams.delete(param);
    });
    navigate(`${location.pathname}?${searchParams}`, { replace: true });
  };

  const [modalState, setModalState] = useState({
    ...modalDefaultState,
  });

  const openModal = ({ modalName, variables }) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: {
        ...prevState[modalName],
        ...variables,
        show: true,
      },
    }));
    if (modalState[modalName]?.urlParams) {
      updateUrlParams([{ param: searchParamKeys.modalName, value: modalName }]);
    }
  };

  const updateModal = ({ modalName, variables }) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: {
        ...prevState[modalName],
        ...variables,
      },
    }));
  };

  const closeModal = ({ modalName }) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: modalDefaultState[modalName],
    }));
    if (modalState[modalName]?.urlParams) {
      removeUrlParams(modalState[modalName].urlParams);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalName = searchParams.get(searchParamKeys.modalName);
    if (!modalName || !modalState[modalName]) return;
    if (
      searchParams.size > 1 &&
      modalState[modalName]?.urlParams &&
      !modalState[modalName].show
    ) {
      const valuesFromUrlParams = modalState[modalName].urlParams.map(
        (item) => ({
          ...item,
          value: searchParams.get(item.param),
        }),
      );
      const assetFromUrl =
        workspaceVehicles?.find(
          ({ id }) => id === parseInt(searchParams.get(searchParamKeys.asset)),
        ) || null;

      const typeFromUrl = modalState[modalName]?.types?.find(
        ({ key }) => key === searchParams.get(searchParamKeys.type),
      );
      const templateFromUrl = getParam(searchParamKeys.template);
      const template = templates.find(
        ({ id }) => id === parseInt(templateFromUrl) || null,
      );
      const variablesFromUrl = {
        asset: assetFromUrl,
        type: typeFromUrl,
        template,
      };
      openModal({
        modalName,
        variables: {
          ...variablesFromUrl,
          urlParams: valuesFromUrlParams,
          participants: [currentUser],
        },
      });
    }
    if (modalState[modalName]?.urlParams && modalState[modalName].show) {
      updateUrlParams(
        modalState[modalName].urlParams
          .map(
            ({ param, paramTarget }) =>
              modalState[modalName][param] && {
                param,
                value: paramTarget
                  ? modalState[modalName][param][paramTarget]
                  : modalState[modalName][param],
              },
          )
          .filter((p) => p),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState, location.search, workspaceVehicles, currentUser]);

  const openConfirmationModal = (variables) => {
    setModalState((prevState) => ({
      ...prevState,
      [modals.confirmation]: {
        ...prevState[modals.confirmation],
        ...variables,
        show: true,
      },
    }));
  };

  return (
    <ModalContext.Provider
      value={{
        modalState,
        openModal,
        closeModal,
        updateModal,
        openConfirmationModal,
      }}
    >
      <CreateAssessmentModal />
      <CreateTemplateModal />
      <CreateIncidentModal />
      <CreateWorkspaceModal />
      <NoWorkspaceModal />
      <FileUploadModal />
      <RecordMeetingModal />
      <AnnouncementModal />
      <CustomQuickActionModal />
      <ObservationModal />
      <ConfirmationModal />
      <TaskModal />
      <CreateCPAModal />
      <CreateAuditModal />
      <RoleModal />
      <VectorResponseModal />
      <MultiFileUploadModal />
      <CertificateModal />
      <CreateBoardModal />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

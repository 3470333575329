import { Icon } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './Accordian.module.scss';
import { Text } from './typography';
import { customColors } from '../utilities';

export default function Accordian({
  title,
  children,
  message = '',
  messageVariant,
  size = 'lg',
  button = false,
  onClick,
  icon = null,
  iconProps = {},
  accordianSize = false,
  isBundle = false,
  backgroundColor = 'white',
  textColor = 'primary',
  expand = true,
  showOnClick = false,
}) {
  const [open, setOpen] = useState(true);

  useEffect(() => setOpen(expand), [expand]);

  return (
    <div className={!accordianSize ? styles.container : styles.smallContainer}>
      <div
        className={classNames(
          !accordianSize ? styles.header : styles.smallHeader,
          styles.icon,
          open && styles.open,
        )}
        style={{ backgroundColor }}
        onClick={() => !button && setOpen(!open)}
      >
        <div
          className={styles.withIcon}
          style={{
            flexDirection:
              iconProps.position !== 'left' ? 'row-reverse' : 'row',
          }}
        >
          {!!showOnClick && (
            <Icon sx={{ color: customColors.BLUE }} onClick={onClick}>
              add
            </Icon>
          )}
          {icon && (
            <Icon
              baseClassName={
                iconProps.outlined
                  ? 'material-icons-outlined'
                  : 'material-icons'
              }
              sx={{ ...iconProps }}
              className={styles.accordianIcon}
            >
              {icon}
            </Icon>
          )}
          <Text
            weight="bold"
            size={size}
            noMargin
            color={textColor}
            noSelect
            className={styles.title}
          >
            {title}
          </Text>
        </div>
        <div className={styles.rightContainer} onClick={() => setOpen(!open)}>
          {!isBundle && (
            <Text
              color={messageVariant ?? textColor}
              weight="semiBold"
              size="md"
              noMargin
              noSelect
              className={styles.message}
            >
              {message}
            </Text>
          )}
          <Icon style={{ color: textColor }}>
            {open ? 'expand_less' : 'expand_more'}
          </Icon>
        </div>
      </div>

      <div className={styles[open ? 'showContainer' : 'hideContainer']}>
        {children}
      </div>
    </div>
  );
}

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import styles from './AddQuestionMenu.module.scss';
import { Close } from '@mui/icons-material';
import AddQuestionButton from './AddQuestionButton';
import { Form, Row, Col } from 'react-bootstrap';
import Button from '../Button';
import { loader } from 'graphql.macro';
import { Text } from '../typography';
import ColumnInput from '../ColumnInput';
import OptionsSelector from '../OptionsSelector';
import { DropdownText } from '../dropdowns/DropdownText';
import {
  dateOptions,
  mockQuestionTypes,
  testQuestionOptions,
  dateTimeTypes,
} from '../../utilities/inspection';

import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import { capatalizeFirstLetter } from '../../utilities';
import ConditionalActionMenu from './conditionalActions/ConditionalActionMenu';
import { Icon } from '@mui/material';
import { useModal } from '../../providers/ModalProvider';
import { conditionalActionsAllowed } from './conditionalActions/conditionalAction.utils';
import { deepCompareObjects } from '../../utilities';

const addQuestionMutation = loader(
  '../../graphql/mutations/inspection_template_item_create.graphql',
);

const updateQuestionMutation = loader('./AddQuestionMenu.update.graphql');

const deleteConditionalActionMutation = loader(
  './AddQuestionMenu.deleteCondition.graphql',
);

export default function AddQuestionMenu({
  category,
  refetchCategory,
  showModal,
  onHide,
  selectedQuestion,
  conditionalAction = {},
  setSelectedQuestion = () => {},
  onAdd = () => {},
  templateId = null,
}) {
  const { openConfirmationModal } = useModal();
  const [showConditionalActions, setShowConditionalActions] = useState(false);
  const [selected, setSelected] = useState('none');
  const [question, setQuestion] = useState('');
  const [description, setDescription] = useState('');
  const [selectType, setSelectType] = useState('single');
  const [isRequired, setIsRequired] = useState(false);
  const [action, setAction] = useState(null);
  const [addQuestion] = useMutation(addQuestionMutation);
  const [updateQuestion] = useMutation(updateQuestionMutation);
  const [deleteConditionalAction] = useMutation(
    deleteConditionalActionMutation,
    { refetchQueries: ['GetInspectionTemplateForCreateQuestions'] },
  );
  const [selectionOptions, setSelectionOptions] = useState('');
  const [columns, setColumns] = useState([]);
  const [testSelectionOptions, setTestSelectionOptions] = useState(
    !selectedQuestion?.additional ? 'Pass, Fail, N/A' : '',
  );
  const [dateType, setDateType] = useState('DATE');
  const { t } = useTranslation();
  const [numberLimit, setNumberLimit] = useState({
    min: undefined,
    max: undefined,
  });
  function clearComponent() {
    setQuestion('');
    setSelected('none');
    setSelectionOptions('');
    setNumberLimit({ min: undefined, max: undefined });
    setDescription('');
    setDateType('DATE');
    setSelectType('single');
    setColumns([]);
    setIsRequired(false);
    setTestSelectionOptions('');
    refetchCategory();
  }

  useEffect(() => {
    if (!!selectedQuestion && showModal) {
      const string = selectedQuestion.inputType?.split('_') || ['TEXT'];
      const selectedType = string[1]?.toLowerCase() || '' || null;
      const inputType = capatalizeFirstLetter(string[0]);
      setIsRequired(selectedQuestion.isRequired);
      setQuestion(selectedQuestion?.title);
      setDescription(selectedQuestion?.description);
      setSelected(inputType);
      setSelectType(selectedType || 'single');
      setIsRequired(selectedQuestion?.required || false);
      if (dateTimeTypes.includes(selectedQuestion?.inputType)) {
        setDateType(selectedQuestion?.inputType);
        setSelected('Date');
      }
      setAction(selectedQuestion?.conditionalAction);
    } else {
      clearComponent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.inputType, selectedQuestion, showModal]);

  useEffect(() => {
    if (selectedQuestion?.title !== question) {
      setShowConditionalActions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestion]);
  const generateAdditionalData = useCallback(() => {
    switch (selected) {
      case 'Select':
        return selectionOptions?.options?.map((o) => o.text).join('|');
      case 'Table':
        return columns.join('|');
      case 'Test':
        return testSelectionOptions.split(', ').join('|');
      case 'Number':
        return numberLimit.min || numberLimit.max
          ? `${numberLimit.min ?? ''}|${numberLimit.max ?? ''}`
          : null;
      default:
        return null;
    }
  }, [numberLimit, columns, selected, testSelectionOptions, selectionOptions]);

  const questionInputType = useMemo(() => {
    switch (selected) {
      case 'Select':
      case 'Signature':
      case 'File':
        return `${selected.toUpperCase()}_${selectType.toUpperCase()}`;
      case 'Text':
      case 'Image':
      case 'User':
        if (selectType === 'multi') {
          return `${selected.toUpperCase()}_${selectType.toUpperCase()}`;
        } else {
          return `${selected.toUpperCase()}`;
        }
      case 'Date':
        return `${dateType.toUpperCase()}`;
      default:
        return `${selected.toUpperCase()}`;
    }
  }, [dateType, selectType, selected]);

  const invalidLimits =
    !isNaN(numberLimit?.min) &&
    !isNaN(numberLimit?.max) &&
    parseFloat(numberLimit?.max) < parseFloat(numberLimit?.min);

  const submitDisabled = useMemo(() => {
    const updatedQuestion = {
      __typename: 'InspectionQuestion',
      id: selectedQuestion?.id,
      title: question,
      description,
      inputType: questionInputType,
      required: isRequired,
      additionalData: generateAdditionalData(),
      conditionalAction: null,
    };

    const deepCompareResult = deepCompareObjects(
      { ...updatedQuestion },
      {
        __typename: 'InspectionQuestion',
        id: selectedQuestion?.id,
        ...selectedQuestion,
        conditionalAction: null,
      },
    );

    return (
      !question ||
      selected === 'none' ||
      (selected?.toUpperCase().includes('SELECT') &&
        !selectionOptions?.options?.length) ||
      (selected?.toUpperCase().includes('TABLE') && !columns?.length) ||
      invalidLimits ||
      deepCompareResult
    );
  }, [
    selectedQuestion,
    question,
    description,
    isRequired,
    questionInputType,
    columns?.length,
    invalidLimits,
    selected,
    selectionOptions?.options?.length,
    generateAdditionalData,
  ]);

  return !showModal ? (
    <></>
  ) : (
    <div className={!conditionalAction?.id ? styles.parent : {}}>
      {!showConditionalActions ? (
        <div className={styles.questionMenu}>
          <div className={styles.header}>
            <Text size="lg" weight="bold" className={styles.text}>
              {!!category
                ? t(keys.templates.ADD_TO, {
                    variable: category?.title,
                  })
                : t(keys.conditionalActions.ADD_TO_ACTION)}
            </Text>
            <Close
              className={styles.closeIcon}
              onClick={() => {
                const close = () => {
                  onHide();
                  setDescription('');
                  setQuestion('');
                  setSelected('none');
                  setColumns([]);
                  setIsRequired(false);
                  setSelectionOptions('');
                  setSelectType('single');
                  setTestSelectionOptions('');
                  setDateType('DATE');
                };

                if (!submitDisabled) {
                  openConfirmationModal({
                    title: t(keys.common.UNSAVED_CHANGES),
                    description: t(keys.common.UNSAVED_CHANGES_DESCRIPTION, {
                      variable: question,
                    }),
                    variant: 'warning',
                    buttonText: t(keys.action.CONFIRM),
                    onSubmit: () => close(),
                  });
                } else {
                  close();
                }
              }}
            />
          </div>
          <Form noValidate onSubmit={(e) => e.preventDefault()}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {t(keys.templates.QUESTION)}
                </Text>
                <Form.Control
                  autoFocus={false}
                  required
                  as="textarea"
                  placeholder={t(keys.templates.QUESTION_TITLE_PLACEHOLDER)}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {`${t(keys.common.DESCRIPTION)} (${t(keys.common.OPTIONAL)})`}
                </Text>
                <Form.Control
                  autoFocus={false}
                  required
                  as="textarea"
                  type="text"
                  placeholder={t(
                    keys.templates.QUESTION_DESCRIPTION_PLACEHOLDER,
                  )}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Row>
          </Form>
          {selected === 'Select' ||
          selected === 'Signature' ||
          selected === 'Text' ||
          selected === 'File' ||
          selected === 'Image' ||
          selected === 'User' ? (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Text
                  noMargin
                  weight="semiBold"
                  size="md"
                  className={styles.formTitle}
                >
                  {t(keys.action.SELECT)}
                </Text>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Check
                      inline
                      checked={selectType === 'single'}
                      type="radio"
                      name="selectOptions"
                      label={
                        selected === 'Text'
                          ? t(keys.templates.SINGLE_LINE)
                          : t(keys.templates.SINGLE)
                      }
                      value="single"
                      onChange={(e) => setSelectType(e.target.value)}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="selectOptions"
                      checked={selectType === 'multi'}
                      label={
                        selected === 'Text'
                          ? t(keys.templates.MULTI_LINE)
                          : t(keys.templates.MULTI)
                      }
                      value="multi"
                      onChange={(e) => setSelectType(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Form>

              {selected === 'Select' && (
                <>
                  <Text
                    noMargin
                    weight="semiBold"
                    size="md"
                    className={styles.formTitle}
                  >
                    {t(keys.common.OPTIONS)}
                  </Text>
                  <OptionsSelector
                    selected={selectionOptions}
                    onSelectionChanged={(opt) => {
                      setSelectionOptions(opt);
                    }}
                  />
                </>
              )}
            </>
          ) : null}
          {selected === 'Number' && (
            <div>
              <Text weight="semiBold">
                {t(keys.assessments.VALUE_TOLERANCE)}
              </Text>
              <div className={styles.numberLimit}>
                <Form.Control
                  placeholder="Min"
                  type="number"
                  isInvalid={invalidLimits}
                  onChange={(e) =>
                    setNumberLimit({ ...numberLimit, min: e.target.value })
                  }
                />
                <Form.Control
                  placeholder="Max"
                  type="number"
                  isInvalid={invalidLimits}
                  onChange={(e) =>
                    setNumberLimit({ ...numberLimit, max: e.target.value })
                  }
                />
              </div>
              {invalidLimits ? (
                <Text color="red">{t(keys.assessments.MIN_MAX)}</Text>
              ) : null}
            </div>
          )}

          {selected === 'Table' && (
            <ColumnInput columns={columns} onChange={setColumns} />
          )}
          {(selected === 'Date' || selected === 'Time') && (
            <Form.Group onChange={(e) => setDateType(e.target.value)}>
              <Text
                noMargin
                weight="semiBold"
                size="md"
                className={styles.formTitle}
              >
                {t(keys.action.SELECT)}
              </Text>
              {Object.entries(dateOptions).map(([key, value]) => (
                <Form.Check
                  key={`${key}-${value.title}`}
                  inline
                  type="radio"
                  label={t(getTranslationKey(key, 'templates'))}
                  name="dateOptions"
                  value={key}
                  defaultChecked={key === dateType}
                />
              ))}
            </Form.Group>
          )}

          {selected === 'Test' && (
            <DropdownText
              items={testQuestionOptions}
              title={t(keys.templates.TEST_TYPE)}
              selected={testSelectionOptions}
              onChange={setTestSelectionOptions}
              namespace="templates"
            />
          )}
          <br />
          <div className={styles.container}>
            {mockQuestionTypes.map(({ icon, name, key }) => (
              <AddQuestionButton
                key={name}
                icon={icon}
                name={name}
                selected={selected === name}
                onClick={() => {
                  if (!selectedQuestion?.conditionalAction || !action) {
                    setSelected(selected === name ? 'none' : name);
                  } else {
                    openConfirmationModal({
                      title: t(keys.conditionalActions.CHANGE_QUESTION_TYPE),
                      description: t(
                        keys.conditionalActions.CHANGE_QUESTION_WARNING,
                      ),
                      variant: 'warning',
                      buttonText: t(keys.action.CONTINUE),
                      onSubmit: () =>
                        deleteConditionalAction({
                          variables: {
                            conditionalActionId:
                              conditionalAction?.id ||
                              selectedQuestion?.conditionalAction?.id,
                          },
                        }).then(() => {
                          setSelected(selected === name ? 'none' : name);
                          setAction(null);
                        }),
                    });
                  }
                }}
              />
            ))}
          </div>
          <br />
          <br />
          <div className={styles.header}>
            <Form.Check
              required
              checked={isRequired}
              id="switch"
              type="switch"
              label={t(keys.common.REQUIRED)}
              onChange={(e) => {
                setIsRequired(e.target.checked);
              }}
            />

            <Button
              className={styles.addButton}
              value={
                !!selectedQuestion
                  ? t(keys.action.SAVE)
                  : t(keys.action.ADD, { variable: null })
              }
              size="md"
              icon="add"
              disabled={submitDisabled}
              onClick={() => {
                if (!!selectedQuestion?.id) {
                  updateQuestion({
                    variables: {
                      id: selectedQuestion.id,
                      title: question,
                      description,
                      inputType: questionInputType,
                      required: isRequired,
                      additionalData: generateAdditionalData(),
                      conditionalActionId: conditionalAction?.id ?? null,
                    },
                  }).then(
                    ({
                      data: { updateInspectionTemplateItem: updatedQuestion },
                    }) => {
                      setSelectedQuestion({
                        ...updatedQuestion,
                        conditionalAction: action,
                      });
                    },
                  );
                } else {
                  addQuestion({
                    variables: {
                      title: question,
                      description,
                      inputType: questionInputType,
                      templateId: templateId ?? category?.inspectionTemplateId,
                      categoryId: category?.id,
                      required: isRequired,
                      additionalData: generateAdditionalData(),
                      conditionalActionId: conditionalAction?.id ?? null,
                    },
                  }).then(
                    ({ data: { addInspectionTemplateItem: newQuestion } }) => {
                      setSelectedQuestion(newQuestion);
                      onAdd();
                      if (conditionalAction?.id) {
                        clearComponent();
                      }
                    },
                  );
                }
              }}
            />
          </div>
          {!conditionalAction?.id &&
            conditionalActionsAllowed.includes(selectedQuestion?.inputType) &&
            conditionalActionsAllowed.includes(questionInputType) && (
              <div className={styles.conditionalAction}>
                <div className={styles.header}>
                  <Text noMargin weight="semiBold">
                    {t(keys.conditionalActions.CONDITIONAL_ACTION)}
                  </Text>
                </div>
                {!!action ? (
                  <div className={styles.conditionalActionContainer}>
                    <div className={styles.conditionalActionButtons}>
                      <Button
                        outlined
                        value={t(keys.action.EDIT)}
                        icon={'edit'}
                        onClick={() => setShowConditionalActions(true)}
                      />
                      <Button
                        outlined
                        variant="danger"
                        value={t(keys.action.DELETE)}
                        icon={'delete_outlined'}
                        onClick={() => {
                          openConfirmationModal({
                            title: t(keys.action.DELETE, {
                              variable: t(
                                keys.conditionalActions.CONDITIONAL_ACTION,
                              ),
                            }),
                            description: t(
                              keys.conditionalActions.DELETE_ACTION_WARNING,
                            ),
                            variant: 'danger',
                            buttonText: t(keys.action.DELETE),
                            onSubmit: () =>
                              deleteConditionalAction({
                                variables: {
                                  conditionalActionId:
                                    selectedQuestion?.conditionalAction?.id,
                                },
                              }).then(() => onHide()),
                          });
                        }}
                      />
                    </div>
                    {(!action.actions || action?.actions?.length < 1) && (
                      <div className={styles.conditionalActionSubtext}>
                        <Icon
                          className={styles.warningIcon}
                          sx={{ fontSize: '1rem' }}
                          baseClassName="material-icons-outlined"
                        >
                          warning_amber
                        </Icon>
                        <Text noMargin color="secondary" size="sm">
                          {t(keys.conditionalActions.NO_ACTIONS_YET)}
                        </Text>
                      </div>
                    )}
                  </div>
                ) : (
                  <Button
                    disabled={!selectedQuestion?.id || !submitDisabled}
                    align={'right'}
                    icon={'add'}
                    value={t(keys.action.ADD, {
                      variable: '',
                    })}
                    outlined
                    onClick={() => setShowConditionalActions(true)}
                  />
                )}
              </div>
            )}
        </div>
      ) : (
        <ConditionalActionMenu
          question={selectedQuestion}
          setParentQuestion={setSelectedQuestion}
          onBackClick={() => {
            setShowConditionalActions(false);
          }}
          templateId={category?.inspectionTemplateId}
        />
      )}
    </div>
  );
}

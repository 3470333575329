import React from 'react';
import { Text, View, Document, Page } from '@react-pdf/renderer';
import {
  styles,
  PageHeader,
  PageHeaderLogo,
  PageFooter,
  CorrectiveActions,
} from '../constants';
import { summaryStringLookup } from '../../audits';
import { textStyles } from '../styles';

export const ExecutiveSummaryDocument = ({
  inspection = {},
  company = {},
  correctiveActions = [],
  showDetails,
}) => {
  const summary = inspection?.executiveSummary || {};
  function countRiskLevels(items) {
    const riskCounts = {
      high: 0,
      medium: 0,
      low: 0,
      observation: 0,
    };
    items.forEach((item) => {
      if (item.riskLevel === 'HIGH') {
        riskCounts.high += 1;
      } else if (item.riskLevel === 'MEDIUM') {
        riskCounts.medium += 1;
      } else if (item.riskLevel === 'LOW') {
        riskCounts.low += 1;
      } else if (item.riskLevel === 'OBSERVATION') {
        riskCounts.observation += 1;
      }
    });
    return `High: ${riskCounts.high}, Medium: ${riskCounts.medium}, Low: ${riskCounts.low}, Observation: ${riskCounts.observation}`;
  }

  const result = countRiskLevels(correctiveActions);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} workspace={null} />
        <Text style={textStyles.header}>Executive Summary</Text>
        <PageHeader
          company={company}
          data={inspection}
          title={inspection.template.title}
          workspace={null}
          type="Audit"
        />
        <View>
          <Text style={textStyles.categoryHeader}>
            {inspection.template.title}
          </Text>
          <Text style={textStyles.subtext}>{`${inspection.auditType}`}</Text>
          <View style={textStyles.spacerSmall} />
          <View style={textStyles.content}>
            {Object.entries(summary).map(([key, value]) => {
              const title = summaryStringLookup[key];
              return title ? (
                <View key={key} style={textStyles.bottomBorder}>
                  <Text style={textStyles.leftText}>{title}</Text>
                  <Text style={textStyles.answer}>{value}</Text>
                </View>
              ) : null;
            })}
          </View>
          <View style={textStyles.spacer} />
          {!!correctiveActions?.length && (
            <View>
              <Text style={textStyles.categoryHeader}>
                Corrective Preventative Actions
              </Text>
              <Text style={textStyles.subtext}>{result}</Text>
              <View style={textStyles.spacerSmall} />
              <View style={textStyles.content}>
                {correctiveActions?.map((c) => (
                  <CorrectiveActions
                    key={c.id}
                    correctiveAction={c || []}
                    showDetails={showDetails}
                  />
                ))}
              </View>
            </View>
          )}
        </View>
        <PageFooter />
      </Page>
    </Document>
  );
};

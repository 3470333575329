import React from 'react';
import styles from './FileDisplayCertificate.module.scss';
import Icon from '../../Icon';

export default function FileDisplayCertificate({ file }) {
  const handleDownload = () => {
    window.open(file.url, '_blank');
  };
  const renderFile = () => {
    if (
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif' ||
      file.type === 'image/webp' ||
      file.type === 'image/svg+xml'
    ) {
      return <img src={file.url} alt={file.name} className={styles.media} />;
    } else if (file.type === 'application/pdf') {
      return (
        <iframe src={file.url} title={file.name} className={styles.media} />
      );
    } else {
      return <div className={styles.fileBox}>Unsupported file type</div>;
    }
  };

  return (
    <div className={styles.square}>
      <div className={styles.mediaWrapper}>
        {renderFile()}
        <div className={styles.overlay} onClick={handleDownload}>
          <Icon>download</Icon>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useState } from 'react';
import styles from './UserProfile.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import defaultLogo from '../../assets/workspace_image.png';
import moment from 'moment';
import { paths, userRoles } from '../../constants/strings';
import { mapDailyReportData } from '../../utilities/daily_report';
import ActivityFeed from './ActivityFeed';
import FileCard from '../../components/files/FileCard';
import Icon from '../../components/Icon';
import { useCurrentUser } from '../../providers/UserProvider';
import Modal from '../../components/modals/Modal';
import UserMultiSelector from '../../components/UserMultiSelector';
import { openFileViewer } from '../../graphql/cache/modal';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import EditInformationModal from './EditInformationModal';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { useWatcher } from '../../providers/WatcherProvider';
import { Spinner } from 'react-bootstrap';
import { getComplianceColor } from '../../utilities/complainceUtilities';
import RequirementsList from './RequirementsList';

const userQuery = loader('./UserProfile.graphql');
const dataQuery = loader('./UserProfile.activity.graphql');
const filesQuery = loader('./UserProfile.files.graphql');
const addFileMutation = loader('./UserProfile.addFile.graphql');
const updateDirectReportMutation = loader(
  './UserProfile.editDirectReports.graphql',
);
const updateUserMutation = loader('./UserProfile.update.graphql');
const rolesQuery = loader('./UserProfile.compliance.getRole.graphql');

export default function UserProfile() {
  const { user: currentUser, isAdmin } = useCurrentUser();
  const { id } = useParams();
  const navigate = useNavigate();
  const [directReportModalOpen, setDirectReportModalOpen] = useState(false);
  const [expandedCertificateState, setExpandedCertificateState] = useState({});
  const [editInformationModalOpen, setEditInformationModalOpen] =
    useState(false);
  const [editRole, setEditRole] = useState(false);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { FollowButton } = useWatcher();

  const [addFile] = useMutation(addFileMutation);
  const [updateUser] = useMutation(updateUserMutation, {
    refetchQueries: ['GetUserDetailsForUserProfile'],
  });

  const [updateDirectReports] = useMutation(updateDirectReportMutation, {
    refetchQueries: ['GetUserDetailsForUserProfile'],
  });

  const { refetch: refetchFiles, data: { files = [] } = {} } = useQuery(
    filesQuery,
    {
      skip: `${currentUser.id}` !== id || currentUser.role !== userRoles.admin,
      variables: {
        options: {
          filters: {
            field: 'userId',
            operator: 'eq',
            value: id,
          },
        },
      },
    },
  );

  const { data = { events: [], timecards: [] } } = useQuery(dataQuery, {
    skip: isNaN(id),
    variables: {
      userId: id,
      pageSize: 10,
      options: {
        pageSize: 2,
        filters: [
          {
            field: 'creatorId',
            operator: 'eq',
            value: `${id}`,
          },
          {
            field: 'status',
            operator: 'ne',
            value: 'DRAFT',
          },
        ],
        sort: [
          {
            field: 'startTime',
            order: 'DESC',
          },
        ],
      },
    },
  });

  const { data: { users: [user] = [], workspacePermissions = [] } = {} } =
    useQuery(userQuery, {
      variables: { id },
      skip: isNaN(id),
    });

  const { data: { roles = [] } = {} } = useQuery(rolesQuery);

  const canEditInformation = useMemo(() => {
    return currentUser.role === 'ADMIN' || user?.id === currentUser?.id;
  }, [currentUser.role, currentUser?.id, user?.id]);

  const showPersonalInfo =
    id === `${currentUser.id}` ||
    currentUser.role === userRoles.admin ||
    currentUser.team.some((tm) => `${tm.id}` === id);

  const history = useMemo(() => {
    if (showPersonalInfo) {
      const dataToProcess = {
        events: data?.events || [],
        timecards: data?.timecards || [],
      };
      return mapDailyReportData(dataToProcess).reverse();
    }
  }, [data, showPersonalInfo]);

  const EmojiTitle = ({ emoji, text }) => (
    <div className={styles.emojiTitle}>
      <Text size="lg" noMargin>
        {emoji}
      </Text>
      <Text size="lg" weight="semiBold" noMargin>
        {text}
      </Text>
    </div>
  );

  const TeamMemberCard = ({
    user: { id, firstName, email, lastName, avatarUrl, phone },
  }) => (
    <div className={styles.teamCard}>
      <div
        className={styles.teamUserContainer}
        onClick={() => navigate(`/${paths.user}/${id}`)}
      >
        <img
          className={styles.teamAvatar}
          src={avatarUrl}
          alt={`${firstName} ${lastName}`}
        />
        <div className={styles.truncate} style={{ width: '100%' }}>
          <Text
            truncate
            weight="semiBold"
            noMargin
          >{`${firstName} ${lastName}`}</Text>
          <Text truncate size="sm" weight="semiBold" color="secondary" noMargin>
            {email}
          </Text>
        </div>
      </div>
      <a
        className={styles.link}
        href={`tel:${user?.phone?.replace(/\D/g, '')}`}
      >
        <Text size="sm" weight="semiBold" color="secondary" noMargin>
          {phone}
        </Text>
      </a>
    </div>
  );

  const getActivityText = (lastActivity) => {
    const onlineThreshold = 15;
    const past = moment().subtract(onlineThreshold, 'minutes');

    if (!lastActivity) {
      return t(keys.settings.NEVER_LOGGED_IN);
    } else if (getLocalTime(lastActivity).isAfter(past)) {
      return `🟢 ${t(keys.settings.ONLINE_NOW)}`;
    } else {
      return getLocalTime(lastActivity).fromNow();
    }
  };

  const returnRole = () => {
    const returnRoleText = (
      <div className={styles.roleBox}>
        <Text weight="semiBold" noMargin>
          {t(keys.common.ROLE)}
        </Text>
        <Text size="sm" weight="semiBold" color="secondary" noMargin>
          {user?.workRole?.title || 'None'}
        </Text>
      </div>
    );
    const returnDropdown = (
      <div className={styles.roleBox}>
        <DropdownText
          items={roles}
          title={t(keys.common.ROLE)}
          selected={user?.workRole?.title || null}
          onChange={(workRole) => {
            const variables = { workRoleId: workRole.id, id: user.id };
            updateUser({ variables });
          }}
          onRemove={() => {
            const variables = { workRoleId: null, id: user.id };
            updateUser({ variables });
          }}
        />
      </div>
    );
    return editRole ? returnDropdown : returnRoleText;
  };

  const toggleCertificateExpand = (id) => {
    setExpandedCertificateState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const isCreator = currentUser?.id === parseInt(id);

  if (!user?.id) {
    return (
      <div className={styles.loadingDiv}>
        <Spinner size="lg" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <Modal
        open={directReportModalOpen}
        onClose={() => setDirectReportModalOpen(false)}
        onSubmit={() => setDirectReportModalOpen(false)}
        title={t(keys.settings.ASSIGN_DIRECT_REPORTS)}
        hideCancel
        submitText={t(keys.settings.DONE)}
      >
        <Text size="md" weight="semiBold" noMargin>
          {t(keys.settings.DIRECT_REPORTS)}
        </Text>
        <UserMultiSelector
          selected={user?.team}
          onUserAdded={(u) => {
            updateDirectReports({
              variables: { id: u.id, supervisorId: user.id },
            });
          }}
          onUserRemoved={(u) => {
            updateDirectReports({
              variables: { id: u.id, supervisorId: null },
            });
          }}
        />
      </Modal>
      <EditInformationModal
        show={editInformationModalOpen}
        setShow={setEditInformationModalOpen}
        originalValues={user}
        onSubmit={(d) => {
          if (!Object.keys(d).length) {
            return;
          }
          const variables = { ...d, id: user.id };
          updateUser({ variables });
        }}
      />
      <div className={styles.container}>
        <div className={styles.parentContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.infoContainer}>
              {id === `${currentUser.id}` ? (
                <div className={styles.editIconContainer}>
                  <Icon
                    className={styles.editIcon}
                    baseClassName="material-icons-outlined"
                    onClick={() => navigate(paths.settings)}
                  >
                    edit
                  </Icon>
                </div>
              ) : (
                <FollowButton
                  type={'user'}
                  id={user?.id}
                  userId={currentUser?.id}
                />
              )}
              {user ? (
                <img
                  src={user.avatarUrl}
                  className={styles.avatar}
                  alt={`${user.firstName} ${user.lastName}`}
                />
              ) : (
                <div className={styles.avatarSkeleton} />
              )}
              <div>
                <Text size="lg" weight="bold" noMargin>{`${
                  user ? `${user.firstName} ${user.lastName}` : '-'
                }`}</Text>
                <Text noMargin weight="semiBold" color="secondary">{`${
                  user ? `${user.email}` : ''
                }`}</Text>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <EmojiTitle
                emoji={'🧑‍🤝‍🧑'}
                text={t(keys.settings.VARIABLE_TEAM, {
                  variable: user?.firstName,
                })}
              />
              <br />
              <div className={styles.editableHeader}>
                <div style={{ width: '100%' }}>
                  <Text weight="semiBold" noMargin>
                    {t(keys.settings.SUPERVISOR)}
                  </Text>
                  <Text weight="semiBold" size="sm" color="secondary" noMargin>
                    {t(keys.settings.DIRECT_MANAGER_OVERSEEING_YOUR_WORK)}
                  </Text>
                </div>
                {id === `${currentUser.id}` && (
                  <div className={styles.editIconContainer}>
                    <Icon
                      className={styles.editIcon}
                      baseClassName="material-icons-outlined"
                      onClick={() => navigate(paths.settings)}
                    >
                      edit
                    </Icon>
                  </div>
                )}
              </div>
              <br />
              {user?.supervisor ? (
                <TeamMemberCard user={{ ...user?.supervisor }} />
              ) : (
                <Text noMargin color="secondaryLight" weight="semiBold">
                  {t(keys.settings.SUPERVISOR_NOT_ASSIGNED)}
                </Text>
              )}
              <br />
              <Text weight="semiBold" noMargin>
                {t(keys.settings.TEAM_MEMBERS)}
              </Text>
              <Text weight="semiBold" size="sm" color="secondary" noMargin>
                {t(keys.settings.COLLEAGUES_WHO_REPORT_TO_SAME_SUPERVISOR)}
              </Text>
              <br />
              <div className={styles.teamListContainer}>
                {user?.supervisor?.team
                  ?.filter((u) => u.id !== user.id)
                  .map((u) => <TeamMemberCard key={u.id} user={u} />) || (
                  <Text noMargin color="secondaryLight" weight="semiBold">
                    {t(keys.common.NONE)}
                  </Text>
                )}
              </div>
              <br />
              <div className={styles.editableHeader}>
                <div style={{ width: '100%' }}>
                  <Text weight="semiBold" noMargin>
                    {t(keys.settings.DIRECT_REPORTS)}
                  </Text>
                  <Text weight="semiBold" size="sm" color="secondary" noMargin>
                    {t(keys.settings.TEAM_MEMBERS_YOU_MANAGE_DIRECTLY)}
                  </Text>
                </div>
                {currentUser.role === userRoles.admin && (
                  <div className={styles.editIconContainer}>
                    <Icon
                      className={styles.editIcon}
                      baseClassName="material-icons-outlined"
                      onClick={() => setDirectReportModalOpen(true)}
                    >
                      edit
                    </Icon>
                  </div>
                )}
              </div>
              <br />
              <div className={styles.teamListContainer}>
                {user?.team?.length ? (
                  user?.team
                    ?.filter((u) => u.id !== user.id)
                    .map((u) => <TeamMemberCard key={u.id} user={u} />)
                ) : (
                  <Text noMargin color="secondaryLight" weight="semiBold">
                    {t(keys.common.NONE)}
                  </Text>
                )}
              </div>
            </div>
            {!!showPersonalInfo && (
              <div className={styles.infoContainer}>
                <EmojiTitle
                  emoji={'🚀'}
                  text={t(keys.settings.VARIABLE_ACTIVITY, {
                    variable: user?.firstName,
                  })}
                />
                <br />
                <ActivityFeed history={history} />
              </div>
            )}
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.infoContainer}>
              <div className={styles.editableHeader}>
                <EmojiTitle emoji={'📞'} text={t(keys.common.INFORMATION)} />
                {canEditInformation && (
                  <div className={styles.editIconContainer}>
                    <Icon
                      className={styles.editIcon}
                      baseClassName="material-icons-outlined"
                      onClick={() => setEditInformationModalOpen(true)}
                    >
                      edit
                    </Icon>
                  </div>
                )}
              </div>
              <br />
              <Text weight="semiBold" noMargin>
                Basic Information
              </Text>
              <div style={{ padding: 10 }}>
                <Text weight="semiBold" color="secondary" noMargin>
                  {t(keys.common.NAME)}
                </Text>
                <Text
                  size="sm"
                  weight="semiBold"
                >{`${user?.firstName} ${user?.lastName}`}</Text>
                <Text weight="semiBold" color="secondary" noMargin>
                  {t(keys.common.ACTIVE)}
                </Text>
                <Text size="sm" weight="semiBold">
                  {getActivityText(user?.lastActivity)}
                </Text>
              </div>

              {!!showPersonalInfo && (
                <>
                  <Text weight="semiBold" noMargin>
                    Personal Information
                  </Text>
                  <div style={{ padding: 10 }}>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.PHONE)}
                    </Text>
                    <a
                      className={styles.link}
                      href={`tel:${user?.phone?.replace(/\D/g, '')}`}
                    >
                      <Text size="sm" weight="semiBold" color="accentPrimary">
                        {user?.phone}
                      </Text>
                    </a>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.EMAIL)}
                    </Text>
                    <a className={styles.link} href={`mailto:${user?.email}`}>
                      <Text
                        truncate
                        weight="semiBold"
                        color="accentPrimary"
                        size="sm"
                      >
                        {user?.email}
                      </Text>
                    </a>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.ADDRESS)}
                    </Text>
                    <Text weight="semiBold" size="sm" noMargin>{`${
                      user?.address || ''
                    }`}</Text>
                    <Text weight="semiBold" size="sm" noMargin>{`${
                      user?.city || ''
                    }`}</Text>
                    <Text weight="semiBold" size="sm">{`${
                      user?.postalCode || ''
                    }`}</Text>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.DATE_OF_BIRTH)}
                    </Text>
                    <Text size="sm" weight="semiBold">{`${
                      user?.dateOfBirth
                        ? moment(user?.dateOfBirth).format('MMMM Do YYYY')
                        : t(keys.settings.NOT_PROVIDED)
                    }`}</Text>
                  </div>
                  <Text weight="semiBold" noMargin>
                    Emergency Information
                  </Text>
                  <div style={{ padding: 10 }}>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.EMERGENCY_CONTACT)}
                    </Text>
                    <Text size="sm" weight="semiBold">{`${
                      user?.emergencyContact || t(keys.settings.NOT_PROVIDED)
                    }`}</Text>
                    <Text weight="semiBold" color="secondary" noMargin>
                      {t(keys.settings.IMPORTANT_INFO)}
                    </Text>
                    <Text size="sm" weight="semiBold">{`${
                      user?.importantInfo || t(keys.settings.NOT_PROVIDED)
                    }`}</Text>
                  </div>
                </>
              )}
            </div>
            {showPersonalInfo && (
              <div className={styles.infoContainer}>
                <div className={styles.editableHeader}>
                  <EmojiTitle emoji={'✅'} text={'Compliance'} />
                  {canEditInformation && (
                    <div className={styles.editIconContainer}>
                      {editRole ? (
                        <Icon
                          className={styles.editIcon}
                          baseClassName="material-icons-outlined"
                          onClick={() => setEditRole(false)}
                        >
                          close
                        </Icon>
                      ) : (
                        <Icon
                          className={styles.editIcon}
                          baseClassName="material-icons-outlined"
                          onClick={() => setEditRole(true)}
                        >
                          edit
                        </Icon>
                      )}
                    </div>
                  )}
                </div>
                <br />
                {
                  <div className={styles.complianceBox}>
                    <Text weight="semiBold" noMargin>
                      {t(keys.common.COMPLIANCE)}
                    </Text>
                    <Text
                      size="md"
                      weight="semiBold"
                      noMargin
                      className={getComplianceColor(user?.compliance || 0)}
                    >
                      {user?.workRole?.requirements?.length
                        ? `${user.compliance}%`
                        : t(keys.common.NA)}
                    </Text>
                  </div>
                }
                {returnRole()}

                {user?.workRole?.requirements ? (
                  <RequirementsList
                    user={user}
                    expandedCertificateState={expandedCertificateState}
                    toggleCertificateExpand={toggleCertificateExpand}
                  />
                ) : null}
              </div>
            )}
            <div className={styles.infoContainer}>
              <EmojiTitle emoji={'🔑'} text={t(keys.common.ACCESS)} />
              <br />
              <Text weight="semiBold" color="secondary" noMargin>
                {t(keys.settings.COMPANY_ACCESS)}
              </Text>
              <Text
                size="sm"
                weight="semiBold"
                color={user?.role === userRoles.admin ? 'red' : 'green'}
                noMargin
              >
                {user?.role === userRoles.admin ? 'Admin' : 'Standard'}
              </Text>
              <br />
              <Text weight="semiBold" color="secondary" noMargin>
                {t(keys.settings.WORKSPACE_ACCESS)}
              </Text>
              <div className={styles.workspacesContainer}>
                {workspacePermissions.map(
                  ({ accessLevel, workspace: { id, title, logoUrl } }) => (
                    <div className={styles.workspaceContainer} key={id}>
                      <img
                        className={styles.workspaceLogo}
                        src={logoUrl || defaultLogo}
                        alt={title}
                      />
                      <div className={styles.workspaceRight}>
                        <Text size="md" weight="semiBold" noMargin>
                          {title}
                        </Text>
                        <Text
                          size="sm"
                          weight="semiBold"
                          color={
                            accessLevel === userRoles.admin ? 'red' : 'green'
                          }
                          noMargin
                        >
                          {accessLevel === userRoles.admin
                            ? 'Admin'
                            : 'Standard'}
                        </Text>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
            {!!showPersonalInfo && (
              <div className={styles.infoContainer}>
                <div className={styles.containerHeader}>
                  <EmojiTitle emoji={'📁'} text={t(keys.common.FILES)} />
                  {(isAdmin || isCreator) && (
                    <Icon
                      className={styles.addFileButton}
                      onClick={() =>
                        openModal({
                          modalName: modals.fileUpload,
                          variables: {
                            user,
                            from: 'USER',
                            onSubmit: ({
                              url,
                              size,
                              fileType,
                              extension,
                              name,
                              expires,
                              status,
                              downloadAllowed,
                              type,
                            }) =>
                              addFile({
                                variables: {
                                  url,
                                  size,
                                  fileType,
                                  extension,
                                  name,
                                  userId: user.id,
                                  expires: expires
                                    ? moment(parseInt(expires)).format(
                                        'YYYY-MM-DD',
                                      )
                                    : null,
                                  status,
                                  downloadAllowed,
                                  type,
                                  hidden: true,
                                },
                              }).then(() => refetchFiles()),
                          },
                        })
                      }
                    >
                      add
                    </Icon>
                  )}
                </div>
                <br />
                <div className={styles.filesContainer}>
                  {files?.length ? (
                    files.map((f, index) => (
                      <FileCard
                        key={`${f.id}-${index}`}
                        className={styles.fileCard}
                        onClick={() => openFileViewer(f)}
                        simple
                        file={f}
                      />
                    ))
                  ) : (
                    <div>
                      <img
                        src="https://app.opasmobile.com/assets/graphics/no_user_files.png"
                        alt="files"
                        className={styles.emptyStateFilesGraphic}
                      />
                      <Text textAlign="center" size="md" weight="bold" noMargin>
                        {t(keys.settings.NO_LINKED_FILES)}
                      </Text>
                      <Text
                        textAlign="center"
                        size="sm"
                        weight="semiBold"
                        color="secondary"
                      >
                        {t(keys.settings.NO_LINKED_FILES_MESSAGE)}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

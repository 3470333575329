import { useState, useEffect } from 'react';
import styles from './PDFDownloadButton.module.scss';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Bugsnag from '@bugsnag/js';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { CorrectiveActionDocument } from '../utilities/pdf_export/audits/corrective_actions';
import { useCurrentUser } from '../providers/UserProvider';

export default function PDFDownloadButton({ data, fileName }) {
  const [PDFError, setPDFError] = useState(false);
  const [componentError, setComponentError] = useState(null);
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  function getDocument() {
    const type = data.__typename;
    switch (type) {
      case 'CorrectiveAction':
        return (
          <CorrectiveActionDocument
            correctiveAction={data}
            company={user.company}
          />
        );
      default:
        return null;
    }
  }
  const document = getDocument();

  useEffect(() => {
    if (componentError) {
      Bugsnag.notify(componentError);
      console.error(componentError);
      setPDFError(true);
    }
  }, [componentError]);

  return !!data && !PDFError ? (
    <div>
      <PDFDownloadLink
        onError={({ error }) => {
          setComponentError(error);
        }}
        document={document}
        fileName={fileName}
      >
        {({ loading, url, error }) => {
          if (error) {
            setComponentError(error);
          }

          return (
            <Button
              className={styles.button}
              variant="primary"
              outlined
              icon="download"
              value={
                loading || !url
                  ? t(keys.action.LOADING)
                  : t(keys.action.DOWNLOAD_VARIABLE, { variable: null })
              }
            />
          );
        }}
      </PDFDownloadLink>
    </div>
  ) : (
    <Button
      outlined
      icon="refresh"
      className={styles.button}
      value="Error Loading PDF"
      onClick={() => {
        window.location.reload();
      }}
    />
  );
}

import React from 'react';
import styles from './InspectionsList.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useNavigate } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { paths, searchParamKeys } from '../../constants/strings.js';
import { convertTemplateFiltersToQueryParams } from '../../utilities/filtering';
import { Icon } from '@mui/material';
import { assessmentTypes } from '../../constants/strings.js';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import TablePrototype from '../../components/TablePrototype.jsx';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider.jsx';
import { useLocation } from 'react-router-dom';
import emptyStateTemplate from '../../assets/empty_state_images/templates.svg';
import { getColor } from '../../constants/strings';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import { customColors } from '../../utilities/misc.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import { modals } from '../../providers/modals.js';
import { useCurrentUser } from '../../providers/UserProvider.jsx';
const templatesQuery = loader('./TemplatesList.graphql');

export default function TemplatesList({ selected = null, showType = true }) {
  const { workspace } = useWorkspace();
  const { allWorkspaceUsers } = useWorkspacePermissions();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const [rawFilters, setRawFilters] = useState({});
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { labelCell, dateCell, textCell } = useTableComponents();
  const { openModal } = useModal();
  const { user } = useCurrentUser();

  const isObservation = pathname
    .split('/')[3]
    .includes(searchParamKeys.observation);

  const filters = useMemo(() => {
    return convertTemplateFiltersToQueryParams(
      rawFilters,
      workspace?.id,
      isObservation,
      selected,
    );
  }, [isObservation, rawFilters, selected, workspace?.id]);

  const {
    loading,
    data: { inspectionTemplates = [], inspectionTemplatesCount = 0 } = {},
  } = useQuery(templatesQuery, {
    skip: !page || !pageSize || !filters || !sortBy || !workspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters: [
          ...filters,
          { field: 'isArchived', operator: 'eq', value: ['false'] },
          { field: 'internalType', operator: 'eq', value: 'null' },
        ],
      },
    },
  });

  const userOptions = useMemo(() => {
    const userOptions = allWorkspaceUsers.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    }));
    return userOptions;
  }, [allWorkspaceUsers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '',
        enableColumnFilter: false,
        enableSorting: false,
        enableRowClick: false,
        size: 30,
        Cell: ({ row }) => {
          const type = row.original.templateType.toLowerCase();
          const isDraft = row.original.isDraft;
          return isDraft ? null : (
            <Icon
              style={{
                color: customColors.BLUE,
                fontSize: '1rem',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                openModal({
                  modalName: modals.createAssessment,
                  variables: {
                    types: [
                      assessmentTypes[type === 'vehicle' ? 'fleet' : type],
                    ],
                    title: t(keys.incidents.START_INCIDENT_ASSESSMENT),
                    type: assessmentTypes[type === 'vehicle' ? 'fleet' : type],
                    participants: [user],
                    template: row.original,
                  },
                });
              }}
            >
              add
            </Icon>
          );
        },
      },
      {
        accessorKey: 'templateType',
        header: showType ? t(keys.common.TYPE) : undefined,
        size: showType ? 100 : 50,
        filterVariant: 'select',
        filterSelectOptions: Object.keys(assessmentTypes).map((type) => ({
          value: assessmentTypes[type].key,
          label: t(assessmentTypes[type].title),
        })),
        enableColumnFilter: !isObservation,
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: getColor(cell?.getValue()?.toLowerCase()),
            }}
          >
            {assessmentTypes[cell.getValue().toLowerCase()]?.icon ||
              'local_shipping'}
          </Icon>
        ),
      },
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        grow: true,
        minSize: 150,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.DRAFT) },
          { value: 'false', label: t(keys.common.PUBLISHED) },
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: !!cell.getValue()
              ? t(keys.common.DRAFT)
              : t(keys.common.PUBLISHED),
            color: !!cell.getValue() ? 'yellow' : 'green',
          }),
      },
      {
        accessorKey: 'revisionNumber',
        header: t(keys.common.REV),
        grow: true,
        enableSorting: false,
        size: 100,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'documentString',
        header: t(keys.templates.FORM_ID),
        grow: true,
        enableSorting: false,
        size: 100,
        Cell: ({ cell }) => textCell({ cell }),
      },
      ...(isObservation
        ? []
        : [
            {
              accessorKey: 'analysisRequested',
              header: t(keys.templates.SECOND_SIGHT),
              minSize: 150,
              grow: true,
              filterVariant: 'select',
              filterSelectOptions: [
                { value: 'true', label: t(keys.common.YES) },
                { value: 'false', label: t(keys.common.NO) },
              ],
              Cell: ({ cell }) => (
                <Icon
                  baseClassName="material-icons-outlined"
                  sx={{
                    color: cell.getValue() ? '#28a745' : '#6c757d',
                    fontSize: '1.25rem',
                  }}
                >
                  {!!cell.getValue() ? 'check' : 'close'}
                </Icon>
              ),
            },
            {
              accessorKey: 'reviewRequired',
              header: t(keys.templates.APPROVAL_REQUIRED),
              minSize: 150,
              grow: true,
              filterVariant: 'select',
              filterSelectOptions: [
                { value: 'true', label: t(keys.common.YES) },
                { value: 'false', label: t(keys.common.NO) },
              ],
              Cell: ({ cell }) => (
                <Icon
                  baseClassName="material-icons-outlined"
                  sx={{
                    color: cell.getValue() ? '#28a745' : '#6c757d',
                    fontSize: '1.25rem',
                  }}
                >
                  {!!cell.getValue() ? 'check' : 'close'}
                </Icon>
              ),
            },
            {
              accessorKey: 'workspaceId',
              header: t(keys.templates.ALL_WORKSPACES),
              minSize: 150,
              grow: true,
              filterVariant: 'select',
              filterSelectOptions: [
                { value: 'is', label: t(keys.common.YES) },
                { value: 'eq', label: t(keys.common.NO) },
              ],
              Cell: ({ cell }) => (
                <Icon
                  baseClassName="material-icons-outlined"
                  sx={{
                    color: cell.getValue() ? '#6c757d' : '#28a745',
                    fontSize: '1.25rem',
                  }}
                >
                  {cell.getValue() ? 'close' : 'check'}
                </Icon>
              ),
            },
          ]),
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        minSize: 350,
        filterVariant: 'date-range',
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
      },
    ],
    [
      showType,
      t,
      isObservation,
      userOptions,
      openModal,
      user,
      textCell,
      labelCell,
      dateCell,
    ],
  );

  return (
    <div className={styles.container}>
      <TablePrototype
        isLoading={loading}
        columns={columns}
        rightClickNavigation={paths.templates}
        data={inspectionTemplates}
        count={inspectionTemplatesCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setRawFilters={setRawFilters}
        setSortBy={setSortBy}
        setIsAscending={setIsAscending}
        onRowClick={(template) =>
          navigate(getRoute(workspace?.id, paths.templates, template.id))
        }
        emptyState={{
          title: t(keys.action.NOT_FOUND, {
            variable: t(keys.common.TEMPLATES),
          }),
          text:
            filters.length > 2
              ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                  variable: t(keys.common.TEMPLATES),
                })
              : t(keys.action.EMPTY_STATE_MESSAGE, {
                  variable: t(keys.common.TEMPLATES),
                }),
          image: emptyStateTemplate,
        }}
      />
    </div>
  );
}

import React from 'react';
import { Page, Document, Text, Image, View, Link } from '@react-pdf/renderer';
import { styles, PageHeader, PageHeaderLogo, PageFooter } from './constants.js';
import { formatTime, getLocalTime } from '../time.js';
import {
  adminAviationOptions,
  aviationFieldsAdditions,
  incidentDetailQuestions,
} from '../incidents.js';
import { incidentStatus, paths } from '../../constants/strings.js';
import { getRoute } from '../../constants/strings.js';
import { Notes } from './constants.js';
import { appEndpoints } from '../../constants/endpoints.js';
import { isProd } from '../misc.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../translator/translation_keys.js';
import { getTranslationKey } from '../translator/translation_keys.js';
import { vehicleString } from '../strings.js';
import { calculateRisk } from '../riskMatrix.js';

const reviewerInputs = Object.keys(adminAviationOptions);

const AdditionalQuestions = ({ questions }) => {
  if (!questions) {
    return null;
  }

  return (
    <View>
      <Text style={styles.categoryHeader}>Aviation Details</Text>
      {Object.entries(questions)?.map(([key, value]) => {
        if (reviewerInputs.includes(key)) {
          return 'skip';
        }
        const display = () => {
          if (!!value) {
            switch (key) {
              case 'units':
                return 'skip';
              case 'category':
                return value?.label;
              case 'nonFlyingPilot':
              case 'pilot':
                return `${value?.firstName} ${value?.lastName}`;
              case 'selectedOptions':
                return `${value.join(', ')}`;
              case 'personnel':
                return `${Object.entries(value)?.map(
                  ([key, value]) => `${key}: ${value} `,
                )}`;
              case 'grossWeight':
                return `${value} ${questions.units?.label}`;
              case 'vehicle':
                return `${!!value ? vehicleString(value) : 'None'}`;
              default:
                return value;
            }
          } else {
            return 'None';
          }
        };
        const text = display();
        return text === 'skip' ? null : (
          <View key={key} style={styles.row}>
            <Text style={styles.text}>
              {aviationFieldsAdditions[key] || key}
            </Text>
            <Text style={styles.semiBoldText}>{text || 'None'}</Text>
          </View>
        );
      })}
    </View>
  );
};

export const IncidentDocument = ({
  incident,
  incidentImages,
  inspections,
  workspace,
  notes,
}) => {
  const company = incident?.creator?.company || {};

  const { t, i18n, ready } = useTranslation();
  const language = i18n.resolvedLanguage;
  const dropdownKeys = i18n.getResourceBundle(
    language,
    'incidents',
  ).dropdownOptions;
  if (!company) {
    return;
  }

  const riskLevel = () => {
    if (!!incident?.initialImpact && !!incident?.initialProbability) {
      const impact = incident.impact || incident.initialImpact;
      const probability = incident.probability || incident.initialProbability;
      const total = impact * probability;

      return {
        string: `${calculateRisk(total)?.level.toUpperCase()} RISK`,
        color: calculateRisk(total)?.textColor,
      };
    }
    return null;
  };

  const translate = (values) => {
    let newValues = [];
    if (language !== 'en') {
      values.forEach((val) => {
        newValues.push(dropdownKeys[val]);
      });
      return newValues.join(', ');
    }
    return values.join(', ');
  };

  const convertBoolean = (value) => {
    if (value === true) {
      return t(keys.common.YES);
    }
    if (value === false) {
      return t(keys.common.NO);
    }
    return dropdownKeys[value] ? dropdownKeys[value] : value;
  };

  const formatValue = (type, value) => {
    if (value) {
      switch (type) {
        case 'DATE':
          return <Text style={styles.semiBoldText}>{formatTime(value)}</Text>;
        case 'SELECT_MULTI':
          return (
            <Text style={styles.semiBoldText}>
              {translate(value?.split('|'))}
            </Text>
          );
        case 'USER':
          return (
            <Text style={styles.semiBoldText}>
              {`${value?.firstName} ${value?.lastName}`}
            </Text>
          );
        case 'SIGNATURE':
          return <Image style={styles.signature} src={value} />;
        default:
          return (
            <Text style={styles.semiBoldText}>{convertBoolean(value)}</Text>
          );
      }
    } else {
      return (
        <Text style={styles.semiBoldText}>
          {value === false ? t(keys.common.NO) : t(keys.common.NONE)}
        </Text>
      );
    }
  };

  const reviewer = incident?.reviewers?.find(
    (r) => r.status === 'INVESTIGATION',
  );

  const closerNotes =
    incident?.reviewers?.find((r) => r.status === 'COMPLETE')?.notes || 'None';

  const requiredComments = incident?.reviewers?.filter(
    (r) => r.status !== 'INVESTIGATION' && r.status !== 'COMPLETE',
  );

  const isAviation = incident.type === 'Aviation';

  return (
    incident &&
    ready && (
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <PageHeaderLogo company={company} />
          <PageHeader
            company={company}
            data={incident}
            title={incident.title}
            workspace={workspace}
            type="Incident"
          />
          <View>
            <Text style={styles.titleHeader}>{incident.title}</Text>
          </View>
          <View wrap={false}>
            <Text style={styles.categoryHeader}>
              {t(keys.incidents.INCIDENT_DETAILS)}
            </Text>
            <View style={styles.row} wrap={false}>
              <Text style={styles.text}>{t(keys.incidents.TYPE)}</Text>
              <Text style={styles.semiBoldText}>{`${t(
                getTranslationKey(incident.type, 'incidents') || incident.type,
              )} - ${t(
                getTranslationKey(incident.subtype, 'incidents') ||
                  incident.subtype,
              )}`}</Text>
            </View>
            {incidentDetailQuestions.map((value, index) => (
              <View style={styles.row} key={`${value}-${index}`}>
                <Text style={styles.text}>{t(value.title)}</Text>
                <Text style={styles.semiBoldText}>
                  {formatValue(value.inputType, incident[value.convertToQuery])}
                </Text>
              </View>
            ))}
            <View style={styles.row}>
              <Text style={styles.text}>Downtime Hours</Text>
              <Text style={styles.semiBoldText}>
                {incident.downTimeHrs || 0}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>Lost Time Hours</Text>
              <Text style={styles.semiBoldText}>
                {incident.lostTimeHrs || 0}
              </Text>
            </View>
            {!!riskLevel() ? (
              <View style={styles.row}>
                <Text style={styles.text}>Risk Assessment</Text>
                <View>
                  <Text style={styles[riskLevel().color]}>
                    {riskLevel().string}
                  </Text>
                  <Text style={styles.semiBoldText}>
                    {`Impact: ${
                      incident?.impact || incident?.initialProbability
                    }/5`}
                  </Text>
                  <Text style={styles.semiBoldText}>
                    {`Probability: ${
                      incident?.probability || incident?.initialProbability
                    }/5`}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>
          {isAviation ? (
            <AdditionalQuestions questions={incident.aviationQuestions || []} />
          ) : null}
          {!!reviewer && (
            <View style={styles.section} wrap={false}>
              <Text style={styles.categoryHeader}>
                {t(keys.incidents.REVIEWER_INFORMATION)}
              </Text>
              <View style={styles.row}>
                <Text style={styles.text}>
                  {t(keys.incidents.REVIEWED_DATE)}
                </Text>
                <Text style={styles.semiBoldText}>
                  {getLocalTime(reviewer?.dateModified).format(
                    'dddd, MMMM Do YYYY',
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.text}>{t(keys.incidents.REVIEWED_BY)}</Text>
                <Text style={styles.semiBoldText}>
                  {`${reviewer?.user?.firstName} ${reviewer?.user?.lastName}`}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.text}>Reviewer Comment</Text>
                <Text style={styles.semiBoldText}>{`${reviewer?.notes}`}</Text>
              </View>
              {isAviation &&
                Object.entries(incident?.aviationQuestions)?.map(
                  ([key, value]) => {
                    if (!reviewerInputs.includes(key)) {
                      return null;
                    }
                    return (
                      <View key={key} style={styles.row}>
                        <Text style={styles.text}>
                          {adminAviationOptions[key]}
                        </Text>
                        <Text style={styles.semiBoldText}>
                          {!!value ? 'Yes' : 'No'}
                        </Text>
                      </View>
                    );
                  },
                )}
            </View>
          )}
          {!!requiredComments?.length && (
            <View style={styles.section} wrap={false}>
              <Text style={styles.categoryHeader}>Stakeholder Comments</Text>
              {requiredComments?.map(({ user, notes, id }) => (
                <View style={styles.row} key={id}>
                  <Text style={styles.text}>
                    {`${user?.firstName} ${user?.lastName}`}
                  </Text>
                  <Text style={styles.semiBoldText}>{notes}</Text>
                </View>
              ))}
            </View>
          )}
          {incident.status === incidentStatus.complete.key && (
            <View>
              <View style={styles.section} wrap={false}>
                <Text style={styles.categoryHeader}>
                  {t(keys.incidents.INCIDENT_CLOSURE_DETAILS)}
                </Text>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    {t(keys.incidents.DATE_INCIDENT_CLOSED)}
                  </Text>
                  <Text style={styles.semiBoldText}>
                    {getLocalTime(incident?.closedAt).format(
                      'dddd, MMMM Do YYYY',
                    )}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>{t(keys.incidents.CLOSED_BY)}</Text>
                  <Text style={styles.semiBoldText}>
                    {incident?.closer?.firstName} {incident?.closer?.lastName}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>Closing Comments</Text>
                  <Text style={styles.semiBoldText}>{closerNotes}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    {t(keys.incidents.CLOSER_SIGNAURE)}
                  </Text>
                  <Image
                    src={`${incident.closerSignatureUrl}`}
                    style={styles.signature}
                  ></Image>
                </View>
              </View>
            </View>
          )}
          <View style={styles.section}>
            <Text style={styles.categoryHeader}>Images/Files</Text>
            {incidentImages?.length ? (
              incidentImages?.map((image, index) => (
                <View
                  wrap={false}
                  style={styles.row}
                  key={`${image.id}-${index}`}
                >
                  <Text style={styles.semiBoldTextHeader}>
                    {image.description ??
                      t(keys.incidents.NO_IMAGE_DESCRIPTION)}
                  </Text>
                  <Image src={image.imageUrl} style={styles.image} />
                </View>
              ))
            ) : (
              <Text style={styles.text}>
                {t(keys.incidents.NO_INCIDENT_IMAGES)}
              </Text>
            )}
          </View>
          <View style={styles.section}>
            <Text style={styles.categoryHeader}>Supplementary Reports</Text>
            {inspections?.length ? (
              inspections?.map((inspection, index) => (
                <View style={styles.row} key={`${inspection.id}-${index}`}>
                  <Text style={styles.text}>
                    {`${inspection.creator.firstName} ${
                      inspection.creator.lastName
                    }, ${getLocalTime(inspection.dateCreated).format(
                      'dddd, MMMM Do YYYY',
                    )}`}
                  </Text>
                  <Link
                    key={`${inspection} + ${index}`}
                    style={styles.linkLeft}
                    src={
                      appEndpoints[isProd ? 'prod' : 'dev'] +
                      getRoute(
                        inspection.workspaceId,
                        paths.assessment,
                        inspection.id,
                      )
                    }
                  >
                    {inspection.template.title}
                  </Link>
                </View>
              ))
            ) : (
              <Text style={styles.text}>
                {t(keys.incidents.NO_INCIDENT_ASSESSMENTS)}
              </Text>
            )}
            <Notes notes={notes} />
            <Text style={styles.text}>{incident.notes}</Text>
          </View>
          <PageFooter />
        </Page>
      </Document>
    )
  );
};

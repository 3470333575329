import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { paths } from '../../constants/strings.js';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import Label from '../../components/action_items/Label.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import Icon from '../../components/Icon.jsx';
import MTTable from '../../components/tables/MTable.jsx';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';
import { customColors } from '../../utilities/misc.js';
import { modals } from '../../providers/modals.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import { useCurrentUser } from '../../providers/UserProvider.jsx';

const templatesQuery = loader('./AuditTemplates.graphql');

export default function TemplateTable() {
  const { workspace } = useWorkspace();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dateCell, textCell, userCell, userFilterOptions } =
    useTableComponents();
  const { user } = useCurrentUser();
  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
  });

  const { filters, page, pageSize, sort, subfilters } = useURLParams();

  const {
    loading,
    data: { inspectionTemplates = [], inspectionTemplatesCount = 0 } = {},
  } = useQuery(templatesQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        subfilters,
        page,
        pageSize,
        sort,
        filters: [
          { field: 'templateType', operator: 'eq', value: 'AUDIT' },
          { field: 'isArchived', operator: 'eq', value: ['false'] },
          ...filters,
        ],
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '',
        enableColumnFilter: false,
        enableSorting: false,
        enableRowClick: false,
        size: 30,
        Cell: ({ row }) => {
          const isDraft = row.original.isDraft;
          return isDraft ? null : (
            <Icon
              style={{
                color: customColors.BLUE,
                fontSize: '1rem',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                openModal({
                  modalName: modals.audit,
                  variables: {
                    template: row.original,
                    participants: [user],
                  },
                });
              }}
            >
              add
            </Icon>
          );
        },
      },
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        grow: true,
        minSize: 150,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'revisionNumber',
        header: t(keys.common.REV),
        grow: true,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        minSize: 150,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.DRAFT) },
          { value: 'false', label: t(keys.common.PUBLISHED) },
        ],
        Cell: ({ cell }) => (
          <Label
            color={!cell.getValue() ? 'green' : 'yellow'}
            name={
              cell.getValue() ? t(keys.common.DRAFT) : t(keys.common.PUBLISHED)
            }
          />
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('all'),
        Cell: ({ cell }) => userCell({ cell }),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        minSize: 350,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
    ],
    [
      dateCell,
      dateSelectors,
      openModal,
      t,
      textCell,
      user,
      userCell,
      userFilterOptions,
    ],
  );

  return (
    <MTTable
      rightToolbar="templates"
      isLoading={loading}
      data={inspectionTemplates || []}
      columns={columns}
      rowCount={inspectionTemplatesCount}
      onRowClick={(template) =>
        navigate(getRoute(workspace?.id, paths.templates, template.id))
      }
      onClearAllFilters={() =>
        setDateSelectors({
          dateCreatedMin: '',
          dateCreatedMax: '',
        })
      }
    />
  );
}

import styles from './FileUploadInput.module.scss';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useState } from 'react';

//this is used to override the default file input modal for translation

export default function FileUploadInput({
  onChange,
  accept,
  multiple = false,
}) {
  const { t } = useTranslation();
  const [selectedFileNames, setSelectedFileNames] = useState('');

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files?.length > 0) {
      onChange(event);
      setSelectedFileNames(Array.from(files).map(({ name }) => name));
    } else {
      setSelectedFileNames('');
    }
  };
  return (
    <div className={styles.container}>
      <label>
        <Text
          noMargin
          weight="semiBold"
          className={styles.button}
          textAlign="center"
        >
          <input
            className={styles.hide}
            type={'file'}
            accept={accept || '*/*'}
            multiple={multiple}
            onChange={handleFileChange}
          />
          {t(keys.action.SELECT_VARIABLE, { variable: t(keys.common.FILE) })}
        </Text>
      </label>
      <Text noMargin className={styles.text}>
        {selectedFileNames
          ? selectedFileNames.map((name) => (
              <Text key={name} noMargin className={styles.text}>
                {name}
              </Text>
            ))
          : t(keys.action.SELECTED, { variable: t(keys.common.NONE) })}
      </Text>
    </div>
  );
}

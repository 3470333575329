import { View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { getLocalTime } from '../../time';

const fontSize = 8;
const textPrimary = '#1d1d1f';
const blue = '#0d6efd';

const styles = StyleSheet.create({
  categoryHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 12,
  },
  text: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
  },
  semiBold: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
  },
  categoryContainer: {
    flexDirection: 'column',
    gap: 1,
    padding: 10,
  },
  flex: {
    flexDirection: 'row',
    gap: 50,
    alignItems: 'center',
  },
  image: {
    maxWidth: 250,
    maxHeight: 50,
  },
});
export const Signatures = ({ signatures }) => {
  return !signatures?.length ? null : (
    <View style={styles.categoryContainer}>
      <Text style={styles.categoryHeader}>Signatures</Text>
      {signatures?.map((s) => (
        <View style={styles.flex} key={s.id}>
          <View>
            <Text style={styles.semiBold}>{s.name}</Text>
            <Text style={styles.text} size="sm" color="secondary" noMargin>
              {getLocalTime(parseInt(s.dateCreated)).format(
                'ddd MMMM Do, YYYY HH:mm',
              )}
            </Text>
          </View>
          <Image style={styles.image} src={s.url} />
        </View>
      ))}
    </View>
  );
};

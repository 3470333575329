import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/typography';
import TextWithIcon from '../../components/typography/TextWithIcon';
import { calculateRisk } from '../../utilities/riskMatrix';
import { getLocalTime } from '../../utilities/time';
import styles from './InspectionIncidentHeader.module.scss';
import { getRoute, paths } from '../../constants/strings';

export default function InspectionIncidentHeader({ incident }) {
  const {
    title,
    description,
    externalId,
    dateOfIncident,
    impact,
    probability,
    initialImpact,
    initialProbability,
    id,
    workspaceId,
  } = incident || {};

  const i = impact || initialImpact;
  const p = probability || initialProbability;
  const risk = i * p;
  const riskLevel = calculateRisk(risk);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text weight="semiBold" noMargin>
          {getLocalTime(dateOfIncident)?.format('dddd, MMMM Do YYYY @ HH:mm')}
        </Text>
        <TextWithIcon
          link
          noMargin
          weight="semiBold"
          color="accentPrimary"
          hover
          textAlign="right"
          onClick={() => navigate(getRoute(workspaceId, paths.incident, id))}
        >
          {externalId || id.toString().padStart('7', '0')}
        </TextWithIcon>
      </div>
      <div className={styles.riskLevel}>
        <Text weight="semiold" noMargin color={riskLevel?.textColor}>
          {!!riskLevel
            ? `${riskLevel?.level} Risk`
            : 'No Risk Assessment Completed'}
        </Text>
        <Text
          noMargin
          weight="semiBold"
          color="secondary"
          size="sm"
        >{`Impact: ${i} Probability: ${p}`}</Text>
        <Text noMargin weight="semiBold">{``}</Text>
      </div>
      <br />
      <Text weight="semiBold" noMargin>
        {title}
      </Text>

      <Text noMargin>{description}</Text>
    </div>
  );
}

import { loader } from 'graphql.macro';
import styles from './ObservationPage.module.scss';
import { useParams } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import { openFileViewer } from '../../graphql/cache/modal';
import { Button } from '../../components';
import { useCurrentUser } from '../../providers/UserProvider';
import { useMutation } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { useActionItemModal } from '../../hooks/misc';
import Icon from '../../components/Icon';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { customColors } from '../../utilities';
import { Form } from 'react-bootstrap';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import {
  observationClassificationTypes,
  observationStatus,
} from '../../utilities/observations';
import { imageExtensions } from '../../utilities/files';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import findFileIcon from '../../utilities/files';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import Accordian from '../../components/Accordian';
import Label from '../../components/action_items/Label';
import Notes from '../../components/notes/Notes';

const observationQuery = loader('./ObservationList.fetch.graphql');
const updateObservationMutation = loader('./ObservationList.update.graphql');
const addActionItemMutation = loader('./ObservationList.addActionItem.graphql');
const addObservationImageMutation = loader('./ObservationPage.addFile.graphql');

export default function ObservationPage() {
  const { id } = useParams();
  const { user, isAdmin } = useCurrentUser();
  const [updateObservation] = useMutation(updateObservationMutation);
  const { openActionItemModal } = useActionItemModal();
  const [addActionItem] = useMutation(addActionItemMutation);
  const [addFile] = useMutation(addObservationImageMutation);
  const observationId = parseInt(id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal, openConfirmationModal } = useModal();
  const [isEditing, setIsEditing] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);

  const {
    refetch,
    networkStatus,
    data: { observations: [observation] = [{}] } = {},
  } = useQuery(observationQuery, {
    skip: !id,
    fetchPolicy: 'network-only',
    variables: {
      options: {
        filters: { field: 'id', operator: 'eq', value: `${id}` },
      },
    },
  });

  const questions = observation?.items ? JSON.parse(observation?.items) : [];
  const {
    dateTime,
    description,
    location,
    images,
    reviewer,
    dateCreated,
    dateReviewed,
    participants,
    creator,
    actionItems,
    workspace,
    negative,
    status,
    incident,
    closer,
    dateClosed,
    externalId,
    title,
    type,
    private: privateObservation,
  } = observation || {};

  const [newTitle, setNewTitle] = useState(title);
  const [newType, setNewType] = useState(type);
  const isReviewed = !!reviewer;
  const readOnly = status === observationStatus.COMPLETE.key || !isAdmin;
  const isCreator = user?.id === creator?.id;
  const isClosed = status === observationStatus.COMPLETE.key;
  const ready = networkStatus !== NetworkStatus.loading;

  const canUpdate = useMemo(() => {
    return status === observationStatus.IN_PROGRESS.key;
  }, [status]);

  useEffect(() => {
    if (!!title && title !== newTitle) {
      setNewTitle(title);
    }
    if (!!type && newType !== type) {
      setNewType(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, type]);

  return !ready ? (
    <Spinner variant="primary" animation="border" />
  ) : (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.header}>
            <Text weight="bold" size="lg" noMargin>
              {user?.company?.observationName
                ? user.company?.observationName + ' Details'
                : t(keys.observations.OBSERVATION_DETAILS)}
            </Text>
            <div className={styles.icons}>
              {!!incident ? (
                <Icon style={{ fontSize: '2rem' }} color="red">
                  fmd_bad
                </Icon>
              ) : null}
              <FlagCircleIcon
                sx={{
                  fontSize: '2rem',
                  color: negative
                    ? customColors.RED_LIGHT
                    : customColors.GREEN_LIGHT,
                }}
              />
            </div>
          </div>

          <div className={styles.private}>
            <Form.Check
              checked={privateObservation}
              onChange={() => {
                const description = `Are you sure you want to make this observation ${
                  privateObservation ? 'public' : 'private'
                }? This will ${
                  privateObservation
                    ? 'allow all users '
                    : 'allow only admins and selected team '
                }to view this observation.`;
                openConfirmationModal({
                  title: 'Change Observation Visibility',
                  description,
                  variant: 'danger',
                  onSubmit: () => {
                    updateObservation({
                      variables: {
                        id: observationId,
                        private: !privateObservation,
                      },
                    });
                  },
                });
              }}
            />
            <Text noMargin weight="semiBold">
              {t(keys.action_items.PRIVATE_TITLE)}
            </Text>
            <Text size="sm" color="secondary" noMargin>
              {privateObservation
                ? t(keys.action_items.PRIVATE_TEXT)
                : t(keys.action_items.PUBLIC)}
            </Text>
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.flexBetween}>
              <div className={styles.column}>
                {isEditing ? (
                  <Form.Control
                    placeholder="Enter Title"
                    value={newTitle}
                    onChange={(e) => {
                      setNewTitle(e.target.value);
                    }}
                    onBlur={() => {
                      updateObservation({
                        variables: { id: observationId, title: newTitle },
                      });
                      setIsEditing(false);
                    }}
                  />
                ) : (
                  <Text
                    weight="bold"
                    color={!!newTitle ? 'primary' : 'red'}
                    size="lg"
                    noMargin
                  >
                    {newTitle || 'No Title*'}
                  </Text>
                )}
                {isEditing ? (
                  <DropdownText
                    required={true}
                    items={observationClassificationTypes}
                    onChange={(value) => {
                      setNewType(value);
                      updateObservation({
                        variables: {
                          id: observationId,
                          type: value,
                          negative:
                            value === 'Positive Observation' ? false : true,
                        },
                      });
                    }}
                    selected={newType}
                    placeholder={'Classification'}
                    highlight={!newType}
                  />
                ) : (
                  <Text noMargin color={!!newTitle ? 'primary' : 'red'}>
                    {newType || 'No Classification Selected*'}
                  </Text>
                )}
              </div>
              {!isClosed && (
                <Icon
                  className={styles.editIcon}
                  onClick={() => {
                    if (isEditing) {
                      updateObservation({
                        variables: { id: observationId, title: newTitle },
                      });
                    }
                    setIsEditing(!isEditing);
                  }}
                  style={{
                    fontSize: '1.5rem',
                    opacity: 0.5,
                    cursor: 'pointer',
                  }}
                >
                  {isEditing ? 'save' : 'edit'}
                </Icon>
              )}
            </div>
          </div>

          <Accordian icon="summarize" title={t(keys.common.DETAILS)}>
            <Text weight="semiBold" noMargin>
              {t(keys.observations.DATE_AND_TIME)}
            </Text>
            <Text>
              {getLocalTime(dateTime).format('dddd MMMM DD, YYYY HH:mm')}
            </Text>
            <Text weight="semiBold" noMargin>
              {t(keys.common.LOCATION)}
            </Text>
            <Text>{location || t(keys.assessments.NO_ANSWER_PROVIDED)}</Text>
            <Text weight="semiBold" noMargin>
              {t(keys.common.DESCRIPTION)}
            </Text>
            <Text>{description}</Text>
            {questions?.map(({ id, value, title, description }) => (
              <div key={id}>
                <Text weight="semiBold" noMargin>
                  {title}
                </Text>
                <Text color="secondary" size="sm" noMargin>
                  {description}
                </Text>
                <Text>{Array.isArray(value) ? value?.join(', ') : value}</Text>
              </div>
            ))}
          </Accordian>
          <div className={styles.spacer} />
          <Accordian
            icon="image"
            button={true}
            title={`${t(keys.common.IMAGES)}/${t(keys.common.FILES)}`}
            showOnClick={!!canUpdate}
            onClick={() =>
              openModal({
                modalName: modals.fileUpload,
                variables: {
                  simple: true,
                  onLoading: () => setLoadingImages(true),
                  onSubmit: ({ url, name }) => {
                    setLoadingImages(true);
                    addFile({
                      variables: {
                        observationId: observation.id,
                        imageUrl: url,
                        name,
                      },
                    }).then(() => {
                      refetch();
                      setLoadingImages(false);
                    });
                  },
                },
              })
            }
          >
            {loadingImages ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div className={styles.images}>
                {images?.map((image, idx) => {
                  const extension = image.imageUrl
                    .split('.')
                    .pop()
                    .toLowerCase();
                  const isImage = imageExtensions.includes(extension);
                  const src = isImage
                    ? image.imageUrl
                    : findFileIcon(extension);
                  const file = {
                    ...image,
                    fileType: extension,
                    url: image.imageUrl,
                    downloadAllowed: true,
                  };
                  return (
                    <div className={styles.imageContainer} key={image.imageUrl}>
                      <img
                        onClick={() => openFileViewer(file)}
                        className={styles.image}
                        src={src}
                        alt={`observation-${idx}`}
                      />
                      <Text
                        color="secondary"
                        size="sm"
                        textAlign="center"
                        noMargin
                        truncate
                      >
                        {image.name || '**Initial Report'}
                      </Text>
                      <Text
                        color="secondary"
                        size="sm"
                        textAlign="center"
                        noMargin
                        truncate
                      >
                        {image.creator || '**Initial Report'}
                      </Text>
                    </div>
                  );
                })}
              </div>
            )}
          </Accordian>
          <div className={styles.spacer} />
          {status !== observationStatus.NEW.key && (
            <>
              <Accordian
                button={true}
                title={t(keys.common.ACTION_ITEMS)}
                message={actionItems?.length || 0}
                icon="task"
                showOnClick={canUpdate}
                onClick={() => {
                  addActionItem({
                    variables: {
                      title:
                        title || externalId
                          ? externalId
                          : `OBSERVATION-${observationId
                              .toString()
                              .padStart(4, '0')}`,
                      description,
                      type: 'OBSERVATION',
                      observationId,
                      workspaceId: workspace?.id,
                      private: true,
                    },
                  }).then(
                    ({
                      data: {
                        addActionItem: { id },
                      },
                    }) => {
                      openActionItemModal(id, 'OBSERVATION', refetch);
                    },
                  );
                }}
              >
                <div className={styles.actionItems}>
                  {actionItems?.map((a) => (
                    <div className={styles.actionItems} key={a.id}>
                      <ActionItemCard
                        boxShadow={false}
                        showName={true}
                        key={a.id}
                        actionItem={a}
                        maxLabels={4}
                        className={styles.actionItemCard}
                        onClick={() => {
                          openActionItemModal(a.id, '', refetch);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Accordian>
              <div className={styles.spacer} />
            </>
          )}
          <Accordian title={t(keys.common.NOTES)} icon="text_snippet">
            <Notes
              parentId={observation.id}
              type={observation.__typename}
              readOnly={false}
            />
            <Text noMargin>{observation.notes}</Text>
          </Accordian>
        </div>
        <div className={styles.right}>
          <Text weight="semiBold" size="lg">
            {t(keys.common.INFORMATION)}
          </Text>
          {!!incident ? (
            <div>
              <Text
                noMargin
                className={styles.incidentText}
                color="secondary"
                weight="semiBold"
                size="sm"
              >
                {`${t(keys.boards.INCIDENT_REPORTED)}`}
              </Text>
              <div className={styles.text}>
                <div className={styles.closed}>
                  <Icon color="red">fmd_bad</Icon>
                  <Text
                    noMargin
                    color="red"
                    hover={isAdmin || isCreator}
                    weight="semiBold"
                    onClick={() => {
                      if (isAdmin || isCreator) {
                        navigate(
                          getRoute(
                            incident?.workspace?.id,
                            paths.incident,
                            incident?.id,
                          ),
                        );
                      }
                    }}
                  >
                    {incident?.title || 'Incident'}
                  </Text>
                </div>
              </div>
              <div className={styles.sectionLine} />
            </div>
          ) : null}
          {!!observation?.externalId ? (
            <Text weight="bold">{observation.externalId}</Text>
          ) : null}
          <Text weight="semiBold" color="secondary" noMargin size="sm">
            {t(keys.observations.OBSERVER)}
          </Text>
          {!!!creator ? (
            <div className={styles.closed}>
              <Icon color="disabled">help</Icon>
              <Text weight="semiBold" noMargin>
                {t(keys.observations.ANONYMOUS)}
              </Text>
            </div>
          ) : (
            <SimpleUserCard
              className={styles.userCard}
              mediumAvatar
              user={creator}
              smallText={getLocalTime(dateCreated).format(
                'dddd MMMM DD, YYYY HH:mm',
              )}
            />
          )}
          <div className={styles.sectionLine} />
          <Text weight="semiBold" color="secondary" size="sm" noMargin>
            {t(keys.common.STATUS)}
          </Text>
          <Label
            rowSized={false}
            name={observationStatus[status]?.title}
            color={observationStatus[status]?.color}
          />
          <div className={styles.sectionLine} />
          <Text noMargin color="secondary" size="sm" weight="semiBold">
            {t(keys.observations.TEAM_MEMBERS_PRESENT)}
          </Text>
          {!participants?.length ? (
            <Text noMargin>{t(keys.common.NONE)}</Text>
          ) : (
            <div className={styles.team}>
              {participants.map((user) => (
                <SimpleUserCard user={user} key={user?.id} />
              ))}
            </div>
          )}
          <div className={styles.sectionLine} />
          {isReviewed ? (
            <>
              <Text weight="semiBold" color="secondary" size="sm" noMargin>
                {t(keys.observations.REVIEWER)}
              </Text>
              <SimpleUserCard
                className={styles.userCard}
                mediumAvatar
                user={reviewer}
                smallText={getLocalTime(dateReviewed).format(
                  'dddd MMMM DD, YYYY HH:mm',
                )}
              />
              <div className={styles.sectionLine} />
            </>
          ) : null}
          {isClosed ? (
            <>
              <Text weight="semiBold" color="secondary" size="sm" noMargin>
                {t(keys.observations.CLOSER)}
              </Text>
              <SimpleUserCard
                className={styles.userCard}
                mediumAvatar
                user={closer}
                smallText={getLocalTime(dateClosed).format(
                  'dddd MMMM DD, YYYY HH:mm',
                )}
              />
              <div className={styles.sectionLine} />
            </>
          ) : null}
          {!isReviewed && !readOnly ? (
            <div className={styles.buttons}>
              <Button
                icon="check_circle"
                className={styles.button}
                disabled={!title || !type}
                onClick={() => {
                  updateObservation({
                    variables: {
                      id: observationId,
                      reviewerId: user.id,
                      status: observationStatus.IN_PROGRESS.key,
                    },
                  }).then(() => refetch());
                }}
                value={t(keys.observations.ACKNOWLEDGE)}
              />
              {!title || !type ? (
                <Text size="sm" color="secondary">
                  A title and classification is required to acknowledge this
                  observation
                </Text>
              ) : null}
              <Button
                value={t(keys.action.DISMISS)}
                className={styles.button}
                icon="close"
                variant="danger"
                onClick={() => {
                  openConfirmationModal({
                    title: t(keys.observations.INVALID_VARIABLE, {
                      variable: t(keys.common.OBSERVATION),
                    }),
                    description: t(keys.observations.INVALID_CONFIRMATION),
                    variant: 'danger',
                    buttonText: t(keys.observations.INVALID),
                    onSubmit: () => {
                      updateObservation({
                        variables: {
                          id: observationId,
                          reviewerId: user.id,
                          status: observationStatus.INVALID.key,
                        },
                      }).then(() =>
                        navigate(getRoute(workspace?.id, paths.observations)),
                      );
                    },
                  });
                }}
              />
            </div>
          ) : null}
          {!isClosed && !readOnly && !!reviewer ? (
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                value={t(keys.common.COMPLETE)}
                icon="check"
                variant="success"
                onClick={() => {
                  openConfirmationModal({
                    title: t(keys.observations.COMPLETE_TITLE),
                    description: t(keys.observations.COMPLETE_MESSAGE),
                    variant: 'success',
                    onSubmit: () => {
                      updateObservation({
                        variables: {
                          id: observationId,
                          closerId: user.id,
                          status: observationStatus.COMPLETE.key,
                        },
                      });
                    },
                  });
                }}
              />
              {!incident && (
                <Button
                  className={styles.button}
                  value={t(keys.observations.ESCALATE)}
                  variant="danger"
                  icon="fmd_bad"
                  onClick={() => {
                    openModal({
                      modalName: modals.createIncident,
                      variables: {
                        navigate: true,
                        title: `Observation ${id}`,
                        participants: !observation?.creator
                          ? [...participants]
                          : participants
                              .map(({ id }) => id)
                              .includes(creator?.id)
                          ? [...participants]
                          : [...participants, creator],
                        observation,
                        location,
                        dateOfIncident: dateTime,
                        type: 'Health and Safety',
                        subtype: 'Near Miss',
                        description,
                      },
                    });
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@mui/material';
import styles from './SpeechToText.module.scss';
import moment from 'moment';
import { showToast } from '../graphql/cache/modal';
import { Form } from 'react-bootstrap';
import { Text } from './typography';
import Button from './Button';
import { languages } from '../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { isProd } from '../utilities';
import { useInspection } from '../hooks/offline-hooks/createInspectionhook';
import Bugsnag from '@bugsnag/js';
import { useModal } from '../providers/ModalProvider';

export default function SpeechToText({
  isRecording,
  setIsRecording,
  transcript,
  setTranscript = () => {},
  setFile = () => {},
  isAssessmentQuestion = false,
  onSubmit = () => {},
  setLoading = () => {},
}) {
  const { t, i18n } = useTranslation();

  const { addAudioFileResults } = useInspection();

  const options = {
    audioBitsPerSecond: 128000,
    bitsPerSecond: 128000,
    ignoreMutedMedia: true,
  };

  const audioChunksRef = useRef([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const scrollRef = useRef(null);
  const [previousTranscript, setPreviousTranscript] = useState('');
  const transcriptRef = useRef(transcript);
  const [showTranscript, setShowTranscript] = useState(false);
  const [notSupported, setNotSupported] = useState(false);
  const [error, setError] = useState(false);
  const [manualInput, setManualInput] = useState(null);
  const { openConfirmationModal } = useModal();

  const recognition = useRef(null);

  useEffect(() => {
    if (!!window.SpeechRecognition) {
      recognition.current = new window.SpeechRecognition();
    } else if (!!window.webkitSpeechRecognition) {
      recognition.current = new window.webkitSpeechRecognition();
    } else {
      setNotSupported(true);
      return;
    }

    recognition.current.language = `${i18n.resolvedLanguage}`;
    recognition.current.interimResults = true;
    recognition.current.continuous = true;
    recognition.current.onerror = (event) => {
      if (event.error === 'language-not-supported') {
        setNotSupported(true);
      } else {
        setError(true);
      }
      console.error(event);
      Bugsnag.notify(event.error);
      recognition.current = null;
      setIsRecording(false);
    };

    recognition.current.onresult = (event) => {
      const interimTranscript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      setTranscript(previousTranscript + ' ' + interimTranscript);
    };
  }, [
    i18n.resolvedLanguage,
    previousTranscript,
    setIsRecording,
    setTranscript,
  ]);

  useEffect(() => {
    transcriptRef.current = transcript;
  }, [transcript]);

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, scrollRef.current.scrollHeight);
  }, [transcript]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        if (!stream) {
          console.error('No audio Stream');
          throw new Error('No audio stream available');
        }
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current = [...audioChunksRef.current, e.data];
          }
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(audioChunksRef.current, {
            type: mediaRecorder.mimeType,
          });
          saveRecording(blob);
          stream.getTracks().forEach((track) => track.stop());
        };

        mediaRecorder.onerror = (e) => {
          console.error('MediaRecorder error:', e);
          showToast({
            title: t(keys.assessments.RECORDING_ERROR),
            message: `An error occurred during recording: ${e.name} - ${e.message}`,
          });
        };
        audioChunksRef.current = [];
        setMediaRecorder(mediaRecorder);
        mediaRecorder.start();
        setIsRecording(true);
        recognition.current.start();
      })
      .catch((error) => {
        showToast({
          title: t(keys.assessments.MIC_ERROR),
          message: `There was an unexpected error: ${error}`,
        });
        console.error(`${t(keys.assessments.MIC_ERROR)}:`, error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      recognition.current.stop();
      setIsRecording(false);
      setPreviousTranscript(transcript);
    }
  };

  const handleIconClick = () => {
    if (isRecording) {
      isAssessmentQuestion
        ? openConfirmationModal({
            title: t(keys.assessments.STOP_RECORDING),
            description: t(keys.assessments.STOP_RECORDING_MESSAGE),
            onSubmit: () => stopRecording(),
            variant: 'warning',
          })
        : stopRecording();
    } else {
      startRecording();
    }
  };

  const recordingName = `${t(
    keys.assessments.MEETING_RECORDING,
  )}_${new moment().format('YYYY-MM-DD HH:mm:ss')}`;

  const saveRecording = async (blob) => {
    addAudioFileResults({
      file: blob,
      transcriptRef,
      onSubmit,
      setFile,
      setLoading,
      recordingName,
    });
  };

  if (notSupported || error) {
    return (
      <div className={styles.container}>
        <Icon
          sx={{
            fontSize: '3rem',
            opacity: '0.5',
          }}
        >
          mic_off
        </Icon>
        <Text weight="semiBold" noMargin textAlign="center">
          {notSupported
            ? t(keys.assessments.BROWSER_NOT_SUPPORTED)
            : t(keys.assessments.MEETING_ERROR)}
        </Text>
        <Text textAlign="center">{t(keys.assessments.RECOMMEND_CHROME)}</Text>
        {!!manualInput ? (
          <Form.Control
            as="textarea"
            rows={10}
            value={transcript}
            onChange={(e) => setTranscript(e.target.value)}
            className={!isAssessmentQuestion ? styles.transcript : styles.hide}
          />
        ) : (
          <Text
            textAlign="center"
            color="accentPrimary"
            hover
            onClick={() => setManualInput(' ')}
          >
            {t(keys.assessments.ENTER_MANUALLY)}
          </Text>
        )}
      </div>
    );
  }

  return isAssessmentQuestion ? (
    <div className={styles.container}>
      <Icon
        baseClassName="material-icons-outlined"
        sx={{
          color: isRecording ? '#28a745' : '#e9222c',
          fontSize: '3rem',
          opacity: '0.8',
        }}
        onClick={handleIconClick}
        className={styles.hover}
      >
        {isRecording ? 'stop_circle' : 'mic'}
      </Icon>

      <Text
        color="secondary"
        size="sm"
        className={!!transcript ? styles.text : styles.hide}
        hover
        noMargin
        onClick={() => setShowTranscript(!showTranscript)}
      >
        {showTranscript
          ? t(keys.assessments.HIDE_TRANSCRIPT)
          : t(keys.assessments.VIEW_TRANSCRIPT)}
      </Text>
      <div
        ref={scrollRef}
        className={showTranscript ? styles.transcript : styles.hide}
      >
        <Text>{transcript}</Text>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <Icon
        baseClassName="material-icons-outlined"
        className={styles.hover}
        sx={{ fontSize: '3rem' }}
        onClick={handleIconClick}
        color="primary"
      >
        {isRecording ? 'stop_circle' : 'mic'}
      </Icon>
      {isRecording ? (
        <div ref={scrollRef} className={styles.transcript}>
          <Text>{transcript}</Text>
        </div>
      ) : transcript ? (
        <Form.Control
          as="textarea"
          rows={15}
          value={transcript}
          onChange={(e) => setTranscript(e.target.value)}
          onBlur={() => setPreviousTranscript(transcript)}
          className={!isAssessmentQuestion ? styles.transcript : styles.hide}
        />
      ) : (
        <div>
          {!isProd && (
            <div className={styles.buttons}>
              {Object.entries(languages).map(([key, value]) => (
                <Button
                  key={key}
                  size="sm"
                  outlined={i18n.resolvedLanguage !== key}
                  value={value.nativeName}
                  onClick={() => {
                    recognition.current.lang = key;
                    i18n.changeLanguage(key);
                  }}
                />
              ))}
            </div>
          )}
          <div className={styles.displayText}>
            <div>
              <Text textAlign="center">{t(keys.assessments.MESSAGE)}</Text>
              <Text textAlign="center">
                {t(keys.utilities.OR).toUpperCase()}
              </Text>
              <Text
                textAlign="center"
                color="accentPrimary"
                hover
                onClick={() => setTranscript(' ')}
              >
                {t(keys.assessments.ENTER_MANUALLY)}
              </Text>
            </div>
            <Text textAlign="center" size="sm" noMargin color="secondary">
              {t(keys.assessments.VOICE_DISCLAIMER)}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}

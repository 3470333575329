import { useState, useMemo } from 'react';
import { Icon } from '@mui/material';
import { Text } from '../../typography';
import styles from './ConditionalAction.module.scss';
import { Form } from 'react-bootstrap';
import { DropdownText } from '../../dropdowns/DropdownText';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import Button from '../../Button';
import InspectionQuestion from '../question/InspectionQuestion';
import { useTranslation } from 'react-i18next';
import AddQuestionMenu from '../AddQuestionMenu';
import { keys } from '../../../utilities/translator/translation_keys';
import { useModal } from '../../../providers/ModalProvider';
import UserMultiSelector from '../../UserMultiSelector';
import {
  actionsKeys,
  actionsList,
  defaultTestValueList,
  operatorsList,
  testValueList,
  months,
  days,
} from './conditionalAction.utils';
import ConditionalValueInput from './ConditionalValueInput';
import NotificationAction from './NotificationAction';
import MessageAction from './MessageAction';
import PopUpAction from './PopUpAction';
import { inputTypes } from '../../../constants/strings';

const addConditionalActionMutation = loader(
  './ConditionalActionMenu.add.graphql',
);
const addConditionalActionItemMutation = loader(
  './ConditionalActionMenu.addItem.graphql',
);
const updateConditionalActionItemMutation = loader(
  './ConditionalActionMenu.updateItem.graphql',
);
const deleteConditionalActionItemMutation = loader(
  './ConditionalActionMenu.deleteItem.graphql',
);
const updateConditionalActionMutation = loader(
  './ConditionalActionMenu.update.graphql',
);
const deleteQuestionMutation = loader(
  '../../../pages/fleet/CreateQuestions.deleteQuestion.graphql',
);
const actionsQuery = loader('./ConditionalActionMenu.actions.graphql');

export default function ConditionalActionMenu({
  question,
  onBackClick = () => {},
  setParentQuestion = () => {},
  templateId = null,
}) {
  const { openConfirmationModal } = useModal();

  const [conditionalAction, setConditionalAction] = useState(
    question?.conditionalAction || {},
  );

  const { refetch: refetchActions, data: { conditionalActions = [] } = {} } =
    useQuery(actionsQuery, {
      skip: !conditionalAction?.id,
      variables: {
        logicId: `${conditionalAction?.id ?? 0}`,
      },
    });

  const { t } = useTranslation();
  const [operator, setOperator] = useState(
    question?.inputType === inputTypes.text
      ? operatorsList.find((o) => o.key === 'EQUALS')
      : operatorsList.find((o) => o.key === conditionalAction?.operator) || '',
  );

  const questionOptions = question?.additionalData?.split('|');

  testValueList?.find(
    (a) =>
      conditionalAction?.value === a.key &&
      questionOptions?.find((o) => o === a.title),
  );

  const [value, setValue] = useState(
    question?.inputType === inputTypes?.test
      ? question?.additionalData
        ? testValueList?.find(
            (a) =>
              conditionalAction?.value === a.key &&
              questionOptions?.find((o) => o === a.title),
          ) || ''
        : defaultTestValueList?.find(
            (v) => v.key === conditionalAction?.value,
          ) || ''
      : conditionalAction?.value || '',
  );

  const [action, setAction] = useState('');
  const [additionalData, setAdditionalData] = useState('');
  const [showQuestionMenu, setShowQuestionMenu] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [addConditionalAction] = useMutation(addConditionalActionMutation);
  const [deleteConditionalActionItem] = useMutation(
    deleteConditionalActionItemMutation,
  );
  const [addConditionalActionItem] = useMutation(
    addConditionalActionItemMutation,
  );
  const [updateConditionalActionItem] = useMutation(
    updateConditionalActionItemMutation,
  );
  const [updateConditionalAction] = useMutation(
    updateConditionalActionMutation,
  );
  const [deleteQuestion] = useMutation(deleteQuestionMutation);

  const conditionalActionOperators = useMemo(() => {
    switch (question?.inputType) {
      case inputTypes.select:
      case inputTypes.test:
        return operatorsList.filter((o) => !o.number);
      case inputTypes.date:
      case inputTypes.dateTime:
      case inputTypes.time:
      case inputTypes.number:
        return operatorsList;
      default:
        return [];
    }
  }, [question]);
  const conditionalActionValues = useMemo(() => {
    const options = question?.additionalData?.split('|');
    switch (question?.inputType) {
      case inputTypes.test:
        return options?.length > 1
          ? options?.map((o) => testValueList?.find((v) => v.title === o))
          : defaultTestValueList;
      case inputTypes.select:
        return options.map((o) => o.toUpperCase());
      case inputTypes.date:
      case inputTypes.dateTime:
        return {
          months,
          days,
        };
      default:
        return [];
    }
  }, [question]);

  return (
    <div
      className={styles.menu}
      style={{ width: showQuestionMenu ? '60vw' : '30vw' }}
    >
      <div style={{ width: showQuestionMenu ? '50%' : '100%' }}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Icon onClick={() => onBackClick()}>arrow_back_ios</Icon>
            <Text noMargin size="lg" weight="bold">
              {conditionalAction?.id ? `${t(keys.action.EDIT)} ` : ''}
              {t(keys.conditionalActions.CONDITIONAL_ACTION)}
            </Text>
          </div>
        </div>

        <Text color="secondary">{t(keys.conditionalActions.BLURB)}</Text>
        <div className={styles.input}>
          <Text noMargin weight="bold">
            {`${t(keys.conditionalActions.IF)} ${question?.title || ''}`}
          </Text>
          <DropdownText
            highlight={!conditionalAction?.id}
            disabled={question?.inputType === inputTypes.text}
            selected={operator}
            items={conditionalActionOperators}
            onChange={(operator) => {
              if (conditionalAction?.id) {
                updateConditionalAction({
                  variables: {
                    conditionalActionId: conditionalAction?.id,
                    operator: operator.key,
                  },
                });
              }
              setOperator(operator);
            }}
            onRemove={() => {
              if (conditionalAction?.id) {
                updateConditionalAction({
                  variables: {
                    conditionalActionId: conditionalAction?.id,
                    operator: null,
                  },
                });
              }
              setOperator(null);
            }}
          />
          <ConditionalValueInput
            inputType={question?.inputType}
            updateFunction={(value) => {
              if (conditionalAction?.id) {
                updateConditionalAction({
                  variables: {
                    conditionalActionId: conditionalAction?.id,
                    value: value?.key || value,
                  },
                });
              }
              setValue(value);
            }}
            selected={value}
            values={conditionalActionValues}
            disabled={!operator}
            highlight={!conditionalAction?.id}
          />
          {!conditionalAction?.id && (
            <Button
              disabled={!operator || !value}
              icon={'add'}
              align={'right'}
              value={'Save'}
              onClick={() =>
                addConditionalAction({
                  variables: {
                    workableType: 'inspectionTemplateItem',
                    workableId: question?.id,
                    operator: operator?.key,
                    value: value?.key || value,
                  },
                }).then(({ data: { addConditionalLogic } }) => {
                  setConditionalAction(addConditionalLogic);
                  setParentQuestion({
                    ...question,
                    conditionalAction: { ...addConditionalLogic },
                  });
                })
              }
            />
          )}
        </div>
        <div className={styles.divider} />
        <div className={styles.input}>
          <Text noMargin weight="bold">
            {t(keys.conditionalActions.THEN)}
          </Text>
          <DropdownText
            disabled={!conditionalAction?.id}
            selected={action}
            items={actionsList}
            onChange={(action) => setAction(action)}
            onRemove={() => setAction(null)}
          />
          {action?.key === actionsKeys.followUpQuestion && (
            <>
              <Button
                value={t(keys.action.ADD, {
                  variable: t(keys.common.QUESTION),
                })}
                icon={'add'}
                size="sm"
                onClick={() => {
                  setSelectedQuestion(null);
                  setShowQuestionMenu(true);
                }}
              />
            </>
          )}
          {action?.key === actionsKeys.sendNotification && (
            <>
              <UserMultiSelector
                onUserAdded={(user) =>
                  setSelectedUsers([...selectedUsers, user])
                }
                onUserRemoved={(user) =>
                  setSelectedUsers(
                    selectedUsers.filter((u) => u.id !== user.id),
                  )
                }
                selected={selectedUsers}
                usersToLeaveOut={
                  conditionalActions
                    ?.map(({ actionable }) => actionable && actionable)
                    ?.filter(Boolean) ?? []
                }
              />
              <Form.Control
                placeholder={t(
                  keys.conditionalActions.NOTIFICATION_PLACEHOLDER,
                )}
                type="text"
                as={'textarea'}
                rows={'2'}
                value={additionalData}
                onChange={({ target: { value } }) => setAdditionalData(value)}
              />
              <Button
                value={t(keys.conditionalActions.USER_TO_NOTIFY_PLACEHOLDER)}
                size="sm"
                icon="add"
                onClick={() => {
                  for (const user of selectedUsers) {
                    addConditionalActionItem({
                      variables: {
                        actionableType: 'user',
                        actionableId: user.id,
                        conditionalActionId: conditionalAction?.id,
                        action: action?.key,
                        additionalData: { message: additionalData },
                      },
                    }).then(() => {
                      refetchActions();
                      setSelectedUsers([]);
                      setAdditionalData('');
                    });
                  }
                }}
              />
            </>
          )}
          {action?.key === actionsKeys.showMessage && (
            <Form.Control
              placeholder={t(keys.conditionalActions.MESSAGE_PLACEHOLDER)}
              type="text"
              as={'textarea'}
              rows={'2'}
              value={additionalData}
              onChange={({ target: { value } }) => setAdditionalData(value)}
              onBlur={() =>
                addConditionalActionItem({
                  variables: {
                    additionalData: { message: additionalData },
                    action: action?.key,
                    conditionalActionId: conditionalAction?.id,
                  },
                }).then(() => {
                  refetchActions();
                  setAdditionalData('');
                })
              }
            />
          )}
          {action?.key === actionsKeys.popUp && (
            <div className={styles.popUpInput}>
              <Form.Control
                placeholder={t(keys.common.TITLE)}
                type="text"
                value={additionalData.title}
                onChange={({ target: { value } }) =>
                  setAdditionalData({ title: value })
                }
              />
              <Form.Control
                placeholder={t(keys.common.MESSAGE)}
                type="text"
                as={'textarea'}
                rows={'2'}
                value={additionalData.message}
                onChange={({ target: { value } }) =>
                  setAdditionalData({ ...additionalData, message: value })
                }
              />
              <Button
                align={'right'}
                value={t(keys.action.SAVE)}
                onClick={() =>
                  addConditionalActionItem({
                    variables: {
                      additionalData,
                      action: action?.key,
                      conditionalActionId: conditionalAction?.id,
                    },
                  }).then(() => {
                    refetchActions();
                    setAdditionalData('');
                  })
                }
              />
            </div>
          )}
          <div className={styles.divider} />
          <Text noMargin weight="semiBold" color="secondary">
            {t(keys.conditionalActions.ACTIONS)}
          </Text>
          {conditionalActions?.length > 0 && (
            <div className={styles.actionList}>
              {conditionalActions.map(
                ({ id: actionId, action, actionable, additionalData }) => {
                  switch (action) {
                    case actionsKeys.followUpQuestion:
                      return (
                        <InspectionQuestion
                          key={`action-question-${actionId}`}
                          t={t}
                          question={actionable}
                          onQuestionClick={() => {
                            if (selectedQuestion?.id !== actionable?.id) {
                              setSelectedQuestion(actionable);
                              setShowQuestionMenu(true);
                            } else {
                              setSelectedQuestion(null);
                              setShowQuestionMenu(false);
                            }
                          }}
                          onDelete={() => {
                            openConfirmationModal({
                              title: t(keys.templates.DELETE_QUESTION),
                              description: t(keys.action.CONFIRMATION_MESSAGE, {
                                variable: t(keys.action.DELETE_VARIABLE, {
                                  variable: `${t(keys.common.QUESTION)} ${
                                    actionable.title
                                  }`,
                                }),
                              }),
                              variant: 'danger',
                              buttonText: t(keys.action.DELETE),
                              onSubmit: () => {
                                deleteQuestion({
                                  variables: {
                                    id: actionable.id,
                                  },
                                }).then(() => refetchActions());
                              },
                            });
                          }}
                          selected={selectedQuestion?.id === actionable?.id}
                          showDelete
                        />
                      );
                    case actionsKeys.sendNotification:
                      return (
                        <NotificationAction
                          key={`action-notification-${actionId}`}
                          user={actionable}
                          onDelete={() =>
                            deleteConditionalActionItem({
                              variables: { actionId },
                            }).then(() => refetchActions())
                          }
                          additional={additionalData?.message}
                          onChange={(value) =>
                            updateConditionalActionItem({
                              variables: {
                                conditionalActionId: actionId,
                                additionalData: { message: value },
                              },
                            })
                          }
                          showDelete
                        />
                      );
                    case actionsKeys.showMessage:
                      return (
                        <MessageAction
                          key={`action-message-${actionId}`}
                          additional={additionalData?.message}
                          onChange={(value) =>
                            updateConditionalActionItem({
                              variables: {
                                conditionalActionId: actionId,
                                additionalData: { message: value },
                              },
                            })
                          }
                          onDelete={() =>
                            deleteConditionalActionItem({
                              variables: { actionId },
                            }).then(() => refetchActions())
                          }
                          showDelete
                        />
                      );
                    case actionsKeys.popUp:
                      return (
                        <PopUpAction
                          key={`action-popup-${actionId}`}
                          additional={additionalData}
                          onDelete={() =>
                            deleteConditionalActionItem({
                              variables: { actionId },
                            }).then(() => refetchActions())
                          }
                          showDelete
                          onChange={(value) =>
                            updateConditionalActionItem({
                              variables: {
                                conditionalActionId: actionId,
                                additionalData: value,
                              },
                            })
                          }
                        />
                      );
                    default:
                      return <></>;
                  }
                },
              )}
            </div>
          )}
        </div>
      </div>
      {showQuestionMenu && <div className={styles.verticalDivider} />}
      <AddQuestionMenu
        showModal={showQuestionMenu}
        onHide={() => {
          setShowQuestionMenu(false);
          setSelectedQuestion(null);
          refetchActions();
        }}
        refetchCategory={refetchActions}
        selectedQuestion={selectedQuestion}
        conditionalAction={conditionalAction}
        onAdd={() => refetchActions()}
        templateId={templateId}
      />
    </div>
  );
}

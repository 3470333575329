import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useWorkspacePermissions } from '../../../providers/WorkspacePermissionsProvider';
import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import StatBox from './StatBox';
import { incidentKPIs } from '../../../utilities/analytics';
import { Text } from '../../../components/typography';
import { Form } from 'react-bootstrap';
import styles from './Statistics.module.scss';
import Icon from '../../../components/Icon';
import { customColors } from '../../../utilities';

const incidentCountQuery = loader('./IncidentStatistics.count.graphql');

export default function IncidentStatistics({ start, end, workspaceIds = [] }) {
  const daysInRange = differenceInDays(new Date(end), new Date(start));
  const previousEnd = subDays(new Date(start), 1);
  const previousStart = subDays(previousEnd, daysInRange);
  const { allUsers } = useWorkspacePermissions();
  const [show, setShow] = useState(false);

  const useIncidentCount = ({
    startDate,
    endDate,
    additionalFilters = [],
    type,
  }) => {
    const value = startDate && endDate ? [startDate, endDate] : endDate;
    const operator = startDate && endDate ? 'between' : 'lte';
    const { data } = useQuery(incidentCountQuery, {
      variables: {
        workspaceIds,
        type,
        options: {
          filters: [
            ...additionalFilters,
            {
              field: 'dateOfIncident',
              operator,
              value,
            },
          ],
        },
      },
    });
    return data?.incidentStatisticCount || 0;
  };

  const IFR_count = useIncidentCount({
    startDate: start,
    endDate: end,
    additionalFilters: [
      { field: 'type', operator: 'eq', value: 'Health and Safety' },
      {
        field: 'subtype',
        operator: 'not',
        value: [
          'Near Miss',
          'Other/Misc',
          'Medical - No Treatment',
          'First Aid',
        ],
      },
    ],
  });

  const IFR_prev = useIncidentCount({
    startDate: previousStart,
    endDate: previousEnd,
  });
  const LTIFR_count = useIncidentCount({
    startDate: start,
    endDate: end,
    additionalFilters: [{ field: 'lostTime', operator: 'eq', value: 'true' }],
  });
  const LTIFR_prev = useIncidentCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [{ field: 'lostTime', operator: 'eq', value: 'true' }],
  });

  const NMFR_count = useIncidentCount({
    startDate: start,
    endDate: end,
    additionalFilters: [{ field: 'nearMiss', operator: 'eq', value: 'true' }],
  });
  const NMFR_prev = useIncidentCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [{ field: 'nearMiss', operator: 'eq', value: 'true' }],
  });

  const LostTimeHrs = useIncidentCount({
    startDate: start,
    endDate: end,
    type: 'SR',
  });
  const LostTimeHrs_prev = useIncidentCount({
    startDate: previousStart,
    endDate: previousEnd,
    type: 'SR',
  });
  const TTRI = useIncidentCount({
    startDate: null,
    endDate: end,
    type: 'TTRI',
  });
  const TTRI_prev = useIncidentCount({
    startDate: null,
    endDate: previousEnd,
    type: 'TTRI',
  });

  const averageAnnualHoursPerEmployee = 2000;
  const hoursPerDayPerEmployee = averageAnnualHoursPerEmployee / 365;

  const averageHoursWorked = useMemo(() => {
    const totalAverageHours =
      allUsers.length * daysInRange * hoursPerDayPerEmployee;
    return totalAverageHours.toFixed(0);
  }, [allUsers.length, daysInRange, hoursPerDayPerEmployee]);

  const [hoursWorked, setHoursWorked] = useState(null);

  const incidentStatistics = useMemo(() => {
    const hours = hoursWorked || averageHoursWorked;

    const IFR = (IFR_count / hours) * 200000;
    const previousIFR = (IFR_prev / hours) * 200000;
    const TRIR_stat = IFR - previousIFR;

    const LTIFR = (LTIFR_count / hours) * 1000000;
    const previousLTIFR = (LTIFR_prev / hours) * 1000000;
    const LTIFR_stat = LTIFR - previousLTIFR;

    const NMRF = (NMFR_count / hours) * 200000;
    const prevNMRF = (NMFR_prev / hours) * 200000;
    const NMRF_stat = NMRF - prevNMRF;

    const SR = LostTimeHrs / IFR_count;
    const SR_stat = SR - LostTimeHrs_prev / IFR_prev;

    const TTRIhours = TTRI / 3600;
    const TTRIhours_prev = TTRI_prev / 3600;

    return {
      TRIR: { value: IFR, stat: TRIR_stat },
      LTIR: { value: LTIFR, stat: LTIFR_stat },
      NMFR: { value: NMRF, stat: NMRF_stat },
      SR: { value: SR, stat: SR_stat },
      TTRI: { value: TTRIhours, stat: TTRIhours - TTRIhours_prev },
    };
  }, [
    hoursWorked,
    averageHoursWorked,
    IFR_count,
    IFR_prev,
    LTIFR_count,
    LTIFR_prev,
    NMFR_count,
    NMFR_prev,
    LostTimeHrs,
    LostTimeHrs_prev,
    TTRI,
    TTRI_prev,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div>
          <Text weight="semiBold" noMargin>
            Enter Calculating Hours
          </Text>
          <Text
            size="sm"
            noMargin
          >{`Estimated hours (${allUsers.length} users @ 40hr week): ${averageHoursWorked}`}</Text>
        </div>
        <Form.Control
          type="number"
          value={hoursWorked}
          placeholder={averageHoursWorked}
          onChange={(e) => setHoursWorked(e.target.value)}
          className={styles.input}
        />
        <Icon
          hover
          style={{ color: customColors.BLUE }}
          onClick={() => setShow(!show)}
        >
          info
        </Icon>
      </div>
      {show && (
        <Text noMargin size="sm" weight="semiBold" color="secondary">
          Note: Only Health and Safety incidents classified as Lost Time,
          Medical Aid, or Fatality will be considered recordable when
          calculating TRIR.
        </Text>
      )}

      <div className={styles.stats}>
        {incidentKPIs.map((kpi) => (
          <StatBox
            show={show}
            key={kpi.id}
            kpi={kpi}
            value={incidentStatistics[kpi.id]?.value}
            stat={incidentStatistics[kpi.id]?.stat}
          />
        ))}
      </div>
    </div>
  );
}

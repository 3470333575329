import { useState, useMemo } from 'react';
import { Text } from '../../typography';
import { Icon } from '@mui/material';
import MessageAction from '../conditionalActions/MessageAction';
import PopUpAction from '../conditionalActions/PopUpAction';
import NotificationAction from '../conditionalActions/NotificationAction';
import styles from './InspectionQuestion.module.scss';
import { actionsKeys } from '../conditionalActions/conditionalAction.utils';
import { inputTypeToIconMap } from '../../../utilities';
import { getTranslationKey } from '../../../utilities/translator/translation_keys';
import { inputTypes } from '../../../constants/strings';
import { numberCheck } from '../../../utilities/inspection';

export default function InspectionQuestion({
  t = {},
  question = {},
  showDelete = false,
  onDelete = () => {},
  selected,
  onQuestionClick = () => {},
  readOnly = false,
}) {
  const [expanded, setExpanded] = useState(false);

  const { hasConditionalActions, actions } = useMemo(
    () => ({
      hasConditionalActions: question?.conditionalAction?.actions?.length > 0,
      actions: question?.conditionalAction?.actions || [],
    }),
    [question],
  );
  return (
    <div
      className={selected ? styles.highlight : {}}
      onClick={(e) => {
        e.stopPropagation();
        !readOnly && onQuestionClick();
      }}
    >
      <div className={styles.container}>
        <div className={styles.rightContainer}>
          <Icon
            baseClassName="material-icons-outlined"
            className={styles.icon}
            sx={{ fontSize: '2rem' }}
          >
            {inputTypeToIconMap[question.inputType]}
          </Icon>
          <div>
            <Text size="md" weight="semiBold" noMargin>
              {`${question.title}${question.required ? ' *' : ''}`}
            </Text>
            <Text size="md" color="secondary" noMargin>
              {question.description || '  '}
            </Text>
            <Text noMargin color={'secondary'}>
              {t(getTranslationKey(question.inputType, 'templates'))}
            </Text>
            <Text noMargin size="sm" color="secondaryLight">
              {question.inputType === inputTypes.number
                ? numberCheck(question?.additionalData)
                : question?.additionalData?.split('|').join(', ')}
            </Text>
          </div>
        </div>
        <div className={styles.leftContainer}>
          {showDelete && (
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              delete
            </Icon>
          )}
          {hasConditionalActions && readOnly && (
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.expandIcon}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'expand_less' : 'expand_more'}
            </Icon>
          )}
        </div>
      </div>
      {expanded && (
        <div className={styles.actionList}>
          {actions?.map(({ action, actionable, additionalData, id }) => {
            switch (action) {
              case actionsKeys.followUpQuestion:
                return (
                  <InspectionQuestion
                    key={`conditional-action-question-${id}`}
                    question={actionable}
                    t={t}
                    readOnly={readOnly}
                    showDelete={showDelete}
                  />
                );
              case actionsKeys.showMessage:
                return (
                  <MessageAction
                    key={`conditional-action-message-${id}`}
                    additional={additionalData?.message}
                    showDelete={showDelete}
                    readOnly={readOnly}
                  />
                );
              case actionsKeys.sendNotification:
                return (
                  <NotificationAction
                    key={`conditional-action-notification-${id}`}
                    user={actionable}
                    additional={additionalData?.message}
                    showDelete={showDelete}
                    readOnly={readOnly}
                  />
                );
              case actionsKeys.popUp:
                return (
                  <PopUpAction
                    key={`conditional-action-popup-${id}`}
                    additional={additionalData}
                    showDelete={showDelete}
                    readOnly={readOnly}
                  />
                );
              default:
                return <></>;
            }
          })}
        </div>
      )}
    </div>
  );
}

import CalendarScheduler from '../../components/tables/CalendarScheduler';
import styles from './ToolboxSchedule.module.scss';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useState, useMemo } from 'react';
import { Button } from '../../components';
import Select from 'react-select';
import { Text } from '../../components/typography';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { Form } from 'react-bootstrap';
import { getImage } from '../../utilities/toolboxtalks';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { multiLineTruncate } from '../../utilities';
import ToolboxScheduleModal from './ToolboxScheduleModal';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../providers/ModalProvider';
import { showToast } from '../../graphql/cache/modal';

const toolboxTalkQuery = loader('./ToolboxTalkGenerator.fetch.graphql');
const scheduleTBTMutation = loader('./ToolboxSchedule.assign.graphql');
const tbtScheduleQuery = loader('./ToolboxSchedule.fetch.graphql');
const deleteScheduleMutation = loader('./ToolboxSchedule.delete.graphql');

export default function ToolboxSchedule() {
  const { data: { toolboxTalks = [] } = {} } = useQuery(toolboxTalkQuery, {
    variables: {
      options: {
        filters: [{ field: 'isDraft', operator: 'eq', value: `${'FALSE'}` }],
      },
    },
  });

  const [deleteSchedule] = useMutation(deleteScheduleMutation);
  const [scheduleTBT] = useMutation(scheduleTBTMutation);
  const { availableWorkspaces, workspace } = useWorkspace();
  const [selectedDates, setSelectedDates] = useState([
    format(new Date(), 'yyyy-MM-dd'),
  ]);
  const [allWorkspaces, setAllWorkspaces] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedData, setSelectedData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openConfirmationModal } = useModal();

  const tbtOptions = toolboxTalks?.map(
    ({ title, id, description, imageUrl }) => ({
      value: id,
      label: title,
      description,
      imageUrl,
    }),
  );

  const [selectedWorkspace, setSelectedWorkspace] = useState({
    label: workspace.title,
    value: workspace.id,
  });

  const workspaceOptions = availableWorkspaces?.map((w) => ({
    value: w.id,
    label: w.title,
  }));

  const { refetch, data: { tbtSchedule = [] } = {} } = useQuery(
    tbtScheduleQuery,
    {
      skip: !selectedDates || !selectedWorkspace || !selectedMonth,
      variables: {
        options: {
          sort: [{ field: 'scheduleDate', order: 'ASC' }],
          filters: [
            {
              field: 'scheduleDate',
              operator: 'between',
              value: [startOfMonth(selectedMonth), endOfMonth(selectedMonth)],
            },
            {
              field: 'workspaceId',
              operator: 'eq',
              value: `${selectedWorkspace.value}`,
            },
          ],
        },
      },
    },
  );
  const dateData = useMemo(() => {
    return tbtSchedule?.reduce((acc, d) => {
      acc[d.scheduleDate] = d.tbt.title;
      return acc;
    }, {});
  }, [tbtSchedule]);

  const override = selectedDates?.some((date) =>
    dateData?.hasOwnProperty(date),
  );
  const disableSubmit = !selectedData?.value || !selectedDates?.length;

  const handleScheduleTBTOperation = () => {
    scheduleTBT({
      variables: {
        workspaceId: selectedWorkspace.value,
        tbtId: selectedData.value,
        dates: selectedDates,
        allWorkspaces,
      },
    }).then(() => {
      showToast({
        title: t(keys.toolbox.ASSIGN_TOAST_TITLE),
        message: t(keys.toolbox.ASSIGN_TOAST_MESSAGE),
      });
      refetch();
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <CalendarScheduler
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          setSelectedMonth={setSelectedMonth}
          dateData={dateData}
          onRemove={() => {
            deleteSchedule({
              variables: {
                workspaceId: selectedWorkspace.value,
                dates: selectedDates,
              },
            }).then(() => refetch());
          }}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.card}>
          <div>
            <div className={styles.header}>
              <Text weight="semiBold" noMargin>
                {t(keys.common.WORKSPACE)}
              </Text>
              <Text
                weight="semiBold"
                color="accentPrimary"
                size="sm"
                noMargin
                hover
                onClick={() => setModalOpen(true)}
              >
                {t(keys.dashboard.SEE_ALL)}
              </Text>
            </div>
            <Select
              options={workspaceOptions}
              value={selectedWorkspace}
              onChange={(newData) => {
                setSelectedWorkspace(newData);
              }}
              placeholder={t(keys.action.SELECT_VARIABLE, {
                variable: t(keys.common.WORKSPACE),
              })}
            />
            <br />
            <Text weight="semiBold">{t(keys.toolbox.TOOLBOX_TALK)}</Text>
            <Select
              options={tbtOptions}
              value={selectedData}
              onChange={(newData) => {
                setSelectedData(newData);
              }}
              className={styles.dropdown}
              placeholder={t(keys.action.SELECT_VARIABLE, {
                variable: t(keys.toolbox.TOOLBOX_TALK),
              })}
            />
            <br />
            {selectedData?.value ? (
              <div
                className={styles.tbtCard}
                onClick={() => {
                  navigate(`/${paths.toolbox}/${selectedData.value}`);
                }}
              >
                <div className={styles.cardText}>
                  <Text weight="semiBold">{selectedData?.label}</Text>
                  <div className={styles.cardContent}>
                    <Text size="sm" noMargin>
                      {!!selectedData?.description
                        ? multiLineTruncate(selectedData?.description, 100)
                        : t(keys.toolbox.DEFAULT_DESCRIPTION)}
                    </Text>
                    <img
                      className={styles.image}
                      alt="tbt"
                      src={selectedData?.imageUrl || getImage()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.emoji}>
                <Text textAlign="center" size="sm">
                  {!!tbtOptions
                    ? t(keys.toolbox.TBT_OPTION_MESSAGE_1)
                    : t(keys.toolbox.TBT_OPTION_MESSAGE_2)}
                </Text>
                🧰
              </div>
            )}
          </div>
          <div className={styles.formFooter}>
            <div className={styles.check}>
              <Text size="sm" noMargin>
                {t(keys.observations.APPLY_TO_ALL_WORKSPACES)}
              </Text>
              <Form.Check
                value={allWorkspaces}
                type="switch"
                onChange={(e) => setAllWorkspaces(e.target.checked)}
              />
            </div>
            <Button
              value={t(keys.toolbox.ASSIGN)}
              align="right"
              disabled={disableSubmit}
              outlined
              onClick={() => {
                if (override || allWorkspaces) {
                  openConfirmationModal({
                    title: t(keys.toolbox.ASSIGN_TOOLBOX_TALK),
                    description: t(keys.toolbox.ASSIGN_CONFIRMATION, {
                      variable: selectedData?.label,
                    }),
                    variant: 'warning',
                    onSubmit: () => {
                      handleScheduleTBTOperation();
                    },
                  });
                } else {
                  handleScheduleTBTOperation();
                }
              }}
            />
          </div>
        </div>
      </div>
      <ToolboxScheduleModal show={modalOpen} setShow={setModalOpen} />
    </div>
  );
}

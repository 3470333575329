import React, { useState, useEffect } from 'react';
import styles from './IncidentReportAviation.module.scss';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import {
  ATAchapters,
  incidentAviationQuestions,
  aviationPersonnelQuestions,
  aviationJSONQuestions,
  aviationOptions,
} from '../../utilities/incidents';
import UserSelector from '../../components/UserSelector';
import Select from 'react-select';
import { aviationFields } from '../../utilities/incidents';
import { useIncidents } from '../../hooks/offline-hooks/incidentHook';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import TextWithIcon from '../../components/typography/TextWithIcon';
import InspectionItemAsset from '../../components/inspections/inspection_items/InspectionItemAsset';
import { getLocalTime } from '../../utilities/time';

export const IncidentReportAviation = ({
  incident,
  readOnly,
  setAviationQuestions = () => {},
}) => {
  const { updateIncident } = useIncidents();

  const { workspaceVehicles } = useWorkspace();

  const aviationResults = incident?.aviationQuestions || {};

  const initialState = () => {
    const personnel = aviationPersonnelQuestions?.personnel?.reduce(
      (acc, key) => {
        acc[key] =
          aviationResults?.personnel &&
          aviationResults?.personnel[key] !== undefined
            ? aviationResults?.personnel[key]
            : 0;
        return acc;
      },
      {},
    );
    const aviationData = Object.keys(aviationFields).reduce((acc, key) => {
      acc[key] =
        aviationResults[key] && aviationResults[key] !== undefined
          ? aviationResults[key]
          : null;
      return acc;
    }, {});

    const selectedOptions = aviationResults.selectedOptions || [];
    return {
      personnel,
      cfn: aviationResults.cfn || false,
      dna: aviationResults.dna || false,
      stf: aviationResults.stf || false,
      tsb: aviationResults.tsb || false,
      investigation: aviationResults.investigation || false,
      vehicle: incident.vehicle,
      totalPersonnel: aviationResults.totalPersonnel || 0,
      units: aviationResults.units,
      ...aviationData,
      selectedOptions,
    };
  };
  const initial = initialState();

  const [inputs, setInputs] = useState(initial);

  const onSubmit = (aviationQuestions) => {
    if (incident.id) {
      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions,
        },
      });
    } else {
      setAviationQuestions(aviationQuestions);
    }
  };

  useEffect(() => {
    const initial = initialState();
    if (!incident.aviationQuestions) {
      setAviationQuestions(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotalPersonnel = (personnel) => {
    const total = aviationPersonnelQuestions?.personnel?.reduce((acc, role) => {
      const count = parseInt(personnel[role], 10);
      return acc + (isNaN(count) ? 0 : count);
    }, 0);
    return total;
  };
  const handlePersonnelChange = (role, value) => {
    const updatedValue = value?.replace(/\D/g, '');

    setInputs((prevInputs) => {
      const updatedPersonnel = {
        ...prevInputs.personnel,
        [role]: updatedValue,
      };
      const totalPersonnel = calculateTotalPersonnel(updatedPersonnel);
      const aviationQuestions = {
        ...prevInputs,
        personnel: updatedPersonnel,
        totalPersonnel,
      };
      onSubmit(aviationQuestions);
      return aviationQuestions;
    });
  };

  const updateAviationQuestions = (key, newValue) => {
    setInputs((prevInputs) => {
      const aviationQuestions = { ...prevInputs, [key]: newValue };
      onSubmit(aviationQuestions);
      return aviationQuestions;
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setInputs((prevInputs) => {
      const updatedSelectedOptions = checked
        ? [...prevInputs.selectedOptions, name]
        : prevInputs.selectedOptions.filter((option) => option !== name);

      return {
        ...prevInputs,
        selectedOptions: updatedSelectedOptions,
      };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.spacer} />
      <div className={styles.spacer} />
      <div className={styles.field}>
        <Text weight="bold" truncate noMargin>
          Date and Time of Departure (UTC)
        </Text>
        {readOnly ? (
          <Text noMargin>
            {aviationResults[aviationJSONQuestions.DEPARTURE_DATE]
              ? getLocalTime(
                  aviationResults[aviationJSONQuestions.DEPARTURE_DATE],
                ).format('dddd MMMM Do, YYYY HH:mm a')
              : 'No Date Selected'}
          </Text>
        ) : (
          <input
            className={styles.date}
            value={inputs?.departureDate || ''}
            type="datetime-local"
            max={new Date().toISOString().slice(0, 16)}
            onChange={(e) => {
              updateAviationQuestions(
                aviationJSONQuestions.DEPARTURE_DATE,
                e.target.value,
              );
            }}
          />
        )}
      </div>
      <div>
        <Text weight="bold" truncate noMargin>
          Aircraft
        </Text>
        <div className={styles.spacer} />
        {readOnly ? (
          <div className={styles.left}>
            <InspectionItemAsset vehicle={incident.vehicle} />
          </div>
        ) : (
          <DropdownCombo
            type={dropdownTypes.ASSET}
            items={workspaceVehicles}
            selected={inputs?.vehicle || null}
            preventStateChange
            onChange={(vehicle) => {
              if (incident.id) {
                updateIncident({
                  variables: { id: incident.id, vehicleId: vehicle?.id },
                });
              }
              updateAviationQuestions('vehicle', vehicle);
            }}
            onRemove={() => {
              if (incident.id) {
                updateIncident({
                  variables: { id: incident.id, vehicleId: null },
                });
              }
              updateAviationQuestions('vehicle', null);
            }}
          />
        )}
        <div className={styles.spacer} />
        <div className={styles.field}>
          <Text noMargin weight="bold">
            {aviationFields.category}
          </Text>
          {readOnly ? (
            <Text noMargin>{inputs?.category?.label || 'Not Selected'}</Text>
          ) : (
            <Select
              isClearable
              value={inputs?.category}
              options={ATAchapters}
              onChange={(value) => {
                updateAviationQuestions('category', value);
              }}
            />
          )}
        </div>
      </div>
      {Object.entries(incidentAviationQuestions).map(([key, questions]) => (
        <div key={key} className={styles.aviation}>
          {questions.map(({ convertToQuery, title, items, subquery }) => {
            return (
              <div className={styles.field} key={title}>
                <Text noMargin weight="bold">
                  {title}
                </Text>
                {readOnly ? (
                  <Text noMargin>
                    {aviationResults[convertToQuery] || 'None'}
                    {!!subquery ? ` ${inputs[subquery]?.label || ''}` : ''}
                  </Text>
                ) : (
                  <div className={styles.input}>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      onChange={(e) =>
                        setInputs({
                          ...inputs,
                          [convertToQuery]: e.target.value,
                        })
                      }
                      value={inputs[convertToQuery] || undefined}
                      onBlur={(e) =>
                        updateAviationQuestions(convertToQuery, e.target.value)
                      }
                    />
                    {!!items ? (
                      <Select
                        className={styles.dropdown}
                        options={items.map((i) => ({ label: i, key: i }))}
                        value={inputs?.units}
                        onChange={(value) =>
                          updateAviationQuestions('units', value)
                        }
                      />
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <Text weight="bold" noMargin>
        Select all that apply
      </Text>
      <div className={styles.spacer} />
      {readOnly ? (
        <Text>{inputs?.selectedOptions?.join(', ') || 'None'}</Text>
      ) : (
        <div className={styles.flex}>
          {aviationOptions.map((o) => (
            <Form.Check
              key={o}
              label={o}
              name={o}
              type="checkbox"
              value={o}
              onChange={(e) => {
                if (readOnly) {
                  return;
                }
                handleCheckboxChange(e);
              }}
              checked={inputs?.selectedOptions?.includes(o)}
            />
          ))}
        </div>
      )}
      <br />
      <TextWithIcon icon="people_outline" size="lg" noMargin weight="bold">
        Personnel
      </TextWithIcon>
      <br />
      <div className={styles.field}>
        <Text weight="bold" truncate noMargin>
          {aviationFields.pilot}
        </Text>
        <UserSelector
          readOnly={readOnly}
          selected={inputs?.pilot}
          onSelectionChanged={(user) => updateAviationQuestions('pilot', user)}
          onClose={() => updateAviationQuestions('pilot', null)}
        />
        <Text weight="bold" truncate noMargin>
          {aviationFields.nonFlyingPilot}
        </Text>
        <UserSelector
          readOnly={readOnly}
          selected={inputs?.nonFlyingPilot}
          onSelectionChanged={(user) => {
            updateAviationQuestions('nonFlyingPilot', user);
          }}
          onClose={() => updateAviationQuestions('nonFlyingPilot', null)}
        />
      </div>

      <div className={styles.personnel}>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            {aviationPersonnelQuestions.personnel?.map((role) => (
              <div key={role}>
                <Text weight="bold" truncate noMargin className={styles.text}>
                  {role}
                </Text>
                <div className={styles.spacer} />
                {readOnly ? (
                  <Text noMargin>{inputs?.personnel[role]}</Text>
                ) : (
                  <Form.Control
                    type="text"
                    value={inputs?.personnel[role]}
                    onChange={(e) =>
                      handlePersonnelChange(role, e.target.value)
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <br />
        <Text weight="bold">{`Total Personnel: ${inputs?.totalPersonnel}`}</Text>
      </div>
    </div>
  );
};

import moment from 'moment';
import { fileExpiryStatus } from '../constants/misc';
import { getLocalTime } from './time';
import styles from '../../src/pages/user/UserProfile.module.scss';

export const getExpiryStatus = (expiryDate) => {
  if (!expiryDate) {
    return fileExpiryStatus.none;
  }
  const currentDateObj = getLocalTime(moment().toDate().getTime());
  const expiryDateObj = getLocalTime(expiryDate);
  const diffDays = expiryDateObj.diff(currentDateObj, 'days');

  if (diffDays < 0) {
    return fileExpiryStatus.expired;
  } else if (diffDays >= 0 && diffDays < 28) {
    return fileExpiryStatus.expiring;
  }
  return fileExpiryStatus.none;
};

export const getExpiryColor = (expiryStatus) => {
  if (expiryStatus === fileExpiryStatus.none) {
    return styles.expireNoneGreen;
  }
  if (expiryStatus === fileExpiryStatus.expiring) {
    return styles.expiringYellow;
  }
  if (expiryStatus === fileExpiryStatus.expired) {
    return styles.expiredRed;
  }
};

export const getComplianceColor = (compliancePercentage) => {
  if (compliancePercentage >= 0 && compliancePercentage <= 49) {
    return styles.expiredRed;
  }
  if (compliancePercentage >= 50 && compliancePercentage <= 79) {
    return styles.expiringYellow;
  }
  if (compliancePercentage >= 80 && compliancePercentage <= 100) {
    return styles.expireNoneGreen;
  }
};

export const getExpiryVariant = (expiryStatus) => {
  if (expiryStatus === fileExpiryStatus.none) {
    return 'primary';
  }
  if (expiryStatus === fileExpiryStatus.expiring) {
    return 'warning';
  }
  if (expiryStatus === fileExpiryStatus.expired) {
    return 'danger';
  }
};

import { Icon } from '@mui/material';
import { Text } from '../../typography';
import { keys } from '../../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import styles from './ConditionalAction.module.scss';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

export default function PopUpAction({
  onChange = () => {},
  onDelete = () => {},
  showDelete = false,
  additional,
  readOnly = false,
}) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(additional?.title || '');
  const [message, setMessage] = useState(additional?.message || '');
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        !readOnly ? setEditing(!editing) : setExpanded(!expanded);
      }}
    >
      <div className={styles.popupContainer}>
        {!editing ? (
          <div className={styles.popupSubContainer}>
            <Icon
              className={styles.popupIcon}
              baseClassName="material-icons-outlined"
            >
              upcoming
            </Icon>
            <Text noMargin>{additional?.title ?? t(keys.common.NONE)}, </Text>
            <Text noMargin className={!expanded ? styles.truncate : {}}>
              {additional?.message ?? keys.common.NONE}
            </Text>
          </div>
        ) : (
          <div className={styles.popupForm}>
            <Form.Control
              onClick={(e) => e.stopPropagation()}
              value={title ?? additional?.title}
              onChange={({ target: { value } }) => setTitle(value)}
              onBlur={() => onChange({ ...additional, title })}
            />
            <Form.Control
              as={'textarea'}
              rows={'2'}
              onClick={(e) => e.stopPropagation()}
              value={message ?? additional?.message}
              onChange={({ target: { value } }) => setMessage(value)}
              onBlur={() => onChange({ ...additional, message })}
            />
          </div>
        )}
        {editing ? (
          <Icon
            className={styles.popupCloseIcon}
            baseClassName="material-icons-outlined"
            onClick={() => setEditing(!editing)}
          >
            close
          </Icon>
        ) : (
          showDelete && (
            <Icon
              className={styles.deleteIcon}
              baseClassName="material-icons-outlined"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              delete
            </Icon>
          )
        )}
      </div>
    </div>
  );
}

import styles from './CustomQuickActionModal.module.scss';
import Modal from '../Modal';
import { useState, useMemo } from 'react';
import { Text } from '../../typography';
import Icon from '../../Icon';
import { Form } from 'react-bootstrap';
import TypeSelectorDropdown from '../../dropdowns/TypeSelectorDropdown';
import { assessmentTypes, quickActionTypes } from '../../../constants/strings';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import { dropdownTypes } from '../../dropdowns/dropdown';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { iconNames } from '../../../utilities';
import { useMutation } from '@apollo/client';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { DropdownText } from '../../dropdowns/DropdownText';
import { useTemplates } from '../../../providers/TemplateProvider';
import classNames from 'classnames';

const modalQuery = loader('./CustomQuickActionModal.fetch.graphql');
const addQuickActionMutation = loader('./CustomQuickActionModal.add.graphql');

export default function CustomQuickActionModal() {
  const { t } = useTranslation();
  const [addQuickAction] = useMutation(addQuickActionMutation);
  const { modalState, closeModal, updateModal } = useModal();
  const { inWorkspaces } = useWorkspace();
  const {
    color,
    name,
    icon,
    type,
    template,
    asset,
    workspace,
    quickActionType,
    url,
    show,
    refetch,
  } = modalState.quickActionModal;

  const { allTemplates } = useTemplates();

  const templates = useMemo(() => {
    return allTemplates
      ?.filter((tem) => !tem.internalType)
      .filter((tem) => tem.templateType === type?.key)
      .filter((tem) => !tem.isArchived)
      .filter(
        (tem) => tem.workspaceId === workspace?.id || tem.workspaceId === null,
      );
  }, [allTemplates, type?.key, workspace?.id]);

  const onHide = () => closeModal({ modalName: modals.quickAction });

  const update = (variables) =>
    updateModal({
      modalName: modals.quickAction,
      variables: { ...variables },
    });

  const [isAssessmentLink, setAssessmentLink] = useState(true);
  const editedQuickAction = useMemo(() => {
    let newUrl;
    if (isAssessmentLink) {
      newUrl = `/workspace/${workspace?.id}/dashboard?&modalName=createAssessmentModal&type=${type.key}`;
      if (asset?.id) {
        newUrl = newUrl.concat(`&asset=${asset.id}`);
      }
      if (template?.id) {
        newUrl = newUrl.concat(`&template=${template.id}`);
      }
    }

    return {
      color,
      name,
      icon,
      url: newUrl || url,
      workspaceId: workspace?.id,
      template: template?.id,
      type: quickActionType,
    };
  }, [
    asset,
    color,
    icon,
    name,
    template,
    type.key,
    workspace?.id,
    isAssessmentLink,
    url,
    quickActionType,
  ]);

  const optional = type?.key !== assessmentTypes.fleet.key;

  const [page, setPage] = useState(1);

  const { data: { workspaceVehicles = [] } = {} } = useQuery(modalQuery, {
    skip: !show || !workspace?.id,
    variables: {
      type: [type.key],
      workspaceId: `${workspace?.id}`,
    },
  });
  return (
    <Modal
      open={show}
      disableCloseOnSubmit={page === 1}
      disableCloseOnCancel={page === 2}
      onClose={() => {
        onHide();
        setPage(1);
      }}
      title={t(keys.dashboard.CUSTOM_QUICK_ACTION)}
      submitText={page === 1 && t(keys.action.NEXT)}
      cancelText={page === 2 && t(keys.action.BACK)}
      onSubmit={() => {
        if (page === 1) {
          setPage(2);
        } else {
          addQuickAction({ variables: { ...editedQuickAction } }).then(() =>
            refetch(),
          );
        }
      }}
      onCancel={() => {
        if (page === 2) {
          setPage(1);
        }
      }}
    >
      <div>
        {page === 1 ? (
          <div className={styles.container}>
            <div className={styles.top}>
              <div>
                <Text weight="semiBold">{t(keys.dashboard.ICON)}</Text>
                <DropdownText
                  items={iconNames}
                  type={dropdownTypes.ICONS}
                  selected={icon}
                  onChange={(icon) => {
                    update({
                      icon,
                    });
                  }}
                />
              </div>
              <div style={{ width: '100%' }}>
                <Text weight="semiBold">{t(keys.common.NAME)}</Text>
                <Form.Control
                  placeholder={t(keys.action.ENTER, {
                    variable: t(keys.common.NAME),
                  })}
                  maxLength={30}
                  value={name || ''}
                  onChange={(e) => update({ name: e.target.value })}
                />
              </div>
            </div>
            <div className={styles.wrapper}>
              <div
                className={styles.buttonPreview}
                style={{ backgroundColor: color }}
              >
                <input
                  className={styles.colorPicker}
                  type="color"
                  value={color || ''}
                  onChange={(e) => update({ color: e.target.value })}
                />
                {!!icon && (
                  <Icon
                    baseClassName="material-icons-outlined"
                    fontSize="inherit"
                    color="white"
                  >
                    {`${icon}`}
                  </Icon>
                )}
                <Text
                  size={!!icon ? 'sm' : 'md'}
                  weight="semiBold"
                  textAlign="center"
                  color="white"
                  noMargin
                  className={styles.text}
                >
                  {name}
                </Text>
              </div>
            </div>
            <Text size="sm" color="secondary" textAlign="center">
              {t(keys.dashboard.CLICK_TO_CHANGE_COLOR)}
            </Text>
          </div>
        ) : (
          <div>
            <div className={styles.quickActionSelectionContainer}>
              <div
                className={classNames(
                  styles.containerLink,
                  !isAssessmentLink ? styles.borderColor : null,
                )}
                title={name}
                onClick={() => {
                  setAssessmentLink(!isAssessmentLink);
                }}
              >
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.icon}
                  fontSize="inherit"
                >
                  link
                </Icon>
                <Text size="md" weight="semiBold" textAlign="center" noMargin>
                  {t(keys.common.LINK)}
                </Text>
              </div>
              <div
                className={classNames(
                  styles.containerLink,
                  isAssessmentLink ? styles.borderColor : null,
                )}
                title={name}
                onClick={() => {
                  setAssessmentLink(!isAssessmentLink);
                }}
              >
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.icon}
                  fontSize="inherit"
                >
                  inventory
                </Icon>
                <Text size="md" weight="semiBold" textAlign="center" noMargin>
                  {t(keys.common.ASSESSMENT)}
                </Text>
              </div>
            </div>
            <br />
            {isAssessmentLink ? (
              <div className={styles.dropdownsContainer}>
                <TypeSelectorDropdown
                  onChange={(type) =>
                    update({
                      type,
                      template: null,
                      asset: null,
                    })
                  }
                  selected={type}
                  items={Object.values(assessmentTypes)
                    .map((t, index) => ({
                      title: t.title,
                      icon: t.icon,
                      color: t.color,
                      key: t.key,
                      id: index,
                    }))
                    .filter(
                      (t) => t.key !== 'OBSERVATION' && t.key !== 'INCIDENT',
                    )}
                  type="Type"
                />
                {quickActionType === quickActionTypes.userLevel && (
                  <DropdownCombo
                    title={t(keys.common.WORKSPACE)}
                    preventStateChange
                    required
                    type={dropdownTypes.WORKSPACE}
                    items={inWorkspaces || []}
                    onChange={(workspace) =>
                      update({ workspace, asset: null, template: null })
                    }
                    selected={workspace}
                  />
                )}

                <DropdownCombo
                  type={dropdownTypes.ASSET}
                  title={t(keys.common.ASSET)}
                  items={workspaceVehicles || []}
                  onChange={(asset) => {
                    update({
                      asset,
                    });
                  }}
                  selected={asset}
                  optional={optional}
                  onRemove={() => update({ asset: null })}
                  required={type.key === 'VEHICLE'}
                  highlight={type.key === 'VEHICLE'}
                />
                <DropdownCombo
                  type={dropdownTypes.TEMPLATE}
                  title={t(keys.common.TEMPLATE)}
                  highlight
                  preventStateChange
                  items={templates || []}
                  onChange={(template) => {
                    update({
                      template,
                    });
                  }}
                  onRemove={() => update({ template: null })}
                  selected={template}
                />
                <br />
              </div>
            ) : (
              <div>
                <input
                  type="url"
                  id="url"
                  name="url"
                  placeholder="Enter URL"
                  value={url || ''}
                  onChange={(e) => {
                    update({ url: e.target.value });
                  }}
                  className={styles.urlInput}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

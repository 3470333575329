import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import CustomSpinner from '../components/CustomSpinner';
import { sortItemsByDateModified } from '../utilities';
import moment from 'moment';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';
import { useCurrentUser } from './UserProvider';
import { useWorkspacePermissions } from './WorkspacePermissionsProvider';

const inspectionsQuery = loader('./MyInspections.graphql');
const paginatedInspectionsQuery = loader('./MyInspections.paginated.graphql');
const inspectionPageQuery = loader(
  '../pages/inspection_page/Inspection.inspection.graphql',
);

const PAGE_SIZE = 5;
const DEBOUCE_MS = 50;

const MyInspectionsProviderContext = createContext();

export const MyInspectionsProvider = ({ children }) => {
  const online = useOnlineStatus();
  const client = useApolloClient();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useCurrentUser();
  const { changingWorkspace } = useWorkspacePermissions();

  const { data: { inspections = [] } = {} } = useQuery(inspectionsQuery, {
    variables: {
      creatorId: `${user?.id}`,
    },
    fetchPolicy: 'cache-only',
  });

  const [fetchInspections] = useLazyQuery(paginatedInspectionsQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    async function fetchData() {
      while (true) {
        const existingData = client.readQuery({
          query: inspectionsQuery,
          variables: {
            creatorId: `${user?.id}`,
          },
        }) ?? {
          inspections: [],
        };

        const sortedInspections = sortItemsByDateModified(
          [...existingData?.inspections] || [],
        );

        const latestCachedDate = sortedInspections.length
          ? new Date(
              parseInt(
                sortedInspections[sortedInspections.length - 1]?.dateModified,
                10,
              ),
            )
          : null;

        const sevenDaysAgo = moment().subtract(7, 'days').toDate();

        const minDate = latestCachedDate
          ? new Date(Math.max(latestCachedDate, sevenDaysAgo))
          : sevenDaysAgo;

        const { data } = await fetchInspections({
          variables: {
            minDate,
            pageSize: PAGE_SIZE,
            creatorId: `${user?.id}`,
          },
        });

        const startData = existingData ?? { inspections: [] };

        const newInspectionIds = data.inspections.map((t) => t.id);

        newInspectionIds.forEach((id) => {
          const inspection = data.inspections.find((i) => i.id === id);
          client.writeQuery({
            query: inspectionPageQuery,
            variables: { id: `${id}` },
            data: { inspections: [inspection] },
          });
        });

        const oldInspections = startData.inspections.filter(
          (t) => !newInspectionIds.includes(t.id),
        );

        const newInspections = sortItemsByDateModified([
          ...oldInspections,
          ...data.inspections,
        ]);

        client.writeQuery({
          query: inspectionsQuery,
          data: { inspections: newInspections },
          variables: {
            creatorId: `${user?.id}`,
          },
        });

        setProgress(
          Math.round((newInspectionIds.length / data.inspectionsCount) * 100),
        );

        if (data?.inspections?.length !== PAGE_SIZE) {
          setProgress(100);
          setLoading(false);
          break;
        }

        await new Promise((r) => setTimeout(r, DEBOUCE_MS));
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedInspections = useMemo(
    () => [...inspections].reverse(),
    [inspections],
  );

  if (loading && online && !changingWorkspace) {
    return (
      <CustomSpinner text={`Fetching recent inspections.. ${progress}%`} />
    );
  }

  return (
    <MyInspectionsProviderContext.Provider
      value={{ inspections: sortedInspections }}
    >
      {children}
    </MyInspectionsProviderContext.Provider>
  );
};
export const useMyInspections = () => useContext(MyInspectionsProviderContext);

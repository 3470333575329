import React, { useState, useEffect } from 'react';
import { isValidUUID } from '../../utilities/offline_hooks_setup_apollo/offlineUtils';
import { getOfflineFileUrl } from '../../hooks/offline-hooks/localForageDb';

const ImageWrapper = ({ image, ...rest }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (isValidUUID(image)) {
        const fetchedUrl = await getOfflineFileUrl(image);
        setUrl(fetchedUrl);
      } else if (image) {
        setUrl(image);
      }
    };

    fetchImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  if (!url) {
    return null;
  }

  return <img src={url} alt="description" {...rest} />;
};

export default ImageWrapper;

import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import CountPieChart from './CountPieChart';
import { useMemo } from 'react';
import { useQueryParams } from '../../../hooks/misc';
import { chartViews, getChartLabel } from '../../../utilities/analytics';

const query = loader('./Observations.graphql');

export default function Observations({
  workspaceIds,
  startDate,
  endDate,
  params,
  view = 1,
  status,
  isInteractive,
  header,
  subheader,
}) {
  const { upsertParams } = useQueryParams();

  const filters = useMemo(() => {
    const f = [];
    if (startDate && endDate) {
      f.push({
        field: 'dateCreated',
        operator: 'between',
        value: [startDate, endDate],
      });
    }
    if (params.status) {
      f.push({ field: 'status', operator: 'eq', value: [params.status] });
    }

    return f;
  }, [endDate, params.status, startDate]);

  const { loading, data: { observationsFilteredCount = [] } = {} } = useQuery(
    query,
    {
      fetchPolicy: 'network-only',
      variables: {
        workspaceIds,
        groupBy: chartViews.OBSERVATIONS[view] || 'type',
        options: {
          filters,
        },
      },
    },
  );
  const total = observationsFilteredCount?.reduce(
    (accumulator, current) => accumulator + current.value,
    0,
  );
  const chartData = useMemo(
    () =>
      observationsFilteredCount?.map((inspection) => ({
        ...inspection,
        label: getChartLabel(inspection.id),
      })),
    [observationsFilteredCount],
  );

  return (
    <div style={{ width: '100%' }}>
      <CountPieChart
        isLoading={loading}
        status={status}
        data={chartData || []}
        total={total || 0}
        header={header}
        subheader={subheader}
        isInteractive={isInteractive}
        onClick={({ data }) => {
          if (!isInteractive) {
            return;
          }
          const target = chartViews.OBSERVATIONS[view];
          const v = parseInt(view) + 1;
          upsertParams({ view: v, [target]: data.id });
        }}
      />
    </div>
  );
}

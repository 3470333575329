import { fileTypesConversion } from './files';
import { strings } from '../pages/hr/Expenses';
import { keys, getTranslationKey } from './translator/translation_keys';
import { t } from 'i18next';
import moment from 'moment';

export const templateFilterOptions = ['Type', 'Creator', 'Status'];
export const templateStatusTypes = ['Draft', 'Published'];

export const fileFilterOptions = ['Type', 'Creator'];

export const vehicleFilterOptions = ['Type', 'Subtype', 'Color', 'Creator'];

export const timecardFilterOptions = [
  'Status',
  'Workspace',
  'Project',
  'Creator',
];
export const timecardStatusOptions = [
  'Draft',
  'Pending',
  'Denied',
  'Approved',
  'Complete',
];

export const expenseFilterOptions = ['Status', 'Workspace', 'Creator'];
export const expenseStatusOptions = [
  'Draft',
  'Pending',
  'Approved',
  'Denied',
  'Complete',
];
export const teamExpenseStatusOptions = [
  'Pending',
  'Approved',
  'Denied',
  'Complete',
];

export const convertExpenseFiltersToQueryParams = (
  { filterData },
  key,
  currentUser,
  team,
  isSupervisor,
  myTeamOnly,
  searchText,
  dateRange,
) => {
  const filters = [
    {
      field: 'creatorId',
      operator: key === 'personal' ? 'eq' : 'ne',
      value: `${currentUser.id}`,
    },
  ];
  if (!!searchText) {
    filters.push({
      field: 'id',
      operator: 'eq',
      value: searchText,
    });
  }
  if (key !== 'personal') {
    filters.push({ field: 'status', operator: 'ne', value: 'DRAFT' });
    if (
      (currentUser.role === 'ADMIN' && myTeamOnly) ||
      (isSupervisor && currentUser.role !== 'ADMIN')
    ) {
      filters.push({
        field: 'creatorId',
        operator: 'or',
        value: team.map((t) => t.id.toString()),
      });
    }
  }

  if (filterData?.Creator) {
    filters.push({
      field: 'creatorId',
      operator: 'eq',
      value: [`${filterData.Creator.id}`],
    });
  }
  if (filterData?.Status) {
    filters.push({
      field: 'status',
      operator: 'eq',
      value: [`${strings[filterData.Status]}`],
    });
  }
  if (filterData?.Workspace) {
    filters.push({
      field: 'workspaceId',
      operator: 'eq',
      value: [`${filterData.Workspace.id}`],
    });
  }
  if (dateRange.min && dateRange.max) {
    filters.push({
      field: 'dateOfExpense',
      operator: 'between',
      value: [dateRange.min, dateRange.max],
    });
  } else {
    if (dateRange.min) {
      filters.push({
        field: 'dateOfExpense',
        operator: 'gte',
        value: [dateRange.min],
      });
    }

    if (dateRange.max) {
      filters.push({
        field: 'dateOfExpense',
        operator: 'lte',
        value: [dateRange.max],
      });
    }
  }

  return filters;
};

export const convertTemplateFiltersToQueryParams = (
  { filterData },
  workspaceId,
  isObservation,
  selected,
) => {
  const filters = [
    {
      field: 'workspaceId',
      operator: 'or',
      value: [`${workspaceId}`, null],
    },
    isObservation
      ? {
          field: 'templateType',
          operator: 'eq',
          value: 'OBSERVATION',
        }
      : {
          field: 'templateType',
          operator: 'not',
          value: ['INCIDENT', 'OBSERVATION', 'AUDIT'],
        },
  ];
  if (selected) {
    filters.push({
      field: 'id',
      operator: 'eq',
      value: `${selected}`,
    });
  }

  if (filterData?.Creator) {
    filters.push({
      field: 'creatorId',
      operator: 'eq',
      value: [`${filterData.Creator.id}`],
    });
  }

  if (filterData?.Type) {
    filters.push({
      field: 'templateType',
      operator: filterData.Type.length > 1 ? 'or' : 'eq',
      value: filterData.Type,
    });
  }

  if (filterData?.Status) {
    filters.push({
      field: 'isDraft',
      operator: 'eq',
      value: filterData.Status === 'Draft' ? ['TRUE'] : ['FALSE'],
    });
  }
  if (filterData?.reactTable) {
    filterData.filters.forEach((filter) => {
      const equalFilters = [
        'creatorId',
        'type',
        'isDraft',
        'reviewRequired',
        'analysisRequested',
        'id',
      ];
      const dateFilters = ['dateCreated'];
      if (filter.key && filter.value) {
        if (equalFilters.includes(filter.key)) {
          filters.push({
            field: filter.key,
            operator: 'eq',
            value: [`${filter.value}`],
          });
        } else if (dateFilters.includes(filter.key)) {
          const [dateMin, dateMax] = filter.value;

          if (dateMin && !dateMax) {
            filters.push({
              field: filter.key,
              operator: 'gte',
              value: [dateMin.format('YYYY-MM-DD 00:00:00')],
            });
          } else if (!dateMin && dateMax) {
            filters.push({
              field: filter.key,
              operator: 'lte',
              value: [dateMax.format('YYYY-MM-DD 23:59:59')],
            });
          } else if (dateMin && dateMax) {
            filters.push({
              field: filter.key,
              operator: 'between',
              value: [
                dateMin.format('YYYY-MM-DD 00:00:00'),
                dateMax.format('YYYY-MM-DD 23:59:59'),
              ],
            });
          }
        } else if (filter.key === 'workspaceId') {
          filters.push({
            field: filter.key,
            operator: filter.value,
            value: filter.value === 'eq' ? [`${workspaceId}`] : ['null'],
          });
        } else {
          filters.push({
            field: filter.key,
            operator: 'iLike',
            value: [`%${filter.value}%`],
          });
        }
      }
    });
  }
  return filters;
};

export const convertVehicleFiltersToQueryParams = (
  { filterData },
  { searchField, searchText },
) => {
  const filters = [];
  if (filterData?.Type) {
    filters.push({
      field: 'type',
      operator: 'eq',
      value: [filterData?.Type],
    });
  }
  if (filterData?.Subtype) {
    filters.push({
      field: 'subtype',
      operator: 'eq',
      value: [filterData?.Subtype],
    });
  }
  if (filterData?.Creator) {
    filters.push({
      field: 'creatorId',
      operator: 'eq',
      value: [`${filterData.Creator.id}`],
    });
  }
  if (filterData?.reactTable) {
    filterData.filters.forEach((filter) => {
      const equalFilters = [
        'creatorId',
        'type',
        'subtype',
        'assigneeId',
        'endOfLifeHours',
      ];
      const dateFilters = ['dateCreated', 'lastInspectionDate', 'endOfLife'];
      if (equalFilters.includes(filter.key)) {
        filters.push({
          field: filter.key,
          operator: 'eq',
          value: [`${filter.value}`],
        });
      } else if (dateFilters.includes(filter.key)) {
        const [dateMin, dateMax] = filter.value;

        if (dateMin && !dateMax) {
          filters.push({
            field: filter.key,
            operator: 'gte',
            value: [dateMin.format('YYYY-MM-DD 00:00:00')],
          });
        } else if (!dateMin && dateMax) {
          filters.push({
            field: filter.key,
            operator: 'lte',
            value: [dateMax.format('YYYY-MM-DD 23:59:59')],
          });
        } else if (dateMin && dateMax) {
          filters.push({
            field: filter.key,
            operator: 'between',
            value: [
              dateMin.format('YYYY-MM-DD 00:00:00'),
              dateMax.format('YYYY-MM-DD 23:59:59'),
            ],
          });
        }
      } else {
        filters.push({
          field: filter.key,
          operator: 'iLike',
          value: [`%${filter.value}%`],
        });
      }
    });
  }
  if (searchText.length > 0) {
    filters.push({
      field: `${searchField}`,
      operator: 'iLike',
      value: [`%${searchText}%`],
    });
  }

  return filters;
};

export const convertFilesToQueryParams = (
  { filterData, urlFilter },
  parent,
  workspaceId,
  rootFolder,
  { searchField, searchText },
) => {
  const rootFolders = {
    ai: [
      { field: 'workspaceId', operator: 'eq', value: ['null'] },
      { field: 'vector', operator: 'eq', value: ['true'] },
    ],
    global: [
      { field: 'workspaceId', operator: 'eq', value: ['null'] },
      { field: 'vector', operator: 'eq', value: ['false'] },
    ],
    workspace: [
      { field: 'workspaceId', operator: 'eq', value: [`${workspaceId}`] },
      { field: 'vector', operator: 'eq', value: ['false'] },
    ],
  };
  const filters = [
    {
      field: 'parentId',
      operator: parent === 'null' ? 'is' : 'eq',
      value: [parent],
    },
    ...rootFolders[rootFolder],
    //This is to stop expense/timecards from files page and WILL break this query until they are merged together
    {
      field: 'expenseId',
      operator: 'eq',
      value: 'null',
    },
    // {
    //   field: 'timecardId',
    //   operator: 'eq',
    //   value: 'null',
    // },
  ];

  if (filterData?.id) {
    return [{ field: 'id', operator: 'eq', value: `${filterData.id}` }];
  }

  if (filterData?.Type) {
    filters.push({
      field: 'type',
      operator: 'eq',
      value: [filterData?.Type],
    });
  }
  if (filterData?.Asset) {
    filters.push({
      field: 'vehicleId',
      operator: 'eq',
      value: [`${filterData.Asset.id}`],
    });
  }
  if (filterData?.Creator) {
    filters.push({
      field: 'creatorId',
      operator: 'eq',
      value: [`${filterData.Creator.id}`],
    });
  }
  if (filterData?.Assignee) {
    filters.push({
      field: 'userId',
      operator: 'eq',
      value: [`${filterData.Assignee.id}`],
    });
  }
  if (filterData?.fileType) {
    filters.push({
      field: 'extension',
      operator: 'or',
      value: fileTypesConversion[filterData.fileType],
    });
  }
  if (filterData?.Status) {
    filters.push({
      field: 'status',
      operator: 'eq',
      value: [`${filterData.Status}`],
    });
  }
  if (filterData?.Hidden) {
    filters.filter(({ field }) => field === 'hidden');
  } else {
    filters.push({
      field: 'hidden',
      operator: 'eq',
      value: `false`,
    });
  }
  if (filterData?.reactTable) {
    filterData.filters.forEach((filter) => {
      if (filter.key && filter.value) {
        const equalFilters = ['creatorId', 'userId', 'downloadAllowed'];
        const dateFilters = ['dateCreated', 'expires'];

        if (equalFilters.includes(filter.key)) {
          filters.push({
            field: filter.key,
            operator: 'eq',
            value: [`${filter.value}`],
          });
        } else if (dateFilters.includes(filter.key)) {
          const [dateMin, dateMax] = filter.value;

          if (dateMin && !dateMax) {
            filters.push({
              field: filter.key,
              operator: 'gte',
              value: [dateMin.format('YYYY-MM-DD 00:00:00')],
            });
          } else if (!dateMin && dateMax) {
            filters.push({
              field: filter.key,
              operator: 'lte',
              value: [dateMax.format('YYYY-MM-DD 23:59:59')],
            });
          } else if (dateMin && dateMax) {
            filters.push({
              field: filter.key,
              operator: 'between',
              value: [
                dateMin.format('YYYY-MM-DD 00:00:00'),
                dateMax.format('YYYY-MM-DD 23:59:59'),
              ],
            });
          }
        } else if (filter.key === 'extension') {
          filters.push({
            field: 'extension',
            operator: 'or',
            value: fileTypesConversion[filter.value],
          });
        } else {
          filters.push({
            field: filter.key,
            operator: 'iLike',
            value: [`%${filter.value}%`],
          });
        }
      }
    });
  }

  if (searchText.length > 0) {
    filters.push({
      field: `${searchField}`,
      operator: 'iLike',
      value: [`%${searchText}%`],
    });
  }

  return filters;
};

export const convertTimecardFiltersToQueryParams = (
  { filterData },
  selectedTab,
  user,
  team,
  isSupervisor,
  myTeamOnly,
  dateFilters,
) => {
  const filters = [
    {
      field: 'creatorId',
      operator: selectedTab === 'personal' ? 'eq' : 'ne',
      value: `${user.id}`,
    },
  ];

  if (selectedTab !== 'personal') {
    filters.push({ field: 'status', operator: 'ne', value: 'DRAFT' });
    if (
      (user.role === 'ADMIN' && myTeamOnly) ||
      (isSupervisor && user.role !== 'ADMIN')
    ) {
      filters.push({
        field: 'creatorId',
        operator: 'or',
        value: team.map((t) => t.id.toString()),
      });
    }
  }
  if (filterData?.Status) {
    filters.push({
      field: 'status',
      operator: 'eq',
      value: [`${strings[filterData?.Status]}`],
    });
  }

  if (filterData?.Creator) {
    filters.push({
      field: 'creatorId',
      operator: 'eq',
      value: [`${filterData.Creator.id}`],
    });
  }

  if (filterData?.Workspace) {
    filters.push({
      field: 'workspaceId',
      operator: 'eq',
      value: [`${filterData.Workspace.id}`],
    });
  }

  if (filterData?.Project) {
    filters.push({
      field: 'poNumber',
      operator: 'iLike',
      value: [`%${filterData.Project}%`],
    });
  }

  if (dateFilters.min && dateFilters.max) {
    filters.push({
      field: 'startTime',
      operator: 'between',
      value: [
        moment(dateFilters.min).format('YYYY-MM-DD 00:00:00'),
        moment(dateFilters.max).endOf('YYYY-MM-DD 23:59:59'),
      ],
    });
  } else {
    if (dateFilters.min) {
      filters.push({
        field: 'startTime',
        operator: 'gte',
        value: [moment(dateFilters.min).format('YYYY-MM-DD 00:00:00')],
      });
    }

    if (dateFilters.max) {
      filters.push({
        field: 'startTime',
        operator: 'lte',
        value: [moment(dateFilters.max).endOf('YYYY-MM-DD 23:59:59')],
      });
    }
  }
  return filters;
};

export const convertTaskDataFiltersToQueryParams = ({
  dateFilter,
  selectedWorkspace,
}) => {
  const subfilters = [];

  if (dateFilter.min && dateFilter.max) {
    subfilters.push({
      association: 'inspection',
      field: 'dateModified',
      operator: 'between',
      value: [
        moment(dateFilter.min).format('YYYY-MM-DD 00:00:00'),
        moment(dateFilter.max).format('YYYY-MM-DD 23:59:59'),
      ],
    });
  } else {
    if (dateFilter.min) {
      subfilters.push({
        association: 'inspection',
        field: 'dateModified',
        operator: 'gte',
        value: [moment(dateFilter.min).format('YYYY-MM-DD 00:00:00')],
      });
    }

    if (dateFilter.max) {
      subfilters.push({
        association: 'inspection',
        field: 'dateModified',
        operator: 'lte',
        value: [moment(dateFilter.max).format('YYYY-MM-DD 23:59:59')],
      });
    }
  }

  if (selectedWorkspace) {
    subfilters.push({
      association: 'inspection',
      field: 'workspaceId',
      operator: 'eq',
      value: [`${selectedWorkspace}`],
    });
  }

  return { subfilters };
};

export const convertTaskListFiltersToQueryParams = ({ filterData }) => {
  const filters = [];
  const subfilters = [];

  if (filterData?.reactTable) {
    filterData.filters.forEach((filter) => {
      const equalFilters = ['creatorId'];
      const dateFilters = ['dateCreated'];

      if (equalFilters.includes(filter.key)) {
        filters.push({
          field: filter.key,
          operator: 'eq',
          value: [`${filter.value}`],
        });
      } else if (dateFilters.includes(filter.key)) {
        const [dateMin, dateMax] = filter.value;

        if (dateMin && !dateMax) {
          filters.push({
            field: filter.key,
            operator: 'gte',
            value: [dateMin.format('YYYY-MM-DD 00:00:00')],
          });
        } else if (!dateMin && dateMax) {
          filters.push({
            field: filter.key,
            operator: 'lte',
            value: [dateMax.format('YYYY-MM-DD 23:59:59')],
          });
        } else if (dateMin && dateMax) {
          filters.push({
            field: filter.key,
            operator: 'between',
            value: [
              dateMin.format('YYYY-MM-DD 00:00:00'),
              dateMax.format('YYYY-MM-DD 23:59:59'),
            ],
          });
        }
      } else if (filter.key.includes('.')) {
        subfilters.push({
          association: filter.key.split('.')[0],
          field: filter.key.split('.')[1],
          operator: 'iLike',
          value: [`%${filter.value}%`],
        });
      } else {
        filters.push({
          field: filter.key,
          operator: 'iLike',
          value: [`%${filter.value}%`],
        });
      }
    });
  }
  return { filters, subfilters };
};

export const forFilterDisplay = {
  VEHICLE: keys.common.VEHICLE,
  COMMISSIONING: keys.common.COMMISSIONING,
  QUALITY: keys.common.QUALITY,
  HSE: keys.common.HSE,
  fileType: keys.utilities.FILETYPE,
  MEETING: keys.common.MEETING,
  DAILY_REPORT: keys.common.DAILY_REPORT,
};

export const formatFilterDisplay = (filterType, filterValue, namespace) => {
  switch (filterType) {
    case 'Creator':
    case 'Assignee':
      return `: ${filterValue.firstName} ${filterValue.lastName}`;
    case 'Asset':
      return `: ${filterValue.year} ${filterValue.make} ${filterValue.model} (${filterValue.unitNumber})`;
    case 'Type':
      if (Array.isArray(filterValue)) {
        let forDisplay = [];
        filterValue.forEach((f) => forDisplay.push(t(forFilterDisplay[f])));
        return `: ${forDisplay.join(', ')}`;
      }
      return `: ${t(getTranslationKey(filterValue, namespace)) || filterValue}`;
    case 'Workspace':
      return `: ${filterValue.title}`;
    case 'DateRange':
      return `: ${filterValue[0].format(
        'YYYY-MM-DD',
      )} - ${filterValue[1].format('YYYY-MM-DD')}`;
    case 'Hidden':
      return `: ${t(keys.common.YES)}`;

    default:
      return Array.isArray(filterValue)
        ? `: ${filterValue.join(', ')}`
        : `: ${t(getTranslationKey(filterValue, namespace)) || filterValue}`;
  }
};

import { ResponsivePie } from '@nivo/pie';
import styles from './CountPage.module.scss';
import { Text } from '../../../components/typography';
import { bootstrapColors } from '../utilities';
import { getChartLabel } from '../../../utilities/analytics';
import { useMemo } from 'react';

const emptyStateImage =
  'https://app.opasmobile.com/assets/graphics/empty_graphs_es.png';

export default function CountPieChart({
  data,
  onClick = () => {},
  total = 0,
  isLoading,
  status = [],
  isInteractive = true,
  header,
  subheader,
}) {
  const coloredData = useMemo(() => {
    return data?.map((item, index) => ({
      ...item,
      label: item.label || getChartLabel(item.id),
      color: bootstrapColors[index % bootstrapColors.length],
    }));
  }, [data]);

  const displayTotal = isLoading
    ? total
    : data?.reduce((sum, item) => sum + (item.value || 0), 0);

  return (
    <div className={styles.page}>
      <div className={styles.filters}>
        <Text size="lg" noMargin weight="bold">
          Total: {displayTotal}
        </Text>
        <Text noMargin weight="semiBold">
          {header}
        </Text>
        <Text noMargin>{subheader}</Text>
        {status?.map((statusItem, index) => (
          <div key={index}>
            {Object.entries(statusItem).map(([key, value]) => (
              <div key={key}>
                <Text color="secondary" size="sm" weight="semiBold" noMargin>
                  {key}
                </Text>
                <Text weight="semiBold" noMargin>
                  {value}
                </Text>
              </div>
            ))}
          </div>
        ))}
        <div className={styles.legend}>
          {coloredData.map((c) => (
            <div className={styles.legendText} key={`${c.label}-${c.value}`}>
              <div
                style={{
                  backgroundColor: c.color,
                  borderRadius: '50%',
                  width: '1rem',
                  height: '1rem',
                }}
              />
              <Text weight="semiBold" size="sm" noMargin>
                {c.label} - {c.value}
              </Text>
            </div>
          ))}
        </div>
      </div>

      {data?.length ? (
        <div className={styles.chartContainer}>
          <ResponsivePie
            data={coloredData}
            id="label"
            colors={{ datum: 'data.color' }}
            margin={{ top: 40, right: 30, bottom: 40, left: 50 }}
            innerRadius={0.5}
            padAngle={0.8}
            cornerRadius={3}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            arcLinkLabelsTextColor="#6e6e73"
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#ffffff"
            activeInnerRadiusOffset={20}
            activeOuterRadiusOffset={20}
            isInteractive={isInteractive}
            onClick={onClick}
            motionStiffness={90}
            motionDamping={15}
            animate={true}
            motionConfig={'gentle'}
            theme={{
              labels: {
                text: {
                  fill: 'white',
                  fontSize: 12,
                },
              },
            }}
          />
        </div>
      ) : (
        !isLoading && (
          <div className={styles.emptyStateContainer}>
            <img src={emptyStateImage} alt="nodata" className={styles.image} />
            <Text textAlign="center" size="lg" weight="semiBold">
              No Data Found
            </Text>
          </div>
        )
      )}
    </div>
  );
}

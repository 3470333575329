import { useState } from 'react';
import { Button } from '../../components';
import Modal from '../../components/modals/Modal';
import styles from './ReportSelector.module.scss';
import { auditReportTypes } from '../audits';
import { Form } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { downloadPDF } from './misc';

export default function ReportSelector({
  inspection,
  correctiveActions,
  notes,
}) {
  const [showModal, setShowModal] = useState(false);
  const [props, setProps] = useState({});
  const handleCheckboxChange = (reportType) => {
    setProps((prevSelected) => ({
      ...prevSelected,
      [reportType]: !prevSelected[reportType],
    }));
  };
  const handleSelectAll = () => {
    const allOptions = Object.keys(auditReportTypes).reduce((acc, key) => {
      acc[key] = allOptionsSelected ? false : true;
      return acc;
    }, {});

    allOptions['comments'] = true;
    setProps(allOptions);
  };
  const hasProp = Object.values(props).some((value) => value === true);
  const allOptionsSelected =
    !!Object.values(props)?.length &&
    Object.values(props).every((value) => value === true);
  return (
    <div>
      <Button
        value="Report"
        icon="download"
        outlined
        className={styles.pdfButton}
        onClick={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        submitDisabled={!hasProp}
        onClose={() => {
          setProps({});
          setShowModal(false);
        }}
        title="Select Report Options"
        onSubmit={() => {
          downloadPDF({
            correctiveActions: correctiveActions,
            audit: inspection,
            props,
            notes,
          });
          setProps({});
        }}
      >
        <br />
        <Text
          noMargin
          weight="semiBold"
          color="accentPrimary"
          onClick={() => handleSelectAll()}
          hover
        >
          {allOptionsSelected ? 'Deselect All' : 'Select All'}
        </Text>
        {Object.entries(auditReportTypes).map(([key, value]) => (
          <div key={key} className={styles.checkContainer}>
            <div className={styles.check}>
              <Form.Check
                checked={!!props[key]}
                onChange={() => handleCheckboxChange(key)}
              />
              <Text noMargin>{value}</Text>
            </div>
            {!!props.checklist && key === 'checklist' && (
              <div className={styles.subCheck}>
                <Form.Check
                  checked={!!props.comments}
                  onChange={() => handleCheckboxChange('comments')}
                />
                <Text noMargin>Comments</Text>
              </div>
            )}
          </div>
        ))}
      </Modal>
    </div>
  );
}

import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import {
  evaluateCondition,
  formatBundleItems,
  numberCheck,
} from '../../inspection';
import { inputTypes } from '../../../constants/strings';
import { Answer } from './assessment_answers';
import { customColors } from '../../misc';
import { actionsKeys } from '../../../components/question_menu/conditionalActions/conditionalAction.utils';
import { textStyles } from '../styles';
import { AssessmentComments } from './assessment_comments';
import { riskLevels } from '../../audits';

const fontSize = 8;
const blue = customColors.BLUE;
const textPrimary = '#1d1d1f';
const textSecondary = customColors.SECONDARY;

const styles = StyleSheet.create({
  bundleIcon: {
    height: '12px',
    width: '12px',
  },
  categoryHeaderContainer: {
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'flex-start',
  },
  categoryHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 3,
  },
  category: {
    flexDirection: 'column',
  },
  bundleAnswer: {
    flexDirection: 'column',
    gap: 5,
  },
  text: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
  },
  limitsText: {
    fontSize: fontSize - 1,
    color: textSecondary,
    fontFamily: 'eina03regular',
  },
  semiBoldText: {
    fontSize: fontSize + 2,
    color: textPrimary,
    fontFamily: 'eina03semibold',
  },
  bundle: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    marginTop: 3,
    marginBottom: 3,
  },
  answer: {
    flexDirection: 'column',
    gap: 7,
    marginTop: 3,
    marginBottom: 3,
  },
  conditionalAction: {
    marginTop: 2,
  },
  conditionalAnswer: {
    marginLeft: 3,
  },
  semiBoldTextSecondary: {
    fontSize: fontSize + 2,
    color: textSecondary,
    fontFamily: 'eina03semibold',
  },
});

export const Category = ({
  category,
  categoryId,
  isBundle,
  questions,
  answers,
  isAudit,
  correctiveActions,
  showComments,
}) => {
  const { bundleMap } = formatBundleItems(answers);
  const getAnswer = (answers, question) => {
    return answers?.find((a) => a.questionId === question.id)
      ? answers?.find((a) => a.questionId === question.id)?.value
      : null;
  };
  return (
    <View style={styles.category}>
      <View style={styles.categoryHeaderContainer}>
        <Text style={styles.categoryHeader}>{category}</Text>
        {isBundle && (
          <View>
            <Image
              style={styles.bundleIcon}
              alt={'bundle_icon'}
              src={
                'https://app.opasmobile.com/assets/graphics/layers_pdf_icon.png'
              }
            />
          </View>
        )}
      </View>
      {isAudit ? (
        <View>
          {questions?.map((question) => {
            const value = getAnswer(answers, question);
            const itemCorrectiveAction =
              correctiveActions?.find((c) => c.question?.id === question.id) ||
              [];
            const answer = answers?.find((a) => a.questionId === question.id);
            return (
              <View style={textStyles.row} key={question.id}>
                <View style={textStyles.fontSizeMd}>
                  <Text style={textStyles.leftText}>{question.title}</Text>
                  {!!showComments && (
                    <View style={textStyles.commentContainer}>
                      <AssessmentComments answer={answer} />
                    </View>
                  )}
                </View>
                <View>
                  {!!itemCorrectiveAction?.riskLevel ? (
                    <Text
                      style={
                        textStyles[
                          riskLevels[itemCorrectiveAction.riskLevel]?.color
                        ]
                      }
                    >
                      {`${itemCorrectiveAction.riskLevel} ${
                        itemCorrectiveAction.riskLevel === 'OBSERVATION'
                          ? ''
                          : 'RISK'
                      }`}
                    </Text>
                  ) : null}
                  <Text
                    style={
                      value === 'PASS' ? textStyles.green : textStyles.rightText
                    }
                  >
                    {value === 'NOT_APPLICABLE'
                      ? 'N/A'
                      : value === 'FAIL'
                      ? itemCorrectiveAction.externalId
                      : value || '-'}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      ) : isBundle ? (
        <>
          {!!bundleMap[categoryId] &&
            Object.entries(bundleMap[categoryId]).map(
              ([bundleId, bundleAnswers], idx) => (
                <View key={`bundle-${bundleId}`} style={styles.bundle}>
                  <Text style={styles.semiBoldText}>{`${idx + 1}.`}</Text>
                  <View style={styles.bundleAnswer}>
                    {Object.values(bundleAnswers)?.map((answer, index) => {
                      if (answer.categoryId !== categoryId) return [];
                      const question = questions.find(
                        (question) => question.id === answer.questionId,
                      );
                      return (
                        <View
                          key={`question-${question.id}-${bundleId}-${index}`}
                        >
                          <Text style={styles.semiBoldText}>
                            {question.title}
                          </Text>
                          {question.inputType === inputTypes.number &&
                          !!question.additionalData ? (
                            <Text style={styles.limitsText}>
                              {numberCheck(question.additionalData)}
                            </Text>
                          ) : null}
                          <Answer
                            answer={answer}
                            inputType={question.inputType}
                            additional={question.additionalData}
                            question={question}
                          >
                            {answer?.value}
                          </Answer>
                        </View>
                      );
                    })}
                  </View>
                </View>
              ),
            )}
        </>
      ) : (
        <View style={styles.answer}>
          {questions?.map((question, index) => {
            const answer = answers?.find((a) => a.questionId === question.id);
            const { conditionalAction } = question || {};
            return (
              <View key={`question-${question.id}-${index}`}>
                <Text style={styles.semiBoldText}>{question.title}</Text>
                {question.inputType === inputTypes.number &&
                !!question.additionalData ? (
                  <Text style={styles.limitsText}>
                    {numberCheck(question.additionalData)}
                  </Text>
                ) : null}

                <Answer
                  answer={answer}
                  inputType={question.inputType}
                  additional={question.additionalData}
                  question={question}
                >
                  {getAnswer(answers, question)}
                </Answer>
                {!!conditionalAction &&
                  conditionalAction?.actions?.length > 0 &&
                  conditionalAction.actions?.map(
                    ({ id, action, actionable, additionalData }, index) => {
                      if (
                        action === actionsKeys.followUpQuestion &&
                        evaluateCondition(answer?.value, question)
                      ) {
                        const conditionalAns = answers?.find(
                          (a) => a.questionId === actionable?.id,
                        );
                        return (
                          <View
                            style={styles.conditionalAction}
                            key={`action-question-${id}-${index}`}
                          >
                            <View style={styles.conditionalAnswer}>
                              <Text style={styles.semiBoldText}>
                                {actionable.title}
                              </Text>
                              {actionable.inputType === inputTypes.number &&
                              !!actionable.additionalData ? (
                                <Text style={styles.limitsText}>
                                  {numberCheck(actionable.additionalData)}
                                </Text>
                              ) : null}

                              <Answer
                                answer={conditionalAns}
                                inputType={actionable.inputType}
                                additional={actionable.additionalData}
                                question={actionable}
                              >
                                {getAnswer(answers, actionable)}
                              </Answer>
                            </View>
                          </View>
                        );
                      } else if (
                        action === actionsKeys.showMessage &&
                        evaluateCondition(answer?.value, question)
                      ) {
                        return (
                          <View
                            style={styles.conditionalAction}
                            key={`action-message-${id}-${index}`}
                          >
                            <View style={styles.conditionalAnswer}>
                              <Text style={styles.semiBoldTextSecondary}>
                                Message
                              </Text>
                              <Text style={styles.semiBoldText}>
                                {additionalData?.message}
                              </Text>
                            </View>
                          </View>
                        );
                      } else if (
                        action === actionsKeys.sendNotification &&
                        evaluateCondition(answer?.value, question)
                      ) {
                        return (
                          <View
                            style={styles.conditionalAction}
                            key={`action-notification-${id}-${index}`}
                          >
                            <View style={styles.conditionalAnswer}>
                              <Text style={styles.semiBoldTextSecondary}>
                                Notified
                              </Text>
                              <Text
                                style={styles.text}
                              >{`${actionable?.firstName} ${actionable?.lastName}`}</Text>
                            </View>
                          </View>
                        );
                      } else {
                        return null;
                      }
                    },
                  )}
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

import { useState } from 'react';
import SimpleUserCard from '../../SimpleUserCard';
import { Icon } from '@mui/material';
import { Form } from 'react-bootstrap';
import { Text } from '../../typography';
import styles from './ConditionalAction.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';

export default function NotificationAction({
  user,
  onDelete = () => {},
  additional,
  onChange = () => {},
  showDelete = false,
  readOnly = false,
}) {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  return (
    <div
      key={`action-user-${user?.id}`}
      onClick={(e) => {
        e.stopPropagation();
        !readOnly && setShowMessage(!showMessage);
      }}
    >
      <div className={styles.notifyContainer}>
        <div className={styles.notifySubContainer}>
          <Icon
            className={styles.notifyIcon}
            baseClassName="material-icons-outlined"
          >
            notifications
          </Icon>
          <SimpleUserCard user={user} />
        </div>
        {showDelete && (
          <Icon
            className={styles.deleteIcon}
            baseClassName="material-icons-outlined"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            delete
          </Icon>
        )}
      </div>
      {showMessage && (
        <div className={styles.notifyForm}>
          <Text noMargin color="secondary">
            {t(keys.conditionalActions.NOTIFICATION_MESSAGE)}
          </Text>
          {readOnly ? (
            <Text>{message ?? additional}</Text>
          ) : (
            <Form.Control
              onClick={(e) => e.stopPropagation()}
              value={message ?? additional}
              onChange={({ target: { value } }) => setMessage(value)}
              onBlur={() => onChange(message)}
            />
          )}
        </div>
      )}
    </div>
  );
}

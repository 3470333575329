import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useMemo, useState } from 'react';
import { useCurrentUser } from '../../providers/UserProvider';
import { Text } from '../../components/typography';
import styles from './IncidentSearch.module.scss';
import { Form } from 'react-bootstrap';
import TextWithIcon from '../../components/typography/TextWithIcon';
import { useModal } from '../../providers/ModalProvider';
import { incidentStatus } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const incidentQuery = loader('./IncidentSearch.fetch.graphql');
const updateInspectionQuery = loader('./IncidentSearch.update.graphql');

export default function IncidentSearch({ inspectionId, onSubmit, skip }) {
  const [searchText, setSearchText] = useState(null);
  const { isAdmin, user } = useCurrentUser();
  const [updateInspection] = useMutation(updateInspectionQuery);
  const { openConfirmationModal } = useModal();
  const { workspace } = useWorkspace();

  const { data: { incidents = [] } = {} } = useQuery(incidentQuery, {
    variables: {
      skip,
      options: {
        filters: [
          {
            field: 'status',
            operator: 'eq',
            value: [incidentStatus.investigation],
          },
          { field: 'workspaceId', operator: 'eq', value: `${workspace.id}` },
        ],
      },
    },
  });

  const incidentList = useMemo(() => {
    if (!!incidents.length) {
      return !!searchText?.length
        ? incidents?.filter(
            (i) =>
              i?.externalId
                ?.toLowerCase()
                .includes(searchText?.toLowerCase()) ||
              i?.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
              i?.description?.toLowerCase().includes(searchText?.toLowerCase()),
          )
        : incidents;
    }
    return [];
  }, [incidents, searchText]);

  return isAdmin ? (
    <div className={styles.container}>
      <TextWithIcon noMargin icon="link" weight="bold">
        {`Link to ${user.company?.incidentName || 'Incident'}`}
      </TextWithIcon>
      <div className={styles.inputContainer}>
        {!!incidents?.length ? (
          <Form.Control
            className={styles.formInput}
            placeholder="Search by ID"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            color={!!incidents?.length ? 'primary' : 'secondary'}
          />
        ) : (
          <Text size="sm" noMargin>
            No Incidents found in this workspace
          </Text>
        )}

        {!!incidents?.length && !!searchText && (
          <div className={styles.dropdownMenu}>
            {incidentList?.map((i) => (
              <div
                onClick={() => {
                  openConfirmationModal({
                    title: 'Link Incident to Report',
                    variant: 'warning',
                    description: `Are you sure you want to link this report to ${i.externalId}? This action cannot be undone.`,
                    onSubmit: () => {
                      updateInspection({
                        variables: { incidentId: i.id, id: inspectionId },
                      }).then(() => {
                        onSubmit();
                        setSearchText(null);
                      });
                    },
                  });
                }}
                key={i.externalId}
                className={styles.dropdownItem}
              >
                <Text hover color="accentPrimary" weight="semiBold" noMargin>
                  {i.externalId}
                </Text>
                <Text noMargin truncate>
                  {i.title || i.description}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null;
}

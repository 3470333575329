import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { useURLParams } from '../../providers/URLParamProvider';
import { CPAActionTypes } from '../../utilities/audits';

export const useTableURLParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { segment } = useURLParams();

  const tableFiltersToURL = (filters) => {
    const searchParams = new URLSearchParams(location.search);
    const templates = Array.from(searchParams.entries()).find(
      ([key, value]) => key === 'tab' && value === 'templates',
    );

    if (!!templates) {
      segment = 'templates';
    }
    for (const key of Array.from(searchParams.keys())) {
      if (tableFilterParams[segment]?.includes(key)) {
        searchParams.delete(key);
      }
    }

    if (!!filters) {
      filters?.forEach(({ id, value }) => {
        if (!!value && !!tableFilterParams[segment]?.length) {
          if (Array.isArray(value)) {
            if (id === 'labels') {
              searchParams.set(id, value.join(','));
            } else {
              searchParams.set(id, `${value[0]}__${value[1]}`);
            }
          } else {
            searchParams.set(id, value);
          }
        }
      });

      subFilterParams[segment]?.forEach((key) => {
        if (!filters.find((filter) => filter.id === key)) {
          searchParams.delete(key);
        }
      });

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  return { tableFiltersToURL };
};

export const formatFilters = (originalField, originalValue, userId) => {
  let operator = 'eq';
  let value = originalValue;
  let field = originalField;

  switch (originalField) {
    case 'type':
      if (originalValue === 'CPA') {
        operator = 'or';
        value = [
          CPAActionTypes.interim,
          CPAActionTypes.final,
          CPAActionTypes.review,
          CPAActionTypes.accept,
        ];
      }
      break;
    case 'allWorkspaces':
      field = 'assignee';
      operator = 'eq';
      value = userId;
      break;
    case 'description':
    case 'title':
    case 'documentNumber':
    case 'documentString':
    case 'firstName':
    case 'lastName':
    case 'email':
    case 'externalActivityId':
    case 'externalId':
    case 'narrative':
      operator = 'iLike';
      value = `%${value}%`;
      break;
    case 'reviewer':
    case 'creator':
    case 'workspace':
    case 'closer':
    case 'assignee':
    case 'vehicle':
      field = `${field}Id`;
      break;
    case 'Passed':
      field = 'passed';
      break;
    case 'closedAt':
    case 'dateCompleted':
    case 'dateTime':
    case 'dateCreated':
    case 'dateModified':
    case 'dueDate':
    case 'dateOfIncident':
      const [min, max] = originalValue;
      const minDateFormat =
        originalField === 'dateOfIncident'
          ? 'YYYY-MM-DD'
          : 'YYYY-MM-DD 00:00:00';

      const maxDateFormat =
        originalField === 'dateOfIncident'
          ? 'YYYY-MM-DD'
          : 'YYYY-MM-DD 23:59:59';
      const dateMin =
        min && moment(min).isValid() ? moment(min).format(minDateFormat) : null;
      const dateMax =
        max && moment(max).isValid() ? moment(max).format(maxDateFormat) : null;
      if (dateMin && !dateMax) {
        operator = 'gte';
        value = [dateMin];
      } else if (!dateMin && dateMax) {
        operator = 'lte';
        value = [dateMax];
      } else if (dateMin && dateMax) {
        operator = 'between';
        value = [dateMin, dateMax];
      } else {
        return null;
      }
      break;
    default:
      break;
  }

  return { field, operator, value };
};
export const formatSubFilter = (originalField, originalValue) => {
  switch (originalField) {
    case 'template.title':
      return {
        association: originalField.split('.')[0],
        field: originalField.split('.')[1],
        operator: 'iLike',
        value: [`%${originalValue}%`],
      };
    case 'labels':
      return {
        association: originalField,
        field: 'labelId',
        operator: 'or',
        value: [...originalValue.toString().split(',')],
      };
    case 'workspaces':
      return {
        association: originalField,
        field: 'id',
        operator: 'eq',
        value: [`${originalValue}`],
      };
    case 'workRole.title':
      return {
        association: originalField.split('.')[0],
        field: originalField.split('.')[1],
        operator: 'iLike',
        value: [`%${originalValue}%`],
      };
    case 'inspection.template.title':
      return {
        association: 'inspection.template',
        field: 'title',
        operator: 'iLike',
        value: [`%${originalValue}%`],
      };
    default:
      return {};
  }
};

export const commonFilters = ['dateCreated', 'dateModified', 'creator', 'id'];

export const tableFilterParams = {
  actionitems: [
    ...commonFilters,
    'title',
    'assignee',
    'priority',
    'status',
    'dueDate',
    'dateCompleted',
    'workspaceId',
    'type',
    'description',
  ],
  assessments: [
    ...commonFilters,
    'inspectionType',
    'isDraft',
    'Passed',
    'dateCompleted',
    'vehicle',
    'workspace',
    'documentNumber',
  ],
  incidents: [
    ...commonFilters,
    'title',
    'type',
    'subtype',
    'status',
    'workspace',
    'dateOfIncident',
    'closedAt',
  ],
  observations: [
    ...commonFilters,
    'description',
    'dateTime',
    'status',
    'workspace',
    'reviewer',
    'closer',
    'negative',
    'title',
  ],
  audits: [
    ...commonFilters,
    'isDraft',
    'dateCompleted',
    'riskLevel',
    'assignee',
    'status',
    'narrative',
    'externalId',
    'isObservation',
  ],
  templates: [...commonFilters, 'title', 'isDraft', 'revisionNumber'],
  settings: [
    ...commonFilters,
    'firstName',
    'lastName',
    'email',
    'externalActivityId',
    'payrollId',
    'title',
    'description',
  ],
};
export const subFilterParams = {
  assessments: ['template.title', 'labels'],
  actionitems: ['labels'],
  incidents: ['labels'],
  audits: ['template.title', 'inspection.template.title'],
  settings: ['workspaces', 'workRole.title'],
};

export const companyWideTables = [
  'incidents',
  'observations',
  'assessments',
  'audits',
  'settings',
];
export const tableSortingParams = [
  'pageSize',
  'pageIndex',
  'sort',
  'desc',
  'tab',
];

export const getWorkspace = (workspaceId, availableWorkspaces) => {
  return availableWorkspaces?.find((w) => w.id === workspaceId);
};

import React from 'react';
import { Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { getLocalTime } from '../time';
import { capatalizeFirstLetter } from '../strings';
import i18next from 'i18next';
import { keys } from '../translator/translation_keys';
import { statusStringLookup } from '../../components/action_items/action_item_utilities';
import { commentStyles, textStyles } from './styles';
import { cpaStatusTypes, riskLevels } from '../audits';
import { customColors } from '../misc';
import { Link } from 'react-router-dom';

Font.register({
  family: 'eina03regular',
  src: '/eina-03-regular.woff',
});

Font.register({
  family: 'eina03bold',
  src: '/eina-03-bold.woff',
});

Font.register({
  family: 'eina03semibold',
  src: '/eina-03-semibold.woff',
});

const red = '#e9222c';
const blue = '#0d6efd';
const green = '#28a745';
const yellow = '#ffc107';
const textPrimary = '#1d1d1f';
const textSecondary = '#6e6e73';
const grayLight = '#abb5be';
const purple = '#6610f2';
const fontSize = 8;

//i have moved styles to separate components this was just too long. I did not clean it up because incidents is using this styling :)
export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
    flexDirection: 'column',
  },
  logo: {
    width: 75,
    padding: 1,
    marginBottom: 10,
    borderRadius: 10,
  },
  link: {
    fontSize,
    fontFamily: 'eina03semibold',
    color: blue,
    maxWidth: 350,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
    margin: 3,
    marginLeft: 'auto',
  },
  linkLeft: {
    fontSize,
    fontFamily: 'eina03bold',
    color: blue,
    maxWidth: 300,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
  section: {
    margin: 3,
  },
  titleHeader: {
    fontSize: fontSize + 4,
    color: textPrimary,
    fontFamily: 'eina03bold',
    margin: 0,
    paddingTop: 10,
  },
  linkNoPosition: {
    fontSize,
    fontFamily: 'eina03semibold',
    color: blue,
    maxWidth: 350,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
  headerText: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    maxWidth: 270,
  },
  text: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
  },
  limitsText: {
    fontSize,
    color: textSecondary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
  },
  questionText: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
  },
  failText: {
    fontSize,
    color: red,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    maxWidth: 300,
    marginLeft: 'auto',
  },
  actionItemTitle: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    marginTop: fontSize,
  },
  textMulti: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    marginTop: fontSize,
    marginBottom: fontSize,
  },
  textMultiAnswer: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    marginBottom: fontSize,
  },
  textPass: {
    fontSize,
    color: green,
    fontFamily: 'eina03bold',
    marginLeft: 'auto',
  },
  textFail: {
    fontSize,
    color: red,
    fontFamily: 'eina03bold',
    marginLeft: 'auto',
  },
  semiBoldText: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    maxWidth: 275,
    marginLeft: 'auto',
    textAlign: 'right',
  },
  semiBoldTextHeader: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    maxWidth: 250,
  },
  title: {
    fontSize: fontSize + 2,
    color: textPrimary,
    fontFamily: 'eina03bold',
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 3,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderColor: grayLight,
    alignItems: 'flex-start',
    margin: 1,
    marginTop: 3,
  },
  bundleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 1,
    marginTop: 3,
  },
  bundleAnswer: {
    borderBottom: 1,
    borderColor: grayLight,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 3,
    borderBottom: 1,
    borderColor: grayLight,
  },
  footerText: {
    fontFamily: 'eina03regular',
    color: textPrimary,
    fontSize,
    margin: 0,
    marginTop: 6,
    paddingTop: 1,
  },
  footerLogo: {
    height: 20,
    paddingTop: 1,
  },
  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    textAlign: 'center',
    paddingTop: 2,
    opacity: 0.5,
  },
  leftHeader: {
    flexDirection: 'column',
    gap: 3,
  },
  categoryContainer: {
    flexDirection: 'column',
    gap: 10,
    padding: 10,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableText: {
    fontSize: fontSize - 2,
    fontFamily: 'eina03regular',
    color: textPrimary,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
  signature: {
    maxWidth: 250,
    maxHeight: 100,
  },
  image: {
    maxWidth: 250,
    maxHeight: 250,
    padding: 2,
  },
  imageContainer: {
    marginLeft: 'auto',
  },
  failImage: {
    maxWidth: 200,
    maxHeight: 200,
    padding: 10,
    marginVertical: 10,
    marginLeft: 'auto',
  },
  categoryHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 12,
  },
  category: {
    flexDirection: 'column',
    gap: 3,
  },
  avatar: {
    height: fontSize,
    borderRadius: fontSize / 2,
  },
  templateImage: {
    width: 200,
    height: 'auto',
    display: 'block',
  },
  templateImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 12,
    marginTop: 12,
    marginBottom: 12,
  },
  cardRow: {
    flexDirection: 'row',
    gap: 3,
  },
  cardRowText: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03semibold',
    maxWidth: 350,
  },
  list: {
    marginLeft: 5,
    marginRight: 5,
    paddingBottom: 12,
  },
  listItem: {
    flexDirection: 'column',
    borderBottom: 1,
    borderColor: grayLight,
  },
  listContentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  listContent: {
    width: 300,
  },
  lastListContent: {
    width: 200,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  listCategoryHeader: {
    fontSize: fontSize + 2,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 3,
  },
  textApproved: {
    fontSize,
    color: green,
    fontFamily: 'eina03bold',
  },
  textDenied: {
    fontSize,
    color: red,
    fontFamily: 'eina03bold',
  },
  textPending: {
    fontSize,
    color: yellow,
    fontFamily: 'eina03bold',
  },
  textDraft: {
    fontSize,
    color: blue,
    fontFamily: 'eina03bold',
  },
  textComplete: {
    fontSize,
    color: purple,
    fontFamily: 'eina03bold',
  },
  subtext: {
    fontSize,
    color: textSecondary,
    marginBottom: 3,
  },
  border: {
    border: 1,
    borderColor: grayLight,
    padding: 3,
  },
  rowNoBorder: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 1,
    marginTop: 3,
  },
  categoryHeaderContainer: {
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },
  bundleIcon: {
    height: '16px',
    width: '16px',
  },
  red: {
    fontFamily: 'eina03semibold',
    maxWidth: 275,
    marginLeft: 'auto',
    textAlign: 'right',
    fontSize: fontSize + 2,
    color: red,
  },
  yellow: {
    fontFamily: 'eina03semibold',
    maxWidth: 275,
    marginLeft: 'auto',
    textAlign: 'right',
    fontSize: fontSize + 2,
    color: yellow,
  },
  green: {
    fontFamily: 'eina03semibold',
    maxWidth: 275,
    marginLeft: 'auto',
    textAlign: 'right',
    fontSize: fontSize + 2,
    color: green,
  },
  cpa: {
    fontSize: fontSize + 2,
    borderBottom: 1,
    borderColor: customColors.SECONDARY_LIGHT,
    padding: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const PageHeaderLogo = ({ company, workspace }) => {
  const logo = workspace?.logoUrl || company?.logoUrl;
  return !!logo ? (
    <View>
      <Image style={styles.logo} src={logo}></Image>
    </View>
  ) : null;
};

export const PageHeader = ({ company, data, title, type, workspace }) => (
  <View>
    <View style={styles.header}>
      <View style={styles.leftHeader}>
        <View>
          <Text style={styles.semiBoldTextHeader}>{company?.name ?? ''}</Text>
          <Text style={styles.headerText}>{company?.address ?? ''}</Text>
          <Text style={styles.headerText}>
            {`${company?.city ?? ''} ${company?.postalCode ?? ''}`}
          </Text>
          <Text style={styles.headerText}>{company?.phone ?? ''}</Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>{`${
            type || 'Assessment'
          } ID:`}</Text>
          <Text style={styles.headerText}>
            {data?.externalId
              ? data.externalId
              : `${String(data?.id).padStart(7, '0')}`}
            {`${String(data?.id).padStart(7, '0')}`}
          </Text>
        </View>
        {!!data?.documentNumber && (
          <View style={styles.headerRow}>
            <Text style={styles.semiBoldTextHeader}>{`${i18next.t(
              keys.assessments.DOCUMENT_NUMBER,
            )}: `}</Text>
            <Text style={styles.headerText}>{data.documentNumber}</Text>
          </View>
        )}
      </View>
      <View>
        {!!workspace && (
          <View style={styles.headerRow}>
            <Text style={styles.semiBoldTextHeader}>
              {`${i18next.t(keys.common.WORKSPACE)}:`}
            </Text>
            <Text style={styles.headerText}>{workspace?.title}</Text>
          </View>
        )}
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>{`${i18next.t(
            keys.common.REPORT,
          )}:`}</Text>
          <Text style={styles.headerText}>{title}</Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>{`${i18next.t(
            keys.common.REPORT_DATE,
          )}:`}</Text>
          <Text style={styles.headerText}>
            {getLocalTime(data?.dateCreated).format('dddd, MMMM Do YYYY')}
          </Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>
            {`${i18next.t(keys.common.REPORT_TIME)}:`}
          </Text>
          <Text style={styles.headerText}>
            {getLocalTime(data?.dateCreated).format('hh:mm a')}
          </Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>{`${i18next.t(
            keys.common.ASSET,
          )}:`}</Text>
          <Text style={styles.headerText}>
            {data?.vehicle?.unitNumber || i18next.t(keys.common.NONE)}
          </Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>{`${i18next.t(
            keys.common.PARTICIPANTS,
          )}:`}</Text>
          <Text style={styles.headerText}>
            {data?.participants
              ?.map((p) => `${p.firstName} ${p.lastName}`)
              .join(', ') || i18next.t(keys.common.NONE)}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

export const PageFooter = () => (
  <View fixed style={styles.footer}>
    <Text style={styles.footerText}>Document powered by </Text>
    <Image style={styles.footerLogo} src="/blue_black_full.png" />
  </View>
);

export const Notes = ({ notes, style }) => {
  return notes?.length ? (
    <View style={style}>
      <View style={commentStyles.commentContainer}>
        <Text style={styles.categoryHeader}>
          {i18next.t(keys.common.NOTES)}
        </Text>
        {notes.map((comment, index) => (
          <View key={index} style={commentStyles.comment}>
            <Text style={commentStyles.semiBold}>
              {`${comment.creator?.firstName} ${comment.creator?.lastName}`}
            </Text>
            <Text style={commentStyles.text}>{comment.text}</Text>
          </View>
        ))}
      </View>
    </View>
  ) : null;
};

export const ActionItems = ({ actionItems, style, title }) => (
  <View wrap={false} style={style}>
    <Text style={styles.categoryHeader}>
      {title || i18next.t(keys.common.ACTION_ITEMS)}
    </Text>
    {actionItems?.length ? (
      actionItems.map((ai) => (
        <View key={ai.id}>
          <Text style={styles.actionItemTitle}>{ai.title}</Text>
          <Text style={styles.text}>{`Status: ${i18next.t(
            statusStringLookup[ai.status].title,
          )}`}</Text>
          <Text style={styles.text}>
            {ai.assignee?.id
              ? `Assigned to ${ai.assignee.firstName} ${ai.assignee.lastName}`
              : 'Not assigned'}
          </Text>
          <Text style={styles.text}>
            {ai.dueDate
              ? `Due on ${getLocalTime(ai.dueDate).format('MMMM Do YYYY')}`
              : 'No due date set'}
          </Text>
        </View>
      ))
    ) : (
      <Text style={styles.text}>{`No ${title || 'Action Items'} found`}</Text>
    )}
  </View>
);
export const CorrectiveActions = ({ correctiveAction, showDetails = true }) => {
  const {
    narrative,
    status,
    assignee,
    riskLevel,
    dateCreated,
    externalId,
    interimActionItem,
    finalActionItem,
    reviewActionItem,
    acceptanceActionItem,
    question,
  } = correctiveAction || {};
  const actions = {
    interim: interimActionItem,
    final: finalActionItem,
    review: reviewActionItem,
    acceptance: acceptanceActionItem,
  };
  const outcomes = Object.entries(actions)?.map(([key, actionItem]) => {
    if (actionItem?.outcome) {
      return (
        <View key={actionItem.id} style={textStyles.commentContainer}>
          <Text style={textStyles.question}>{`${
            key.charAt(0).toUpperCase() + key.slice(1)
          } Action Taken:`}</Text>
          <Text style={textStyles.answer}>{actionItem.outcome}</Text>
        </View>
      );
    }
    return null;
  });

  const Label = ({ text, color, style }) => (
    <View style={[textStyles.label, style]}>
      <Text style={{ color }}>{text}</Text>
    </View>
  );

  return (
    <View style={styles.cpa} wrap={false}>
      <View>
        <View style={textStyles.flexRow}>
          <Text style={textStyles.questionLg}>{externalId}</Text>
          <Text style={textStyles.leftText}>{question?.title}</Text>
        </View>
        {showDetails && (
          <View>
            <Text style={textStyles.answer}>{narrative}</Text>
            {outcomes}
          </View>
        )}
      </View>
      <View style={textStyles.right}>
        <Text style={textStyles[riskLevels[riskLevel]?.color]}>{`${riskLevel} ${
          riskLevel !== 'OBSERVATION' ? 'RISK' : ''
        }`}</Text>
        <Text style={textStyles.rightText}>
          {`${assignee?.firstName} ${assignee?.lastName}`}
        </Text>
        <Text style={textStyles.textSmall}>
          {getLocalTime(dateCreated).format('dddd MMMM DD YYYY HH:mm')}
        </Text>
        <View style={styles.spacer} />

        <Label
          text={cpaStatusTypes[status]?.title}
          color={'white'}
          style={{ backgroundColor: cpaStatusTypes[status]?.colorString }}
        />
      </View>
    </View>
  );
};
export const Comment = ({ comment }) => {
  return (
    <View style={commentStyles.commentContainer}>
      <View>
        <Text style={commentStyles.semiBold}>
          {`${comment.creator?.firstName} ${comment.creator?.lastName}`}
        </Text>
        <Text style={textStyles.textSmall}>{`${getLocalTime(
          comment.dateCreated,
        ).format('dddd MMMM Do YYYY HH:mm')} `}</Text>
      </View>
      {comment.attachmentUrl ? (
        <Link style={commentStyles.commentLink} src={comment.attachmentUrl}>
          {comment.text}
        </Link>
      ) : (
        <Text style={commentStyles.text}>{comment.text}</Text>
      )}
    </View>
  );
};
const formatFilters = (filter, user) => {
  switch (filter.field) {
    case 'startTime':
      return (
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>Date Range: </Text>
          <Text style={styles.headerText}>{`${
            filter.value[0].split(' ')[0]
          } - ${filter.value[1].split(' ')[0]}`}</Text>
        </View>
      );
    case 'creatorId':
      // redundant with the title being the employee but may be useful down the road
      return (
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>Employee: </Text>
          <Text
            style={styles.headerText}
          >{`${user.firstName} ${user.lastName}`}</Text>
        </View>
      );
    case 'status':
      return (
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>
            {capatalizeFirstLetter(filter.field)}:
          </Text>
          <Text
            style={
              filter.value[0] === 'APPROVED'
                ? styles.textApproved
                : filter.value[0] === 'DENIED'
                ? styles.textDenied
                : filter.value[0] === 'SUBMITTED'
                ? styles.textPending
                : filter.value[0] === 'DRAFT'
                ? styles.textDraft
                : styles.headerText
            }
          >
            {filter.value}
          </Text>
        </View>
      );
    case 'workspaceId':
      return (
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>Workspace ID: </Text>
          <Text style={styles.headerText}>{`${filter.value}`}</Text>
        </View>
      );
    default:
      return (
        <View style={styles.headerRow}>
          <Text style={styles.semiBoldTextHeader}>
            {capatalizeFirstLetter(filter.field)}:
          </Text>
          <Text style={styles.headerText}>{filter.value}</Text>
        </View>
      );
  }
};

export const BatchPageHeader = ({ company, user, filters, info }) => (
  <View>
    <View style={styles.header}>
      <View style={styles.leftHeader}>
        {!!user?.id && (
          <Text
            style={styles.title}
          >{`${user.firstName} ${user.lastName}`}</Text>
        )}
        <Text style={styles.semiBoldTextHeader}>{company?.name}</Text>
        <Text style={styles.headerText}>{company?.address}</Text>
        <Text
          style={styles.headerText}
        >{`${company?.city}, ${company?.postalCode}`}</Text>
        <Text style={styles.headerText}>{company?.phone}</Text>
      </View>
      <View>
        <View style={styles.headerRow}>
          {filters && filters.length && (
            <View>
              <Text style={styles.title}>Filters</Text>
              {filters.map((filter) => (
                <View key={`${filter.field}-$${filter.value}`}>
                  {formatFilters(filter, user)}
                </View>
              ))}
            </View>
          )}
        </View>
        <View style={styles.headerRow}>
          {info && info.length && (
            <View>
              {info.map((infoItem, index) => (
                <View key={`${index}`} style={styles.headerRow}>
                  <Text style={styles.semiBoldTextHeader}>
                    {infoItem.title}:
                  </Text>
                  <Text style={styles.headerText}>{`${
                    infoItem.value || '-'
                  }`}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </View>
  </View>
);

import React, { useRef } from 'react';
import Icon from '../Icon';
import Button from '../Button';
import styles from './ImageDropzone.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { loader } from 'graphql.macro';
import { useCurrentUser } from '../../providers/UserProvider';
import { CF_DOMAIN } from '../../constants/aws';
import { Text } from '../typography';
import { MAX_FILE_UPLOAD_SIZE } from '../../constants/misc';
import { useModal } from '../../providers/ModalProvider';
import SimpleFileCard from '../files/SimpleFileCard';
import { Spinner } from 'react-bootstrap';

const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');

export default function QuickFileDropzone({
  onSubmit = () => {},
  setLoading = () => {},
  loading = false,
  icon = false,
  iconText = 'Add File',
  iconImage = 'add',
  attachments = [],
  onDelete = () => {},
  readOnly = false,
  disableAddFile = false,
  iconSize = '1.2rem',
  iconColor = 'primary',
}) {
  const fileInputRef = useRef(null);
  const iconFileInputRef = useRef(null);
  const { t } = useTranslation();
  const client = useApolloClient();
  const { user } = useCurrentUser();
  const { openConfirmationModal } = useModal();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleIconClick = () => {
    iconFileInputRef.current.click();
  };

  const handleUpload = (file) => {
    setLoading(true);
    const name = file.name;
    const fileUUID = uuidv4();
    const fileParts = file.name.split('.');
    const fileName = fileUUID;
    const fileType = fileParts[fileParts.length - 1];
    const size = file.size;
    if (size > MAX_FILE_UPLOAD_SIZE) {
      return openConfirmationModal({
        title: 'Max File Size',
        description: t(keys.files.FILE_SIZE_WARNING, { size: '100MB' }),
        onSubmit: () => setLoading(false),
        buttonText: 'OK',
        variant: 'danger',
      });
    }
    client
      .query({
        query: uploadUrlQuery,
        variables: {
          fileName: `assets/${user?.company?.id}/${fileName}.${fileType}`,
          fileType,
        },
      })
      .then((data) => {
        const signedUrl = data.data.simpleStorageUploadUrl;
        const options = {
          headers: {
            'Content-Type': fileType === 'pdf' ? 'application/pdf' : fileType,
          },
        };
        return axios.put(signedUrl, file, options);
      })
      .then((result) => {
        if (result.status === 200) {
          onSubmit({
            url: `${CF_DOMAIN(user)}${fileName}.${fileType}`,
            name,
            extension: fileType,
            size,
          });
        }
      })
      .catch((error) => {
        console.error('Upload Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return icon ? (
    <div>
      {!disableAddFile && !readOnly && (
        <div className={styles.iconContainer} onClick={handleIconClick}>
          <Text noMargin color="secondary" hover size="sm" textAlign="left">
            {iconText}
          </Text>
          <input
            type="file"
            ref={iconFileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              handleUpload(e.target.files[0]);
            }}
          />
          <Icon
            hover={!loading}
            disabled={loading}
            color={loading ? 'disabled' : iconColor}
            style={{ fontSize: iconSize }}
          >
            {iconImage}
          </Icon>
        </div>
      )}
      {attachments?.map((a, idx) => {
        return (
          <div className={styles.attachments}>
            <SimpleFileCard
              key={`${a.id}-${idx}`}
              extension={a.extension}
              file={{ ...a, downloadAllowed: true }}
              fileName={a.name ?? `Attachment-${idx + 1}`}
              onDelete={onDelete}
              readOnly={readOnly}
            />
          </div>
        );
      })}
    </div>
  ) : disableAddFile ? null : (
    <div className={styles.smallContainer}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          handleUpload(e.target.files[0]);
        }}
      />
      <Icon style={{ fontSize: '2rem' }} className={styles.icon}>
        photo_camera
      </Icon>
      <Button
        className={styles.button}
        variant="secondary"
        outlined
        onClick={handleButtonClick}
        disabled={loading}
        value={
          loading
            ? t(keys.action.LOADING)
            : t(keys.action.SELECT_VARIABLE, { variable: t(keys.common.FILE) })
        }
      />
    </div>
  );
}

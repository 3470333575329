import SimpleUserCard from '../../components/SimpleUserCard';
import { Text } from '../../components/typography';
import UserMultiSelector from '../../components/UserMultiSelector';
import { incidentStatus } from '../../constants/strings';
import styles from './IncidentRequiredComments.module.scss';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useCurrentUser } from '../../providers/UserProvider';
import Icon from '../../components/Icon';
import { Button } from '../../components';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useModal } from '../../providers/ModalProvider';
import { getLocalTime } from '../../utilities/time';

const updateReviewMutation = loader(
  './IncidentRequiredComments.updateReview.graphql',
);

export default function IncidentRequiredComments({
  incident,
  onUserAdded,
  onUserRemoved,
  selectedUsers,
  requiredComments = [],
  readOnly,
}) {
  const [comment, setComment] = useState('');
  const { user: currentUser } = useCurrentUser();
  const [updateReview] = useMutation(updateReviewMutation);
  const { openConfirmationModal } = useModal();

  return readOnly ? (
    <div>
      <div className={styles.comments}>
        <div className={styles.spacer} />
        {requiredComments?.map(({ user, notes, id, dateModified }) => (
          <div key={user.id}>
            {currentUser.id === user?.id && !notes ? (
              <div className={styles.currentUser}>
                <SimpleUserCard user={user} largeAvatar />
                <div>
                  <Form.Control
                    as="textarea"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                  <div className={styles.spacer} />
                  <Button
                    align="right"
                    size="sm"
                    disabled={!comment}
                    value="Submit"
                    icon="save"
                    className={styles.smallButton}
                    onClick={() => {
                      openConfirmationModal({
                        variant: 'warning',
                        title: 'Submit Comment',
                        description:
                          'Are you sure you want to submit this comment? This cannot be edited once submitted.',
                        onSubmit: () =>
                          updateReview({
                            variables: {
                              reviewerId: id,
                              notes: comment || '',
                              status: 'COMMENT_SUBMITTED',
                            },
                          }),
                      });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.comment}>
                <SimpleUserCard
                  user={user}
                  largeAvatar
                  smallText={getLocalTime(dateModified).format(
                    'ddd MMM DD YYYY HH:mm',
                  )}
                />
                <Text noMargin color={!!notes ? 'primary' : 'secondaryLight'}>
                  {notes || 'Pending Response'}
                </Text>
                <Icon
                  className={styles.icon}
                  style={{ color: !!notes ? 'green' : 'red' }}
                >
                  {!!notes ? 'check_circle' : 'timer'}
                </Icon>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.bottom}>
      <div className={styles.spacer} />
      <div>
        <Text weight="bold" noMargin>
          Select Required Personnel for Comment
        </Text>
        <Text color="secondaryLight" size="sm" noMargin>
          This Incident cannot be closed until the selected users have commented
        </Text>
      </div>
      <UserMultiSelector
        readOnly={incident.status !== incidentStatus.initiated}
        selected={selectedUsers}
        onUserAdded={onUserAdded}
        onUserRemoved={onUserRemoved}
      />
    </div>
  );
}

import { loader } from 'graphql.macro';
import styles from './DashboardV2.module.scss';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useCurrentUser } from '../../../providers/UserProvider';
import SectionContainer from './SectionContainer';
import { Text } from '../../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import SafetyShareCard from '../SafetyShareCard';
import {
  announcementIcons,
  assessmentTypes,
  customTaskAssessmentType,
  getRoute,
  quickActionTypes,
} from '../../../constants/strings';
import DashboardCard from './DashboardCard';
import { getLocalTime } from '../../../utilities/time';
import { priorityStringLookup } from '../../../components/action_items/action_item_utilities';
import DashboardNumberStats from './DashboardNumberStats';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../constants/strings';
import { useActionItemModal } from '../../../hooks/misc';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useIsMobile } from '../../../hooks/misc';
import Dashboard from '../Dashboard';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc';
import { useModal } from '../../../providers/ModalProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { modals } from '../../../providers/modals';
import { openDailyReportModal } from '../../../graphql/cache/modal';
import QuickAction from '../QuickAction';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import NotificationList from '../../../components/notifications/NotificationList';
import { closeModalComponents } from '../../../utilities';
import CalendarChart from '../../analytics/CalendarChart';
import moment from 'moment';
import Icon from '../../../components/Icon';
import party from '../../../assets/empty_state_images/party_actions.svg';
import CustomQuickAction from '../CustomQuickAction';
import { dashboardImages } from '../../../utilities/toolboxtalks';
import CustomSpinner from '../../../components/CustomSpinner';
import OfflineDashboard from './OfflineDashboard';
import { useMyInspections } from '../../../providers/MyInspectionsProvider';

const dashboardQuery = loader('./DashboardV2.graphql');
const userEventQuery = loader('./DashboardV2.events.graphql');
const announcementQuery = loader('./DashboardV2.announcements.graphql');
const taskQuery = loader('./DashboardV2.tasks.graphql');
const quickActionQuery = loader('./DashboardV2.quickAction.graphql');

const announcementLength = {
  min: 4,
  max: 200,
};

export default function DashboardV2() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [showNotifications, setShowNotifications] = useState(false);
  const today = new moment().format('yyyy-MM-DD');
  const { openModal } = useModal();
  const { workspaceId, workspace, observationTemplate } = useWorkspace();
  const ref = useRef(null);
  const { enableDailyReports, enableTimecards } = useFlags();
  const online = useOnlineStatus();
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isEditingButtons, setIsEditingButtons] = useState(false);
  const { inspections } = useMyInspections();

  const emptyState = (
    <div className={styles.emptyStateContainer}>
      <img src={party} alt="party" className={styles.emptyStateImage} />
      <Text noMargin size="sm" color="secondary" textAlign="center">
        {t(keys.dashboard.ACTION_ITEMS_EMPTY_STATE_TITLE)}
      </Text>
    </div>
  );

  const filterDates = useMemo(() => {
    return {
      startDate: moment().format(`${selectedYear}-01-01 00:00:00`),
      endDate: moment().format(`${selectedYear}-12-31 23:59:59`),
    };
  }, [selectedYear]);

  const [announcementsExpanded, setAnnouncementsExpanded] = useState(false);

  const {
    networkStatus,
    refetch,
    data: {
      actionItems = [],
      daysSinceLastIncident = 0,
      nearMissCount = 0,
      openActionItems = 0,
    } = {},
  } = useQuery(dashboardQuery, {
    skip: !online,
    variables: {
      userId: `${user.id}`,
    },
    fetchPolicy: 'network-only',
  });

  const {
    refetch: refetchQuickActions,
    data: { quickActions = [], workspaceQuickActions = [] } = {},
  } = useQuery(quickActionQuery, {
    variables: {
      userId: `${user.id}`,
    },
  });

  const now = moment();

  const dailyTaskDate = now.clone().format('YYYY-MM-DD');
  const weeklyTaskDate = now.clone().startOf('week').format('YYYY-MM-DD');
  const monthlyTaskDate = now.clone().startOf('month').format('YYYY-MM-DD');
  const {
    data: {
      dailyTasks = [],
      weeklyTasks = [],
      monthlyTasks = [],
      oneTimeTasks = [],
    } = {},
  } = useQuery(taskQuery, {
    skip: !user?.id || !online,
    variables: {
      userId: `${user.id}`,
      dailyDate: dailyTaskDate,
      weeklyDate: weeklyTaskDate,
      monthlyDate: monthlyTaskDate,
    },
    fetchPolicy: 'network-only',
  });

  const tasks = [
    ...oneTimeTasks,
    ...dailyTasks,
    ...weeklyTasks,
    ...monthlyTasks,
  ];

  const customQuickActions = [
    ...(quickActions || []),
    ...(workspaceQuickActions || []),
  ];

  const editableQuickAction = useMemo(() => {
    return [
      ...(isEditingButtons || !quickActions?.length
        ? [
            {
              icon: 'add',
              color: 'customButton',
              onClick: () =>
                openModal({
                  modalName: modals.quickAction,
                  variables: {
                    workspace: workspace,
                    quickActionType: quickActionTypes.userLevel,
                    refetch: refetchQuickActions,
                  },
                }),
            },
          ]
        : []),
      ...(quickActions?.length
        ? [
            {
              icon: isEditingButtons ? 'check' : 'edit',
              color: isEditingButtons ? 'editButton' : 'customButton',
              onClick: () => setIsEditingButtons(!isEditingButtons),
            },
          ]
        : []),
    ];
  }, [
    quickActions,
    isEditingButtons,
    openModal,
    refetchQuickActions,
    workspace,
  ]);

  const baseQuickActions = useMemo(() => {
    return [
      {
        title: user?.company?.assessmentName || t(keys.dashboard.ASSESS),
        icon: 'task',
        color: 'blue',
        onClick: () => {
          openModal({
            modalName: modals.createAssessment,
            variables: {
              participants: [user],
              types: Object.values(assessmentTypes).filter(
                (t) =>
                  t.key !== 'INCIDENT' &&
                  t.key !== 'OBSERVATION' &&
                  t.key !== 'AUDIT',
              ),
            },
          });
        },
      },
      ...(enableTimecards
        ? [
            {
              title: 'Log Time',
              icon: 'schedule',
              color: 'yellow',
              onClick: () => {
                navigate('/timecard');
              },
            },
          ]
        : []),
      {
        title: user.company?.observationName || 'Observation',
        icon: 'content_paste_search',
        color: 'darkBlue',
        onClick: () => {
          openModal({
            modalName: modals.observation,
            variables: {
              participants: [],
              workspaceId,
              templateId: observationTemplate?.id,
              questions: observationTemplate?.categories[0]?.questions || [],
              dateTime: new moment().format('YYYY-MM-DD HH:mm'),
            },
          });
        },
      },
      {
        title: t(keys.common.MEETING),
        icon: 'mic',
        color: 'teal',
        onClick: () =>
          openModal({
            modalName: modals.recordMeeting,
            variables: {
              participants: [user],
              refetch: () => refetch(),
            },
          }),
      },
      {
        title: t(keys.dashboard.ACTION),
        icon: 'task_alt',
        color: 'green',
        onClick: () => openActionItemModal(null, 'GENERAL'),
      },
      {
        title: user.company?.incidentName || t(keys.common.INCIDENT),
        icon: 'fmd_bad',
        color: 'red',
        onClick: () =>
          openModal({
            modalName: modals.createIncident,
            variables: { participants: [user] },
          }),
      },
      ...(enableDailyReports
        ? [
            {
              title: 'Daily Log',
              icon: 'inventory',
              color: 'purple',
              onClick: () => {
                openDailyReportModal();
              },
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enableDailyReports,
    enableTimecards,
    isEditingButtons,
    quickActions?.length,
    user,
  ]);

  const {
    data: {
      announcementInteractions = [],
      announcementInteractionsCount = 0,
    } = {},
  } = useQuery(announcementQuery, {
    skip: !online,
    variables: {
      today,
      userId: `${user.id}`,
      pageSize: announcementsExpanded
        ? announcementLength.max
        : announcementLength.min,
    },
    fetchPolicy: 'network-only',
  });

  const { data: { events = [] } = {} } = useQuery(userEventQuery, {
    variables: {
      userId: `${user.id}`,
      startDate: filterDates.startDate,
      endDate: filterDates.endDate,
    },
    skip: isMobile || !online,
    fetchPolicy: 'no-cache',
  });
  const hideShowAll =
    announcementInteractions.length === announcementInteractionsCount;

  useEffect(
    () => closeModalComponents(ref, setShowNotifications),
    [setShowNotifications, ref],
  );

  const loading = networkStatus === NetworkStatus.loading;
  const stats = [
    {
      title: t(keys.dashboard.DAYS_SINCE_LAST_INCIDENT),
      number: daysSinceLastIncident,
      image: dashboardImages.firstAidKit,
      card: 'card1',
    },
    {
      title: t(keys.dashboard.OPEN_ACTION_ITEMS),
      number: openActionItems,
      image: dashboardImages.clipboard,
      card: 'card2',
    },
    {
      title: t(keys.dashboard.NEAR_MISSES),
      number: nearMissCount,
      image: dashboardImages.writeReport,
      card: 'card3',
    },
  ];
  if (!online) {
    return (
      <OfflineDashboard inspections={inspections} quickActions={quickActions} />
    );
  }
  if (loading) {
    return <CustomSpinner text="" />;
  }

  return isMobile ? (
    <Dashboard
      refetch={refetchQuickActions}
      loading={loading}
      tasks={tasks}
      actionItems={actionItems}
      inspections={inspections}
      quickActions={baseQuickActions}
      customQuickActions={customQuickActions}
      editableQuickAction={editableQuickAction}
      announcements={announcementInteractions}
      hideShowAll={hideShowAll}
      setAnnouncementsExpanded={setAnnouncementsExpanded}
      announcementExpanded={announcementsExpanded}
      isEditing={isEditingButtons}
    />
  ) : (
    <div className={styles.container}>
      <Text size="lg" weight="bold">
        {t(keys.dashboard.DASHBOARD)}
      </Text>
      <div className={styles.split}>
        <div className={styles.left}>
          <div className={styles.quickActions}>
            {baseQuickActions.map(
              ({ title, color, icon, onClick, disabled, className }, idx) => (
                <QuickAction
                  key={title + idx + icon}
                  title={title}
                  icon={icon}
                  color={color}
                  onClick={onClick}
                  disabled={disabled}
                  className={className}
                />
              ),
            )}
            {customQuickActions?.map((quickAction) => (
              <CustomQuickAction
                key={quickAction.id}
                quickAction={quickAction}
                refetch={refetchQuickActions}
                isEditing={
                  isEditingButtons &&
                  quickAction.type === quickActionTypes.userLevel
                }
              />
            ))}
            {editableQuickAction.map(
              ({ title, color, icon, onClick, disabled, className }, idx) => (
                <QuickAction
                  key={title + idx + icon}
                  title={title}
                  icon={icon}
                  color={color}
                  onClick={onClick}
                  disabled={disabled}
                  className={className}
                />
              ),
            )}
          </div>
          <br />
          <SafetyShareCard />
          <br />
          <div className={styles.contentContainer}>
            <SectionContainer
              title={t(keys.dashboard.RECENT_ASSESSMENTS)}
              icon="task"
              hasChildren={!!inspections.length}
              className={styles.splitSection}
              emptyState={emptyState}
              onClick={() => {
                const filter = new URLSearchParams({
                  creator: user.id,
                  allWorkspaces: 'TRUE',
                });
                navigate(getRoute(workspaceId, `assessments?${filter}`));
              }}
            >
              {inspections?.map(
                ({
                  dateModified,
                  id,
                  isDraft,
                  workspaceId: inspectionWorkspaceId,
                  template: { title, templateType },
                }) => {
                  return (
                    <DashboardCard
                      key={id}
                      header={title}
                      subtext={`${t(keys.dashboard.UPDATED)} ${getLocalTime(
                        dateModified,
                      ).fromNow()}`}
                      icon={
                        assessmentTypes[templateType.toLowerCase()]?.icon ||
                        assessmentTypes['fleet'].icon
                      }
                      iconColor={
                        assessmentTypes[templateType.toLowerCase()]?.color ||
                        assessmentTypes['fleet']?.color
                      }
                      onClick={() =>
                        navigate(
                          getRoute(
                            inspectionWorkspaceId || workspaceId,
                            paths.assessment,
                            id,
                          ),
                        )
                      }
                      rightContent={
                        <Icon
                          size="lg"
                          opacity="0.3"
                          color={isDraft ? 'secondary' : 'green'}
                        >
                          {isDraft ? 'edit' : 'check_circle'}
                        </Icon>
                      }
                    />
                  );
                },
              )}
            </SectionContainer>
            <SectionContainer
              title={t(keys.dashboard.ASSIGNED_ACTION_ITEMS, {
                variable: null,
              })}
              hasChildren={!!actionItems.length}
              className={styles.splitSection}
              emptyState={emptyState}
              icon="task_alt"
              onClick={() => {
                const filter = new URLSearchParams({
                  assignee: user.id,
                  allWorkspaces: 'TRUE',
                });
                navigate(getRoute(workspaceId, `actionitems?${filter}`));
              }}
            >
              {actionItems?.map(
                ({ title, priority, dateCreated, id, dueDate }) => (
                  <DashboardCard
                    key={id}
                    header={title}
                    icon="error_outline"
                    iconColor={priorityStringLookup[priority]?.color}
                    subtext={
                      dueDate
                        ? `Due ${getLocalTime(dueDate).fromNow()}`
                        : `Created ${getLocalTime(dateCreated).fromNow()}`
                    }
                    onClick={() => openActionItemModal(id)}
                  />
                ),
              )}
            </SectionContainer>
          </div>
          <div className={styles.bottomContainer}>
            <SectionContainer
              title={t(keys.dashboard.MY_CONTRIBUTIONS, {
                variable: selectedYear,
              })}
              icon="construction"
            >
              <div className={styles.calendarSelector}>
                <Icon
                  hover
                  hide={selectedYear === 2024}
                  color="secondaryLight"
                  onClick={() => {
                    if (selectedYear !== 2024) {
                      setSelectedYear(selectedYear - 1);
                    }
                  }}
                >
                  chevron_left
                </Icon>
                <CalendarChart
                  top={0}
                  translateY={0}
                  events={events}
                  hover
                  startDate={filterDates.startDate}
                  endDate={filterDates.endDate}
                  margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
                  height={180}
                  width={'95%'}
                  legends={false}
                />
                <Icon
                  hover
                  hide={selectedYear === currentYear}
                  color="secondaryLight"
                  onClick={() => {
                    if (selectedYear !== currentYear) {
                      setSelectedYear(selectedYear + 1);
                    }
                  }}
                >
                  chevron_right
                </Icon>
              </div>
            </SectionContainer>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.statsContainer}>
            <Box
              className={styles.statsBox}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'auto',
                gridTemplateAreas: `
                  "card1 card1"
                  "card2 card3"
                `,
              }}
            >
              {stats.map((stat) => {
                return (
                  <Box key={stat.title} sx={{ gridArea: stat.card }}>
                    <DashboardNumberStats
                      backgroundImage={stat.image}
                      number={stat.number}
                      text={stat.title}
                      maxWidth={stat.maxWidth}
                    />
                  </Box>
                );
              })}
            </Box>
          </div>
          <SectionContainer
            title={t(keys.tasks.MY_TASKS)}
            icon="assignment_turned_in"
            hasChildren={!!tasks.length}
            emptyState={emptyState}
          >
            {tasks.length &&
              tasks?.map((task) => (
                <DashboardCard
                  key={task?.id}
                  header={task?.title}
                  subtext={`Due ${
                    task.interval === 'WEEKLY'
                      ? now.clone().day(7).fromNow()
                      : task.interval === 'MONTHLY'
                      ? now.clone().add(1, 'month').startOf('month').fromNow()
                      : 'today'
                  }`}
                  icon={
                    { ...assessmentTypes, ...customTaskAssessmentType }[
                      task?.template?.templateType.toLowerCase()
                    ]?.icon || 'content_paste_search'
                  }
                  iconColor={
                    { ...assessmentTypes, ...customTaskAssessmentType }[
                      task?.template?.templateType.toLowerCase()
                    ]?.color || 'blue'
                  }
                  onClick={() => {
                    if (task.taskType === 'INSPECTION') {
                      openModal({
                        modalName: modals.createAssessment,
                        variables: {
                          dataWorkspaceId: task.workspace.id,
                          participants: [user],
                          type:
                            { ...assessmentTypes, ...customTaskAssessmentType }[
                              task.template.templateType.toLowerCase()
                            ] || assessmentTypes.fleet,
                          template: task.template,
                        },
                      });
                    } else if (task.taskType === 'OBSERVATION') {
                      openModal({
                        modalName: modals.observation,
                        variables: {
                          participants: [],
                          workspaceId,
                          templateId: observationTemplate?.id,
                          questions:
                            observationTemplate?.categories[0]?.questions || [],
                          dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                        },
                      });
                    }
                  }}
                />
              ))}
          </SectionContainer>
          <SectionContainer
            title={t(keys.dashboard.RECENT_ANNOUNCEMENTS)}
            hasChildren={!!announcementInteractions.length}
            emptyState={emptyState}
            isLoading={loading}
            icon="campaign"
            actionText={
              announcementsExpanded
                ? t(keys.dashboard.SHOW_LESS)
                : t(keys.dashboard.SHOW_MORE)
            }
            onClick={() => {
              setAnnouncementsExpanded(!announcementsExpanded);
            }}
          >
            <div>
              {announcementInteractions?.map(
                ({ dateReleased, status, announcement, id }) => {
                  return (
                    <DashboardCard
                      key={id}
                      header={announcement.title}
                      subtext={dateReleased}
                      icon={announcementIcons[status].icon}
                      iconColor={announcementIcons[status].color}
                      onClick={() => {
                        openModal({
                          modalName: modals.announcement,
                          variables: {
                            announcement,
                            status,
                            id,
                          },
                        });
                      }}
                    />
                  );
                },
              )}
            </div>
          </SectionContainer>
        </div>
      </div>
      <NotificationList
        show={showNotifications}
        onHide={() => setShowNotifications(false)}
        expand={true}
        containerRef={ref}
      />
    </div>
  );
}

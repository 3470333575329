import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import {
  PageHeader,
  PageFooter,
  styles,
  PageHeaderLogo,
  CorrectiveActions,
} from '../constants';
import { Notes } from '../constants';
import { VehicleCard } from '../vehicle';
import { Category } from './../assessment_components/assessment_category';
import { Signatures } from './../assessment_components/assessment_signatures';
import { summaryStringLookup } from '../../audits';
import { textStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { keys } from '../../translator/translation_keys';

export const AuditDocument = ({
  inspection,
  correctiveActions,
  notes,
  props = {},
}) => {
  const company = inspection?.creator?.company;
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} workspace={null} />
        <PageHeader
          company={company}
          data={inspection}
          title={inspection.template.title}
          workspace={null}
          type="Audit"
        />
        <View>
          <Text style={styles.titleHeader}>{inspection.template.title}</Text>
          <Text style={styles.headerText}>
            {inspection.template.description}
          </Text>
        </View>
        {!!inspection.vehicle ? (
          <VehicleCard vehicle={inspection?.vehicle} />
        ) : null}
        {!!props.summary && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeader}>Executive Summary</Text>
            {!inspection?.executiveSummary ? (
              <Text style={styles.text}>{t(keys.common.NONE)}</Text>
            ) : (
              Object.entries(inspection.executiveSummary)?.map(
                ([key, value]) => {
                  const title = !!key ? summaryStringLookup[key] : '';
                  return title ? (
                    <View key={key} style={textStyles.bottomBorder}>
                      <Text style={textStyles.question}>{title}</Text>
                      <Text style={textStyles.answer}>{value || 'None'}</Text>
                    </View>
                  ) : null;
                },
              )
            )}
          </View>
        )}
        {props.checklist && (
          <View style={styles.categoryContainer}>
            {inspection.template.categories.map((category, index) => (
              <Category
                key={`${category.id}-${index}`}
                category={category.title}
                categoryId={category.id}
                isBundle={category.isBundle}
                questions={category.questions}
                answers={inspection.items}
                isAudit={true}
                correctiveActions={correctiveActions}
                showComments={!!props.comments}
              />
            ))}
          </View>
        )}
        {!!props.actions && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeader}>Corrective Actions</Text>
            {correctiveActions?.map((c) => (
              <CorrectiveActions correctiveAction={c || []} key={c.id} />
            ))}
          </View>
        )}
        {!!props.notes && (
          <View>
            <Notes notes={notes} style={styles.categoryContainer} />
            <Text style={styles.text}>{inspection.notes}</Text>
          </View>
        )}

        {!!props.signatures && !!inspection.attachments?.length && (
          <Signatures
            signatures={
              inspection.attachments?.filter(
                (a) => a.extension === 'SIGNATURE',
              ) || []
            }
          />
        )}
        <PageFooter />
      </Page>
    </Document>
  );
};

export const operatorsList = [
  {
    key: 'EQUALS',
    title: 'Equals',
  },
  {
    key: 'NOT_EQUALS',
    title: "Doesn't Equal",
  },
  {
    key: 'LESS_THAN',
    title: 'Less Than',
    number: true,
  },
  {
    key: 'LESS_EQUALS',
    title: 'Less Than or Equal To',
    number: true,
  },
  {
    key: 'GREATER_THAN',
    title: 'Greater Than',
    number: true,
  },
  {
    key: 'GREATER_EQUALS',
    title: 'Greater Than or Equal To',
    number: true,
  },
];

export const actionsList = [
  {
    key: 'FOLLOW_UP_QUESTION',
    title: 'Follow Up Question',
  },
  {
    key: 'SEND_NOTIFICATION',
    title: 'Send Notification',
  },
  {
    key: 'SHOW_MESSAGE',
    title: 'Show Message',
  },
  {
    key: 'SHOW_POP_UP',
    title: 'Pop Up',
  },
];

export const actionsKeys = {
  sendNotification: 'SEND_NOTIFICATION',
  followUpQuestion: 'FOLLOW_UP_QUESTION',
  showMessage: 'SHOW_MESSAGE',
  popUp: 'SHOW_POP_UP',
};

export const defaultTestValueList = [
  {
    title: 'Pass',
    key: 'PASS',
  },
  {
    title: 'Fail',
    key: 'FAIL',
  },
  {
    title: 'N/A',
    key: 'NOT_APPLICABLE',
  },
];

export const testValueList = [
  {
    title: 'Pass',
    key: 'PASS',
  },
  {
    title: 'Fail',
    key: 'FAIL',
  },
  {
    title: 'N/A',
    key: 'NOT_APPLICABLE',
  },
  {
    title: 'OK',
    key: 'PASS',
  },
  {
    title: 'Issue',
    key: 'FAIL',
  },
  {
    title: 'Yes',
    key: 'PASS',
  },
  {
    title: 'No',
    key: 'FAIL',
  },
];

export const conditionalActionsAllowed = [
  'TEST',
  'NUMBER',
  'TEXT',
  'TEXT_MULTI',
  'DATE',
  'DATE_TIME',
  'TIME',
  'SELECT_SINGLE',
];

export const months = [
  { title: 'January', key: '01' },
  { title: 'February', key: '02' },
  { title: 'March', key: '03' },
  { title: 'April', key: '04' },
  { title: 'May', key: '05' },
  { title: 'June', key: '06' },
  { title: 'July', key: '07' },
  { title: 'August', key: '08' },
  { title: 'September', key: '09' },
  { title: 'October', key: '10' },
  { title: 'November', key: '11' },
  { title: 'December', key: '12' },
];

export const days = Array.from({ length: 31 }, (_, i) => i + 1);

import styles from './CertificateModal.module.scss';
import { Text } from '../../typography';
import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { useTranslation } from 'react-i18next';
import { modals } from '../../../providers/modals';
import { keys } from '../../../utilities/translator/translation_keys';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Icon from '../../Icon';
import { getLocalTime } from '../../../utilities/time';
import DatePickerComponent from '../DatePickerComponent';
import moment from 'moment';
import Button from '../../Button';
import FileDisplayCertificate from './FileDisplayCertificate';
import { useCurrentUser } from '../../../providers/UserProvider';
import { userRoles } from '../../../constants/strings';

const addCertificateMutation = loader(
  './CertificateModal.createCertifcate.graphql',
);

const updateCertificateMutation = loader(
  './CertificateModal.updateCertificate.graphql',
);

const deleteCertificateMutation = loader(
  './CertificateModal.deleteCertificate.graphql',
);

const addFileMutation = loader('./CertificateModal.addFile.graphql');

export default function CertificateModal() {
  const {
    modalState,
    closeModal,
    updateModal,
    openModal,
    openConfirmationModal,
  } = useModal();
  const { t } = useTranslation();
  const update = (variables) =>
    updateModal({
      modalName: modals.certificate,
      variables: { ...variables },
    });

  const onHide = () => {
    closeModal({ modalName: modals.certificate });
  };
  const {
    show,
    certificateId,
    requirementId,
    userId,
    certificateData,
    files,
    readOnly,
  } = modalState.certificateModal;
  const [addFile] = useMutation(addFileMutation);

  const [addCertificate] = useMutation(addCertificateMutation, {
    refetchQueries: ['GetUserDetailsForUserProfile'],
  });
  const [updateCertificate] = useMutation(updateCertificateMutation, {
    refetchQueries: ['GetUserDetailsForUserProfile'],
  });
  const [deleteCertificate] = useMutation(deleteCertificateMutation, {
    refetchQueries: ['GetUserDetailsForUserProfile'],
  });
  const { user } = useCurrentUser();

  const [enableEditCertifiacteExpiry, setEnableEditCertificateExpiry] =
    useState(!certificateId);

  const createCertificate = () => {
    return (
      <div>
        <div>
          <div>
            <Text weight="semibold" noMargin>
              {t(keys.common.FILES)}
            </Text>
            <div className={styles.spacerSm} />
          </div>
          <div className={styles.flexBoxGap}>
            {files?.map((file) => (
              <FileDisplayCertificate
                key={file.id}
                file={{
                  url: file.url,
                  type: file.fileType,
                  name: file.name,
                }}
              />
            ))}
          </div>
          {files.length ? <div className={styles.spacerSm} /> : null}
          <div>
            <Button
              value={t(keys.action.UPLOAD_VARIABLE, {
                variable: t(keys.common.FILE),
              })}
              icon="backup"
              onClick={() => {
                openModal({
                  modalName: modals.fileUpload,
                  variables: {
                    assessment: false,
                    simple: true,
                    onSubmit: async ({
                      url,
                      name,
                      fileType,
                      extension,
                      size,
                      downloadAllowed,
                    }) => {
                      update({
                        show,
                        certificateId,
                        files: [
                          ...files,
                          {
                            url,
                            name,
                            fileType,
                            extension,
                            size,
                            downloadAllowed,
                          },
                        ],
                        readOnly,
                      });

                      if (!!certificateId) {
                        await addFile({
                          variables: {
                            url,
                            name,
                            fileType,
                            extension,
                            size,
                            downloadAllowed,
                            certificateId: certificateId,
                          },
                        });
                      }
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <div className={styles.spacerMd} />
        <div className={styles.flexBoxContainer}>
          <Text className={styles.label} noMargin weight="semiBold">
            {t(keys.action.EXPIRES)}
          </Text>
          {!enableEditCertifiacteExpiry && !!certificateId && (
            <Icon
              className={styles.editIcon}
              baseClassName="material-icons-outlined"
              onClick={() => setEnableEditCertificateExpiry(true)}
            >
              edit
            </Icon>
          )}
        </div>

        {enableEditCertifiacteExpiry || !!!certificateId ? (
          <div>
            <div className={styles.spacerSm} />
            <DatePickerComponent
              selected={
                certificateData?.expires
                  ? new Date(certificateData.expires)
                  : null
              }
              onChange={async (date) => {
                if (!!certificateId) {
                  await updateCertificate({
                    variables: {
                      updateCertificateId: certificateId,
                      expires: date ? moment(date).toISOString() : null,
                    },
                  });
                }
                update({
                  show,
                  certificateId,
                  certificateData: {
                    ...certificateData,
                    expires: date ? moment(date).toISOString() : null,
                  },
                  readOnly,
                });
                if (!!certificateId) {
                  setEnableEditCertificateExpiry(false);
                }
              }}
              isClearable
            />
          </div>
        ) : (
          <div className={styles.expiryCertificateContainer}>
            <div>
              <Text weight="semiBold" color="secondary" noMargin>
                {certificateData?.expires
                  ? getLocalTime(certificateData.expires).format('MMMM Do YYYY')
                  : t(keys.common.NONE)}
              </Text>
              <div className={styles.spacerSm} />
            </div>
          </div>
        )}
        <div className={styles.spacerSm} />
        {/* Non editable Data display below*/}
        {!!certificateId && (
          <div>
            <Text noMargin weight="semiBold" size="md">
              {t(keys.common.DATE_MODIFIED)}
            </Text>
            <Text weight="semiBold" color="secondary" noMargin>
              {getLocalTime(certificateData.dateModified).format(
                'MMMM Do YYYY',
              )}
            </Text>
            <div className={styles.spacerSm} />
            <Text noMargin weight="semiBold" size="md">
              {t(keys.common.DATE_CREATED)}
            </Text>
            <Text weight="semiBold" color="secondary" noMargin>
              {getLocalTime(certificateData.dateCreated).format('MMMM Do YYYY')}
            </Text>
          </div>
        )}
        {user?.role === userRoles.admin && certificateId ? (
          <>
            <br />
            <Button
              className={styles.deleteButton}
              value="Delete"
              icon="delete"
              variant="danger"
              outlined
              onClick={() => {
                openConfirmationModal({
                  title: 'Delete Certificate',
                  description: `Are you sure you want to delete this Certificate? This action cannot be undone.`,
                  buttonText: 'Delete',
                  variant: 'danger',
                  onSubmit: () => {
                    deleteCertificate({
                      variables: { id: certificateId },
                    }).then(() => {
                      onHide();
                    });
                  },
                });
              }}
            />
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      variant={'primary'}
      title={
        !!certificateId
          ? t(keys.common.CERTIFICATE)
          : t(keys.action.ADD, {
              variable: t(keys.common.CERTIFICATE),
            })
      }
      hideCancel
      titleImage={
        <img
          className={styles.taskImage}
          src={'https://app.opasmobile.com/assets/graphics/cert_1247.png'}
          alt="task_png"
        />
      }
      submitText={
        certificateId ? t(keys.action.UPDATE) : t(keys.action.LOG_AS_COMPLETE)
      }
      hideSubmit={!!certificateId}
      disableCloseOnSubmit={true}
      submitDisabled={!!certificateId}
      onSubmit={() => {
        if (!!!certificateId) {
          addCertificate({
            variables: {
              requirementId,
              userId,
              expires: certificateData?.expires,
            },
          }).then(({ data: { addCertificate } }) => {
            update({
              show,
              certificateId: addCertificate.id,
              certificateData: {
                ...certificateData,
                ...addCertificate,
              },
              readOnly,
            });
            if (files?.length) {
              files.forEach((file) => {
                addFile({
                  variables: {
                    ...file,
                    certificateId: addCertificate.id,
                  },
                });
              });
            }
            setEnableEditCertificateExpiry(false);
          });
        }
      }}
      className={styles.modalMinWidth}
    >
      {createCertificate()}
    </Modal>
  );
}

import CountPieChart from './CountPieChart';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { getChartLabel } from '../../../utilities/analytics';
import { chartViews } from '../../../utilities/analytics';
import { useQueryParams } from '../../../hooks/misc';

const query = loader('./CorrectiveActions.fetch.graphql');

export default function CorrectiveActions({
  workspaceIds,
  startDate,
  endDate,
  params,
  view = 1,
  status,
  isInteractive,
  header,
  subheader,
}) {
  const { upsertParams } = useQueryParams();
  const filters = useMemo(() => {
    const f = [];
    if (startDate && endDate) {
      f.push({
        field: 'dateCreated',
        operator: 'between',
        value: [startDate, endDate],
      });
    }
    return f;
  }, [endDate, startDate]);

  const { loading, data: { correctiveActionFilteredCount = [] } = {} } =
    useQuery(query, {
      fetchPolicy: 'network-only',
      variables: {
        workspaceIds,
        groupBy: chartViews.CPAS[view] || 'status',
        options: {
          filters,
        },
      },
    });

  const total = correctiveActionFilteredCount.reduce(
    (accumulator, current) => accumulator + current.value,
    0,
  );
  const chartData = useMemo(
    () =>
      correctiveActionFilteredCount?.map((inspection) => ({
        ...inspection,
        label: getChartLabel(inspection.id),
      })),
    [correctiveActionFilteredCount],
  );
  return (
    <div>
      <CountPieChart
        isLoading={loading}
        data={chartData}
        total={total}
        status={status}
        header={header}
        subheader={subheader}
        isInteractive={isInteractive}
        onClick={({ data }) => {
          if (!isInteractive) {
            return;
          }
          const target = chartViews.CPAS[view];
          const v = parseInt(view) + 1;
          upsertParams({ view: v, [target]: data.id });
        }}
      />
    </div>
  );
}

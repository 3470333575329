import { inputTypes } from '../../../constants/strings';
import { DropdownText } from '../../dropdowns/DropdownText';
import { Form } from 'react-bootstrap';
import styles from './ConditionalAction.module.scss';
import { Text } from '../../typography';

const MonthDayPicker = ({ disabled, selected, values, updateFunction }) => {
  let month, day;
  month = selected?.split('-')[0];
  if (month === 'TODAY') month = undefined;
  day = selected?.split('-')[1];

  return (
    <div className={styles.monthPickerContainer}>
      <div className={styles.monthDayContainer}>
        <div className={styles.selector}>
          <Text noMargin>Month</Text>
          <DropdownText
            disabled={disabled}
            highlight={(!!day && selected !== 'TODAY') || !selected}
            selected={values?.months?.find(
              (m) => m.key === selected?.split('-')[0],
            )}
            items={values?.months}
            onChange={(value) =>
              updateFunction(`${value?.key}-${selected?.split('-')[1]}`)
            }
            onRemove={() => updateFunction(null)}
          />
        </div>
        <div className={styles.selector}>
          <Text noMargin>Day</Text>
          <DropdownText
            highlight={(!!month && selected !== 'TODAY') || !selected}
            disabled={disabled}
            selected={values?.days?.find(
              (d) => d === parseInt(selected?.split('-')[1]),
            )}
            items={values?.days}
            onChange={(value) =>
              updateFunction(`${selected?.split('-')[0]}-${value}`)
            }
            onRemove={() => updateFunction(`${selected?.split('-')[0]}-`)}
          />
        </div>
      </div>
      <div className={styles.checkbox}>
        <Text noMargin>Today</Text>
        <Form.Check
          disabled={disabled}
          checked={selected === 'TODAY'}
          onChange={({ target: { checked } }) =>
            updateFunction(checked ? 'TODAY' : null)
          }
        />
      </div>
    </div>
  );
};

export default function ConditionalValueInput({
  inputType = '',
  updateFunction = () => {},
  selected = {},
  values = [],
  disabled = true,
  highlight = false,
}) {
  switch (inputType) {
    case inputTypes.select:
    case inputTypes.test:
      return (
        <DropdownText
          highlight={highlight}
          disabled={disabled}
          selected={selected}
          items={values}
          onChange={(value) => updateFunction(value)}
          onRemove={() => updateFunction(null)}
        />
      );
    case inputTypes.number:
      return (
        <Form.Control
          className={highlight ? styles.highlight : {}}
          disabled={disabled}
          type="number"
          value={selected}
          onChange={({ target: { value } }) => updateFunction(value)}
        />
      );
    case inputTypes.text:
    case inputTypes.textMulti:
      return (
        <Form.Control
          className={highlight ? styles.highlight : {}}
          disabled={disabled}
          type="text"
          as="textarea"
          rows={4}
          value={selected}
          onChange={({ target: { value } }) => updateFunction(value)}
        />
      );
    case inputTypes.date:
      return (
        <MonthDayPicker
          disabled={disabled}
          updateFunction={updateFunction}
          values={values}
          selected={selected}
        />
      );
    case inputTypes.time:
      return (
        <Form.Control
          className={highlight ? styles.highlight : {}}
          disabled={disabled}
          type="time"
          value={selected}
          onChange={({ target: { value } }) => updateFunction(value)}
        />
      );
    case inputTypes.dateTime:
      const update = (value, option) => {
        if (option === 'time') {
          updateFunction(`${selected?.split(' ')[0]} ${value}`);
        } else if (option === 'date') {
          updateFunction(`${value} ${selected?.split(' ')[1]}`);
        }
      };
      return (
        <div>
          <MonthDayPicker
            disabled={disabled}
            updateFunction={(value) => update(value, 'date')}
            values={values}
            selected={selected?.split(' ')[0]}
          />
          <Form.Control
            className={highlight ? styles.highlight : {}}
            disabled={disabled}
            type="time"
            value={selected?.split(' ')[1]}
            onChange={({ target: { value } }) => update(value, 'time')}
          />
        </div>
      );

    default:
      return <></>;
  }
}

import { loader } from 'graphql.macro';
import styles from './ObservationList.module.scss';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useMemo, useEffect } from 'react';
import {
  observationStatus,
  observationTypes,
} from '../../utilities/observations';
import Icon from '../../components/Icon';
import { useState } from 'react';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '../../providers/UserProvider';
import { useActionItemModal } from '../../hooks/misc';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import defaultLogo from '../../assets/workspace_image.png';
import Label from '../../components/action_items/Label';
import { imageExtensions } from '../../utilities/files';
import MTTable from '../../components/tables/MTable';
import { useURLParams } from '../../providers/URLParamProvider';
import { useQueryParams } from '../../hooks/misc';
import { useTableComponents } from '../../components/tables/MTableComponents';
import findFileIcon from '../../utilities/files';

const observationsQuery = loader('./ObservationList.fetch.graphql');
const addActionItemMutation = loader('./ObservationList.addActionItem.graphql');

export default function ObservationsList() {
  const navigate = useNavigate();
  const { updateModal, modalState } = useModal();
  const { show } = modalState.observationModal;
  const { availableWorkspaces } = useWorkspace();
  const { allUsers } = useWorkspacePermissions();
  const { user: currentUser } = useCurrentUser();
  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateModified')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateModified')?.split('__')[1] || '',
    dateTimeMin: getParam('dateTime')?.split('__')[0] || '',
    dateTimeMax: getParam('dateTime')?.split('__')[1] || '',
  });
  const { dateCell, textCell } = useTableComponents();

  const { t } = useTranslation();
  const { filters, page, pageSize, sort } = useURLParams();

  const users = allUsers.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.id}`,
  }));

  const workspaces = availableWorkspaces.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));

  const {
    refetch,
    networkStatus,
    previousData: { observationCount: oldCount } = {},
    data: { observations = [], observationCount = 0 } = {},
  } = useQuery(observationsQuery, {
    skip: !page || !sort || !pageSize,
    variables: {
      options: {
        page,
        pageSize,
        sort,
        filters,
      },
    },
  });

  useEffect(() => {
    updateModal({
      modalName: modals.observation,
      variables: { refetch },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'private',
        header: '',
        size: 50,
        grow: false,
        enableColumnFilter: false,
        enableSorting: false,
        filterVariant: 'select',
        Cell: ({ cell }) => (
          <Icon
            style={{
              opacity: '0.5',
              fontSize: '1.25rem',
            }}
          >
            {!cell.getValue() ? 'visibility' : 'visibility_off'}
          </Icon>
        ),
      },
      {
        accessorKey: 'negative',
        header: '',
        size: 85,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.values(observationTypes).map((o) => ({
            value: o.key,
            label: o.title,
          })),
        ],
        Cell: ({ cell }) => {
          const key = cell.getValue() === true ? 'NEGATIVE' : 'POSITIVE';
          const value = {
            icon: observationTypes[key].icon,
            color: observationTypes[key].color,
          };
          return (
            <Icon opacity={0.5} color={value.color}>
              {value.icon}
            </Icon>
          );
        },
      },
      {
        accessorKey: 'externalId',
        header: 'ID',
        Cell: ({ cell }) => textCell({ cell }),
      },

      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'description',
        header: t(keys.common.DESCRIPTION),
        grow: true,
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'dateTime',
        header: t(keys.observations.DATE_AND_TIME),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateTimeMin}
            maxDate={dateSelectors.dateTimeMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateTimeMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateTimeMax: value })
            }
          />
        ),
        Cell: ({ cell }) => dateCell({ cell }),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.values(observationStatus)
            .filter((s) =>
              currentUser.role !== 'ADMIN'
                ? s.key !== 'NEW' && s.key !== 'INVALID'
                : s.key !== 'INVALID',
            )
            .map((s) => ({
              value: s.key,
              label: s.title,
            })),
        ],
        Cell: ({ cell }) => {
          return cell.getValue() ? (
            <Label
              noMargin
              name={
                t(
                  getTranslationKey(
                    observationStatus[cell.getValue()]?.title,
                    'common',
                  ),
                ) ?? cell.getValue()
              }
              weight="semiBold"
              color={
                !cell.getValue()
                  ? 'green'
                  : observationStatus[cell.getValue()]?.color || 'primary'
              }
            />
          ) : null;
        },
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ row, cell }) => (
          <>
            <img
              src={cell.getValue().logoUrl || defaultLogo}
              className={styles.workspaceLogo}
              alt={'workspace_img'}
            />
            <Text noMargin weight="semiBold" size="sm" truncate>
              {cell.getValue().title}
            </Text>
          </>
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        filterVariant: 'select',
        filterSelectOptions: users,
        enableSorting: false,
        grow: true,
        Cell: ({ cell }) =>
          !!cell.getValue() ? (
            <SimpleUserCard size="sm" user={cell.getValue()} />
          ) : (
            <Text noMargin size="sm" weight="semiBold">
              Anonymous
            </Text>
          ),
      },
      {
        accessorKey: 'reviewer',
        header: t(keys.observations.REVIEWER),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: users,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'closer',
        header: t(keys.observations.CLOSER),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: users,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.LAST_UPDATED),
        grow: true,
        size: 275,
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {getLocalTime(cell.getValue()).fromNow()}
          </Text>
        ),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        grow: true,
        size: 275,
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {getLocalTime(cell.getValue()).fromNow()}
          </Text>
        ),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'id',
        header: 'ID',
        size: 120,
        Cell: ({ cell }) => (
          <Text
            noMargin
            weight="semiBold"
            size="sm"
            truncate
            data-cy={`observation-id-${cell.getValue()}`}
          >
            {cell.getValue()}
          </Text>
        ),
      },
    ],
    [t, workspaces, users, textCell, dateSelectors, dateCell, currentUser.role],
  );

  return (
    <MTTable
      isLoading={networkStatus === NetworkStatus.loading}
      columns={columns}
      data={observations || []}
      rowCount={observationCount || oldCount}
      enableExpanding={true}
      enableExpandAll={true}
      rightClickNavigation={paths.observation}
      renderDetailPanel={({ row }) => (
        <ObservationExpansionCard row={row} refetch={refetch} />
      )}
      onClearAllFilters={() => {
        setDateSelectors({});
      }}
      onRowClick={(observation) =>
        navigate(
          getRoute(observation.workspace.id, paths.observation, observation.id),
        )
      }
      dataCy="observations-table"
    />
  );
}

function ObservationExpansionCard({ row, refetch }) {
  const {
    original: {
      dateTime,
      participants,
      location,
      description,
      negative,
      images,
      creator,
      reviewer,
      id,
      dateReviewed,
      actionItems,
      workspace,
      status,
      incident,
      closer,
      dateClosed,
      private: privateObservation,
    },
  } = row || {};

  const { t } = useTranslation();
  const { user, isAdmin } = useCurrentUser();
  const [addActionItem] = useMutation(addActionItemMutation);
  const { openActionItemModal } = useActionItemModal();
  const navigate = useNavigate();
  const readOnly =
    status === observationStatus.COMPLETE.key ||
    status === observationStatus.INVALID.key ||
    !isAdmin;

  const isCreator = user?.id === creator?.id;
  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={
          status === observationStatus.INVALID.key
            ? styles.gray
            : negative
            ? styles.red
            : styles.green
        }
      />
      <div className={styles.card}>
        <div className={styles.left}>
          <div className={styles.mainHeader}>
            <Text
              noMargin
              size="lg"
              weight="semiBold"
              color={observationStatus[status]?.color}
            >
              {t(getTranslationKey(status, 'common'))?.toUpperCase() ?? status}
            </Text>
            {privateObservation ? (
              <Icon opacity={'0.6'}>visibility_off</Icon>
            ) : null}
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondary" noMargin>
                {t(keys.observations.OBSERVER)}
              </Text>
              {!!creator ? (
                <SimpleUserCard user={creator} />
              ) : (
                <Text noMargin weight="semiBold">
                  {t(keys.observations.ANONYMOUS)}
                </Text>
              )}
            </div>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondary" noMargin>
                {t(keys.observations.DATE_AND_TIME)}
              </Text>
              <Text weight="semiBold" noMargin>
                {getLocalTime(dateTime).format('dddd, MMM D YYYY HH:mm')}
              </Text>
            </div>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondary" noMargin>
                {t(keys.common.LOCATION)}
              </Text>
              {!location ? (
                <Text noMargin weight="semiBold">
                  {t(keys.common.NONE)}
                </Text>
              ) : (
                <Text weight="semiBold" noMargin>
                  {location}
                </Text>
              )}
            </div>
          </div>
          <div className={styles.bottom}>
            <Text weight="semiBold" size="sm" color="secondary" noMargin>
              {t(keys.common.DESCRIPTION)}
            </Text>
            <Text weight="semiBold" noMargin>
              {description}
            </Text>
            <Text weight="semiBold" size="sm" color="secondary" noMargin>
              {t(keys.common.FILES)}
            </Text>
            {!images?.length ? (
              <Text noMargin weight="semiBold">
                {t(keys.common.NONE)}
              </Text>
            ) : (
              <div className={styles.images}>
                {images.map((image) => {
                  const extension = image.imageUrl
                    .split('.')
                    .pop()
                    .toLowerCase();
                  const isImage = imageExtensions.includes(extension);
                  const src = isImage
                    ? image.imageUrl
                    : findFileIcon(extension);
                  return (
                    <img
                      key={image.id}
                      className={styles.image}
                      alt={`observation-${image.id}`}
                      src={src}
                    />
                  );
                })}
              </div>
            )}
            <Text weight="semiBold" size="sm" color="secondary" noMargin>
              {t(keys.observations.TEAM_MEMBERS_PRESENT)}
            </Text>
            {!participants?.length ? (
              <Text weight="semiBold" noMargin>
                {t(keys.common.NONE)}
              </Text>
            ) : (
              <div className={styles.team}>
                {participants.map((user) => (
                  <SimpleUserCard user={user} key={user.id} size="sm" />
                ))}
              </div>
            )}
          </div>
          <br />
          {status !== observationStatus.INVALID.key && (
            <div className={styles.showMore}>
              <Text
                color="accentPrimary"
                size="sm"
                hover
                noMargin
                onClick={() =>
                  navigate(getRoute(workspace.id, paths.observation, id))
                }
              >
                {t(keys.dashboard.SHOW_MORE).toUpperCase()}
              </Text>
            </div>
          )}
        </div>
        <div className={styles.right}>
          {!!incident ? (
            <div className={styles.incidentText}>
              <Icon color="red" style={{ fontSize: '1rem' }}>
                fmd_bad
              </Icon>
              <Text noMargin weight="semiBold" color="red">
                {`${t(keys.boards.INCIDENT_REPORTED)}`}
              </Text>
              {(isCreator || isAdmin) && (
                <Text
                  color="accentPrimary"
                  hover
                  size="sm"
                  noMargin
                  onClick={() =>
                    navigate(
                      getRoute(
                        incident?.workspace?.id,
                        paths.incident,
                        incident?.id,
                      ),
                    )
                  }
                >
                  {t(keys.assessments.VIEW).toUpperCase()}
                </Text>
              )}
            </div>
          ) : null}
          {!!reviewer ? (
            <Text className={styles.textAlignRight} noMargin size="sm">
              {t(keys.observations.ACKNOWLEDGED_BY)}{' '}
              <span>
                {reviewer.firstName} {reviewer.lastName}{' '}
              </span>
              {getLocalTime(dateReviewed).format('dddd MMMM DD, YYYY')}
            </Text>
          ) : null}
          {!!closer ? (
            <Text noMargin className={styles.textAlignRight} size="sm">
              {t(keys.incidents.CLOSED_BY)}{' '}
              <span>
                {closer.firstName} {closer.lastName}{' '}
              </span>
              {getLocalTime(dateClosed).format('dddd MMMM DD, YYYY')}
            </Text>
          ) : null}
          {!!reviewer ? (
            <div className={styles.column}>
              <div className={styles.actionItemHeader}>
                <Text
                  color="accentPrimary"
                  noMargin
                  size="lg"
                  weight="semiBold"
                >
                  {t(keys.common.ACTION_ITEMS)}
                </Text>
                {!readOnly && (
                  <Icon
                    color="primary"
                    hover
                    onClick={() => {
                      addActionItem({
                        variables: {
                          title: `Observation ${id}`,
                          description,
                          type: 'OBSERVATION',
                          observationId: id,
                          workspaceId: workspace.id,
                        },
                      }).then(
                        ({
                          data: {
                            addActionItem: { id },
                          },
                        }) => {
                          openActionItemModal(id, 'OBSERVATION', () =>
                            refetch(),
                          );
                        },
                      );
                    }}
                  >
                    add
                  </Icon>
                )}
              </div>
              {actionItems?.length ? (
                <div className={styles.actionItems}>
                  {actionItems.map((a) => (
                    <ActionItemCard
                      key={a.id}
                      actionItem={a}
                      onClick={() =>
                        openActionItemModal(a.id, 'OBSERVATION', () => {})
                      }
                    />
                  ))}
                </div>
              ) : (
                <Text
                  className={styles.textAlignRight}
                  noMargin
                  weight="semiBold"
                >
                  {t(keys.common.NONE)}
                </Text>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={
          status === observationStatus.INVALID.key
            ? styles.gray
            : negative
            ? styles.red
            : styles.green
        }
      />
    </div>
  );
}

import styles from './Text.module.scss';
import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

export default function TextWithIcon({
  children,
  className,
  size = 'md',
  weight = 'regular',
  color = 'primary',
  noVerticalMargin = false,
  noMargin = false,
  textAlign = 'left',
  noSelect = false,
  truncate = false,
  onClick = () => {},
  hover,
  link = false,
  icon = undefined,
  leftIcon,
  iconProps = {},
}) {
  return (
    <div className={link ? styles.link : styles.icon} onClick={onClick}>
      {!!leftIcon && (
        <Icon
          className={classNames(
            styles[size],
            hover && styles.hover,
            styles[color],
          )}
          style={{ ...iconProps }}
        >
          {leftIcon}
        </Icon>
      )}
      <p
        className={classNames(
          styles[size],
          hover && styles.hover,
          noVerticalMargin && styles.noVerticalMargin,
          noSelect && styles.noSelect,
          noMargin && styles.noMargin,
          styles[weight.toLowerCase()],
          styles[color],
          styles[textAlign],
          truncate && styles.truncate,
          link && styles.blue,
          className,
        )}
      >
        {children}
      </p>
      {(!!icon || !!link) && (
        <Icon
          className={classNames(
            styles[size],
            hover && styles.hover,
            styles[color],
            link && styles.blue,
          )}
          style={{ ...iconProps }}
        >
          {link ? 'arrow_right' : icon}
        </Icon>
      )}
    </div>
  );
}

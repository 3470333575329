import localForage from 'localforage';

let db;

if (!db) {
  db = localForage.createInstance({
    name: 'files-audio-image-db',
    storeName: 'files',
  });
}

export const addOfflineFile = async (id, file) => {
  try {
    await db.setItem(id, file);
  } catch (error) {
    console.error('Error adding file:', error);
    throw error;
  }
};

export const getOfflineFile = async (id) => {
  try {
    return await db.getItem(id);
  } catch (error) {
    console.error('Error retrieving file:', error);
    throw error;
  }
};

export const getOfflineFileUrl = async (id) => {
  const record = await getOfflineFile(id);
  return record ? URL.createObjectURL(record) : null;
};

export const deleteOfflineFile = async (id) => {
  try {
    await db.removeItem(id);
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

export const clearDatabase = async () => {
  try {
    await db.clear();
  } catch (error) {
    console.error('Error clearing database:', error);
    throw error;
  }
};

export default db;

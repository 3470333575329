import styles from './Incidents.module.scss';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import TabSystem from '../../components/TabSystem';
import IncidentTemplates from './IncidentTemplateList';
import { searchParamKeys } from '../../constants/strings';
import { useQueryParams } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { assessmentTypes } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useMemo, useState } from 'react';
import IncidentTable from './IncidentTable';
import { loader } from 'graphql.macro';
import { gql } from '@apollo/client';
import { useURLParams } from '../../providers/URLParamProvider';
import { useApolloClient } from '@apollo/client';
import { fetchAllData } from '../../utilities/excel_export/table_excel_export';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant } from '../../constants/misc';
import { exportToExcel } from '../../components/excel/ExcelExport';
import format from 'date-fns/format';
import {
  excelHeaderLookup,
  formatIncident,
} from '../../utilities/excel_export/data_converter';
import ProgressModal from '../../components/modals/ProgressModal';
import IncidentReportList from './IncidentReportList';

const defaultTab = 'incidents';
const incidentExportQuery = loader('./Incidents.fetch.graphql');

export default function Incidents({ disabled = false }) {
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { filters } = useURLParams();
  const { isWorkspaceAdmin } = useWorkspace();
  const { openModal } = useModal();
  const { user, isAdmin } = useCurrentUser();
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);
  const options = useMemo(() => {
    return [
      {
        title: user.company?.incidentName || t(keys.incidents.INCIDENTS),
        element: IncidentTable,
        key: 'incidents',
      },
      { title: 'Reports', element: IncidentReportList, key: 'reports' },
      ...(isAdmin
        ? [
            {
              title: t(keys.common.TEMPLATES),
              element: IncidentTemplates,
              key: 'templates',
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleExport = async () => {
    const query = gql`
      ${incidentExportQuery}
    `;
    await fetchAllData({
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'incidents',
      count: 'incidentsCount',
      filters,
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }
      if (response?.length) {
        exportToExcel({
          data: response.map((d) => formatIncident(d)),
          sheetName: 'Incident Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Incident',
          fileName: `Incident_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'Incident Table Export'],
          headerMap: excelHeaderLookup,
        });
      }
    });
  };

  return (
    <div className={disabled ? styles.disabled : null}>
      <div className={styles.container}>
        <Text size="lg" weight="bold">
          {user.company?.incidentName || t(keys.incidents.INCIDENTS)}
        </Text>
        {(isWorkspaceAdmin || tab === 'incidents') && (
          <div className={styles.buttons}>
            <Button
              variant="primary"
              icon="add"
              size="md"
              value={t(keys.common.NEW)}
              onClick={() => {
                switch (tab) {
                  case 'incidents':
                    openModal({
                      modalName: modals.createIncident,
                      variables: {
                        participants: [user],
                      },
                    });
                    break;
                  case 'templates':
                    openModal({
                      modalName: modals.createTemplate,
                      variables: {
                        types: [assessmentTypes.incident],
                        type: assessmentTypes.incident,
                        modalTitle: t(keys.incidents.CREATE_INCIDENT_TEMPLATE),
                      },
                    });
                    break;
                  case 'reports':
                    openModal({
                      modalName: modals.createAssessment,
                      variables: {
                        types: [assessmentTypes.incident],
                        title: t(keys.incidents.START_INCIDENT_ASSESSMENT),
                        type: assessmentTypes.incident,
                        participants: [user],
                      },
                    });
                    break;
                  default:
                    throw new Error(`Invalid type ${tab}`);
                }
              }}
            />
            {tab === defaultTab && isWorkspaceAdmin && (
              <Button
                outlined
                icon="file_upload"
                value={t(keys.action.EXPORT)}
                onClick={handleExport}
              />
            )}
          </div>
        )}
      </div>
      <TabSystem type={tab} options={options} />
      <ProgressModal
        show={isFetchingAllData}
        progress={progress}
        title="Exporting Data"
        message="One moment while we fetch your data. Please don't close this window!"
        icon="import_export"
      />
    </div>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styles from './Boards.module.scss';
import React from 'react';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import BoardCard from './BoardCard';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useModal } from '../../providers/ModalProvider';
import { t } from 'i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { modals } from '../../providers/modals';

const boardQuery = loader('./Boards.graphql');
const deleteBoardMutation = loader('./Boards.delete.graphql');

export default function Boards() {
  const navigate = useNavigate();

  const {
    data: { boards = [] } = {},
    refetch,
    loading,
  } = useQuery(boardQuery, { fetchPolicy: 'no-cache' });
  const [deleteBoard] = useMutation(deleteBoardMutation);
  const { openConfirmationModal, openModal } = useModal();

  return (
    !loading && (
      <div className={styles.rootContainer}>
        <div className={styles.header}>
          <Text className={styles.text} color="secondary" noMargin>
            {`Note: These totals are based on logged events. Even if an item is modified or deleted later, its event still counts, so the numbers may not match the current state.`}
          </Text>
          <div className={styles.button}>
            <Button
              value="New Board"
              icon="add"
              align={'right'}
              onClick={() => openModal({ modalName: modals.board })}
            />
          </div>
        </div>

        <div className={styles.container}>
          {loading ? (
            <div className={styles.loading}>
              <Spinner variant="primary" />
            </div>
          ) : boards?.length ? (
            <div>
              <div className={styles.cardContainer}>
                {boards.map((board) => (
                  <BoardCard
                    key={`Board:${board.id}`}
                    board={board}
                    onDelete={() => {
                      openConfirmationModal({
                        title: t(keys.boards.DELETE_BOARD_CONFIRM),
                        description: t(keys.boards.DELETE_BOARD_MESSAGE, {
                          variable: board.title,
                        }),
                        buttonText: t(keys.action.CONFIRM),
                        variant: 'danger',
                        onSubmit: () => {
                          deleteBoard({ variables: { id: board.id } }).then(
                            () => {
                              refetch();
                            },
                          );
                        },
                      });
                    }}
                    onClick={() => {
                      navigate(`board/${board.id}`);
                    }}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.emptyStateParent}>
              <img
                className={styles.emptyStateGraphic}
                alt="Graphs"
                src="https://app.opasmobile.com/assets/graphics/insights_es.png"
              />
              <div className={styles.emptyStateContainer}>
                <Text textAlign="center" size="lg" weight="semiBold">
                  {t(keys.boards.EMPTY_STATE_BOARDS_HEADER)}
                </Text>
                <Text
                  textAlign="center"
                  size="md"
                  weight="semiBold"
                  color="secondary"
                >
                  {t(keys.boards.EMPTY_STATE_BOARDS_MESSAGE)}
                </Text>
              </div>
              <br />
              <Button
                value={t(keys.action.START)}
                onClick={() => openModal({ modalName: modals.board })}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
}

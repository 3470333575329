import React from 'react';
import styles from './LabelsDisplay.module.scss';
import Label from './action_items/Label';
import { Text } from './typography';

export default function LabelsDisplay({ labels = [], maxLabels = 2 }) {
  return (
    <div className={styles.container}>
      {labels?.slice(0, maxLabels).map(({ id, color, name }) => (
        <Label key={`${id}-${name}-label`} name={name} color={color} />
      ))}
      {labels?.length > maxLabels ? (
        <Text size="sm" weight="semiBold" color="accentPrimary">{`+ ${
          labels.length - maxLabels
        }`}</Text>
      ) : (
        <div className={styles.noText} />
      )}
    </div>
  );
}

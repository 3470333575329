import moment from 'moment';
import { getRoute, paths } from '../constants/strings';
import { getLocalTime } from './time';
import { appEndpoints } from '../constants/endpoints';
import { isProd } from './misc';
import i18n from '../i18n';
import { keys } from './translator/translation_keys';

export const mapDailyReportData = ({ events = [], timecards = [] }) => {
  const history = [];
  events.forEach((e) => {
    const historyItem = {};
    historyItem.momentOfEvent = getLocalTime(e.dateCreated);
    historyItem.time = historyItem.momentOfEvent.format('hh:mm A');
    if (e.eventType === 'PARTICIPANT_ASSESSMENT') {
      historyItem.title = i18n.t(keys.settings.PARTICIPATED_IN_ASSESSMENT);
      historyItem.linkText = `Assessment ${e.information.AssessmentId}`;
      historyItem.miniUrl = getRoute(
        e.workspaceId,
        paths.assessment,
        e.information.AssessmentId,
      );
      historyItem.fullUrl =
        appEndpoints[isProd ? 'prod' : 'dev'] + historyItem.miniUrl;
      historyItem.icon = 'description';
      historyItem.color = 'green';
      historyItem.description = i18n.t(keys.settings.COMPLETED_A, {
        variable: e.information.Template,
      });
    } else if (e.eventType === 'COMPLETE_ACTION_ITEM') {
      historyItem.title = i18n.t(keys.settings.CLOSED_AN_ACTION_ITEM);
      historyItem.icon = 'check';
      historyItem.color = 'blue';
      historyItem.miniUrl =
        getRoute(e.workspaceId, paths.actionItems) +
        `?actionitem=${e.information.ActionItemId}`;
      historyItem.fullUrl =
        appEndpoints[isProd ? 'prod' : 'dev'] + historyItem.miniUrl;
      historyItem.description = e.information.Title;
    } else if (e.eventType === 'REPORT_INCIDENT') {
      historyItem.title = i18n.t(keys.settings.REPORTED_AN_INCIDENT);
      historyItem.icon = 'shield';
      historyItem.color = 'red';
      historyItem.miniUrl = getRoute(
        e.workspaceId,
        paths.incident,
        e.information.IncidentId,
      );
      historyItem.fullUrl =
        appEndpoints[isProd ? 'prod' : 'dev'] + historyItem.miniUrl;

      historyItem.linkText = `Incident ${e.information.IncidentId}`;

      historyItem.description = `${e.information.Type} - ${
        e.information.Subtype || 'None'
      }`;
    } else {
      return;
    }
    history.push(historyItem);
  });

  timecards.forEach((t) => {
    const icon = 'schedule';
    const color = 'yellow';
    const miniUrl = `/timecard/?id=${t.id}`;
    const fullUrl = appEndpoints[isProd ? 'prod' : 'dev'] + miniUrl;
    const linkText = `Timecard ${t.id}`;

    const clockIn = {};
    clockIn.momentOfEvent = new moment(parseInt(t.startTime));
    clockIn.time = clockIn.momentOfEvent.format('hh:mm A');
    clockIn.title = `Clocked In`;
    clockIn.icon = icon;
    clockIn.color = color;
    clockIn.linkText = linkText;
    clockIn.miniUrl = miniUrl;
    clockIn.fullUrl = fullUrl;
    clockIn.description = t.description;

    const clockOut = {};
    clockOut.momentOfEvent = new moment(parseInt(t.endTime));
    clockOut.time = clockOut.momentOfEvent.format('hh:mm A');
    clockOut.title = `Clocked Out`;
    clockOut.description = t.description;
    clockOut.icon = icon;
    clockOut.color = color;
    clockOut.linkText = linkText;
    clockOut.miniUrl = miniUrl;
    clockOut.fullUrl = fullUrl;
    history.push(clockIn);
    history.push(clockOut);
  });

  return history.sort(
    ({ momentOfEvent: a }, { momentOfEvent: b }) =>
      a.format('YYYYMMDDHHmm') - b.format('YYYYMMDDHHmm'),
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { keys } from '../../utilities/translator/translation_keys';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths, quickActionTypes } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import {
  tableOptions,
  tableStyle,
  useTableComponents,
} from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Form } from 'react-bootstrap';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import styles from './WorkspaceTable.module.scss';
import { useCurrentUser } from '../../providers/UserProvider';
import Select from 'react-select';
import { Text } from '../../components/typography';
import UserMultiSelector from '../../components/UserMultiSelector';
import defaultLogo from '../../assets/workspace_image.png';
import { useModal } from '../../providers/ModalProvider';
import { useTemplates } from '../../providers/TemplateProvider';
import WorkspaceLocations from './WorkspaceLocations';
import CustomQuickAction from '../dashboard/CustomQuickAction';
import { modals } from '../../providers/modals';
import QuickAction from '../dashboard/QuickAction';
import Icon from '../../components/Icon';
import { Button } from '../../components';
import { showToast } from '../../graphql/cache/modal';

const updateWorkspaceMutation = loader(
  '../../../src/pages/workspace/WorkspaceDetailsPage.updateWorkspace.graphql',
);
const updateCompanyMutation = loader(
  '../../graphql/mutations/company_update.graphql',
);

const addWorkspaceUserMutation = loader(
  '../../pages/workspace/WorkspaceDetailsPage.addUser.graphql',
);

const getPermissionsQuery = loader('./WorkspaceTable.permissions.graphql');
const removeUserMutation = loader(
  '../../pages/workspace/WorkspaceDetailsPage.removeUser.graphql',
);

export default function WorkspacesTable() {
  const { availableWorkspaces, refetchWorkspace } = useWorkspace();
  const { templates } = useTemplates();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dropdownCell, textCell, userFilterOptions } = useTableComponents();
  const { user: currentUser, isAdmin } = useCurrentUser();
  const [addUser] = useMutation(addWorkspaceUserMutation);
  const [updateCompany] = useMutation(updateCompanyMutation);
  const [updateWorkspace] = useMutation(updateWorkspaceMutation);
  const [removeUser] = useMutation(removeUserMutation);
  const { openConfirmationModal, openModal } = useModal();
  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState(
    currentUser.company?.locations?.split('|') ?? [],
  );

  const observationTemplates = templates?.filter(
    (t) => t.templateType === 'OBSERVATION',
  );

  const { refetch, data: { workspacePermissions = [] } = {} } = useQuery(
    getPermissionsQuery,
    {
      nextFetchPolicy: 'standby',
      variables: {
        options: {
          filters: [
            {
              field: 'workspaceId',
              operator: 'ne',
              value: [`${null}`],
            },
          ],
        },
      },
    },
  );

  const [state, setState] = useState({});

  useEffect(() => {
    if (availableWorkspaces) {
      const newState = availableWorkspaces.reduce((acc, w) => {
        acc[w.id] = w;
        return acc;
      }, {});
      setState(newState);
    }
  }, [availableWorkspaces]);

  const detailPanel = ({ row }) => {
    const workspace = row?.original || {};
    const editableQuickAction = [
      ...(state[workspace.id]?.isEditingButtons ||
      !workspace?.quickActions?.length
        ? [
            {
              icon: 'add',
              color: 'customButton',
              onClick: () =>
                openModal({
                  modalName: modals.quickAction,
                  variables: {
                    workspace: workspace,
                    quickActionType: quickActionTypes.workspaceLevel,
                    refetch: refetchWorkspace,
                  },
                }),
            },
          ]
        : []),
      ...(workspace?.quickActions?.length
        ? [
            {
              icon: state[workspace.id]?.isEditingButtons ? 'check' : 'edit',
              color: state[workspace.id]?.isEditingButtons
                ? 'editButton'
                : 'customButton',
              onClick: () =>
                setState({
                  ...state,
                  [workspace.id]: {
                    ...state[workspace.id],
                    isEditingButtons: !state[workspace.id]?.isEditingButtons,
                  },
                }),
            },
          ]
        : []),
    ];

    const workspaceAdmins = workspacePermissions
      ?.filter(
        (w) => w.workspaceId === workspace.id && w.accessLevel === 'ADMIN',
      )
      .map((u) => u.user);
    const workspaceUsers = workspacePermissions
      ?.filter(
        (w) => w.workspaceId === workspace.id && w.accessLevel === 'USER',
      )
      .map((u) => u.user);

    const leaveWorkspace = (userId) => {
      openConfirmationModal({
        title: t(keys.workspace.LEAVE_WORKSPACE),
        description: t(keys.workspace.LEAVE_WORKSPACE_DESCRIPTION, {
          variable: workspace.title,
        }),
        variant: 'danger',
        buttonText: t(keys.action.LEAVE, { variable: null }),
        onSubmit: () => {
          removeUser({
            variables: {
              id: workspace.id,
              userId: userId,
            },
          }).then(() => {
            refetch();
          });
        },
      });
    };

    const workspaceObservationTemplate =
      state[workspace.id]?.observationTemplate || {};
    const emergencyString = state[workspace.id]?.incidentEmergencyMessage || '';

    const observationTemplate = workspaceObservationTemplate
      ? {
          label: workspaceObservationTemplate.title,
          value: workspaceObservationTemplate.id,
        }
      : null;

    const companyLocations = currentUser.company?.locations?.split('|') || [];

    return (
      <div className={styles.detailPanel} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <img
            src={workspace.logoUrl || defaultLogo}
            alt="logo"
            className={styles.logo}
            onClick={() =>
              navigate(getRoute(row.original?.id, paths.workspaceSettings))
            }
          />
          <Text size="lg" weight="semiBold" noMargin truncate>
            {workspace.title}
          </Text>
        </div>
        <div className={styles.spacer} />
        {isAdmin ? (
          <>
            <div className={styles.worspaceQuickActionsContainer}>
              {workspace?.quickActions?.map((quickAction) => (
                <CustomQuickAction
                  key={quickAction.id}
                  quickAction={quickAction}
                  refetch={refetchWorkspace}
                  isEditing={state[workspace.id]?.isEditingButtons}
                />
              ))}
              {editableQuickAction.map(
                ({ title, color, icon, onClick, disabled, className }, idx) => (
                  <QuickAction
                    key={title + idx + icon}
                    title={title}
                    icon={icon}
                    color={color}
                    onClick={onClick}
                    disabled={disabled}
                    className={className}
                  />
                ),
              )}
            </div>
            <br />
          </>
        ) : null}

        <div className={styles.container}>
          <div className={styles.flex}>
            <Text weight="semiBold" size="sm" noMargin>
              {t(keys.workspace.ADMINISTRATORS)}
            </Text>
            <UserMultiSelector
              usersToLeaveOut={workspaceUsers}
              selected={[
                { ...workspace.creator, readOnly: true },
                ...workspaceAdmins?.filter(
                  (u) => u.id !== workspace.creator.id,
                ),
              ]}
              type="all"
              placeholder={t(keys.workspace.ADMINISTRATORS)}
              onUserAdded={(user) => {
                addUser({
                  variables: {
                    id: workspace.id,
                    userId: user.id,
                    accessLevel: 'ADMIN',
                  },
                }).then(() => refetch());
              }}
              onUserRemoved={(user) => {
                user.id === currentUser.id
                  ? leaveWorkspace(user.id)
                  : removeUser({
                      variables: {
                        id: workspace.id,
                        userId: user.id,
                      },
                    }).then(() => refetch());
              }}
            />
            <Text weight="semiBold" size="sm" noMargin>
              {t(keys.workspace.WORKSPACE_USERS)}
            </Text>
            <UserMultiSelector
              usersToLeaveOut={workspaceAdmins}
              selected={workspaceUsers}
              type="all"
              onUserRemoved={(user) => {
                user.id === currentUser.id
                  ? leaveWorkspace(user.id)
                  : removeUser({
                      variables: {
                        id: workspace.id,
                        userId: user.id,
                      },
                    }).then(() => refetch());
              }}
              onUserAdded={(user) => {
                addUser({
                  variables: {
                    id: workspace.id,
                    userId: user.id,
                    accessLevel: 'USER',
                  },
                }).then(() => refetch());
              }}
            />
          </div>
          <div className={styles.flex}>
            <Text noMargin weight="semiBold" size="sm">
              Locations
            </Text>
            <WorkspaceLocations
              workspace={workspace}
              updateWorkspace={updateWorkspace}
              refetchWorkspace={refetchWorkspace}
              companyLocations={companyLocations}
            />
            <Text noMargin weight="semiBold" size="sm">
              {`${
                currentUser.company?.observationName || 'Observation'
              } Template`}
            </Text>
            <Select
              value={observationTemplate}
              options={observationTemplates?.map((o) => ({
                label: o.title,
                value: o.id,
              }))}
              onChange={(selected) => {
                updateWorkspace({
                  variables: {
                    id: workspace.id,
                    observationTemplateId: selected.value,
                  },
                }).then(() => refetchWorkspace());
              }}
            />
            <Text noMargin weight="semiBold" size="sm">
              {`${currentUser.company?.incidentName || 'Incident'} Message`}
            </Text>
            <Form.Control
              as="textarea"
              value={emergencyString}
              onChange={(e) =>
                setState({
                  ...state,
                  [workspace.id]: {
                    ...state[workspace.id],
                    incidentEmergencyMessage: e.target.value,
                  },
                })
              }
              onBlur={() => {
                updateWorkspace({
                  variables: {
                    id: workspace.id,
                    incidentEmergencyMessage:
                      state[workspace.id]?.incidentEmergencyMessage || '',
                    incidentEmergencyMessageType: 'STRING',
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        enableColumnFilter: true,
        filterFn: 'contains',
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        enableColumnFilter: true,
        size: 100,
        filterFn: 'contains',
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        grow: true,
        size: 150,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
        filterFn: (row, columnId, filterValue) => {
          return row.getValue(columnId).id === parseInt(filterValue);
        },
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'locations',
        header: 'Locations',
        size: 330,
        enableColumnFilter: true,
        filterFn: 'contains',
        Cell: ({ cell }) => {
          const cellValue = cell.getValue();
          const locations = cellValue?.split('|') || null;

          return (
            <Text truncate weight="semiBold" size="sm" noMargin>
              {locations?.join(', ') ?? ''}
            </Text>
          );
        },
      },
      {
        accessorKey: 'incidentEmergencyMessage',
        header: `${currentUser.company?.incidentName || 'Incident'} Message`,
        filterFn: 'contains',
        enableSorting: false,
        Cell: ({ cell }) => {
          return textCell({ cell });
        },
      },
      {
        accessorKey: 'observationTemplate.title',
        header:
          currentUser.company.observationName ||
          t(keys.observations.OBSERVATION_TEMPLATE),
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return textCell({ cell });
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, textCell, userFilterOptions, updateWorkspace, dropdownCell],
  );

  const table = useMaterialReactTable({
    data: availableWorkspaces || [],
    columns: tableColumns,
    ...tableStyle,
    ...tableOptions,
    manualFiltering: false,
    manualSorting: false,
    manualPagination: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(getRoute(row.original?.id, paths.workspaceSettings));
      },
    }),
    enableExpanding: true,
    enableExpandAll: true,
    renderDetailPanel: ({ row }) => detailPanel({ row }),
    initialState: {
      showColumnFilters: true,
      density: 'compact',
    },
  });
  const handleAddLocation = () => {
    if (location.trim() === '') return;
    const updatedLocations = [...locations, location];
    const l = updatedLocations.join('|');
    setLocations(updatedLocations);
    setLocation('');
    updateCompany({ variables: { id: currentUser.company.id, locations: l } });
  };

  const handleRemoveLocation = (indexToRemove) => {
    const updatedLocations = locations.filter(
      (_, index) => index !== indexToRemove,
    );
    setLocations(updatedLocations);
  };

  return (
    <div className={styles.workspaceTableContainer}>
      <br />
      <div className={styles.header}>
        <Text weight="semiBold" noMargin size="lg">
          Locations
        </Text>
        <Button
          size="sm"
          type="button"
          disabled={!locations.length}
          value="Apply to all Workspaces"
          onClick={() => {
            openConfirmationModal({
              variant: 'warning',
              title: 'Set Locations for all Workspaces',
              description:
                'Are you sure you want to set locations for all Workspaces?',
              onSubmit: () => {
                for (let workspace of availableWorkspaces) {
                  updateWorkspace({
                    variables: {
                      id: workspace.id,
                      locations: locations.join('|'),
                    },
                  }).then(() =>
                    showToast({
                      title: 'All Workspaces Updated',
                      message:
                        'All Workspaces have been updated to use All Company Locations. To Edit, you can go to the Workspaces tab.',
                    }),
                  );
                }
              },
            });
          }}
        />
      </div>

      <Text size="sm" color="secondary">
        These will be used in the Incident and Observation report. If none are
        provided, it will default to a text input.
      </Text>

      <div className={styles.locationContainer}>
        <div className={styles.flexRow}>
          {!!locations?.length ? (
            locations.map((loc, index) => (
              <div key={index} className={styles.location}>
                <Text noMargin weight="semiBold">
                  {loc}
                </Text>
                <Icon
                  hover
                  style={{ fontSize: '1rem' }}
                  variant="link"
                  onClick={() => handleRemoveLocation(index)}
                  className={styles.removeButton}
                >
                  close
                </Icon>
              </div>
            ))
          ) : (
            <Text color="secondary" noMargin>
              {t(keys.common.NONE)}
            </Text>
          )}
        </div>
        <div className={styles.spacer} />

        <div className={styles.header}>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ADD, {
              variable: t(keys.common.LOCATION),
            })}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <Button value="Add" icon="add" onClick={() => handleAddLocation()} />
        </div>
      </div>
      <br />
      <Text weight="semiBold" noMargin size="lg">
        Workspaces
      </Text>
      <div className={styles.spacer} />
      <MaterialReactTable table={table} />
    </div>
  );
}

import { useOnlineStatus } from './offline-misc';
import { useApolloClient, useMutation } from '@apollo/client';
import mimeDatabase from 'mime-db';
import { useCachedMutations } from './offline-misc';
import { v4 as uuidv4 } from 'uuid';
import { loader } from 'graphql.macro';
import { useCurrentUser } from '../../providers/UserProvider';
import produce from 'immer';
import axios from 'axios';
import { CF_DOMAIN } from '../../constants/aws';
import moment from 'moment';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useTemplates } from '../../providers/TemplateProvider';
import { useUpdateVehicle } from './vehicleMutation';
import { addOfflineFile } from './localForageDb';

const inspectionMutation = loader(
  '../../components/modals/provider_modals/CreateAssessmentModal.inspection.graphql',
);

const inspectionNotesMutation = loader(
  '../../pages/inspection_page/Inspection.updateNotes.graphql',
);

const inspectionQuery = loader(
  '../../pages/inspection_page/Inspection.inspection.graphql',
);

const inspectionListQuery = loader('../../providers/MyInspections.graphql');

const publishMutation = loader(
  '../../pages/inspection_page/Inspection.complete.graphql',
);

const inspectionTemplateFragment = loader(
  '../../pages/inspection_page/Inspection.inspectionTemplate.graphql',
);
const vehicleFragment = loader(
  '../../pages/fleet/Vehicle.vehicleFragment.graphql',
);

const userFragment = loader('./User.userFragment.graphql');

const notesFragment = loader('./InspectionNotesFragment.graphql');

const inspectionItemMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemText.graphql',
);

const inspectionItemUpdateMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemText.updateInspection.graphql',
);

const deleteInspectionItemUsersMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemInput.deleteUsers.graphql',
);

const addInspectionItemUsersMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemInput.addUsers.graphql',
);

const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');

const submitBundleMutation = loader(
  '../../pages/inspection_page/Inspection.bundle.graphql',
);

const deleteBundleMutation = loader(
  '../../pages/inspection_page/Inspection.deleteBundle.graphql',
);

export const useInspection = () => {
  const { workspace, workspaceVehicles } = useWorkspace();
  const online = useOnlineStatus();
  const client = useApolloClient();
  const { addMutation } = useCachedMutations();
  const updateAsset = useUpdateVehicle();

  //inspection addition
  const [addInspectionMutation] = useMutation(inspectionMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  const [updateInspectionNotesMutation] = useMutation(inspectionNotesMutation);

  //inspection TEXT type question addition
  const [addInspectionItemMutation] = useMutation(inspectionItemMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  const [deleteInspectionItemUsers] = useMutation(
    deleteInspectionItemUsersMutation,
    {
      refetchQueries: ['InspectionForInspectionCreation'],
    },
  );

  const [addInspectionItemUsers] = useMutation(addInspectionItemUsersMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  //inspection TEXT type question update
  const [updateInspectionItemMutation] = useMutation(
    inspectionItemUpdateMutation,
    {
      refetchQueries: ['InspectionForInspectionCreation'],
    },
  );

  const [publishInspection] = useMutation(publishMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  // bundle mutations
  const [submitBundle] = useMutation(submitBundleMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  const [deleteBundle] = useMutation(deleteBundleMutation, {
    refetchQueries: ['InspectionForInspectionCreation'],
  });

  const { user: currentUser } = useCurrentUser();
  const { templates } = useTemplates();
  const updateBundle = async ({ inspectionId, categoryId }) => {
    const options = {
      variables: {
        inspectionId,
        categoryId,
      },
    };

    if (online) {
      return submitBundle(options);
    }

    const oldData = await client.readQuery({
      query: inspectionQuery,
      variables: {
        id: inspectionId.toString(),
      },
    });

    const template = templates.find(
      (t) => t.id === oldData.inspections[0].template.id,
    );

    const category = template.categories.find(({ id }) => id === categoryId);

    const newIds = [];

    const bundleId = uuidv4().toString();

    for (const question of category.questions) {
      const uuid = uuidv4().toString();

      const addInspectionItemMock = {
        __typename: 'InspectionItem',
        id: `${uuid}`,
        questionId: question.id,
        value: '',
        failed: false,
        status: '',
        failNotes: null,
        failImage: null,
        user: null,
        users: null,
        additional: null,
        impact: 0,
        probability: 0,
        dateModified: null,
        categoryId,
        bundleId,
        vehicle: null,
        comments: [],
        subject: null,
        customer: null,
        auditType: null,
        attachments: [],
        actionItemId: null,
      };

      const previousData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });

      const newData = produce(previousData, (draft) => {
        draft.inspections[0].items = [
          ...draft.inspections[0].items,
          addInspectionItemMock,
        ];
      });

      client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });

      newIds.push(addInspectionItemMock.id);
    }

    const mockResult = {
      __typename: 'Inspection',
      id: inspectionId,
      items: newIds.map((id) => ({ id, categoryId, bundleId })),
    };

    const uuid = uuidv4().toString();

    await addMutation(
      submitBundleMutation,
      options,
      uuid,
      mockResult,
      ['InspectionForInspectionCreation'],
      '',
      true,
    );

    const inspection = await client.readQuery({
      query: inspectionQuery,
      variables: {
        id: inspectionId.toString(),
      },
    });
    return new Promise((resolve) => {
      const responseData = { data: { submitBundle: inspection } };
      resolve(responseData);
    });
  };

  const removeBundle = async ({ inspectionId, bundleId }) => {
    const options = {
      variables: {
        inspectionId,
        bundleId,
      },
    };

    if (online) {
      return deleteBundle(options);
    }

    const mockResult = {
      __typename: 'Inspection',
      id: inspectionId,
    };

    const uuid = uuidv4().toString();

    await addMutation(
      deleteBundleMutation,
      options,
      uuid,
      mockResult,
      ['InspectionForInspectionCreation'],
      '',
    );

    const oldData = await client.readQuery({
      query: inspectionQuery,
      variables: {
        id: inspectionId.toString(),
      },
    });

    const newData = produce(oldData, (draft) => {
      if (
        draft.inspections &&
        draft.inspections[0] &&
        draft.inspections[0].items
      ) {
        const foundInspectionItems = draft.inspections[0].items
          .map((obj) => obj.bundleId === bundleId && obj)
          .filter((i) => i);

        if (foundInspectionItems.length > 0) {
          foundInspectionItems.forEach((item) => {
            item.isArchived = true;
            item.dateModified = null;
          });

          draft.inspections[0].items = draft.inspections[0].items.filter(
            (item) => item.bundleId !== bundleId,
          );
        }
      }
    });

    client.writeQuery({
      query: inspectionQuery,
      variables: {
        id: inspectionId.toString(),
      },
      data: newData,
    });
  };

  const updateNotes = async (inspectionId, notes) => {
    const options = {
      variables: { id: inspectionId, notes },
    };
    if (online) {
      return updateInspectionNotesMutation(options);
    }

    const mockResult = {
      __typename: 'Inspection',
      id: inspectionId,
      notes,
    };

    const uuid = uuidv4().toString();

    await addMutation(
      inspectionNotesMutation,
      options,
      uuid,
      mockResult,
      [],
      'updateInspection.id',
    );

    const cacheId = `Inspection:${inspectionId}`;

    const inspection = client.cache.readFragment({
      id: cacheId,
      fragment: notesFragment,
      fragmentName: 'NotesFragment',
    });

    if (inspection) {
      const newInspection = { ...inspection, notes };

      client.cache.writeFragment({
        id: cacheId,
        fragment: notesFragment,
        data: newInspection,
      });
    }
  };

  const addInspection = async (
    templateId,
    vehicleId,
    inspectionType,
    participants,
    workspaceId,
    incidentId,
    timecardId,
    privateAssessment,
  ) => {
    const options = {
      variables: {
        templateId,
        vehicleId,
        inspectionType,
        participants,
        workspaceId,
        incidentId,
        timecardId,
        private: privateAssessment,
      },
    };

    if (online) {
      return addInspectionMutation(options);
    } else {
      const template = templates.find(({ id }) => id === templateId);

      const documentNumberPlaceholder = !!template?.documentString
        ? `${template.documentString}-00000`
        : '';

      const uuid = uuidv4().toString();
      const mockResult = {
        __typename: 'Inspection',
        id: `${uuid}`,
        templateId,
        vehicleId,
        inspectionType,
        participants,
        reviewers: [],
        regrets: [],
        readyForReview: false,
        workspaceId,
        workspace: null,
        private: privateAssessment,
        labels: [],
        previousStates: [],
        documentNumber: documentNumberPlaceholder,
        subject: null,
        customer: null,
        auditType: null,
        attachments: [],
        completer: null,
        executiveSummary: '',
      };

      await addMutation(
        inspectionMutation,
        options,
        uuid,
        mockResult,
        ['InspectionForInspectionCreation'],
        'addInspection.id',
      );

      //fetching the fragment from the cache for InspectionTemplate and Vehicle as fragments
      const inspectionTemplate = await client.readFragment({
        id: `InspectionTemplate:${templateId}`, // The ID format should be "typename:id"
        fragment: inspectionTemplateFragment,
      });

      const vehicleFragmentData = await client.readFragment({
        id: `Vehicle:${vehicleId}`,
        fragment: vehicleFragment,
      });

      const participantsFragementData = participants.map((participant) => {
        // Read the fragment for each participant using client.readFragment
        const participantData = client.readFragment({
          id: `User:${participant}`,
          fragment: userFragment,
        });

        return participantData;
      });

      if (
        !inspectionTemplate ||
        !vehicleFragmentData ||
        !participantsFragementData
      ) {
        //return a page saying the inspection cannt be perforemed because you dont have enough data saved or cached to do it offline,
        // inform the user that they might need to make sure they have Template, vehicel and participants data offline before hand
      }

      const mockResultForInspectionList = {
        ...mockResult,
        __typename: 'Inspection',
        id: `${uuid}`,
        passed: true,
        isDraft: true,
        workspaceId: workspace?.id,
        dateCreated: new moment().valueOf().toString(),
        dateModified: new moment().valueOf().toString(),
        riskAnalysis: null,
        inspectionType: inspectionType,
        dateCompleted: null,
        template: inspectionTemplate,
        vehicle: vehicleFragmentData ?? null,
        workspace: workspace ?? null,
        creator: currentUser,
        readyForReview: false,
        private: !!privateAssessment,
        labels: [],
        previousStates: [],
        documentNumber: documentNumberPlaceholder,
        notes: '',
        feedbackLike: null,
        feedbackNotes: null,
        incident: null,
        actionItems: [],
        timecard: null,
        items: [],
        participants: participantsFragementData,
        subject: null,
        customer: null,
        auditType: null,
        attachments: [],
        completer: null,
      };

      // Take all the values, and write them to the cache
      // Then you read the values back via the appropriate fragment
      // Then write the fragment result back to the query

      const items = [];

      for (const category of inspectionTemplate.categories) {
        if (category.isBundle) {
          const bundleId = uuidv4();

          for (const question of category.questions) {
            const uuid = uuidv4().toString();

            const addInspectionItemMock = {
              __typename: 'InspectionItem',
              id: `${uuid}`,
              questionId: question.id,
              value: '',
              comments: [],
              failed: false,
              status: '',
              failNotes: null,
              failImage: null,
              user: null,
              users: null,
              additional: null,
              impact: 0,
              probability: 0,
              dateModified: null,
              categoryId: category.id,
              bundleId,
              vehicleId: null,
              vehicle: null,
            };

            items.push(addInspectionItemMock);
          }
        }
      }

      const mockResultWithAdditionalFields = {
        inspections: [
          {
            ...mockResult,
            __typename: 'Inspection',
            id: `${uuid}`,
            dateCreated: new moment().valueOf().toString(),
            dateModified: new moment().valueOf().toString(),
            dateCompleted: null,
            inspectionType: inspectionType,
            participants: participantsFragementData,
            reviewers: [],
            regrets: [],
            readyForReview: false,
            template: inspectionTemplate,
            vehicle: vehicleFragmentData ?? null,
            isDraft: true,
            notes: null,
            riskAnalysis: null,
            incident: null,
            actionItems: [],
            labels: [],
            approvals: [],
            items,
            creator: currentUser,
            passed: true,
            timecard: null,
            private: !!privateAssessment,
            feedbackLike: null,
            feedbackNotes: null,
            workspaceId: null,
            workspace: null,
            previousStates: [],
            documentNumber: documentNumberPlaceholder,
          },
        ],
      };

      client.writeQuery({
        query: inspectionQuery,
        variables: { id: `${uuid}` },
        data: mockResultWithAdditionalFields,
      });

      const inspectionListVariables = {
        creatorId: `${currentUser?.id}`,
      };

      const inspectionsList = await client.readQuery({
        query: inspectionListQuery,
        variables: inspectionListVariables,
      });

      client.writeQuery({
        query: inspectionListQuery,
        variables: inspectionListVariables,
        data: {
          inspections: [
            ...(inspectionsList?.inspections || []),
            mockResultForInspectionList,
          ],
          inspectionsCount: inspectionsList?.inspectionCount + 1 || 1,
        },
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            addInspection: { id: uuid },
          },
        };
        resolve(responseData);
      });
    }
  };

  const updateInspectionTestResults = async (
    inspectionId,
    itemId,
    value,
    failed,
    status,
    failNotes,
    failImage,
    userId,
    additional,
    impact,
    probability,
    questionId,
    inputType,
    vehicleId,
    actionItemId,
  ) => {
    const options = {
      variables: {
        id: itemId,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId: userId ? userId : null,
        additional: additional ? additional : null,
        impact: impact ? impact : 0,
        probability: probability ? probability : 0,
        vehicleId,
        actionItemId,
      },
    };

    if (online) {
      return updateInspectionItemMutation(options);
    } else {
      const uuid = uuidv4().toString();
      const mockResult = {
        __typename: 'InspectionItem',
        id: itemId,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId: userId ? userId : null,
        additional: additional ? additional : null,
        impact: impact ? impact : 0,
        probability: probability ? probability : 0,
        questionId: questionId,
        inputType: inputType,
        categoryId: null,
        bundleId: null,
        vehicleId,
        comments: [],
        vehicle: null,
        actionItemId: null,
      };
      //the last field is empty array because in update we are not createing anythig so we dont need to actually find and delete it.
      await addMutation(
        inspectionItemUpdateMutation,
        options,
        uuid,
        mockResult,
        ['InspectionForInspectionCreation'],
        '',
      );

      const oldData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });
      const vehicle = workspaceVehicles?.find((v) => v.id === vehicleId);

      const newData = produce(oldData, (draft) => {
        if (
          draft.inspections &&
          draft.inspections[0] &&
          draft.inspections[0].items
        ) {
          const foundInspectionItem = draft.inspections[0].items.find(
            (obj) => obj.id === itemId,
          );
          foundInspectionItem.value = value;
          foundInspectionItem.failed = failed;
          foundInspectionItem.status = status;
          foundInspectionItem.failNotes = failNotes ?? null;
          foundInspectionItem.failImage = failImage ?? null;
          foundInspectionItem.additional = additional ? additional : null;
          foundInspectionItem.impact = impact ? impact : 0;
          foundInspectionItem.probability = probability ? probability : 0;
          foundInspectionItem.dateModified = null;
          foundInspectionItem.vehicle = vehicle ?? null;
          foundInspectionItem.actionItem = null;
        }
      });

      await client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });
      //edit the promise later
      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            updateInspectionItem: '',
          },
        };
        resolve(responseData);
      });
    }
  };

  const addInspectionTestResults = async (
    inspectionId,
    questionId,
    value,
    failed,
    status,
    failNotes,
    failImage,
    userId,
    additional,
    impact,
    probability,
    inputType,
    vehicleId,
    parentBundleId,
  ) => {
    const options = {
      variables: {
        inspectionId,
        questionId,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId: userId || null,
        additional,
        impact,
        probability,
        vehicleId,
        parentBundleId,
      },
    };

    if (online) {
      return addInspectionItemMutation(options);
    } else {
      const uuid = uuidv4().toString();
      const mockResult = {
        __typename: 'InspectionItem',
        id: `${uuid}`,
        inspectionId,
        questionId,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId: userId || null,
        additional,
        impact,
        probability,
        inputType: inputType,
        categoryId: null,
        bundleId: null,
        vehicleId,
        vehicle: null,
        comments: [],
        actionItemId: null,
        parentBundleId,
      };
      await addMutation(
        inspectionItemMutation,
        options,
        uuid,
        mockResult,
        ['InspectionForInspectionCreation'],
        'addInspectionItem.id',
      );

      const oldData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });

      const vehicle = workspaceVehicles?.find((v) => v.id === vehicleId);

      const addInspectionItemMock = {
        __typename: 'InspectionItem',
        id: `${uuid}`,
        questionId,
        value,
        failed,
        status,
        failNotes: failNotes ? failNotes : null,
        failImage: failImage ? failImage : null,
        user: null,
        users: null,
        additional: additional ? additional : null,
        impact: impact ? impact : 0,
        probability: probability ? probability : 0,
        dateModified: null,
        bundleId: null,
        categoryId: null,
        comments: [],
        vehicle: vehicle ?? null,
        actionItemId: null,
        parentBundleId: parentBundleId ?? null,
      };

      const newData = produce(oldData, (draft) => {
        draft.inspections[0].items = [
          ...draft.inspections[0].items,
          addInspectionItemMock,
        ];
      });

      await client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            addInspectionItem: addInspectionItemMock,
          },
        };
        resolve(responseData);
      });
    }
  };

  const deleteInspectionItemUsersResults = async (
    userId,
    inspectionId,
    _questionId,
    itemId,
  ) => {
    const options = {
      variables: {
        userId: userId,
        inspectionId: inspectionId,
        questionId: itemId,
      },
    };

    if (online) {
      return deleteInspectionItemUsers(options);
    } else {
      const uuid = uuidv4().toString();
      //instead of mockResults we have null here because when we are deleting there is nothing to delete after we go online so no mock results
      await addMutation(
        deleteInspectionItemUsersMutation,
        options,
        uuid,
        null,
        ['InspectionForInspectionCreation'],
        '',
      );

      const oldData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });

      const newData = produce(oldData, (draft) => {
        if (
          draft.inspections &&
          draft.inspections[0] &&
          draft.inspections[0].items
        ) {
          let foundInspectionItem = draft.inspections[0].items.find(
            (obj) => obj.id === itemId,
          );

          if (foundInspectionItem) {
            foundInspectionItem.users = foundInspectionItem.users.filter(
              (user) => user.id !== userId,
            );
          }
        }
      });

      await client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            deleteInspectionItemUser: {
              id: inspectionId,
              __typename: 'Inspection',
            },
          },
        };
        resolve(responseData);
      });
    }
  };

  const addInspectionItemUsersResults = async (
    userId,
    inspectionId,
    questionId,
    itemId,
  ) => {
    const options = {
      variables: {
        userId: userId,
        inspectionId: inspectionId,
        questionId: questionId,
        inspectionItemId: itemId,
      },
    };

    if (online) {
      return addInspectionItemUsers(options);
    } else {
      //no Mock results as we dont need them cause we are not creating any new item but adding stuff to the one we have
      const uuid = uuidv4().toString();
      await addMutation(
        addInspectionItemUsersMutation,
        options,
        uuid,
        null,
        ['InspectionForInspectionCreation'],
        '',
      );

      const oldData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });

      const newData = produce(oldData, (draft) => {
        if (
          draft.inspections &&
          draft.inspections[0] &&
          draft.inspections[0].items
        ) {
          let foundInspectionItem = draft.inspections[0].items.find(
            (obj) => obj.id === itemId,
          );

          const addedUserFragmentData = client.readFragment({
            id: `User:${userId}`,
            fragment: userFragment,
          });

          if (!foundInspectionItem.users) {
            foundInspectionItem.users = []; // Ensure users array exists
          }

          foundInspectionItem.users.push(addedUserFragmentData);
        }
      });

      await client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            deleteInspectionItemUser: {
              id: inspectionId,
              __typename: 'Inspection',
            },
          },
        };
        resolve(responseData);
      });
    }
  };

  const addImageResults = async (
    description,
    onSubmit,
    file,
    signatureDataText = null,
  ) => {
    if (!signatureDataText) {
      const fileUUID = uuidv4();
      if (online) {
        //not offline and not signature
        uploadImageFile(
          fileUUID,
          file,
          description,
          onSubmit,
          false,
          false,
          null,
          null,
        );
      } else {
        await addOfflineFile(fileUUID, file);
        onSubmit(fileUUID, description);
      }
    } else if (signatureDataText) {
      const fileParts = file.name.split('.');
      const fileName = fileParts[0];

      if (online) {
        //not offline and yes signature
        uploadImageFile(
          fileName,
          file,
          description,
          onSubmit,
          false,
          signatureDataText,
          null,
          null,
        );
      } else {
        //addint it to indexedDB
        await addOfflineFile(fileName, file);
        //submitting it to the update/new Mutation which will handle the writting of the new image url
        const newAdditional = signatureDataText.originalAdditional?.length
          ? signatureDataText.originalAdditional.concat(
              '|' + signatureDataText.signatureText,
            )
          : signatureDataText.signatureText;
        onSubmit(fileName, false, 'PASS', newAdditional);
      }
    }
  };

  const addUploadFileResults = async (
    onSubmit,
    file,
    filename,
    previousAdditional,
  ) => {
    const fileUUID = uuidv4();
    if (online) {
      uploadImageFile(
        fileUUID,
        file,
        null,
        onSubmit,
        false,
        null,
        filename,
        previousAdditional,
      );
    } else {
      //addint it to indexedDB
      await addOfflineFile(fileUUID, file);
      //submitting it to the update/new Mutation which will handle the writting of the new image url
      const newAdditional = previousAdditional
        ? `${previousAdditional}|${filename}`
        : `${filename}`;
      onSubmit({
        url: fileUUID,
        name: newAdditional,
      });
    }
  };

  //Note: The onSubmit function default value is empty function because when we are doing offline we don't need onSubmit.
  //Also same goes for isOfflineImage because its value remains false until you need offline image upload.
  //The onSubmit and isOfflineImage, in any case one of them will have a default value.
  //When onlinerequest then onsubmit will be somehting
  const uploadImageFile = async (
    fileUUID,
    file,
    description,
    onSubmit = () => {},
    isOfflineImage = false,
    signatureDataText = null,
    nonImageFileName = null,
    previousAdditional = null,
  ) => {
    const fileParts = file.name.split('.');
    const fileName = fileUUID;
    const fileType = nonImageFileName
      ? fileParts[fileParts.length - 1]
      : fileParts[1];

    try {
      const { data } = await client.query({
        query: uploadUrlQuery,
        variables: {
          fileName: `assets/${currentUser.company.id}/${fileName}.${fileType}`,
          fileType,
        },
      });

      const signedUrl = data.simpleStorageUploadUrl;
      const options = {
        headers: {
          'Content-Type': fileType === 'pdf' ? 'application/pdf' : fileType,
        },
      };
      const result = await axios.put(signedUrl, file, options);

      if (result.status === 200) {
        const imageUrl = `${CF_DOMAIN(currentUser)}${fileName}.${fileType}`;
        if (signatureDataText) {
          const newAdditional = signatureDataText.originalAdditional?.length
            ? signatureDataText.originalAdditional.concat(
                '|' + signatureDataText.signatureText,
              )
            : signatureDataText.signatureText;
          if (!isOfflineImage) {
            onSubmit(imageUrl, false, 'PASS', newAdditional);
          }
        } else if (nonImageFileName) {
          const newAdditional = previousAdditional
            ? `${previousAdditional}|${nonImageFileName}`
            : `${nonImageFileName}`;
          axios.put(signedUrl, file, options).then((result) => {
            if (result.status === 200) {
              onSubmit({
                url: imageUrl,
                name: newAdditional,
              });
            }
          });
        } else {
          if (!isOfflineImage) {
            onSubmit(imageUrl, description);
          }
        }
        return imageUrl;
      }
    } catch (error) {
      throw new Error(`Upload failed: ${error}`);
    }
  };

  const completeInspection = async (inspectionId) => {
    const options = {
      variables: {
        id: inspectionId,
      },
    };
    if (online) {
      return publishInspection(options);
    } else {
      const uuid = uuidv4().toString();
      const mockResult = {
        __typename: 'InspectionItem',
        id: `${uuid}`,
      };

      await addMutation(
        publishMutation,
        options,
        uuid,
        mockResult,
        ['InspectionForInspectionCreation'],
        '',
      );
      const oldData = await client.readQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
      });
      const newData = produce(oldData, (draft) => {
        if (draft.inspections && draft.inspections[0]) {
          draft.inspections[0].isDraft = false;
          draft.inspections[0].dateCompleted = Date.now();
          draft.inspections[0].completerId = currentUser.id;
          draft.inspections[0].completer = currentUser;
        }
      });

      await client.writeQuery({
        query: inspectionQuery,
        variables: {
          id: inspectionId.toString(),
        },
        data: newData,
      });
    }
  };

  const uploadAudioFile = async ({
    fileUUID,
    blob,
    onSubmit = () => {},
    transcriptRef = null,
    recordingName = null,
    setFile = () => {},
    setLoading = () => {},
  }) => {
    setLoading(true);
    if (!blob.size) {
      console.error('Blob is empty');
      return;
    }

    const fileType = blob.type;
    const typeData = mimeDatabase[fileType.split(';')[0]];
    const extension =
      typeData.extensions[0] === 'weba' ? 'webm' : typeData.extensions[0];

    const fileName = `assets/${currentUser?.company?.id}/${fileUUID}.${extension}`;
    const URL = `${CF_DOMAIN(currentUser)}${fileUUID}.${extension}`;

    try {
      const { data } = await client.query({
        query: uploadUrlQuery,
        variables: {
          fileName,
          fileType,
        },
      });

      const signedUrl = data.simpleStorageUploadUrl;
      if (!signedUrl) {
        console.error('Failed to retrieve signed URL');
        return;
      }

      const uploadOptions = {
        headers: {
          'Content-Type': blob.type,
        },
      };
      await axios.put(signedUrl, blob, uploadOptions);
      onSubmit(transcriptRef.current, URL);
      setFile((prevFile) => ({
        url: prevFile?.url ? `${prevFile.url}|${URL}` : URL,
        name: prevFile?.name
          ? `${prevFile.name}|${recordingName}`
          : recordingName,
      }));
      setLoading(false);
      return URL;
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const addAudioFileResults = async ({
    file,
    transcriptRef,
    onSubmit,
    setFile,
    setLoading,
    recordingName,
  }) => {
    const fileUUID = uuidv4();

    const audioFileDetails = {
      blob: file,
      fileUUID,
      onSubmit,
      transcriptRef,
      recordingName,
      setFile,
      setLoading,
    };

    if (online) {
      uploadAudioFile(audioFileDetails);
    } else {
      setLoading(true);
      if (!file.size) {
        console.error('Blob is empty');
        return;
      }
      await addOfflineFile(fileUUID, file);
      onSubmit(transcriptRef.current, fileUUID);
      setFile((prevFile) => ({
        url: prevFile?.url ? `${prevFile.url}|${fileUUID}` : fileUUID,
        name: prevFile?.name
          ? `${prevFile.name}|${recordingName}`
          : recordingName,
      }));
      setLoading(false);
    }
  };

  return {
    addInspection,
    addInspectionTestResults,
    updateInspectionTestResults,
    deleteInspectionItemUsersResults,
    addInspectionItemUsersResults,
    addImageResults,
    uploadImageFile,
    addUploadFileResults,
    completeInspection,
    updateNotes,
    addAudioFileResults,
    uploadAudioFile,
    updateBundle,
    removeBundle,
    updateAsset,
  };
};

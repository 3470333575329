import React, { useMemo } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import styles from './Statistics.module.scss';
import { observationKPIs } from '../../../utilities/analytics';
import StatBox from './StatBox';
import { differenceInDays, subDays } from 'date-fns';

const query = loader('./ObservationStatistics.graphql');

function Statistics({
  workspaceIds,
  startDate,
  endDate,
  prevEnd,
  prevStart,
  info,
}) {
  const additionalFilters = useMemo(() => {
    let filters = info.additionalFilters || [];
    return filters;
  }, [info.additionalFilters]);

  const { data: { observationCount = 0 } = {} } = useQuery(query, {
    variables: {
      workspaceIds,
      options: {
        filters: [
          ...additionalFilters,
          {
            field: 'dateCreated',
            operator: 'between',
            value: [startDate, endDate],
          },
        ],
      },
    },
  });
  const { data: { observationCount: previousData = 0 } = {} } = useQuery(
    query,
    {
      variables: {
        workspaceIds,
        options: {
          filters: [
            ...additionalFilters,
            {
              field: 'dateCreated',
              operator: 'between',
              value: [prevStart, prevEnd],
            },
          ],
        },
      },
    },
  );

  const stat = observationCount > 0 ? observationCount - previousData : null;
  return (
    <StatBox kpi={info} value={observationCount} stat={stat} float={false} />
  );
}

export default function ObservationStatistics({ start, end, workspaceIds }) {
  const daysInRange = differenceInDays(new Date(end), new Date(start));
  const previousEnd = subDays(new Date(start), 1);
  const previousStart = subDays(previousEnd, daysInRange);
  return (
    <div className={styles.container}>
      {observationKPIs.map((kpi) => {
        return (
          <Statistics
            key={kpi.id}
            startDate={start}
            endDate={end}
            workspaceIds={workspaceIds}
            info={kpi}
            prevEnd={previousEnd}
            prevStart={previousStart}
          />
        );
      })}
    </div>
  );
}

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { keys, languages } from './utilities/translator/translation_keys';
import pjson from '../package.json';
import moment from 'moment';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${pjson.version}`,
    },
    detection: {
      order: ['navigator'],
    },
    ns: Object.keys(keys),
    defaultNS: 'common',
    fallbackLng: 'en',
    debug: false,
    whiteList: Object.keys(languages),
    load: 'languageOnly',
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;

import styles from './IncidentReport.module.scss';
import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { defaultNavbarState, navbarVar } from '../../graphql/cache/navbar';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import {
  adminAviationOptions,
  aviationResponseTime,
  incidentDetailQuestions,
} from '../../utilities/incidents';
import Accordian from '../../components/Accordian';
import { Button } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  assessmentTypes,
  incidentStatus,
  paths,
} from '../../constants/strings';
import { showToast, signatureModalVar } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import IncidentSidebar from './IncidentSidebar';
import { Table } from 'react-bootstrap';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getDaysFromNow, getLocalTime } from '../../utilities/time';
import Label from '../../components/action_items/Label';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import FormQuestion from '../../components/forms/FormQuestion';
import { useActionItemModal, useIsMobile } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { incidentTypes } from '../../utilities/incidents';
import IncidentImageCard from './IncidentImageCard';
import SimpleFileCard from '../../components/files/SimpleFileCard';
import { imageExtensions } from '../../utilities/files';
import moment from 'moment';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useIncidents } from '../../hooks/offline-hooks/incidentHook';
import { calculateRisk } from '../../utilities/riskMatrix';
import { addDays } from 'date-fns';
import { IncidentReportAviation } from './IncidentReportAviation';
import Notes from '../../components/notes/Notes';
import IncidentRequiredComments from './IncidentRequiredComments';
import RiskMatrix from '../../components/RiskMatrix';
import TextWithIcon from '../../components/typography/TextWithIcon';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { pastDue } from '../../utilities/audits';

const incidentQuery = loader('./IncidentReport.fetch.graphql');
const publishMutation = loader('./IncidentReport.publish.graphql');
const addIncidentImageMutation = loader('./IncidentReport.addImage.graphql');
const incidentInspectionQuery = loader('./IncidentReport.inspections.graphql');
const actionItemQuery = loader('./IncidentReport.actionItems.graphql');
const addActionItemMutation = loader('./IncidentReport.addActionItem.graphql');
const incidentImageQuery = loader('./IncidentReport.getImages.graphql');
const deleteIncidentImageMutation = loader(
  './IncidentReport.deleteImage.graphql',
);
const reviewerIncidentMutation = loader('./IncidentReport.review.graphql');
const deleteIncidentMutation = loader('./IncidentSidebar.delete.graphql');
const approvedStatuses = [
  incidentStatus.investigation,
  incidentStatus.complete,
];

const notesStatuses = [
  incidentStatus.complete,
  incidentStatus.invalid,
  incidentStatus.closed,
  incidentStatus.investigation,
];

export default function IncidentReport() {
  const { id } = useParams();
  const { workspace, workspaceId, observationTemplate } = useWorkspace();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [publishIncident] = useMutation(publishMutation);
  const [addIncidentImage] = useMutation(addIncidentImageMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const { updateIncident } = useIncidents();
  const [reviewIncident] = useMutation(reviewerIncidentMutation);
  const [deleteIncident] = useMutation(deleteIncidentMutation);
  const maxLabels = 3;
  const { user, isAdmin, incidentName } = useCurrentUser();
  const { openModal, openConfirmationModal } = useModal();
  const { t } = useTranslation();
  const incidentId = parseInt(id);
  const [deleteIncidentImage] = useMutation(deleteIncidentImageMutation);
  const isOnline = useOnlineStatus();
  const [loadingImages, setLoadingImages] = useState(false);
  const [notes, setNotes] = useState({});
  const [reviewerComment, setReviewerComment] = useState(null);

  const [expand, setExpand] = useState(true);
  useEffect(() => {
    navbarVar({ ...defaultNavbarState, showMenu: false });
  }, []);

  const {
    refetch: refetchIncident,
    data: { incidents: [incident] = [{}] } = {},
  } = useQuery(incidentQuery, {
    skip: !id,
    fetchPolicy: isOnline ? 'cache-and-network' : 'cache-only',
    variables: {
      id: `${id}`,
    },
  });

  const { data: { inspections = [] } = {} } = useQuery(
    incidentInspectionQuery,
    {
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        options: {
          filters: [
            {
              field: 'incidentId',
              operator: 'eq',
              value: [`${id}`],
            },
          ],
        },
      },
    },
  );
  const {
    loading,
    refetch: refetchImages,
    data: { incidentImages } = {},
  } = useQuery(incidentImageQuery, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      incidentId: `${id}`,
    },
  });

  const { refetch: refetchActionItems, data: { actionItems = [] } = {} } =
    useQuery(actionItemQuery, {
      skip: !id,
      variables: {
        incidentId: `${id}`,
        actionItemType: 'INCIDENT',
      },
    });
  const [title, setTitle] = useState(incident?.title);

  useEffect(() => {
    setTitle(incident?.title);
  }, [incident.title]);

  const submitDisabled = useMemo(() => {
    return (
      !incident.description ||
      !incident.location ||
      !incident.dateOfIncident ||
      !incident.title ||
      !incident.initialImpact ||
      !incident.initialProbability
    );
  }, [
    incident.dateOfIncident,
    incident.description,
    incident.initialImpact,
    incident.initialProbability,
    incident.location,
    incident.title,
  ]);

  const closeDisabled = useMemo(() => {
    return (
      !!inspections?.map((i) => i.isDraft)?.includes(true) ||
      !!incident.reviewers
        ?.map((r) => r.status === incidentStatus.commentRequired)
        .includes(true) ||
      !isAdmin
    );
  }, [incident.reviewers, inspections, isAdmin]);

  const isMobile = useIsMobile();

  const { observation } = incident || {};

  const combinedImages = [
    ...(observation?.images || []),
    ...(incidentImages || []),
  ];

  const isImageFile = (extension) => {
    return imageExtensions.includes(extension);
  };

  const allFiles = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return !isImageFile(extension);
  });

  const allImages = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return isImageFile(extension);
  });

  const risk = useMemo(() => {
    const { impact, initialImpact, probability, initialProbability } =
      incident || {};
    let value = null;
    let string = null;
    let dueDate = null;
    const i = impact || initialImpact;
    const p = probability || initialProbability;

    if (!isNaN(i) && !isNaN(p)) {
      value = i * p;
      string = calculateRisk(value)?.level;
    }

    const days = aviationResponseTime[string] || 28;
    const now = new Date();
    dueDate = addDays(now, days);

    return { value, string, dueDate };
  }, [incident]);

  const readOnly = incident.status !== incidentStatus.initiated || !isAdmin;

  const [isPrivate, setIsPrivate] = useState(incident.private);

  useEffect(() => {
    if (incident) {
      setIsPrivate(incident.private);
    }
  }, [incident]);

  const [selectedUsers, setSelectedUsers] = useState(
    incident?.reviewers
      ?.filter(
        ({ status }) =>
          status === incidentStatus.commentRequired ||
          status === incidentStatus.commentSubmitted,
      )
      .filter(Boolean)
      .map(({ user }) => user) ?? [],
  );

  const requiredComments = incident.reviewers?.filter(
    (r) =>
      r.status === incidentStatus.commentRequired ||
      r.status === incidentStatus.commentSubmitted,
  );

  const requiredQuestionStatus = useMemo(() => {
    const total = requiredComments?.length || 0;
    const complete = requiredComments?.filter((r) => !!r.notes).length;
    return incident.status === incidentStatus.initiated
      ? selectedUsers.length
      : !!total
      ? `${complete}/${total}`
      : 0;
  }, [incident.status, requiredComments, selectedUsers.length]);

  const reviewer = incident?.reviewers?.find(
    (f) => f.status === incidentStatus.investigation,
  );

  const [isEditingRisk, setIsEditingRisk] = useState(
    incident.status === incidentStatus.initiated,
  );
  const incidentRisk = useMemo(() => {
    const { initialImpact, initialProbability, impact, probability } = incident;

    return {
      impact: impact || initialImpact,
      probability: probability || initialProbability,
    };
  }, [incident]);

  const closerComment =
    incident.reviewers?.find((f) => f.status === incidentStatus.complete)
      ?.notes || null;

  const [lostTimeHrs, setLostTimeHrs] = useState(incident.lostTimeHrs);
  const [downTimeHrs, setDownTimeHrs] = useState(incident.downTimeHrs);
  const isAviation = incident.type === 'Aviation';

  const [aviationQuestions, setAviationQuestions] = useState(null);

  useEffect(() => {
    const questions = incident?.aviationQuestions || {};
    if (aviationQuestions === null && isAviation) {
      setAviationQuestions({ ...questions });
    }
  }, [isAviation, aviationQuestions, incident?.aviationQuestions]);

  const handleCheckboxChange = (key, checked) => {
    if (incident.status === incidentStatus.complete.key) {
      return;
    }
    const updatedQuestions = {
      ...aviationQuestions,
      [key]: checked,
    };

    setAviationQuestions({ ...updatedQuestions });
    const variables = {
      aviationQuestions: updatedQuestions,
      id: incident.id,
    };
    if (key === 'tsb') {
      variables['reportable'] = checked;
    }
    updateIncident({ variables });
  };
  const [isEditingTitle, setIsEditingTitle] = useState(
    incident.status === incidentStatus.initiated && !title,
  );

  return (
    <div className={styles.split}>
      <div
        className={classNames([
          styles.container,
          sidebarOpen && isMobile && styles.hide,
        ])}
      >
        {!!incident?.id && (
          <div>
            <div className={styles.header}>
              <div className={styles.titleIcon}>
                <TextWithIcon
                  size="lg"
                  weight="bold"
                  noMargin
                  icon="fmd_bad"
                  iconProps={{ color: 'red' }}
                >
                  {`${
                    user.company?.incidentName || t(keys.common.INCIDENT)
                  } Report`}
                </TextWithIcon>
                <div
                  onClick={() => {
                    setSidebarOpen(true);
                  }}
                  className={classNames([styles.hideDesktop])}
                >
                  <Icon baseClassName="material-icons-outlined">
                    more_horiz
                  </Icon>
                </div>
              </div>
              {isAdmin && incident.status !== 'INITIATED' ? (
                <div>
                  <div className={styles.private}>
                    <Form.Check
                      checked={isPrivate}
                      onChange={() => {
                        const description = `Are you sure you want to make this incident ${
                          isPrivate ? 'public' : 'private'
                        }? This will ${
                          !isPrivate
                            ? 'allow only admins and participants '
                            : 'allow all users '
                        }to view this incident.`;
                        openConfirmationModal({
                          title: 'Change Incident Visibility',
                          description,
                          variant: 'danger',
                          onSubmit: () => {
                            updateIncident({
                              variables: {
                                id: incident.id,
                                private: !isPrivate,
                              },
                            });
                            setIsPrivate(!isPrivate);
                          },
                        });
                      }}
                    />
                    <Text noMargin weight="semiBold">
                      {t(keys.action_items.PRIVATE_TITLE)}
                    </Text>
                  </div>
                  <Text size="sm" color="secondary" textAlign="right" noMargin>
                    {!!incident.private
                      ? t(keys.action_items.PRIVATE_TEXT)
                      : t(keys.action_items.PUBLIC)}
                  </Text>
                </div>
              ) : null}
            </div>
            <div className={styles.marginLeft}>
              <TextWithIcon
                hover
                weight="semiBold"
                color="accentPrimary"
                textAlign="right"
                icon={expand ? 'expand_less' : 'expand_more'}
                size="sm"
                noMargin
                onClick={() => setExpand(!expand)}
              >
                {!expand ? 'SHOW' : 'HIDE'}
              </TextWithIcon>
            </div>
            <div className={styles.titleContainer}>
              {isEditingTitle ? (
                <div style={{ width: '100%' }}>
                  <Form.Control
                    value={title || ''}
                    placeholder={t(keys.action.ENTER, {
                      variable: t(keys.common.TITLE),
                    })}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() =>
                      updateIncident({
                        variables: { id: incidentId, title },
                      }).then(() => refetchIncident())
                    }
                  />
                  <div className={styles.row}>
                    <DropdownText
                      required={true}
                      highlight
                      placeholder={t(keys.incidents.TYPE)}
                      selected={incident.type}
                      onChange={(newType) =>
                        updateIncident({
                          variables: {
                            id: incidentId,
                            type: newType,
                            subtype: '',
                          },
                        }).then(() => refetchIncident())
                      }
                      items={Object.keys(incidentTypes)}
                    />
                    <DropdownText
                      highlight
                      placeholder={t(keys.incidents.SUBTYPE)}
                      required={true}
                      selected={incident.subtype}
                      onChange={(newType) =>
                        updateIncident({
                          variables: { id: incidentId, subtype: newType },
                        }).then(() => refetchIncident())
                      }
                      items={incidentTypes[incident.type]}
                      disabled={!incident.type}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <Text
                    noMargin
                    weight="bold"
                    size="lg"
                    color={!!incident.title ? 'primary' : 'red'}
                  >
                    {incident.title || 'No Title*'}
                  </Text>
                  <Text noMargin weight="bold" size="md" color="secondary">
                    {`${
                      t(getTranslationKey(incident?.type, 'incidents')) ||
                      incident?.type
                    } `}
                  </Text>
                  <Text
                    noMargin
                    weight="bold"
                    size="sm"
                    color={incident?.subtype ? 'secondary' : 'red'}
                  >
                    {incident.subtype
                      ? ` ${
                          t(
                            getTranslationKey(incident?.subtype, 'incidents'),
                          ) || incident?.subtype
                        }`
                      : 'Subtype Required**'}
                  </Text>
                </div>
              )}
              {!readOnly ? (
                <Icon
                  onClick={() => setIsEditingTitle(!isEditingTitle)}
                  baseClassName="material-icons-outlined"
                  style={{ opacity: 0.5, cursor: 'pointer' }}
                >
                  {isEditingTitle ? 'save' : 'edit'}
                </Icon>
              ) : null}
            </div>
            <div className={styles.spacer} />
            <div className={styles.bottomContainer}>
              {!!incident?.id ? (
                <Accordian
                  expand={expand}
                  icon="summarize"
                  button={true}
                  title={t(keys.common.INFORMATION)}
                  message={
                    incident?.status === 'INITIATED'
                      ? t(keys.common.IN_PROGRESS)
                      : t(keys.common.COMPLETE)
                  }
                >
                  <div className={styles.content}>
                    {incidentDetailQuestions.map((question) => (
                      <FormQuestion
                        key={question.id}
                        question={question}
                        objectKey={question.convertToQuery}
                        value={incident[question.convertToQuery]}
                        update={updateIncident}
                        parentId={incident.id}
                        readOnly={readOnly}
                        legacyInput={question.legacyInput}
                      />
                    ))}
                  </div>
                </Accordian>
              ) : null}
              {isAviation ? (
                <>
                  <Accordian
                    title="Aviation Details"
                    icon="flight"
                    expand={expand}
                  >
                    <IncidentReportAviation
                      incident={incident}
                      readOnly={readOnly}
                    />
                  </Accordian>
                </>
              ) : null}
              <Accordian
                expand={expand}
                icon="image"
                title={`${t(keys.common.IMAGES)}/${t(keys.common.FILES)}`}
                message={combinedImages?.length || 0}
                button={true}
                showOnClick={incident?.status === incidentStatus.investigation}
                onClick={
                  incident?.status !== 'COMPLETE'
                    ? () => {
                        openModal({
                          modalName: modals.fileUpload,
                          variables: {
                            simple: true,
                            onLoading: () => setLoadingImages(true),
                            onSubmit: ({ url, name }) => {
                              setLoadingImages(true);
                              addIncidentImage({
                                variables: {
                                  incidentId: incident.id,
                                  description: name,
                                  type: incident.status,
                                  imageUrl: url,
                                },
                              }).then(() => {
                                refetchImages();
                                setLoadingImages(false);
                              });
                            },
                          },
                        });
                      }
                    : false
                }
              >
                <div className={styles.content}>
                  {loadingImages || loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : !!combinedImages?.length ? (
                    <div>
                      <div className={styles.outerImagesContainer}>
                        {allFiles?.map((f, index) => {
                          const extension = f.imageUrl
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const file = {
                            ...f,
                            fileType: extension,
                            url: f.imageUrl,
                            downloadAllowed: true,
                            creator: !!f.creator
                              ? f.creator
                              : observation?.creator,
                          };
                          return (
                            <SimpleFileCard
                              key={f.id}
                              extension={extension}
                              file={file}
                              readOnly={
                                f.__typename === 'ObservationImage' ||
                                incident.status === incidentStatus.complete
                              }
                              fileName={
                                file.description ??
                                `${extension.toUpperCase()}-observation-attachment-${index}`
                              }
                              onDelete={() => {
                                openConfirmationModal({
                                  title: t(keys.action.DELETE_VARIABLE, {
                                    variable: t(keys.common.IMAGE),
                                  }),
                                  description: t(
                                    keys.action.CONFIRMATION_MESSAGE,
                                    {
                                      variable: t(keys.action.DELETE_VARIABLE, {
                                        variable: t(keys.common.IMAGE),
                                      }),
                                    },
                                  ),
                                  variant: 'danger',
                                  onSubmit: () => {
                                    deleteIncidentImage({
                                      variables: { id: file.id },
                                    }).then(() => refetchImages());
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className={styles.outerImagesContainer}>
                        {allImages?.map((image, index) => {
                          const extension = image.imageUrl
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const file = {
                            ...image,
                            fileType: extension,
                            url: image.imageUrl,
                            creator: !!image.creator
                              ? image.creator
                              : observation?.creator,
                          };
                          return (
                            <IncidentImageCard
                              file={file}
                              key={`${image}-${index}`}
                              refetch={refetchImages}
                              readOnly={
                                image.__typename === 'ObservationImage' ||
                                incident.status === incidentStatus.complete
                              }
                              onDelete={() => {
                                openConfirmationModal({
                                  title: t(keys.action.DELETE_VARIABLE, {
                                    variable: t(keys.common.IMAGE),
                                  }),
                                  description: t(
                                    keys.action.CONFIRMATION_MESSAGE,
                                    {
                                      variable: t(keys.action.DELETE_VARIABLE, {
                                        variable: t(keys.common.IMAGE),
                                      }),
                                    },
                                  ),
                                  variant: 'danger',
                                  onSubmit: () => {
                                    deleteIncidentImage({
                                      variables: { id: file.id },
                                    }).then(() => refetchImages());
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Accordian>
              <Accordian
                title="Risk Assessment"
                icon="grading"
                expand={expand}
                message={requiredQuestionStatus}
                button={true}
              >
                <div>
                  <div className={styles.header}>
                    <div />
                    {incident.status === incidentStatus.investigation && (
                      <Icon
                        baseClassName="material-icons-outlined"
                        onClick={() => setIsEditingRisk(!isEditingRisk)}
                        sx={{
                          fontSize: '1.5rem',
                          cursor: 'pointer',
                          opacity: '0.5',
                        }}
                      >
                        {!isEditingRisk ? 'edit' : 'check'}
                      </Icon>
                    )}
                  </div>
                  <div className={styles.spacer} />
                  <RiskMatrix
                    impact={incidentRisk?.impact}
                    probability={incidentRisk?.probability}
                    readOnly={
                      incident.status === incidentStatus.initiated
                        ? false
                        : !isEditingRisk
                    }
                    onChange={(i, p) => {
                      if (incident.status === incidentStatus.initiated) {
                        updateIncident({
                          variables: {
                            id: incident.id,
                            initialImpact: i,
                            initialProbability: p,
                          },
                        });
                      } else {
                        updateIncident({
                          variables: {
                            id: incident.id,
                            impact: i,
                            probability: p,
                          },
                        });
                      }
                    }}
                  />
                  <br />
                  {isAviation && aviationQuestions && (
                    <div>
                      <div className={styles.sectionLine} />
                      <Text
                        weight="semiBold"
                        color={
                          isEditingRisk ||
                          incident.status === incidentStatus.initiated
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        {isEditingRisk ||
                        incident.status === incidentStatus.initiated
                          ? 'Select all that apply'
                          : 'Actions Required'}
                      </Text>

                      <div className={styles.flexSmall}>
                        {Object.entries(adminAviationOptions).map(
                          ([key, value]) =>
                            isEditingRisk ||
                            incident.status === incidentStatus.initiated ? (
                              <Form.Check
                                key={`${key}${value}`}
                                label={value}
                                name={key}
                                type="checkbox"
                                checked={!!aviationQuestions[key]}
                                onChange={(e) =>
                                  handleCheckboxChange(key, e.target.checked)
                                }
                              />
                            ) : (
                              <div className={styles.flexSmall} key={key}>
                                <Icon
                                  baseClassName="material-icons-outlined"
                                  sx={{
                                    fontSize: '1.2rem',
                                    color: !!aviationQuestions[key]
                                      ? 'green'
                                      : 'red',
                                  }}
                                >
                                  {!!aviationQuestions[key]
                                    ? 'check_circle'
                                    : 'cancel'}
                                </Icon>
                                <Text weight="semiBold" noMargin key={key}>
                                  {value}
                                </Text>
                              </div>
                            ),
                        )}
                      </div>
                      <div className={styles.spacer} />
                      {incident.reportable ? (
                        <div className={styles.contactCard}>
                          <Text noMargin size="sm" color="secondary">
                            Contact Information
                          </Text>
                          <div className={styles.flex}>
                            <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                              link
                            </Icon>
                            <Text
                              noMargin
                              weight="semiBold"
                              color="accentPrimary"
                              hover
                              onClick={() =>
                                window.open(
                                  'https://www.tsb.gc.ca/eng/form/air-transportation-occurrence-re',
                                )
                              }
                            >
                              Transportation Safety Board
                            </Text>
                            <Icon>right_arrow</Icon>
                          </div>
                          <div className={styles.flex}>
                            <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                              phone
                            </Icon>
                            <Text weight="semiBold" noMargin>
                              819-994-3741 or 1-800-387-3557
                            </Text>
                          </div>
                        </div>
                      ) : null}
                      <div className={styles.sectionLine} />
                    </div>
                  )}
                  {incident?.status === incidentStatus.initiated ? (
                    <div>
                      <Text noMargin weight="bold">
                        Reviewer Comments
                      </Text>
                      <div className={styles.spacer} />
                      <Form.Control
                        as="textarea"
                        onChange={(e) => setReviewerComment(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div>
                      <Text noMargin weight="semiBold" color="secondary">
                        Reviewer Comments
                      </Text>
                      <div className={styles.comment}>
                        <SimpleUserCard
                          smallText={getLocalTime(
                            reviewer?.dateModified,
                          ).format('ddd MMM DD YYYY, HH:mm')}
                          largeAvatar
                          user={reviewer?.user}
                        />
                        <Text noMargin>{reviewer?.notes || 'No Comment'}</Text>
                      </div>
                    </div>
                  )}
                </div>
                {!!requiredComments?.length && (
                  <>
                    <div className={styles.sectionLine} />
                    <div className={styles.header}>
                      <Text weight="semiBold" color="secondary" noMargin>
                        Required Comments
                      </Text>
                      <div>
                        {incident.dueDate && (
                          <div>
                            <Text
                              noMargin
                              weight="semiBold"
                              textAlign="right"
                              color={
                                !!pastDue(incident?.dueDate) ? 'red' : 'primary'
                              }
                            >
                              {getDaysFromNow(incident?.dueDate)}
                            </Text>
                            <Text
                              size="sm"
                              color="secondary"
                              noMargin
                            >{`${getLocalTime(incident.dueDate).format(
                              'dddd, MMMM Do YYYY HH:mm',
                            )}`}</Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <IncidentRequiredComments
                  incident={incident}
                  onChange={setReviewerComment}
                  onUserAdded={(user) => {
                    setSelectedUsers([...selectedUsers, user]);
                  }}
                  onUserRemoved={(user) => {
                    setSelectedUsers(
                      selectedUsers.filter((u) => u.id !== user.id),
                    );
                  }}
                  selectedUsers={selectedUsers}
                  readOnly={incident.status !== incidentStatus.initiated}
                  requiredComments={requiredComments}
                />
                <div className={styles.spacer} />
              </Accordian>

              {approvedStatuses.includes(incident.status) && (
                <>
                  <Accordian
                    button={true}
                    icon="content_paste"
                    title={t(keys.incidents.INVESTIGATION_FORMS)}
                    message={inspections?.length}
                    showOnClick={
                      incident.status === incidentStatus.investigation
                    }
                    onClick={
                      incident.status === incidentStatus.complete
                        ? false
                        : () => {
                            openModal({
                              modalName: modals.createAssessment,
                              variables: {
                                types: [assessmentTypes.incident],
                                title: t(
                                  keys.incidents.START_INCIDENT_ASSESSMENT,
                                ),
                                incidentId: incident.id,
                                type: assessmentTypes.incident,
                                participants: [user],
                              },
                            });
                          }
                    }
                  >
                    <div className={styles.content}>
                      {inspections?.length ? (
                        <div className={styles.inspectionList}>
                          <br />
                          <Table striped bordered hover>
                            <thead style={{ zIndex: 1 }}>
                              <tr>
                                <th>
                                  <Text
                                    size="sm"
                                    weight="semiBold"
                                    noMargin
                                    noSelect
                                  >
                                    {t(keys.common.TITLE)}
                                  </Text>
                                </th>
                                <th>
                                  <Text
                                    size="sm"
                                    weight="semiBold"
                                    noMargin
                                    noSelect
                                  >
                                    {t(keys.common.CREATOR)}
                                  </Text>
                                </th>
                                <th className={styles.hideOnMobile}>
                                  <Text
                                    size="sm"
                                    weight="semiBold"
                                    noMargin
                                    noSelect
                                  >
                                    {t(keys.common.LABELS)}
                                  </Text>
                                </th>
                                <th>
                                  <Text
                                    size="sm"
                                    weight="semiBold"
                                    noMargin
                                    noSelect
                                  >
                                    {t(keys.common.DATE_CREATED)}
                                  </Text>
                                </th>
                                <th>
                                  <Text
                                    size="sm"
                                    weight="semiBold"
                                    noMargin
                                    noSelect
                                  >
                                    {t(keys.common.STATUS)}
                                  </Text>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {inspections?.map((inspection) => (
                                <tr
                                  key={inspection.id}
                                  onClick={() =>
                                    navigate(
                                      getRoute(
                                        workspace?.id,
                                        paths.assessment,
                                        inspection?.id,
                                      ),
                                    )
                                  }
                                >
                                  <td>
                                    <Text size="sm" noMargin noSelect>
                                      {inspection?.template?.title}
                                    </Text>
                                  </td>
                                  <td>
                                    <div className={styles.creator}>
                                      <SimpleUserCard
                                        size="sm"
                                        user={inspection?.creator}
                                        hideAvatar
                                      />
                                    </div>
                                  </td>
                                  <td className={styles.hideOnMobile}>
                                    <div className={styles.labelContainer}>
                                      {inspection?.labels
                                        .slice(0, maxLabels)
                                        .map((label) => (
                                          <Label
                                            key={label.id}
                                            className={styles.label}
                                            name={label.name}
                                            color={label.color.toLowerCase()}
                                          />
                                        ))}
                                      {inspection.labels?.length >
                                        maxLabels && (
                                        <Text
                                          size="sm"
                                          weight="semiBold"
                                          noMargin
                                          color="accentPrimary"
                                        >
                                          {`+ ${
                                            inspection.labels.length - maxLabels
                                          }`}
                                        </Text>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <Text
                                      size="xs"
                                      noMargin
                                      noVerticalMargin
                                      noSelect
                                    >
                                      {getLocalTime(
                                        inspection?.dateCreated,
                                      ).format('MM/DD/YY')}
                                    </Text>
                                  </td>
                                  <td className={styles.labelContainer}>
                                    {inspection?.isDraft ? (
                                      <Label
                                        className={styles.label}
                                        color="yellow"
                                        name={t(keys.common.IN_PROGRESS)}
                                      />
                                    ) : (
                                      <Label
                                        className={styles.label}
                                        color="green"
                                        name={t(keys.common.COMPLETE)}
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : null}
                    </div>
                  </Accordian>
                  <Accordian
                    icon="task_alt"
                    title={t(keys.common.ACTION_ITEMS)}
                    message={actionItems?.length}
                    button={true}
                    showOnClick={
                      incident.status === incidentStatus.investigation
                    }
                    onClick={
                      incident.status === incidentStatus.complete
                        ? false
                        : () => {
                            addActionItem({
                              variables: {
                                title: incident.title,
                                description: '',
                                type: 'INCIDENT',
                                incidentId: incident.id,
                                workspaceId: workspace.id,
                                private: true,
                              },
                            }).then(
                              ({
                                data: {
                                  addActionItem: { id },
                                },
                              }) => {
                                openActionItemModal(
                                  id,
                                  'INCIDENT',
                                  refetchActionItems,
                                );
                              },
                            );
                          }
                    }
                  >
                    {actionItems?.map((a) => (
                      <ActionItemCard
                        key={a.id}
                        actionItem={a}
                        boxShadow={false}
                        maxLabels={4}
                        showName={true}
                        className={styles.actionItemCard}
                        onClick={() =>
                          openActionItemModal(a.id, null, refetchActionItems)
                        }
                      />
                    ))}
                  </Accordian>
                </>
              )}
              {incident.status === incidentStatus.initiated && isAdmin && (
                <>
                  <div className={styles.buttons}>
                    <Button
                      variant="secondary"
                      size="md"
                      icon="cancel"
                      value={t(keys.action.DISMISS)}
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.DISMISS_VARIABLE, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          description: t(keys.action.DISMISS_CONFIRMATION, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          variant: 'danger',
                          buttonText: t(keys.action.DISMISS),
                          onSubmit: () => {
                            reviewIncident({
                              variables: {
                                incidentId: incident.id,
                                status: incidentStatus.invalid,
                              },
                            }).then(() => deleteIncident({ id: incident.id }));
                          },
                        });
                      }}
                    />
                    <Button
                      variant="danger"
                      size="md"
                      value={t(keys.action.DEESCALATE)}
                      icon="arrow_circle_down"
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.DEESCALATE_VARIABLE, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          description: t(keys.action.DEESCALATE_CONFIRMATION, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          variant: 'warning',
                          buttonText: t(keys.action.DEESCALATE),
                          onSubmit: () => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.closed,
                              },
                            });
                            reviewIncident({
                              variables: {
                                incidentId: incident.id,
                                status: incidentStatus.closed,
                              },
                            });
                          },
                        });
                      }}
                    />
                    <Button
                      disabled={submitDisabled}
                      variant="success"
                      size="md"
                      icon="check_circle"
                      value={t(keys.action.APPROVE)}
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.CONFIRM_CHANGES),
                          description: t(keys.action.PUBLISH_CONFIRMATION),
                          variant: 'warning',
                          buttonText: t(keys.action.APPROVE),
                          onSubmit: () => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.investigation,
                                aviationQuestions,
                                dueDate:
                                  incident.type === 'Aviation'
                                    ? risk.dueDate
                                    : undefined,
                              },
                            }).then(() => {
                              reviewIncident({
                                variables: {
                                  incidentId: incident.id,
                                  status: incidentStatus.investigation,
                                  notes: reviewerComment || 'No Comment',
                                },
                              }).then(() => {
                                const dueDate = risk?.dueDate || undefined;
                                const priority = risk?.string?.toUpperCase();
                                for (let user of selectedUsers) {
                                  reviewIncident({
                                    variables: {
                                      status: incidentStatus.commentRequired,
                                      userId: user?.id,
                                      incidentId,
                                    },
                                  }).then(() => {
                                    addActionItem({
                                      variables: {
                                        title: `Feedback required for ${incident.externalId}`,
                                        description: incident.description,
                                        type: 'INCIDENT_COMMENT',
                                        incidentId: incident.id,
                                        workspaceId: workspace.id,
                                        dueDate,
                                        priority,
                                        assigneeId: user.id,
                                        private: true,
                                      },
                                    });
                                  });
                                }
                                refetchIncident();
                              });
                              setIsEditingTitle(false);
                              setIsEditingRisk(false);
                            });
                          },
                        });
                      }}
                    />
                  </div>
                  <Text
                    noMargin
                    weight="semiBold"
                    size="sm"
                    color="secondaryLight"
                    textAlign="right"
                  >
                    {submitDisabled
                      ? t(keys.action.SUBMIT_DISABLED_MESSAGE)
                      : ''}
                  </Text>
                </>
              )}
              {notesStatuses.includes(incident.status) && (
                <Accordian title={t(keys.common.NOTES)} icon="text_snippet">
                  <Notes
                    parentId={incidentId}
                    type={incident.__typename}
                    setNotes={setNotes}
                  />
                  <Text noMargin>{incident.notes}</Text>
                </Accordian>
              )}
              {incident.status === incidentStatus.closed &&
                !incident?.observation &&
                incident?.participants
                  .map(({ id }) => id)
                  .includes(user?.id) && (
                  <Button
                    key={`${id}`}
                    className={styles.submitButton}
                    variant="primary"
                    size="md"
                    onClick={() => {
                      openModal({
                        modalName: modals.observation,
                        variables: {
                          participants: [...incident?.participants],
                          workspaceId,
                          templateId: observationTemplate?.id,
                          questions:
                            observationTemplate?.categories[0]?.questions || [],
                          dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                          incidentId: incident?.id,
                        },
                      });
                    }}
                    icon={'content_paste_search'}
                    value={`${t(keys.action.CREATE_VARIABLE, {
                      variable: t(keys.common.OBSERVATION),
                    })}`}
                  />
                )}
              {!!incident?.closer?.id && !!incident?.closerSignatureUrl ? (
                <Accordian title="Closing Details" icon="check_circle">
                  <div className={styles.flex}>
                    <Text noMargin weight="semiBold">
                      Total Downtime (hours)
                    </Text>
                    <Text weight="semiBold" noMargin>
                      {incident.downTimeHrs || 0}
                    </Text>
                  </div>
                  <div className={styles.flex}>
                    <Text textAlign="right" noMargin weight="semiBold">
                      Total Lost Time (hours)
                    </Text>
                    <Text weight="semiBold" noMargin>
                      {incident.lostTimeHrs || 0}
                    </Text>
                  </div>
                  <br />
                  {!!closerComment && <Text>{closerComment}</Text>}
                  <div>
                    <Text noMargin>
                      {t(keys.incidents.INCIDENT_CLOSED_ON, {
                        variable: `${getLocalTime(incident.closedAt).format(
                          'LLLL',
                        )} ${t(keys.common.BY)} ${incident.closer.firstName} ${
                          incident.closer.lastName
                        }`,
                      })}
                    </Text>
                    <img
                      className={styles.signature}
                      alt="signature"
                      src={incident.closerSignatureUrl}
                    />
                  </div>
                </Accordian>
              ) : (
                incident.status === incidentStatus.investigation && (
                  <>
                    <div className={styles.footer}>
                      <div className={styles.flex}>
                        <Text noMargin weight="semiBold">
                          Total Lost Time (hours)
                        </Text>
                        <Form.Control
                          className={styles.number}
                          type="number"
                          value={lostTimeHrs}
                          onChange={(e) =>
                            setLostTimeHrs(parseInt(e.target.value))
                          }
                          onBlur={() =>
                            updateIncident({
                              variables: { id: incident.id, lostTimeHrs },
                            })
                          }
                        />
                        <Text noMargin weight="semiBold">
                          Total Downtime (hours)
                        </Text>
                        <Form.Control
                          value={downTimeHrs}
                          type="number"
                          className={styles.number}
                          onChange={(e) =>
                            setDownTimeHrs(parseInt(e.target.value))
                          }
                          onBlur={() =>
                            updateIncident({
                              variables: { id: incident.id, downTimeHrs },
                            })
                          }
                        />
                      </div>
                    </div>
                    <Button
                      variant="danger"
                      size="md"
                      disabled={closeDisabled}
                      onClick={() => {
                        signatureModalVar({
                          title: t(keys.action.REQUIRED, {
                            variable: t(keys.common.SIGNATURE),
                          }),
                          message: t(keys.incidents.CLOSE_INCIDENT_MESSAGE),
                          variant: 'warning',
                          buttonText: t(keys.incidents.CLOSE_INCIDENT),
                          additionalInput: true,
                          show: true,
                          onSubmit: (url, notes) => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.complete,
                                closerSignatureUrl: url,
                              },
                            }).then(() => {
                              reviewIncident({
                                variables: {
                                  incidentId: incident.id,
                                  status: incidentStatus.complete,
                                  notes,
                                },
                              });
                              setTimeout(() => {
                                showToast({
                                  title: incident.title,
                                  message: t(keys.incidents.INCIDENT_CLOSED),
                                  variant: toastVariant.info,
                                  time: toastLength.md,
                                });
                              }, 1000);
                            });
                          },
                        });
                      }}
                      value={`${t(keys.common.CLOSE)} ${
                        incidentName || 'Incident'
                      } `}
                    />
                    <br />
                  </>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <IncidentSidebar
        onClose={() => setSidebarOpen(false)}
        inspections={inspections}
        incidentImages={combinedImages}
        refetch={() => refetchIncident()}
        className={!sidebarOpen && isMobile && styles.hide}
        incident={incident}
        title={incident.title}
        notes={notes}
      />
    </div>
  );
}

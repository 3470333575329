import React, { useMemo, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './CountPage.module.scss';
import { Text } from '../../../components/typography';
import Assessments from './Assessments';
import Select from 'react-select';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import GraphDateSelectors from './GraphDateSelectors';
import { keys } from '../../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useQueryParams } from '../../../hooks/misc';
import { Button } from '../../../components';
import {
  analyticFilterParams,
  chartViews,
  statusStringConverter,
} from '../../../utilities/analytics';
import Incidents from './Incidents';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TextWithIcon from '../../../components/typography/TextWithIcon';
import Observations from './Observations';
import IncidentStatistics from './IncidentStatistics';
import subDays from 'date-fns/subDays';
import AssessmentStatistics from './AssessmentStatistics';
import CorrectiveActions from './CorrectiveActions';
import CorrectiveActionStatistics from './CorrectiveActionStatistics';
import ObservationStatistics from './ObservationStatistics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Boards from '../Boards';

export default function CountPage() {
  const location = useLocation();
  const { availableWorkspaces } = useWorkspace();
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [dateType, setDateType] = useState('last30');
  const today = new Date();
  const thirtyDays = subDays(today, 30);
  const [selectedDates, setSelectedDates] = useState({
    start: thirtyDays,
    end: today,
  });
  const [subheader, setSubheader] = useState([]);
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    company: { observationName, incidentName },
  } = user;
  const { upsertParams, deleteParam, upsertParam } = useQueryParams();

  const localFilters = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  }, [location.search]);

  const { charttab, view } = localFilters || {};

  const handleBackClick = () => {
    const v = view === '1' ? 1 : view - 1;
    const key = tabConfig[charttab].key;
    const paramToRemove = chartViews[key][v];
    deleteParam(paramToRemove);
    upsertParam('view', v);
  };

  const [reset, setReset] = useState(false);
  const { auditsAndCorrectiveActions, enableCountCharts } = useFlags();

  const tabConfig = [
    ...(enableCountCharts
      ? [
          {
            name: 'inspection',
            component: Assessments,
            title: 'Reporting',
            icon: 'inventory',
            stats: AssessmentStatistics,
            views: 3,
            key: 'ASSESSMENTS',
          },
          {
            name: 'incident',
            component: Incidents,
            title: incidentName || t(keys.incidents.INCIDENTS),
            stats: IncidentStatistics,
            icon: 'fmd_bad',
            views: 4,
            key: 'INCIDENTS',
          },
          {
            name: 'observation',
            component: Observations,
            title: observationName || t(keys.common.OBSERVATIONS),
            icon: 'content_paste_search',
            stats: ObservationStatistics,
            views: 3,
            key: 'OBSERVATIONS',
          },
        ]
      : []),
    ...(enableCountCharts && auditsAndCorrectiveActions
      ? [
          {
            name: 'correctiveAction',
            component: CorrectiveActions,
            title: 'Corrective Actions',
            icon: 'checklist',
            stats: CorrectiveActionStatistics,
            views: 2,
            key: 'CPAS',
          },
        ]
      : []),
    {
      name: 'boards',
      component: Boards,
      title: 'Events',
      icon: 'timeline',
      views: 3,
      key: 'EVENTS',
    },
  ];

  const workspaceString = !selectedWorkspaces.length
    ? t(keys.templates.ALL_WORKSPACES)
    : selectedWorkspaces.map((w) => w.label).join(', ');

  const workspaces = availableWorkspaces?.map((w) => ({
    value: w.id,
    label: w.title,
  }));

  const currentChartTab = tabConfig[charttab]?.key;

  const content = useMemo(() => {
    const currentTab = !!charttab ? tabConfig[charttab] : 0;

    let chart, stats;

    if (currentTab.component) {
      chart = React.createElement(currentTab.component, {
        startDate: selectedDates?.start,
        endDate: selectedDates?.end,
        workspaceIds: selectedWorkspaces?.map((w) => parseInt(w.value)) || [],
        isInteractive:
          tabConfig[charttab]?.views !== parseInt(localFilters.view),
        params: localFilters,
        view: localFilters.view || 1,
        status: Object.entries(localFilters)
          .map(([key, value]) => statusStringConverter(key, value))
          .filter(Boolean),
        header: workspaceString,
        subheader,
      });

      if (currentTab.stats) {
        stats = React.createElement(currentTab.stats, {
          start: selectedDates?.start,
          end: selectedDates?.end,
          workspaceIds: selectedWorkspaces?.map((w) => parseInt(w.value)) || [],
          dateType,
        });
      }
    }
    return { chart, stats };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    charttab,
    localFilters,
    selectedDates?.end,
    selectedDates?.start,
    selectedWorkspaces,
  ]);

  const handleTabChange = (event, newValue) => {
    const param = Object.keys(localFilters);
    param.forEach((p) => {
      if (!analyticFilterParams.includes(p)) {
        deleteParam(p);
      }
    });
    setSelectedWorkspaces([]);
    let params = {};
    const tab = tabConfig[newValue].key;
    if (tab === 'AUDITS') {
      params = {
        inspection_type: 'AUDIT',
      };
    }
    upsertParams({ ...params, charttab: newValue, view: 1 });
  };

  const isInitialView = useMemo(() => {
    return (
      selectedWorkspaces?.length === 0 &&
      dateType === 'last30' &&
      (view === '1' || !view)
    );
  }, [dateType, selectedWorkspaces?.length, view]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <TextWithIcon
            size="lg"
            icon={tabConfig[charttab]?.icon}
            weight="bold"
            noMargin
          >
            {tabConfig[charttab]?.title}
          </TextWithIcon>
          <Text weight="semiBold" color="secondary" noMargin></Text>
          <Text noMargin></Text>
        </div>
        <Tabs
          variant="scrollable"
          value={parseInt(charttab)}
          onChange={handleTabChange}
        >
          {tabConfig.map((charttab, index) => (
            <Tab
              className={styles.charttab}
              key={index}
              label={charttab.title}
            />
          ))}
        </Tabs>
      </div>
      <div className={styles.sectionLine} />
      <div className={styles.content}>
        <>{content.chart}</>
        {currentChartTab === 'EVENTS' ? null : (
          <div
            className={
              currentChartTab === 'EVENTS' ? styles.hide : styles.right
            }
          >
            {currentChartTab !== 'CPAS' ? (
              <>
                <Text noMargin weight="semiBold">
                  Workspaces
                </Text>
                <Select
                  placeholder="Select Workspaces"
                  options={workspaces}
                  value={
                    selectedWorkspaces.length ? selectedWorkspaces.value : null
                  }
                  onChange={(workspace) => {
                    setSelectedWorkspaces(workspace);
                  }}
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </>
            ) : null}
            <GraphDateSelectors
              setSubheader={setSubheader}
              setDates={setSelectedDates}
              reset={reset}
              setReset={setReset}
              setDateType={setDateType}
            />
            <Button
              icon="refresh"
              value="Reset"
              outlined
              disabled={isInitialView}
              className={styles.button}
              onClick={() => {
                navigate(`?charttab=${charttab}&view=1`);
                setSelectedDates({ start: thirtyDays, end: today });
                setDateType('last30');
                setSelectedWorkspaces([]);
                setReset(true);
              }}
            />
            {view !== '1' && !!view ? (
              <TextWithIcon
                leftIcon="arrow_left"
                weight="semiBold"
                color="accentPrimary"
                hover
                noMargin
                onClick={() => handleBackClick()}
              >
                Previous
              </TextWithIcon>
            ) : null}
          </div>
        )}
      </div>
      {content.stats ? <>{content.stats} </> : null}
    </div>
  );
}

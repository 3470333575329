import styles from './StatBox.module.scss';
import { Text } from '../../../components/typography';

export default function StatBox({
  kpi,
  stat,
  value,
  show,
  setShow,
  float = true,
  showUnits = false,
}) {
  const { title, description, formula, id, units, positive } = kpi || {};
  const getColor = () => {
    if ((positive === true && stat < 0) || (positive !== true && stat > 0)) {
      return 'red';
    }
    return 'green';
  };

  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.header}>
            <Text noMargin color="secondary">
              {id}
            </Text>
            <Text textAlign="right" weight="bold" noMargin color={getColor()}>
              {`${stat > 0 ? '+' : ''}${
                stat ? stat?.toFixed(float ? 2 : 0) : 0
              }`}
              <span className={styles.units}>{units}</span>
            </Text>
          </div>
          <div className={styles.middle}>
            <div className={styles.bottom}>
              <Text weight="bold" size="lg" noMargin>
                {value ? value?.toFixed(float ? 2 : 0) : 0}
                <span>{showUnits && units}</span>
              </Text>
              <Text
                weight="semiBold"
                noMargin
                size="sm"
                hover
                onClick={() => setShow(!show)}
              >
                {title}
              </Text>
            </div>
          </div>
        </div>
      </div>
      {show ? (
        <div className={styles.footer}>
          <Text noMargin size="sm" textAlign="center" weight="semiBold">
            {description}
          </Text>
          <Text color="secondary" textAlign="center" noMargin size="xs">
            {formula}
          </Text>
        </div>
      ) : null}
    </div>
  );
}

import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import CountPieChart from './CountPieChart';
import { useQueryParams } from '../../../hooks/misc';
import { useMemo } from 'react';
import { getLabel } from '../../../utilities/incidents';
import { chartViews } from '../../../utilities/analytics';

const incidentCountQuery = loader('./Incidents.fetch.graphql');

export default function Incidents({
  workspaceIds,
  startDate,
  endDate,
  params,
  view = 1,
  status,
  isInteractive,
  header,
  subheader,
}) {
  const { upsertParams } = useQueryParams();
  const filters = useMemo(() => {
    const f = [];
    if (params.type) {
      f.push({
        field: 'type',
        operator: 'eq',
        value: params.type,
      });
    }
    if (startDate && endDate) {
      f.push({
        field: 'dateOfIncident',
        operator: 'between',
        value: [startDate, endDate],
      });
    }
    if (params.status) {
      f.push({ field: 'status', operator: 'eq', value: params.status });
    }
    if (params.sub_type) {
      f.push({ field: 'subtype', operator: 'eq', value: params.sub_type });
    }
    return f;
  }, [endDate, params, startDate]);

  const { loading, data: { incidentsFilteredCount = [] } = {} } = useQuery(
    incidentCountQuery,
    {
      fetchPolicy: 'network-only',
      variables: {
        groupBy: chartViews.INCIDENTS[view] || 'is_draft',
        workspaceIds,
        options: {
          filters,
        },
      },
    },
  );

  const chartData = useMemo(
    () =>
      incidentsFilteredCount?.map((incident) => ({
        ...incident,
        label: getLabel(incident.id),
      })),
    [incidentsFilteredCount],
  );
  const total = incidentsFilteredCount.reduce(
    (accumulator, current) => accumulator + current.value,
    0,
  );

  return (
    <div>
      <CountPieChart
        data={chartData}
        isLoading={loading}
        isInteractive={view !== '4'}
        header={header}
        subheader={subheader}
        total={total}
        status={status}
        onClick={({ data }) => {
          if (!isInteractive) {
            return;
          }
          const target = chartViews.INCIDENTS[view] || 'type';
          const v = parseInt(view) + 1;
          upsertParams({ view: v, [target]: data.id });
        }}
      />
    </div>
  );
}

import { useState } from 'react';
import { Icon } from '@mui/material';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import styles from './ConditionalAction.module.scss';

export default function MessageAction({
  additional = '',
  onChange = () => {},
  onDelete = () => {},
  showDelete = false,
  readOnly = false,
}) {
  const [editMessage, setEditMessage] = useState(false);
  const [message, setMessage] = useState(additional || '');
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        !readOnly ? setEditMessage(!editMessage) : setExpanded(!expanded);
      }}
    >
      <div className={styles.messageContainer}>
        {!editMessage ? (
          <div className={styles.messageSubContainer}>
            <Icon
              className={styles.messageIcon}
              baseClassName="material-icons-outlined"
            >
              message
            </Icon>
            <Text noMargin className={!expanded ? styles.truncate : {}}>
              {additional}
            </Text>
          </div>
        ) : (
          <Form.Control
            onClick={(e) => e.stopPropagation()}
            value={message ?? additional}
            onChange={({ target: { value } }) => setMessage(value)}
            onBlur={() => onChange(message)}
          />
        )}
        {editMessage ? (
          <Icon
            baseClassName="material-icons-outlined"
            onClick={() => setEditMessage(!editMessage)}
          >
            close
          </Icon>
        ) : (
          showDelete && (
            <Icon
              className={styles.deleteIcon}
              baseClassName="material-icons-outlined"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              delete
            </Icon>
          )
        )}
      </div>
    </div>
  );
}
